import {apiService} from "../api.service";

export class ForgotPasswordService {
    public async sendEmail(values: {username: string}): Promise<void> {
        await apiService.post("forgot-password", {...values});
    }

    public async checkIfComplete(data: {token: string}): Promise<void> {
        await apiService.post("forgot-password/complete", {...data})
    }
}

export const forgotPasswordService = new ForgotPasswordService();