import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Date} from "../../../core/commons/date/date";
import {MergedInvoiceStoreContext} from "../merged.invoice.store";
import {useTranslation} from "react-i18next";
import {useVT} from "virtualizedtableforantd4";
import {ColumnsType} from "antd/lib/table";
import {MergedInvoiceIncludedInvoice} from "../../../../models/invoices/merged-invoice/merged.invoice";
import {displayPrice, splitNumber} from "../../../../models/amount/amount.helper";
import {Amount} from "../../../../models/amount/amount";
import { Link } from "react-router-dom";
import {ExpandedTable} from "../../../core/commons/expanded-table/expanded.table";

export const MergedInvoiceIncludedInvoices: React.FC = observer(() => {
    const store = useContext(MergedInvoiceStoreContext);
    const {t} = useTranslation("translation", {useSuspense: false});
    const [vt] = useVT(() => ({scroll: {y: 600}}), []);

    const columns: ColumnsType<MergedInvoiceIncludedInvoice> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "merged_invoice_included_invoices_id",
            width: 90,
            render: (id: number) => <Link className="link" to={"/invoices/" + id}>{id}</Link>
        },
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "merged_invoice_included_invoices_number",
            width: 110
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "merged_invoice_included_invoices_subtotal",
            width: 90,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "merged_invoice_included_invoices_subtotalVat",
            width: 80,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "merged_invoice_included_invoices_grandTotal",
            width: 100,
            render: (amount: Amount) => <span style={{fontWeight: "bold"}}>{displayPrice(amount)}</span>
        },
        {
            title: t("WEIGHT_BRUTTO"),
            dataIndex: "totalGrossWeight",
            key: "merged_invoice_included_invoices_totalGrossWeight",
            width: 90,
            render: (item: number) => splitNumber(item)
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "merged_invoice_included_invoices_createdAt",
            width: 100,
            render: (date: string) => <Date dateString={date}/>
        }
    ];

    const columnsConfig = [
        {
            title: "ID",
            key: "merged_invoice_included_invoices_id"
        },
        {
            title: "INVOICES.NUMBER",
            key: "merged_invoice_included_invoices_number"
        },
        {
            title: "TOTAL_PRICE",
            key: "merged_invoice_included_invoices_subtotal"
        },
        {
            title: "VAT.TITLE",
            key: "merged_invoice_included_invoices_subtotalVat"
        },
        {
            title: "GRAND_TOTAL_PRICE",
            key: "merged_invoice_included_invoices_grandTotal"
        },
        {
            title: "WEIGHT_BRUTTO",
            key: "merged_invoice_included_invoices_totalGrossWeight"
        },
        {
            title: "CREATED_AT",
            key: "merged_invoice_included_invoices_createdAt"
        }
    ];

    return <ExpandedTable<MergedInvoiceIncludedInvoice>
        title="INVOICE.MERGED.INCLUDED_INVOICES.TITLE"
        pageKey="merged_invoice_included_invoices"
        columns={columns}
        columnsConfig={columnsConfig}
        tableData={store.loading ? [] : [...store.invoice.invoices]}
        tableLoading={store.loading}
        isVirtualList={false}
    />
})
