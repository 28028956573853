import React, {useEffect, useState} from "react";
import {WidgetProps} from "../../../../../core/ui/widget/widget";
import {useTranslation} from "react-i18next";
import {Select} from "antd";
import {articlesService} from "../../../../../../services/articles/articles.service";
import {Submodel} from "../../../../../../models/articles/submodels/submodel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface UIInputWidgetEngineProps extends WidgetProps {
    modelId: number;
    onClear?: () => void;
    onSelect?: () => void;
}

const createValuesList = (values: Submodel[]): {[type: string]: {engineAndPower: string, id: number}[]} => {

    const engineTypes: {[type: string]: {engineAndPower: string, id: number}[]} = {};

    values.forEach(item => {
        if (!engineTypes[item.fuelType]) {
            engineTypes[item.fuelType] = [];
        }
        engineTypes[item.fuelType].push({engineAndPower: item.engineAndPower, id: item.id})
    });

    return engineTypes;
}

export const UIInputWidgetEngine: React.FC<UIInputWidgetEngineProps> = ({value, onChange, multiple, modelId, onSelect, onClear}) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const [values, setValues] = useState<{[type: string]: {engineAndPower: string, id: number}[]}>({});

    useEffect(() => {
        if (modelId) {
            articlesService.getSubmodels(modelId).then(values => setValues(createValuesList(values)))
        } else {
            setValues({})
        }
    }, [modelId])

    return (
        <Select
            suffixIcon={<FontAwesomeIcon icon={["fas", "angle-down"]} />}
            bordered={false}
            className="selects_block_select"
            placeholder={t("ARTICLES.SUBMODEL")}
            dropdownMatchSelectWidth={250}
            showSearch
            optionFilterProp={"children"}
            value={value}
            onChange={onChange}
            disabled={!modelId}
            allowClear
        >
            {Object.keys(values).map(type => (
                <Select.OptGroup key={type} label={type}>
                    {values[type].map(({id, engineAndPower}) => (
                        <Select.Option key={id} value={id}>{engineAndPower}</Select.Option>
                    ))}
                </Select.OptGroup>
            ))}
        </Select>
    )
}