import React, {useContext} from "react";
import {Button, Card} from "antd";
import {RootControllersContext} from "../../controllers/root/root.controllers";
import {RootStoreContext} from "../../stores/root/root";

export const GEDClosedComponent: React.FC = () => {

    const context = useContext(RootControllersContext);

    return <Card style={{width: '70%', margin: '150px auto'}}>
        <p><b>Dear Partner,</b></p>
        <p>We have to inform you that FEBEST Autoteile GmbH stopped its operation since October 1.</p>
        <p>Please contact our Estonian company - FEBEST Europe Distribution OÜ - with cooperation questions.</p>
        <p>Contact persons: Ivan Gorlovits - <a href="mailto:ivan@febest.eu">ivan@febest.eu</a>, Dmitrii Medvedev - <a href="mailto:dmitrii.medvedev@febest.eu">dmitrii.medvedev@febest.eu</a><br />Phone: +372 3370 170</p>
        <p>We do apologize for the inconvenience, and believe you will establish fruitful cooperation with FEBEST Europe Distribution, Estonia</p>
        <p><i>Best wishes,<br />Team of FEBEST Autoteile GmbH</i></p>
        <Button type={"primary"} onClick={context.loginController.logout}>Logout</Button>
    </Card>
}

export const SERClosedComponent: React.FC = () => {

    const context = useContext(RootControllersContext);

    return <Card style={{width: '70%', margin: '150px auto'}}>
        <p><b>Dear Partner,</b></p>
        <p>As FEBEST Auto Parts Balkans is closed since January 1, 2023, all further sales to Serbia will be made by <b>FEBEST Europe Distribution OÜ, Estonia </b>- the main European distributor of FEBEST auto parts.</p>
        <p>Please create an account in the B2B web shop - https://ws.febest.eu - to access prices and availability. We know you already registered there, but now the B2B system is re-directed to our company in Estonia. So, please, we kindly ask you to register again - it won't take much time, and we will do our best to activate your account asap.</p>
        <p>If you have questions, please send an email to sales@febest.eu or all call at +372 3370 170</p>
        <p><i>Best regards,<br />FEBEST Sales Team</i></p>
        <Button type={"primary"} onClick={context.loginController.logout}>Logout</Button>
    </Card>
}

export const ClosedComponent: React.FC = () => {

    const {branch} = useContext(RootStoreContext).authStore;

    const content: Record<string, React.FC> = {
        ged: GEDClosedComponent,
        ser: SERClosedComponent
    }

    if (!content[branch]) {
        return <>Branch is closed</>
    }

    const Component = content[branch];

    return <Component />
}