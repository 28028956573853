import {Row, Col, Table} from "antd";
import React from "react";
import "./double-details.table.footer.scss";
import {useTranslation} from "react-i18next";
import {displayPrice, splitNumber} from "../../../../models/amount/amount.helper";
import {NotCompletedOrderSummary} from "../../../../models/cart/checkout/not.completed.order";

interface DoubleDetailsTableFooterProps {
    backorderSummary: NotCompletedOrderSummary;
    stockSummary: NotCompletedOrderSummary;
    vatPercent: number;
}

export const DoubleDetailsTableFooter: React.FC<DoubleDetailsTableFooterProps> = ({
                                                                                   backorderSummary,
                                                                                   stockSummary,
                                                                                   vatPercent
                                                                               }) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const columns = [];
    columns.push({title: '', dataIndex: 'title'})
    if (backorderSummary.qty > 0) {
        columns.push({title: 'Back Order', dataIndex: 'backOrderValue'})
    }
    columns.push({title: 'Stock Order', dataIndex: 'stockValue'})

    return (
        <Row justify="end">
            <Col xxl={9} xl={10} md={12} sm={24} xs={24}>
                <Table
                    className={"order-checkout-table-summary"}
                    columns={columns}
                    dataSource={[
                        {
                            title: t("SHIPPED_QUANTITY"),
                            stockValue: splitNumber(stockSummary.qty) + t("PCS"),
                            backOrderValue: splitNumber(backorderSummary.qty) + t("PCS")
                        },
                        {
                            title: t("TOTAL_WEIGHT"),
                            stockValue: <>{String(stockSummary.totalNetWeight).replaceAll(".", ",")} ({t("KG")})</>,
                            backOrderValue: <>{String(backorderSummary.totalNetWeight).replaceAll(".", ",")} ({t("KG")})</>
                        },
                        {
                            title: t("TOTAL_PRICE"),
                            stockValue: displayPrice(stockSummary.subtotal),
                            backOrderValue: displayPrice(backorderSummary.subtotal)
                        },
                        {
                            title: "VAT(" + vatPercent + "%)",
                            stockValue: displayPrice(stockSummary.subtotalVat),
                            backOrderValue: displayPrice(backorderSummary.subtotalVat)
                        },
                        {
                            title: t("GRAND_TOTAL_PRICE"),
                            stockValue: displayPrice(stockSummary.grandTotal),
                            backOrderValue: displayPrice(backorderSummary.grandTotal)
                        }
                    ]}
                    pagination={false}
                >
                </Table>
            </Col>
        </Row>
    );
};
