import {AccountWithMeta} from "../../models/account/account";
import {apiService} from "../api.service";

export class AccountService {
    public async getAccountDetails(): Promise<AccountWithMeta> {
        return (await apiService.get<AccountWithMeta>("account")).data;
    }
}

export const accountService = new AccountService();
