import {AuthStore} from "../auth/auth.store";
import React, {Context} from "react";
import {LangStore} from "../lang/lang.store";
import {AccountStore} from "../account/account.store";
import {CommonStore} from "../common/common.store";
import {ConfirmStore} from "../confirm/confirm.store";

export class RootStore {
    public authStore: AuthStore =  new AuthStore();
    public langStore: LangStore = new LangStore();
    public accountStore: AccountStore = new AccountStore();
    public commonStore: CommonStore = new CommonStore();
    public confirmStore: ConfirmStore = new ConfirmStore();
}

export const RootStoreContext = React.createContext<RootStore | null>(null) as Context<RootStore>;
