import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {ContainerItem} from "../../models/container/container-item";

export class ContainersService {
    public async getAllItems(request: PaginatedRequest): Promise<PaginatedResult<ContainerItem>> {
        return (await apiService.get<PaginatedResult<ContainerItem>>("containers", {params: request.toParams()})).data
    }
}

export const containersService = new ContainersService();
