import React from "react";
import {Row, Select, Typography} from "antd";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useRequiredLayers} from "../../../utils/context";
import {RootStore, RootStoreContext} from "../../../stores/root/root";
import {RootControllers, RootControllersContext} from "../../../controllers/root/root.controllers";
import history from "../../../utils/history";
import logo from "./febest_ws_logo.svg"
import "./login.scss";

interface LoginWrapperProps {
    title: string
    children?: React.ReactNode
}
export const LoginWrapper: React.FC<LoginWrapperProps> = observer(({title, children}) => {
    const {t, i18n} = useTranslation('translation', {useSuspense: false});
    const {
        store: {langStore},
    } = useRequiredLayers<RootStore, RootControllers>(RootStoreContext, RootControllersContext);

    return (
        <div className="login-wrapper">
            <Row justify="center" align="middle" style={{height: "100vh"}} className="login-row">
                <div className="login-block">
                    <img style={{cursor: "pointer"}} onClick={() => history.push("/login")} alt={"Logo"} src={logo}/>
                    <div className="login-form-wrapper">
                        <Row justify="space-between" style={{width: "100%"}}>
                            <Typography.Title level={3}>{t(title)}</Typography.Title>
                            <Select
                                style={{width: "70px", height: "30px"}}
                                value={i18n.language}
                                onChange={(value: string) => i18n.changeLanguage(value)}
                                bordered={false}
                            >
                                {langStore.locales.map(locale => (
                                    <Select.Option key={locale} value={locale}>{locale.toUpperCase()}</Select.Option>
                                ))}
                            </Select>
                        </Row>
                        {children}
                    </div>
                </div>
            </Row>
        </div>
    )
})