import React, {LegacyRef, useEffect} from "react";
import {FilterItemState, FilterKey, FilterKeyOption, FilterTag, GroupedFilterKeyOption} from "../../../../../../models/filtered-table/filtered.table";
import {Button, DatePicker, Form, Input, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createLabel} from "../../../../../../models/filtered-table/filter.helper";
import {find} from "lodash";
import moment from "moment";

import "./filter.popup.scss";
import {useTranslation} from "react-i18next";

interface SelectedFilterProps {
    onAddCallback: (item: FilterTag) => void,
    close: () => void,
    editingTag: FilterTag | undefined,
    removeEditingTag: () => void,
    filter: FilterKey[]
}

const FilterPopup: React.FC<SelectedFilterProps> = ({onAddCallback, removeEditingTag, close, editingTag, filter}) => {
    const [form] = Form.useForm();
    let foundFilter: FilterKey | undefined;

    const popupRef: LegacyRef<HTMLDivElement> | undefined = React.createRef();
    const {t} = useTranslation('translation', {useSuspense: false});
    useEffect(() => {
        form.setFieldsValue(editingTag ? {
            key: editingTag.key,
            queryType: editingTag.queryType,
            values: editingTag.type === "date" && Array.isArray(editingTag.values) ? (
                editingTag.values.map((item: any) => moment(item, "DD-MM-YYYY"))
            ) : editingTag.values
        } : {queryType: "="});
    }, [editingTag]);


    useEffect(() => {
        const closePopupHandler = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if(!popupRef?.current?.contains(target) ){
                closePopup();
            }
        }

        document.addEventListener('mousedown', closePopupHandler)

        return () => {
            document.removeEventListener('mousedown', closePopupHandler)
        }
    }, []);




    const onAdd = (value: FilterItemState): void => {
        if (foundFilter) {

            if (foundFilter.type === "date" && Array.isArray(value.values)) {
                value.values = value.values.map((item: any) => item.format("YYYY-MM-DD"));
            }

            onAddCallback({
                key: value.key,
                values: value.values,
                queryType: value.queryType ?? "",
                label: createLabel(foundFilter, value, foundFilter.type),
                type: foundFilter.type
            });

        }
        form.resetFields();
        removeEditingTag();
    };

    const onClose = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        closePopup();
    };

    const closePopup = (): void =>{
        form.resetFields();
        removeEditingTag();
        close();
    }

    const displaySelectedFilters = (key: string): JSX.Element | null => {
        foundFilter = find(filter, {key: key});
        let selectedFilter = null;

        if (foundFilter) {
            selectedFilter = foundFilter.type;
        }

        if (selectedFilter === "int") {
            return (
                <>
                    <Form.Item className={'filterValueWithQueryTypeContainer'}>
                        <Input.Group compact>
                            <Form.Item
                                noStyle
                                name={"queryType"}
                                rules={[{
                                    required: false,
                                }]}
                            >
                                <Select
                                    placeholder={t("FILTER.SELECT_FIELD")}
                                    style={{width: '33%'}}
                                        getPopupContainer={() => popupRef.current as HTMLElement}
                                >
                                    <Select.Option value={"="}>=</Select.Option>
                                    <Select.Option value={"!="}>!=</Select.Option>
                                    {/*<Select.Option value={"--"}>Between</Select.Option>*/}
                                    <Select.Option value={">"}>{">"}</Select.Option>
                                    <Select.Option value={"=>"}>{"=>"}</Select.Option>
                                    <Select.Option value={"<"}>{"<"}</Select.Option>
                                    <Select.Option value={"<="}>{"<="}</Select.Option>
                                    <Select.Option value={""}>Extended</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={"values"}
                                noStyle
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                            >
                                <Input style={{width: "67%"}} placeholder={t("FILTER.VALUE")} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </>
            );
        }
        if (selectedFilter === "string") {
            return (
                <>
                    <Form.Item className={'filterValueWithQueryTypeContainer'}>
                        <Input.Group compact>
                            <Form.Item
                                name={"queryType"}
                                noStyle
                                rules={[{
                                    required: false,
                                }]}
                            >
                                <Select
                                    placeholder={t("FILTER.SELECT_FIELD")}
                                    style={{width: '33%'}}
                                        getPopupContainer={() => popupRef.current as HTMLElement}
                                >
                                    <Select.Option value={"="}>=</Select.Option>
                                    <Select.Option value={"!="}>!=</Select.Option>
                                    <Select.Option value={"%"}>Like</Select.Option>
                                    <Select.Option value={""}>Extended</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={"values"}
                                noStyle
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                            >
                                <Input style={{width: "67%"}} placeholder={t("FILTER.VALUE")} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </>
            );
        }
        if (selectedFilter === "date") {
            return (
                <Form.Item
                    className={'filterDatePicker'}
                    name={"values"}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                >
                    <DatePicker.RangePicker
                        style={{width: "100%"}}
                        format="DD.MM.YYYY"
                        inputReadOnly
                        getPopupContainer={() => popupRef.current as HTMLElement}
                    />
                </Form.Item>
            );
        }
        if (selectedFilter === "select") {
            return (
                <Form.Item
                    className={'filterValueSelector'}
                    name={"values"}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                >
                    <Select
                        mode="multiple"
                        placeholder="Select"
                        showSearch
                        optionFilterProp={"children"}
                        getPopupContainer={() => popupRef.current as HTMLElement}
                    >
                        {foundFilter && foundFilter.options
                            ? foundFilter.options.map((item: FilterKeyOption, index: number) => (
                                <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                            ))
                            : null
                        }
                    </Select>
                </Form.Item>
            );
        }
        if (selectedFilter === "groupedSelect") {
            return (
                <Form.Item
                    className={'filterValueSelector'}
                    name={"values"}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                >
                    <Select
                        placeholder="Select"
                        getPopupContainer={() => popupRef.current as HTMLElement}
                    >
                        {foundFilter && foundFilter.groupedOptions
                            ? foundFilter.groupedOptions.map((item: GroupedFilterKeyOption) => (
                                <Select.OptGroup key={item.groupName} label={item.groupName}>
                                    {item.options.map((group: any, index: number) => (
                                        <Select.Option key={index} value={group.value}>
                                            {group.label}
                                        </Select.Option>
                                    ))}
                                </Select.OptGroup>
                            ))
                            : null
                        }
                    </Select>
                </Form.Item>
            );
        }
        if (selectedFilter === "boolean") {
            return (
                <Form.Item
                    className={'filterValueSelector'}
                    name={"values"}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                >
                    <Select
                        placeholder="Select"
                        showSearch
                        optionFilterProp={"children"}
                        getPopupContainer={() => popupRef.current as HTMLElement}
                    >
                        <Select.Option value={"1"}>Yes</Select.Option>
                        <Select.Option value={"0"}>NO</Select.Option>
                    </Select>
                </Form.Item>
            );
        }

        return null;
    };

    return (
        <div className="filter-popup" ref={popupRef}>
            <Form
                layout={"inline"}
                form={form}
                onFinish={onAdd}
            >
                <Form.Item
                    className={'filterKeySelect'}
                    name={"key"}
                >
                    <Select
                        placeholder={t("FILTER.SELECT_FIELD")}
                        // style={{width: "200px"}}
                        onSelect={() => form.resetFields(["values"])}
                        getPopupContainer={() => popupRef.current as HTMLElement}
                    >
                        {filter.map((item, index) => (
                            <Select.Option
                                key={item.key}
                                value={item.key}
                            >
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item style={{width: "100%"}} noStyle
                           shouldUpdate={(prevValue, curValue) => prevValue.key !== curValue.key}
                >
                    {({getFieldValue}) => displaySelectedFilters(getFieldValue("key"))}
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prevValue, curValue) => prevValue.key !== curValue.key}>
                    {({getFieldValue}) => getFieldValue("key") ? (
                        <Form.Item className={"filterSubmitButton"}>
                            <Button
                                className="filter-btn"
                                style={{width: "100%"}}
                                htmlType="submit"
                                type="primary"
                                icon={<FontAwesomeIcon icon={["fas", "check"]}/>}
                            />
                        </Form.Item>
                    ) : null}
                </Form.Item>


                <Form.Item className={"filterCloseButton"}>
                    <Button
                        className="filter-btn"
                        style={{width: "100%"}}
                        danger
                        icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                        onClick={onClose}
                    />
                </Form.Item>
            </Form>
        </div>
    );
}

export default FilterPopup;
