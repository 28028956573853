import React, {useState} from "react";
import {Alert, Button, Skeleton, Space, Typography} from "antd";
import {observer} from "mobx-react";
import {DocumentsStore, DocumentsStoreContext, ExportModalType} from "./documents.store";
import {DocumentsController, DocumentsControllerContext} from "./documents.controller";
import {documentsService} from "../../services/documents/documents.service";
import {DocumentsDownloadButton} from "./documents-download-button/documents.download.button";
import "./documents.scss";
import "./documents-download-button/documents.download.button.scss";
import {warehousesService} from "../../services/warehouses/warehouses.service";
import {useTranslation} from "react-i18next";
import {AvailabilityExportModal} from "./availability-export-modal/availability-export-modal";
import {Date} from "../core/commons/date/date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Documents: React.FC = observer(() => {
    const [store] = useState(() => new DocumentsStore());
    const [controller] = useState(() => new DocumentsController(store, documentsService, warehousesService));

    const { t } = useTranslation("translation", { useSuspense: false });

    return (
        <DocumentsStoreContext.Provider value={store}>
            <DocumentsControllerContext.Provider value={controller}>
                <div className="page_content">
                    <Space direction="vertical" size={[30, 30]} style={{ width: "100%" }} className="content">
                        <div>
                            <Typography.Title level={2}>{t("DOCUMENTS.MASTER_FILES")}:</Typography.Title>
                            <Space direction="horizontal" size={[10, 10]} wrap>
                                {store.loading ? (
                                    <>
                                        <Skeleton.Button active />
                                        <Skeleton.Button active />
                                        <Skeleton.Button active />
                                        <Skeleton.Button active />
                                    </>
                                ) : (
                                    <>
                                        <DocumentsDownloadButton fileKey={"oem"} />
                                        <DocumentsDownloadButton fileKey={"cross-references"} />
                                        <DocumentsDownloadButton fileKey={"ktype"} />
                                        <DocumentsDownloadButton fileKey={"categories"} />
                                    </>
                                )}
                            </Space>
                        </div>
                        <div>
                            <Typography.Title level={2}>{t("DOCUMENTS.PHOTOS")}:</Typography.Title>
                            <Space direction="horizontal" size={[10, 10]} wrap>
                                {store.loading ? (
                                    <>
                                        <Skeleton.Button active />
                                        <Skeleton.Button active />
                                        <Skeleton.Button active />
                                    </>
                                ) : (
                                    <>
                                        <DocumentsDownloadButton fileKey={"photo-schemes-archive"} />
                                        <DocumentsDownloadButton fileKey={"photo-archive"} />
                                        <DocumentsDownloadButton fileKey={"master-photos"} />
                                    </>
                                )}
                            </Space>
                        </div>
                        <div>
                            <Typography.Title level={2}>
                                {t("DOCUMENTS.AVAILABILITY_PRICES.TITLE")}:
                            </Typography.Title>
                            <Space direction={"vertical"}>
                                <Space direction="horizontal" align="baseline" size={[10, 10]} wrap>
                                    {store.loading ? (
                                        <>
                                            <Skeleton.Button active />
                                            <Skeleton.Button active />
                                        </>
                                    ) : (
                                        <>
                                            <a
                                                href="/api/v3/articles/export-with-defaults"
                                                rel="noopener noreferrer"
                                                className="documents-link"
                                            >
                                                {t("DOWNLOAD.DO_DOWNLOAD")}
                                            </a>
                                            <Button onClick={() => controller.openModal(ExportModalType.BASIC)} className="documents-link">
                                                {t("DOCUMENTS.DOWNLOAD")}
                                            </Button>
                                        </>
                                    )}
                                </Space>
                                {store.documents.availability?.nextPriceScheduledAt ? (
                                    <Alert
                                        message={t("DOCUMENTS.AVAILABILITY_PRICES.NEW_PRICES.TITLE")}
                                        description={<>{t("DOCUMENTS.AVAILABILITY_PRICES.NEW_PRICES.DESCR")} <Date dateString={store.documents.availability.nextPriceScheduledAt} /></>}
                                        type="error"
                                        showIcon
                                        icon={<FontAwesomeIcon icon={["fas", "tag"]}/>}
                                    />
                                ) : null}
                            </Space>
                        </div>
                    </Space>
                    {store.exportModalShown ? (
                        <AvailabilityExportModal store={store} controller={controller} />
                    ) : null}
                </div>
            </DocumentsControllerContext.Provider>
        </DocumentsStoreContext.Provider>
    );
});
