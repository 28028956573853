import {action, computed, makeObservable, observable} from "mobx";
import {Access} from "../../../models/accesses/access";
import React from "react";
import {SystemWithGroups} from "../../../models/system/system.groups";

export class AccessesStore {
    @observable
    private _isLoading: boolean = false;

    @observable
    private _userAccesses: Access[] = [];

    @observable
    private _userSystemGroups: SystemWithGroups[] = [];

    constructor() {
        makeObservable(this);
    }

    private set isLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }

    private set userAccesses(value: Access[]) {
        this._userAccesses = value;
    }

    @computed
    public get accesses(): Access[] {
        return this._userAccesses;
    }

    @action
    public setAccesses(value: Access[]): void {
        this.userAccesses = value;
    }

    @action
    public editAccess(index: number, value: Access): void {
        this._userAccesses[index] = value;
    }

    private set userSystemGroups(value: SystemWithGroups[]) {
        this._userSystemGroups = value;
    }

    @computed
    public get systemWithGroups(): SystemWithGroups[] {
        return this._userSystemGroups;
    }

    @action
    public setSystemGroups(value: SystemWithGroups[]): void {
        this.userSystemGroups = value;
    }
}

export const AccessesStoreContext = React.createContext<null | AccessesStore>(null) as React.Context<AccessesStore>;