import moment from "moment";
import {Amount} from "../amount/amount";
import {SystemUser} from "../user/user";
import {Article} from "../articles/article";
import {createFileList} from "../../components/claims/view/part/images/file_list.factory";
import {CustomUploadFile} from "../../components/core/commons/file-uploader/file-uploader";
import {SearchedMergedInvoice, SearchedMergedInvoiceItem} from "../invoices/merged-invoice/merged.invoice";

export enum ClaimType
{
    RETURN = "return",
    RECLAMATION = "reclamation"
}

export enum ClaimStatus
{
    RECEIVED = "received",
    ACCEPTED = "accepted",
    CLOSED    = "closed",
}

export type ClaimStatusColor = Record<ClaimStatus, string>;

export const claimStatusColor: ClaimStatusColor =
{
    received: "gold",
    accepted: "green",
    closed  : "red",
}

export enum ClaimItemAcceptStatus
{
    RECEIVED = "received",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
    CLARIFICATION_AWAITING = "clarification_awaiting",
}

export type ClaimItemAcceptStatusColor = Record<ClaimItemAcceptStatus, string>;

export const claimItemAcceptStatusColor: ClaimItemAcceptStatusColor =
{
    received: "gold",
    accepted: "green",
    rejected: "red",
    clarification_awaiting: "gold",
}

export const claimItemAcceptCardColor: ClaimItemAcceptStatusColor =
{
    received: "#d48806",
    accepted: "#b7eb8f",
    rejected: "#cf1322",
    clarification_awaiting: "#d48806",
}

export interface Claim
{
    id: number;
    type: ClaimType;
    status: ClaimStatus;
    documentNumber: string;
    documentDate: string;
    subTotal: Amount;
    taxTotal: Amount;
    grandTotal: Amount;
    comments: ClaimComment[]
    items: ClaimItem[]
}

export interface ClaimComment
{
    id: string;
    claim: Claim;
    user: SystemUser;
    viewToClient: boolean;
    text: string;
    createdAt: string;
}

export interface ClaimItem
{
    id: number;
    claim: Claim;
    article: Article;
    attachments: ClaimItemAttachment[]
    status: ClaimItemAcceptStatus;
    invoiceDocumentNumber: string;
    invoiceDate: string;
    qty: number;
    priceByItem: Amount;
    description: string;
    fittingDate: string;
    fittingOnMillage: number;
    problemDate: string;
    problemOnMillage: number;
    carBrand: string;
    carModel: string;
    carModelClarification: string;
    carModelYear: number;
    vinNumber: string;
    hasCrashedBoxRetention: boolean;
    hasBadPackingRetention: boolean;
    rejectionReason: ClaimItemRejectReason | "";
    rejectionExplanation: string;
}

export interface ClaimItemAttachment
{
    id: number;
    fileName: string;
    hash: string;
}

// Commands

export interface ClaimStatusUpdate
{
    status: ClaimStatus;
}

export interface ClaimAddComment
{
    viewToClient: boolean;
    text: string;
}

export interface CreateClaim
{
    items: CreateClaimItem[]
    type: ClaimType
}

export type CreateClaimItemType = "known" | "unknown";

export interface CreateClaimItem {
    type?: CreateClaimItemType;
    data?: CreateClaimItemData;
    clarification?: CreateClaimItemClarification;
}

export type CreateClaimItemData = CreateClaimItemWithInvoice | CreateClaimItemWithoutInvoice;

export interface CreateClaimItemWithInvoice
{
    sku: string;
    qty: number;
    description: string;
    images?: CustomUploadFile[];
    invoice?: SearchedMergedInvoice;
    item?: SearchedMergedInvoiceItem;
}

export interface CreateClaimItemWithoutInvoice
{
    sku: string;
    invoiceNumber: string;
    invoiceDate: moment.Moment;
    qty: number;
    price: number;
    description: string;
    images?: CustomUploadFile[];
}

export interface EditClaimItem {
    data?: EditClaimItemData;
    clarification?: CreateClaimItemClarification;
}

export interface EditClaimItemData {
    qty: number;
    description: string;
}

export interface CreateClaimItemClarification
{
    fittingDate?: moment.Moment;
    fittingOnMillage?: number;
    problemDate?: moment.Moment;
    problemOnMillage?: number;
    carBrand?: string;
    carModel?: string;
    carModelClarification?: string;
    carModelYear?: number;
    vinNumber?: string;
    hasCrashedBoxRetention?: boolean;
    hasBadPackingRetention?: boolean;
}

export enum ClaimItemRejectReason
{
    INSUFFICIENT_DESC = 'insufficient_desc',
    NEED_PHOTO = 'need_photo',
    NEED_VIDEO = 'need_video',
    WRONG_MOUNTING = 'wrong_mounting',
    WARRANTY_EXPIRED = 'warranty_expired',
    NOT_PURCHASED = 'not_purchased',
    OTHER = 'other',
}

export interface ClaimItemReject
{
    reason: ClaimItemRejectReason;
    explanation: string;
}

export const createEditRequestFromClaimItem = (item: ClaimItem): EditClaimItem => {
    return {
        data: {
            qty: item.qty,
            description: item.description,
        },
        clarification: {
            fittingDate: moment(item.fittingDate),
            fittingOnMillage: item.fittingOnMillage,
            problemDate: moment(item.problemDate),
            problemOnMillage: item.problemOnMillage,
            carBrand: item.carBrand,
            carModel: item.carModel,
            carModelClarification: item.carModelClarification,
            carModelYear: item.carModelYear,
            vinNumber: item.vinNumber,
            hasCrashedBoxRetention: item.hasCrashedBoxRetention,
            hasBadPackingRetention: item.hasBadPackingRetention,
        }
    }
}