import React from "react";
import {Card} from "antd";
import {PlannerTaskComment} from "../../../../models/issues/issues";
import {MarkdownViewer} from "../../../core/markdown/markdown-viewer/markdown-viewer";
import {Date} from "../../../core/commons/date/date";

interface TaskCommentProps {
    comment: PlannerTaskComment;
}

export const TaskComment: React.FC<TaskCommentProps> = ({comment}) => {
    return (
        <Card
            title={comment.author.firstName + ' ' + comment.author.lastName}
            extra={<i><Date dateString={comment.createdAt} showTime /></i>}
            size={"small"}
        >
            <MarkdownViewer>
                {comment.content}
            </MarkdownViewer>
        </Card>
    )
}