import React, {useEffect} from "react";
import {Skeleton} from "antd";
import {Amount} from "../../../../models/amount/amount";
import {useTranslation} from "react-i18next";
import {displayPrice} from "../../../../models/amount/amount.helper";
import {useRequiredLayers} from "../../../../utils/context";
import {OrderDataStore, OrderDataStoreContext} from "../order.data.store";
import {OrderDataController, OrderDataControllerContext} from "../order.data.controller";
import {generateDetailsData} from "../../../../models/orders/order-data/order-data-helper/order.data.helper";
import {observer} from "mobx-react";
import {displayCustomPrice} from "../../../../utils/helpers";
import {OrderDetailsData} from "../../../../models/orders/order-data/order.data";
import i18n from "i18next";
import {ColumnsType} from "antd/lib/table";
import {ExpandedTable} from "../../../core/commons/expanded-table/expanded.table";
import { Link } from "react-router-dom";
import {DoubleDetailsTableFooter} from "../../../core/commons/double-details-table-footer/double-details.table.footer";


export const Details: React.FC = observer(() => {
    const {store, controller} = useRequiredLayers<OrderDataStore, OrderDataController>(OrderDataStoreContext, OrderDataControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});

    useEffect(() => {
        i18n.on("languageChanged", controller.onLngChange);
        return () => i18n.off("languageChanged", controller.onLngChange)
    }, [])

    const columns: ColumnsType<OrderDetailsData> = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "order_detail_articleName",
            width: "135px",
            className: "expanded-table-table-header",
            render: (item: string) => {
                return item ? item.toUpperCase() === "DELIVERY" ? t("DELIVERY.TITLE") : (
                    <Link to={"/catalog/article/" + item} className="link article-link">{item}</Link>
                ) : null
            }
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "articleDescription",
            key: "order_detail_articleDescription",
            width: "30%",
            className: "expanded-table-table-header"
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "order_detail_price",
            className: "expanded-table-table-header",
            render: (item: Amount | undefined, row: OrderDetailsData) => item ? displayCustomPrice({price: row.price}) : null
        },
        {
            title: t("ORDERS.REQUESTED_QUANTITY"),
            dataIndex: "qtyRequested",
            key: "order_detail_qtyRequested",
            className: "expanded-table-table-header",
        },
        {
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "order_detail_quantity",
            className: "expanded-table-table-header",
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "order_detail_totalPrice",
            className: "expanded-table-table-header",
            render: (item: Amount | undefined) => item ? <>{displayPrice(item)}</> : null
        }
    ];

    const columnsConfig = [
        {
            title: "ARTICLES.TITLE_SINGLE",
            key: "order_detail_articleName"
        },
        {
            title: "DESCRIPTION",
            key: "order_detail_articleDescription"
        },
        {
            title: "PRICE",
            key: "order_detail_price"
        },
        {
            title: "ORDERS.REQUESTED_QUANTITY",
            key: "order_detail_qtyRequested"
        },
        {
            title: "QUANTITY",
            key: "order_detail_quantity"
        },
        {
            title: "TOTAL_AMOUNT",
            key: "order_detail_totalPrice"
        },
    ]


    return (
        <ExpandedTable<OrderDetailsData>
            title="ORDERS.ORDER.DETAILS.TITLE"
            pageKey="order"
            columns={columns}
            columnsConfig={columnsConfig}
            defaultHiddenColumns={["order_detail_nettoWeight"]}
            tableData={store.isLoading ? [] : generateDetailsData(store.order!.items)}
            tableLoading={store.isLoading}
            tableFooter={store.isLoading ?
                <Skeleton active/> :
                <DoubleDetailsTableFooter
                    backorderSummary={store.order!.backorderSummary}
                    stockSummary={store.order!.stockSummary}
                    vatPercent={store.order!.vatPercent}
                />
            }
        />
    )
});
