import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Date } from "../date/date";

interface CustomCardProps {
    data: { [key: string]: any };
    rowKeys: { key: string; label?: string, render?: (value: any) => React.ReactElement }[];
}
export const CustomCard: React.FC<CustomCardProps> = observer(({ data, rowKeys }) => {
    const { t } = useTranslation("translation", { useSuspense: false });

    return (
        <table>
            <tbody>
                {rowKeys.map(({ key, label, render }) =>
                    data[key] ? (
                        <tr key={key}>
                            <td
                                style={{
                                    padding: "1px 13px 1px 1px",
                                    color: "#939393",
                                }}
                            >
                                {t(label ? label : key.toUpperCase())}:
                            </td>
                            <td style={{ padding: "1px 3px 1px 1px" }}>
                                {data[key] ? render ? render(data[key]) : data[key] : "-"}
                            </td>
                        </tr>
                    ) : null
                )}
            </tbody>
        </table>
    );
});
