import React from "react";
import {Row, Typography, Card, Col, Skeleton} from "antd";
import {OrderDataController, OrderDataControllerContext} from "../order.data.controller";
import {OrderDataStore, OrderDataStoreContext} from "../order.data.store";
import {useRequiredLayers} from "../../../../utils/context";
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../core/commons/custom-card/custom.card";
import {observer} from "mobx-react";

export const OrderCredentials: React.FC = observer(() => {
    const {
        store
    } = useRequiredLayers<OrderDataStore, OrderDataController>(OrderDataStoreContext, OrderDataControllerContext);
    const {t} = useTranslation("translation", {useSuspense: false});
    return (
        <div className="order_credentials">
            <Typography.Title level={3}>{t("PAYER_SELLER_DETAILS.TITLE")}</Typography.Title>
            <Row gutter={[20, 20]}>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Card
                        className="card"
                        title={
                            <Typography.Text title={t("PAYER_SELLER_DETAILS.PAYER_DETAILS")}>
                                {t("PAYER_SELLER_DETAILS.PAYER_DETAILS")}:
                            </Typography.Text>
                        }
                        bordered={false}
                        size="small"
                    >
                        {store.isLoading ? (
                            <Skeleton active paragraph={{rows: 5, width: "100%"}} title={false}/>
                        ) : (
                            <CustomCard
                                data={{
                                    name: store.order!.payer.companyName,
                                    address: [store.order!.payer.address, store.order!.payer.index, store.order!.payer.city, store.order!.payer.region, store.order!.payer.country.originalName].filter(item => item.trim() !== "").join(", "),
                                    vat: store.order!.payer.vat,
                                    phone: store.order!.payer.phone,
                                    email: store.order!.payer.email
                                }}
                                rowKeys={[{key: "name"}, {key: "address"}, {key: "vat", label: "VAT.TITLE"}, {key: "phone"}, {key: "email"}]}
                            />

                        )}
                    </Card>
                </Col>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Card
                        className="card"
                        title={
                            <Typography.Text title={t("PAYER_SELLER_DETAILS.RECEIVER_DETAILS")}>
                                {t("PAYER_SELLER_DETAILS.RECEIVER_DETAILS")}:
                            </Typography.Text>
                        }
                        size="small"
                    >
                        {store.isLoading ? (
                            <Skeleton active paragraph={{rows: 5, width: "100%"}} title={false}/>
                        ) : (
                            <CustomCard
                                data={{
                                    name: store.order!.receiver.companyName,
                                    address: [store.order!.receiver.address, store.order!.receiver.index, store.order!.receiver.city, store.order!.receiver.region, store.order!.receiver.country.originalName].filter(item => item.trim() !== "").join(", "),
                                    vat: store.order!.receiver.vat,
                                    phone: store.order!.receiver.phone,
                                    email: store.order!.receiver.email
                                }}
                                rowKeys={[{key: "name"}, {key: "address"}, {key: "vat", label: "VAT.TITLE"}, {key: "phone"}, {key: "email"}]}
                            />

                        )}
                    </Card>
                </Col>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Card
                        className="card"
                        title={
                            <Typography.Text title={t("PAYER_SELLER_DETAILS.SELLER_DETAILS")}>
                                {t("PAYER_SELLER_DETAILS.SELLER_DETAILS")}:
                            </Typography.Text>
                        }
                        size="small"
                    >
                        {store.isLoading ? (
                            <Skeleton active paragraph={{rows: 3, width: "100%"}} title={false}/>
                        ) : (
                            <CustomCard
                                data={{
                                    name: store.order!.seller.name,
                                    vat: store.order!.seller.vatNumber,
                                    address: [store.order!.seller.street, store.order!.seller.zip, store.order!.seller.city, store.order!.seller.country.originalName].filter(item => item.trim() !== "").join(", ")
                                }}
                                rowKeys={[{key: "name"}, {key: "vat", label: "VAT.TITLE"}, {key: "address"}]}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
});
