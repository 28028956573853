import React from "react";
import {DocumentsService} from "../../services/documents/documents.service";
import {WarehousesService} from "../../services/warehouses/warehouses.service";
import {DocumentsStore, ExportModalType} from "./documents.store";

export class DocumentsController {
    constructor(
        private readonly _documentsStore: DocumentsStore,
        private readonly _documentsService: DocumentsService,
        private readonly _warehousesService: WarehousesService
    ) {
        this.init();
    }

    private async init(): Promise<void> {
        this._documentsStore.setLoading(true);
        this._documentsStore.setDocuments(await this._documentsService.getDocuments());
        const warehouses = await this._warehousesService.getWarehouses();
        this._documentsStore.setWarehouses(warehouses);
        this._documentsStore.setLoading(false);
    }

    public closeModal = (): void => {
        this._documentsStore.setExportModalShown(false);
    };

    public openModal = (type: ExportModalType): void => {
        this._documentsStore.setExportModalShown(true, type);
    };

    public setSubmitting = (value: boolean): void => {
        this._documentsStore.setSubmitting(value);
    };

    public getExportDefaultValues = async () => {
        const config = await this._documentsService.getExportConfig();
        if (config) {
            return { ...config };
        }
        return {
            language: "en",
            config: { withHeaders: true, format: "xlsx" },
            fields: [{ name: "", visibleName: "" }],
            save: false,
        };
    };

    public export = async (values: any) => {
        const reqValues = {
            ...values,
            fields: values.fields.slice().map((item: any, index: number) => ({ ...item, sort: index })),
            save: values.save !== undefined || values.save !== null ? values.save : false,
        };

        if (this._documentsStore.exportModalType === ExportModalType.BASIC) {
            return await this._documentsService.exportArticles(reqValues);
        } else {
            return await this._documentsService.exportNextMonthArticles(reqValues);
        }
    };
}

export const DocumentsControllerContext = React.createContext<null | DocumentsController>(
    null
) as React.Context<DocumentsController>;
