import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useRequiredLayers} from "../../../../../utils/context";
import {Alert, Card, Col, Row, Skeleton, Typography} from "antd";
import {CartCheckoutController, CartCheckoutControllerContext} from "../../cart.checkout.controller";
import {CartCheckoutShippingsCard} from "./cart-checkout-shippings-card/cart.checkout.shippings.card";
import {NotCompletedOrder} from "../../../../../models/cart/checkout/not.completed.order";
import {CartCheckoutStore, CartCheckoutStoreContext} from "../../cart.checkout.store";


interface CartCheckoutShippingsProps {
    order: NotCompletedOrder
}
export const CartCheckoutShippings: React.FC<CartCheckoutShippingsProps> = observer(({order}) => {
    const {store, controller} = useRequiredLayers<CartCheckoutStore, CartCheckoutController>(CartCheckoutStoreContext, CartCheckoutControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});

    useEffect(() => {
        controller.getShipping(order);
    }, [])

    return (
        <>
            <Typography.Title level={3}>{t("CART.DELIVERY.OPTIONS.TITLE")}</Typography.Title>
            <Row gutter={[30, 30]}>
                {store.shippings[order.id] ? store.shippings[order.id].map(shipping => (
                    <Col className="card-col" key={shipping.id} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <CartCheckoutShippingsCard shipping={shipping} order={order}/>
                    </Col>
                )) : (
                    <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Card
                            className="card"
                            title={<Skeleton active paragraph={{rows: 1, width: "100%"}} title={false}/>}
                        >
                            <Skeleton active paragraph={{rows: 3, width: "100%"}} title={false}/>
                        </Card>
                    </Col>
                )}
                {store.shippingsNotFound[order.id] ? (
                    <Col span={24}>
                        <Alert type={"error"} message={t("CART.DELIVERY.OPTIONS.NOT_FOUND")}/>
                    </Col>
                ) : null}
            </Row>
        </>
    );
})
