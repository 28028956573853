import React, {ReactNode, useEffect, useState} from "react";
import {Pagination, Space} from "antd";
import {useTranslation} from "react-i18next";
import {PaginatedRequest} from "../../../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../../../models/requests/paginated-result/paginated.result";

export interface PaginatorProps<T> {
    dataLoader: (request: PaginatedRequest) => Promise<PaginatedResult<T>>
    children: (value: T[]) => React.ReactNode;
    noDataMessage: ReactNode;
}

export const Paginator = <T extends unknown>({dataLoader, children, noDataMessage}: PaginatorProps<T>) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const [total,  setTotal]  = useState<number>(0);
    const [page,   setPage]   = useState<number>(1);
    const [limit,  setLimit]  = useState<number>(20);
    const [data,   setData]   = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const reload = () => {
        setLoaded(false);
        const request = new PaginatedRequest();
        request.page = page;
        request.limit = limit;
        dataLoader(request).then(({items, meta}) => {
            setTotal(meta.total);
            setPage(meta.page);
            setLimit(meta.limit);
            setData(items);
            setLoaded(true);
        })
    }

    useEffect(() => {
        reload();
    }, [page, limit])

    if (!loaded) {
        return <></>;
    }

    if (data.length === 0) {
        return <>{noDataMessage}</>;
    }

    return (
        <Space direction={"vertical"} style={{width: '100%'}}>
            <div style={{marginTop: 10}}>
                {children(data)}
            </div>
            <Pagination
                style={{marginTop: 10}}
                total={total}
                current={page}
                pageSize={limit}
                pageSizeOptions={["10", "20", "50", "100"]}
                showSizeChanger
                showQuickJumper
                onChange={page => setPage(page)}
                onShowSizeChange={(_, size) => setLimit(size)}
                showTotal={(total) => t("TOTAL_ITEMS") + ": " + total}
            />
        </Space>
    );
}