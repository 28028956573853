import {ShipmentWithTrackingUrl, TableShipment} from "./order.shipments.model";

export const convertDataForTable = (shipments: ShipmentWithTrackingUrl[]): TableShipment[] => {
    return shipments.map((shipment, index) => {
        const firstParcel = shipment.parcels && shipment.parcels[0] ? shipment.parcels[0] : null;
        const otherParcels =
            shipment.parcels && shipment.parcels.length > 1 ? shipment.parcels.slice(1) : undefined;

        return {
            id: shipment.id + "",
            isShipment: true,
            reference: shipment.reference,
            warehouse: shipment.warehouse,
            carrier: shipment.carrier.name,
            carrierCode: shipment.carrier.id,
            packedAt: shipment.packedAt,
            status: shipment.status,
            parcelId: firstParcel!.id,
            trackCode: firstParcel ? firstParcel.trackCode : "",
            type: firstParcel ? firstParcel.type : "",
            number: firstParcel ? firstParcel.number + "" : "",
            trackingUrl: firstParcel ? firstParcel.trackingUrl : "",
            children: otherParcels
                ? otherParcels.map((parcel) => ({
                    id: "CHILD" + shipment.id + "_" + parcel.id + parcel.boxCode + parcel.trackCode,
                    isShipment: false,
                    parcelId: parcel.id,
                    trackCode: parcel.trackCode,
                    type: parcel.type,
                    number: parcel.number + "",
                    packedAt: null,
                    parentId: shipment.id + "",
                }))
                : undefined,
        };
    });
}