import React, {useState} from "react";
import {Button, Col, Row} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Article} from "../../../../models/articles/article";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Link} from "react-router-dom";
import {ArticleCardPopup} from "../../article-card-popover/catalog.card.popup";

interface AddToCartProps {
    article: Article;
    inCart: boolean;
    inCartQty: number;
    addToCart: (article: Article, qty: number) => void;
    removeFromCart: (article: Article) => void;
    title: string;
    inCartTitle: string;
    goToTitle: string;
    goToLink: string;
    icon: IconProp;
    additionalClass?: string;
}

export const AddToCart: React.FC<AddToCartProps> = ({article, inCart, inCartQty, inCartTitle, goToTitle, goToLink, addToCart, removeFromCart, title, icon, additionalClass = ""}) => {

    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    return (
        <Row gutter={[10, 10]}>
            <Col>
                <div className="popup-btns-wrapper" style={{marginLeft: 40}}>
                    {popupVisible ? (
                        <ArticleCardPopup
                            article={article}
                            addToCart={addToCart}
                            deleteFromCart={removeFromCart}
                            inCartQty={inCartQty}
                            close={() => setPopupVisible(false)}
                            style={{top: 42, left: 0}}
                        />
                    ) : null}
                    {inCart ? (
                        <Button
                            className={"article_cart-btn in-cart" + (additionalClass ? (" " + additionalClass) : "")}
                            icon={<FontAwesomeIcon style={{marginRight: 8}} icon={icon}/>}
                            onMouseDown={() => {
                                if (!popupVisible) {
                                    setPopupVisible(true);
                                }
                            }}
                        >
                            {inCartTitle} ({inCartQty})
                        </Button>
                    ) : (
                        <Button
                            className={"article_cart-btn" + (additionalClass ? (" " + additionalClass) : "")}
                            icon={<FontAwesomeIcon style={{marginRight: 8}} icon={icon}/>}
                            onMouseDown={() => {
                                if (!popupVisible) {
                                    setPopupVisible(true);
                                }
                            }}
                        >
                            {title}
                        </Button>
                    )}
                </div>
            </Col>
            {inCart ? (
                <Col>
                    <Link to={goToLink}>
                        <Button
                            className={"article_cart-btn" + (additionalClass ? (" " + additionalClass) : "")}
                            icon={<FontAwesomeIcon style={{marginRight: 8}} icon={["fas", "arrow-right"]}/>}
                        >
                            {goToTitle}
                        </Button>
                    </Link>
                </Col>
            ) : null}
        </Row>

    )
}