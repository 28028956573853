import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {useRequiredLayers} from "../../../../utils/context";
import {Contact} from "../../../../models/contacts/contact";
import {Button, Card, Col, Popconfirm, Space, Tooltip} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {ContactsModal} from "../contacts-modal/contacts.modal";
import {CustomCard} from "../../../core/commons/custom-card/custom.card";
import {ContactsControllerContext} from "../contacts.controller";


interface ContactItemProps {
    contact: Contact
}
export const ContactItem: React.FC<ContactItemProps> = observer(({contact}) => {
    const controller = useContext(ContactsControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    const cardDataRows = [
        {key: "firstName"},
        {key: "lastName"},
        {key: "position"},
        {key: "email"},
        {key: "workPhone"},
        {key: "mobilePhone"},
    ]

    return (
        <>
            <Col className="card-col" xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Space direction="horizontal" className="buttons-wrapper" size={[5, 5]}>
                    <Tooltip key={1} placement="topRight" title={t("EDIT")}>
                        <Button
                            onClick={() => setModalShown(true)}
                            className="card-btn btn"
                            type="default"
                            size={"small"}
                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                        />
                    </Tooltip>
                    <Popconfirm
                        key={2}
                        title={t("ARE_YOU_SURE")}
                        onConfirm={() => controller.removeContact(contact)}
                        placement="top"
                        okText={t("YES")}
                        cancelText={t("NO")}
                    >
                        <Tooltip placement="topRight" title={t("REMOVE")}>
                            <Button className="btn-remove card-btn btn" type="default" size={"small"} icon={<FontAwesomeIcon icon={["fas", "times"]}/>}/>
                        </Tooltip>
                    </Popconfirm>
                </Space>
                <Card
                    style={{paddingTop: 10}}
                    className="card"
                    bordered={false}
                    size="small"
                >
                    <CustomCard data={contact} rowKeys={cardDataRows}/>
                </Card>
            </Col>
            <ContactsModal
                visible={modalShown}
                onClose={() => setModalShown(false)}
                contact={contact}
            />
        </>
    );
})
