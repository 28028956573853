import { LoginController } from "../../../../controllers/login/login.controller";
import { AuthStore } from "../../../../stores/auth/auth.store";
import { UserService } from "../../../../services/user/user.service";
import { SearchedUser } from "../../../../models/user/user";

export class FakerController {
    constructor(
        private readonly _authStore: AuthStore,
        private readonly _authController: LoginController,
        private readonly _userService: UserService
    ) {
        this.switchUser = this.switchUser.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
    }

    public async switchUser(values: { user?: { value: string } }): Promise<void> {
        const user = values.user?.value || null;
        if (user) {
            await this._userService.switchUser(user);
        } else {
            await this._userService.resetUser();
        }
        window.location.href = "/";
    }

    public async searchUsers(value: string) {
        const response: SearchedUser[] = await this._userService.searchUser(value, "wholesale");
        return response.map((item) => ({
            value: item.email,
            label: item.firstName + " " + item.lastName,
            key: item.id,
        }));
    }
}
