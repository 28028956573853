import React from "react";
import { RouteConfig } from "../../../routers/routers";
import { Switch } from "react-router-dom";
import { ExtendedRoute } from "./extended.route";

interface ExtendedSwitchProps {
    routers: RouteConfig[];
    [K: string]: any;
}

export const ExtendedSwitch: React.FC<ExtendedSwitchProps> = ({ routers, ...rest }) => {
    return (
        <Switch>
            {routers.map((route, index) => (
                <ExtendedRoute
                    disallowAttributes={route.disallowAttributs}
                    component={route.component}
                    path={route.path}
                    exact={route.exact !== undefined ? route.exact : true}
                    authRequired={route.authRequired}
                    systemName={route.systemName}
                    routeChildren={route.children}
                    configData={route.configData}
                    access={route.access}
                    name={route.name}
                    key={index}
                    {...rest}
                />
            ))}
        </Switch>
    );
};
