import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {Button, Card, Checkbox, Col, Popconfirm, Space, Tooltip, Typography} from "antd";
import {useRequiredLayers} from "../../../../utils/context";
import {Address, AddressType} from "../../../../models/addresses/address";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {AddressesModal} from "../addressess-modal/addresses.modal";
import {CustomCard} from "../../../core/commons/custom-card/custom.card";
import {AddressesControllerContext} from "../addresses.controller";


interface MyProfileAddressProps {
    address: Address
}

export const AddressItem: React.FC<MyProfileAddressProps> = observer(({address}) => {
    const controller = useContext(AddressesControllerContext);
    const {t} = useTranslation("translation", {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    const customCardData = () => {
        let tempAddress = {...address};
        return {...tempAddress, country: tempAddress.country.originalName}
    }

    const cardDataRows = [
        {key: "companyName", label: "COMPANY_NAME"},
        {key: "firstName"},
        {key: "lastName"},
        {key: "vat", label: "VAT.TITLE"},
        {key: "regCode", label: "REG_NUMBER"},
        {key: "address"},
        {key: "city"},
        {key: "country"},
        {key: "phone"},
        {key: "email"},
    ];

    return (
        <>
            <Col className="card-col" xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card
                    className="card"
                    bordered={false}
                    size="small"
                    title={
                        <Typography.Text title={address.name} ellipsis={true}>{address.name}</Typography.Text>
                    }
                    extra={
                        <Space direction="horizontal" size={[5,5]}>
                            {address.type === AddressType.SHIPPING ? (
                                <Tooltip key={0} placement="topRight" title={t("ADDRESS_DEFAULT")}>
                                    <Checkbox className="large" checked={address.default} onChange={(event) => {
                                        if (event.target.checked) {
                                            controller.setDefaultAddress(address);
                                        }
                                    }}/>
                                </Tooltip>
                            ) : null}
                            <Tooltip key={1} placement="topRight" title={t("EDIT")}>
                                <Button
                                    onClick={() => setModalShown(true)}
                                    className="card-btn btn"
                                    type="default"
                                    size={"small"}
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                                />
                            </Tooltip>
                            {address.type === AddressType.SHIPPING ? (
                                <Popconfirm
                                    key={2}
                                    disabled={address.default}
                                    title={t("ARE_YOU_SURE")}
                                    onConfirm={() => controller.removeAddress(address)}
                                    placement="top"
                                    okText={t("YES")}
                                    cancelText={t("NO")}
                                >
                                    <Tooltip placement="topRight" title={t("REMOVE")}>
                                        <Button
                                            className="btn-remove card-btn btn"
                                            disabled={address.default}
                                            type="default"
                                            size={"small"}
                                            icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            ) : null}
                        </Space>
                    }
                >

                    <CustomCard data={customCardData()} rowKeys={cardDataRows}/>
                </Card>
            </Col>
            <AddressesModal type={address.type} visible={modalShown} onClose={() => setModalShown(false)} address={address}/>
        </>
    );
});
