import React, {ReactNode, useState} from "react";
import {observer} from "mobx-react";
import {ColumnsType} from "antd/lib/table";
import {useVT} from "virtualizedtableforantd4";
import {Button, Row, Typography, Table} from "antd";
import {ColumnsModal} from "../columns-modal/columns.modal";
import {ExpadedTableStore} from "./expaded.table.store";
import {useTranslation} from "react-i18next";
import {ExpandedTableController} from "./expanded.table.controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./expaded.table.scss";


interface ExpandedTableProps<T extends object> {
    pageKey: string;
    columns: ColumnsType<T>;
    columnsConfig: { title: string, key: string }[];
    title: string;
    tableData: T[];
    tableLoading: boolean;
    tableFooter?: ReactNode;
    defaultHiddenColumns?: string[];
    isVirtualList?: boolean
}
export const ExpandedTable = observer(<T extends object>(
    {
        pageKey,
        columns,
        columnsConfig,
        title,
        tableData,
        tableFooter,
        tableLoading,
        defaultHiddenColumns = [],
        isVirtualList = true,
        children
    }:
        ExpandedTableProps<T> & { children?: ReactNode }
) => {
    const {t} = useTranslation("translation", {useSuspense: false});
    const [vt] = useVT(() => ({scroll: {y: 600}}), []);


    const [store] = useState(() => new ExpadedTableStore(
        columns.map(item => ({
            title: String(item.title),
            value: String(item.key)
        })),
        pageKey,
        defaultHiddenColumns
    ));
    const [controller] = useState(() => new ExpandedTableController(store));

    const getColumns = (allColumns: any[]): any => {
        const filteredColumns = allColumns.filter(item => store.selectedColumns.includes(item.key) && item.key !== "action");
        const actionColumn = allColumns.find(item => item.key === "action");
        if (actionColumn) {
            filteredColumns.push(actionColumn);
        }
        return filteredColumns ? filteredColumns : []
    }


    return (
        <div className="order_details">
            <Row style={{alignItems: "baseline"}}>
                <Typography.Title level={3}>{t(title)}</Typography.Title>
                <Button
                    onClick={controller.openColumnsSettingsModal}
                    className={"table-columns-button btn"}
                    icon={<FontAwesomeIcon icon={["fas", "cog"]}/>}
                />
            </Row>
            {children}
            <Table<T>
                rowKey="id"
                className="order-data-details"
                columns={getColumns(columns)}
                dataSource={tableData}
                loading={tableLoading}
                scroll={isVirtualList ? {y: 600, x: true} : {y: 600}}
                components={isVirtualList ? vt : undefined}
                pagination={false}
                footer={tableFooter ? () => tableFooter : undefined}
            />
            <ColumnsModal
                visible={store.columnsSettingsModalShown}
                columns={columnsConfig}
                selectedColumns={store.selectedColumns}
                selectColumns={controller.selectColumns}
                onClose={controller.closeColumnsSettingsModal}
            />
        </div>
    );
})
