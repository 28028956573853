import {announcementsService} from "../services/announcements/announcements.service";
import {Button, notification, Space} from "antd";
import {TFunction} from "react-i18next";
import history from "./history";

export const initializeAnnouncements = (t: TFunction) => {

    setTimeout(() => {
        initializeAnnouncements(t);
    }, 1800000)

    announcementsService.getUnreadAnnouncements().then(announcements => {
        announcements.forEach(announcement => {
            const key = "announcement_" + announcement.id;
            const markNotified = () => {
                announcementsService.markNotified(announcement.id)
            }
            const toAnnouncement = () => {
                markNotified();
                history.push("/announcement/" + announcement.id)
                notification.close(key)
            }

            notification.info({
                key: key,
                duration: null,
                message: t('ANNOUNCEMENTS.ANNOUNCEMENT.TITLE'),
                description: announcement.shortMessage,
                btn: (
                    <Space>
                        <Button type="link" size="small" onClick={() => toAnnouncement()}>
                            Show more
                        </Button>
                    </Space>
                ),
                onClick: () => toAnnouncement(),
                onClose: () => markNotified()
            })
        })
    })
}