import {
    FilterItemState,
    FilterKey,
    FilterKeyOption,
    FilterTag,
    GroupedFilterKeyOption
} from "./filtered.table";
import {find, includes, flatten} from "lodash";
export const createLabel = (filter: FilterKey, value: FilterItemState, type: string): string => {
    let selectOptions: FilterKeyOption[] = [];
    if (filter.type === "select") {
        selectOptions = !filter.options?.length ? JSON.parse(localStorage.storedKeysWithOptions)[filter.key] : filter.options;
    }

    let selectGroupedOptions: FilterKeyOption[] = [];
    if (filter.type === "groupedOptions") {
        selectGroupedOptions = (filter.groupedOptions && filter.groupedOptions?.length) ? flatten(filter.groupedOptions.map(item => item.options)) : flatten(JSON.parse(localStorage.storedKeysWithOptions)[filter.key].map((item: GroupedFilterKeyOption) => item.options));
    }

    const labelObj: any = {
        string: Array.isArray(value.values) ? [filter.label, value.queryType, value.values.join(", ")].join(" : ") : [filter.label, value.queryType, value.values].join(" : "),
        int: Array.isArray(value.values) ? [filter.label, value.queryType, value.values.join(", ")].join(" : ") : [filter.label, value.queryType, value.values].join(" : "),
        date: Array.isArray(value.values) ? filter.label + " : " + value.values.join(", ") : "",
        select: filter.label + " : " + selectOptions.filter(item => includes(value.values, item.value)).map(item => item.label).join(", "),
        groupedSelect: filter.label + " : " + selectGroupedOptions.filter((item) => includes(value.values, item.value)).map(({label}) => label).join(", "),
        boolean: filter.label + " : " + (value.values === "1" ? "Yes": "No")
    }
    return labelObj[type];
}


export const createTagsFromItemStates = (keys: FilterKey[], itemStates: FilterItemState[]): FilterTag[] => {
    const newTags: FilterTag[] = [];

    let storedKeysWithOptions: any = {};

    keys.forEach(key => {
        if (key.type === "select") {
            storedKeysWithOptions[key.key] = key.options;
        }
        if (key.type === "groupedSelect") {
            storedKeysWithOptions[key.key] = key.groupedOptions;
        }
    });

    if (!localStorage.storedKeysWithOptions) {
        localStorage.setItem("storedKeysWithOptions", JSON.stringify(storedKeysWithOptions));
    }

    if (itemStates) {
        itemStates.forEach((item: FilterItemState) => {
            const filterKey = find(keys, {key: item.key})

            if (filterKey) {
                newTags.push({
                    key: item.key,
                    queryType: item.queryType,
                    values: item.values,
                    label: createLabel(filterKey, item, item.type),
                    type: item.type
                })
            }
        });
    }

    return newTags;
}

export const createItemStatesFromTags = (tags: FilterTag[]): any => {
    return tags.map((tag) => {
        return {
            key: tag.key,
            queryType: tag.queryType,
            values: tag.values,
            type: tag.type
        }
    });
}
