import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./catalog.product.cart.product-info.scss"

interface CatalogProductCartProductInfoProps {
    title: string;
    values: {title: string, description: string, highlighted?: boolean}[];
}

export const CatalogProductCartProductInfo: React.FC<CatalogProductCartProductInfoProps> = ({title, values}) => {

    return (
        <div className={"product-info"}>
            <div  className={"product-info-title"}>
                <FontAwesomeIcon
                    className="warehouses_col-icon"
                    icon={["fas", "info-circle"]}
                /> {title}
            </div>
            <div className={"product-info-list"}>
                <table width={"100%"}>
                    {values.map((value, key) => (
                        <tr key={key} className={value.highlighted ? "highlighted" : ""}>
                            <td>{value.title}:</td>
                            <td>{value.description}</td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
}