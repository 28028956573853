import React, {Context} from "react";
import {LoginController} from "../login/login.controller";
import {RootStore} from "../../stores/root/root";
import {LangController} from "../lang/lang.controller";
import {userService} from "../../services/user/user.service";
import {authService} from "../../services/auth/auth.service";
import {countiesService} from "../../services/countries/countries.service";
import {localesService} from "../../services/locales/locales.service";
import {i18n} from "i18next";
import {AccountController} from "../account/account.controller";

export class RootControllers {
    constructor(
        private rootStore: RootStore,
        private i18n: i18n,
    ) {
        this.init();
    }

    public accountController = new AccountController(this.rootStore.accountStore);

    public loginController = new LoginController(
        this.rootStore.authStore,
        authService,
        userService,
    );

    public langController = new LangController(
        this.rootStore.langStore,
        countiesService,
        localesService
    );

    private init(): void {
        this.rootStore.langStore.setI18n(this.i18n);
    }
}

export const RootControllersContext = React.createContext<RootControllers | null>(null) as Context<RootControllers>;
