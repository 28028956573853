import {ForgotPasswordStore} from "./forgot.password.store";
import {ForgotPasswordService} from "../../../../services/forgot-password/forgot.password.service";

export class ForgotPasswordController {

    constructor(
        private readonly _forgotPasswordStore: ForgotPasswordStore,
        private readonly _forgotPasswordService: ForgotPasswordService
    ) {

    }

    public async sendEmail(values: {username: string}): Promise<void> {
        this._forgotPasswordStore.setDisabled(true);
        await this._forgotPasswordService.sendEmail(values);
        this._forgotPasswordStore.setDisabled(false);
        this._forgotPasswordStore.setSent(true);
    }
}