import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {WishlistItem, WishlistItemArticle} from "../../../models/wishlist/wishlist.item";
import {Button, Checkbox, Col, Dropdown, Form, Menu, Select, Space} from "antd";
import {FilterKey} from "../../../models/filtered-table/filtered.table";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import {wishlistService} from "../../../services/wishlist/wishlist.service";
import {MissingItemsController, MissingItemsControllerContext} from "./missing.items.controller";
import {MissingItem, MissingItemsLinkedOrder} from "../../../models/wishlist/missing.item";
import {AddAllAvailableModal} from "./all-available-modal/add.all.available.modal";
import {Export} from "../../core/commons/export/export";
import "./missing.items.scss";
import i18n from "i18next";
import { Link } from "react-router-dom";
import {MissingItemsStore, MissingItemsStoreContext} from "./missing.items.store";
import {warehousesService} from "../../../services/warehouses/warehouses.service";
import {isAllowed} from "../../../utils/helpers";
import {RootStoreContext} from "../../../stores/root/root";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const MissingItems: React.FC<ComponentPropsFromRoute> = observer(({requiredConfigs}) => {

    const [store] = useState(() => new MissingItemsStore(!requiredConfigs!.active));
    const [controller] = useState(() => new MissingItemsController(store, wishlistService, warehousesService));
    const {t} = useTranslation("translation", {useSuspense: false});
    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);

    useEffect(() => {
        i18n.on("languageChanged", controller.onLngChange);
        return () => i18n.off("languageChanged", controller.onLngChange)
    }, []);

    const columns: any = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "article",
            key: "article",
            render: (item: WishlistItemArticle) => item.name !== "DELIVERY" ? <Link to={"/catalog/article/" + item.name} className="link article-link">{item.name}</Link> : item.name
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "article",
            key: "article",
            render: (item: WishlistItemArticle) => <>{item.description}</>
        },
        {
            title: t("ORDERS.LINKED"),
            dataIndex: "linkedOrders",
            key: "missing_items_report_linked_orders",
            render: (items: MissingItemsLinkedOrder[]) =>
                items.map((order, index) => (
                    <Link to={"/order/" + order.id}>
                        {order.id}
                        {index !== items.length - 1 ? ", " : null}
                    </Link>
                )),
        },
        {
            title: "References",
            dataIndex: "linkedOrders",
            key: "missing_items_report_references",
            render: (item: MissingItemsLinkedOrder[]) => item.map(i => i.ref).join(", "),
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty"
        },
        {
            title: t("APPLIED_QUANTITY"),
            dataIndex: "appliedQty",
            key: "appliedQty"
        }
    ];

    store.warehouses.forEach(warehouse => {
        if (warehouse.type !== "consignation") {
            columns.push({
                title: warehouse.name,
                dataIndex: warehouse.id,
                key: warehouse.id,
                render: (_: any, row: WishlistItem) => {
                    const foundWarehouse = row.article.stocks.find(item => item.warehouse.id === warehouse.id);
                    return foundWarehouse ? foundWarehouse.amount : "";
                }
            });
        }
    });

    if (isAllowed(attributes, 'order_create_reservation')) {
        columns.push(
            {
                title: "In transit",
                dataIndex: "article",
                key: "inTransitQty",
                render: (a: any) => a.inTransitQty
            }
        );
    }

    columns.push({
        key: "actions",
        render: (_: any, item: any) => (
            <Space direction={"horizontal"}>
                {item.hidden ? (
                    <Button
                        size={"small"}
                        icon={<FontAwesomeIcon icon={["fas", "eye"]}/>}
                        onClick={() => controller.unhideItem(item.id)}
                    />
                ) : (
                    <Button
                        size={"small"}
                        icon={<FontAwesomeIcon icon={["fas", "eye-slash"]}/>}
                        onClick={() => controller.hideItem(item.id)}
                    />
                )}
            </Space>
        )
    });

    const rowSelection: any = {
        type: "checkbox",
        onChange: (selectedRowKeys: number[], selectedRows: MissingItem[]) => {
            controller.onArticleCheck(selectedRowKeys);
        },
        getCheckboxProps: (record: MissingItem) => ({
            // disabled: record.article.stocks.every(item => item.amount === 0), // Column configuration not to be checked
            // name: record.article.name,
        }),
    };

    const filters: FilterKey[] = [
        {
            key: "sku",
            label: t("ARTICLES.TITLE_SINGLE"),
            type: "string"
        },
        {
            key: "linkedOrders",
            label: t("ORDERS.LINKED"),
            type: "int"
        },
        {
            key: "references",
            label: "References",
            type: "string"
        },
        {
            key: "date",
            label: t("DATE"),
            type: "date"
        },
        {
            key: "fullyApplied",
            label: t("MISSING_ITEM.FULLY_APPLIED"),
            type: "boolean"
        }
    ];

    if (!store.warehouses.length) {
        return <></>;
    }

    return (
        <MissingItemsControllerContext.Provider value={controller}>
            <MissingItemsStoreContext.Provider value={store}>
                <Space className="wish-list-wrapper" direction="vertical" size={[10, 10]} style={{width: "100%"}}>
                    {requiredConfigs!.active ? (
                        <Space className="missing-items-buttons1" direction={"horizontal"} size={[10, 10]}>
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {store.selectedMissingItems.length > 0 ? (
                                            <>
                                                <Menu.Item
                                                    onClick={controller.createOrder}
                                                >
                                                    Create from Stock and In Transit (selected)
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={controller.createOrderWithoutReservation}
                                                >
                                                    Create only from Stock (selected)
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={controller.createReservation}
                                                >
                                                    Create only from In Transit (selected)
                                                </Menu.Item>
                                            </>
                                        ) : (
                                            <>
                                                <Menu.Item
                                                    onClick={controller.createOrderWithFilters}
                                                >
                                                    Create from Stock and In Transit
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={controller.createOrderWithoutReservationWithFilters}
                                                >
                                                    Create only from Stock
                                                </Menu.Item>
                                                <Menu.Item
                                                    onClick={controller.createReservationWithFilters}
                                                >
                                                    Create only from In Transit
                                                </Menu.Item>
                                            </>
                                        )}
                                    </Menu>
                                }
                            >
                                <Button
                                    type="primary"
                                >
                                    Create order
                                </Button>
                            </Dropdown>


                            {/*{store.selectedMissingItems.length > 0 ? (*/}
                            {/*    <Button*/}
                            {/*        onClick={controller.addToCartItems}*/}
                            {/*        disabled={!store.missingItemsDataHandler.items.length || !store.selectedMissingItems.length}*/}
                            {/*        type="primary"*/}
                            {/*    >*/}
                            {/*        {t("WISH_LIST.ADD_TO_BASKET")}*/}
                            {/*    </Button>*/}

                            {/*) : (*/}
                            {/*    <Button*/}
                            {/*        onClick={controller.openAddAllAvailableToCartModal}*/}
                            {/*        type="primary"*/}
                            {/*    >*/}
                            {/*        {t("WISH_LIST.ADD_ALL_AVAILABLE_TO_BASKET")}*/}
                            {/*    </Button>*/}
                            {/*)}*/}
                            <Button
                                disabled={!store.missingItemsDataHandler.items.length}
                                onClick={controller.openExportModal}
                                type="primary"
                            >
                                {t("WISH_LIST.DOWNLOAD")}
                            </Button>
                            <Button
                                danger
                                type="primary"
                                disabled={!store.missingItemsDataHandler.items.length}
                                onClick={controller.hideAllItems}
                            >
                                {t("WISH_LIST.HIDE_ALL")}
                            </Button>
                        </Space>
                    ) : null}
                    <FilteredTable
                        columns={columns}
                        filterKeys={filters}
                        rowSelection={{
                            type: "checkbox",
                            selectedRowKeys: store.selectedMissingItems,
                            ...rowSelection,
                        }}
                        dataHandler={store.missingItemsDataHandler}
                    />
                    <AddAllAvailableModal visible={store.addAvailableModalShown} onClose={controller.closeAddAllAvailableToCartModal}/>
                </Space>
                <Export
                    title={"WISH_LIST.DOWNLOAD"}
                    withBasicExportFields
                    isShown={store.exportAllModalShown}
                    config={{
                        link: "/api/v3/cart/missing-items",
                    }}
                    additionalDefaultValues={{withStock: false, warehouses: store.warehouses.map(w => w.id)}}
                    onClose={controller.closeExportModal}
                >
                    <Col span={24}>
                        <Form.Item
                            name="warehouses"
                            label={t("WAREHOUSES.TITLE")}
                        >
                            <Select
                                allowClear
                                autoFocus
                                mode="multiple"
                                placeholder={t("WAREHOUSES.TITLE")}
                                getPopupContainer={trigger => trigger.parentElement}
                            >
                                {store.warehouses.map((warehouse) => (
                                    <Select.Option
                                        key={warehouse.id}
                                        value={warehouse.id}
                                    >
                                        {warehouse.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="includeInTransit"
                            valuePropName={"checked"}
                        >
                            <Checkbox>{t("MISSING_ITEMS_EXPORT.WITH_TRANSIT")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="withStock"
                            valuePropName={"checked"}
                        >
                            <Checkbox>{t("WISH_LIST.WITH_STOCK")}</Checkbox>
                        </Form.Item>
                    </Col>
                </Export>
            </MissingItemsStoreContext.Provider>
        </MissingItemsControllerContext.Provider>
    );
});
