import {apiService} from "../api.service";

import {SystemUser} from "../../models/user/user";


export class AuthService {
    private url: string = 'login_check';

    public async auth(data: SystemUser): Promise<{token: string}> {
        return (await apiService.post<{token: string}>(this.url, {...data})).data;
    }

    public async logout(): Promise<void> {
        return (await apiService.get<void>("logout")).data;
    }

    public async switchAccount(id: number): Promise<void> {
        return (await apiService.post<void>("authentication/switch-account/" + id)).data;
    }
}


export const authService = new AuthService();
