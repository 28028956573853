import { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Amount } from "../../../../models/amount/amount";
import { displayPrice } from "../../../../models/amount/amount.helper";
import { OrderStatus, OrderStatusType } from "../../../orders/order-status/order.status";
import { MergedInvoiceStoreContext } from "../merged.invoice.store";
import { Date } from "../../../core/commons/date/date";
import { ExpandedTable } from "../../../core/commons/expanded-table/expanded.table";
import { Warehouse } from "../../../../models/warehoues/warehouse";

export const MergedInvoiceInvludedOrders: React.FC = observer(() => {
    const store = useContext(MergedInvoiceStoreContext);
    const { t } = useTranslation("translation", { useSuspense: false });

    const columns: ColumnsType<any> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: string) => (
                <Link className="link" to={"/orders/" + id}>
                    {id}
                </Link>
            ),
        },
        {
            title: t("ORDERS.STATUS.TITLE"),
            dataIndex: "status",
            key: "orders_status",
            render: (status: OrderStatusType) => <OrderStatus status={status} />,
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "orders_grandTotal",
            render: (total: Amount) => <>{displayPrice(total)}</>,
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "orders_warehouse",
            render: (warehouse: Warehouse) => <>{warehouse.name}</>,
        },
        {
            title: t("ORDERS.ALT_NUMBER"),
            dataIndex: "clientOrderId",
            key: "orders_clientOrderId",
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "orders_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
    ];

    const columnsConfig = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "ORDERS.STATUS.TITLE",
            key: "orders_status",
        },
        {
            title: "GRAND_TOTAL_PRICE",
            key: "orders_grandTotal",
        },
        {
            title: "WAREHOUSES.WAREHOUSE_TITLE",
            key: "orders_warehouse",
        },
        {
            title: "ORDERS.ALT_NUMBER",
            key: "orders_clientOrderId",
        },
        {
            title: "CREATED_AT",
            key: "orders_createdAt",
        },
    ];

    return (
        <ExpandedTable<any>
            title="PROFORMAS.INCLUDED_ORDERS.TITLE"
            pageKey="merged_invoice_included_orders"
            columns={columns}
            columnsConfig={columnsConfig}
            tableData={store.loading ? [] : [...store.invoice.included_orders]}
            tableLoading={store.loading}
            isVirtualList={false}
        />
    );
});
