import {action, makeObservable, observable} from "mobx";

export class CommonStore {

    @observable
    public outdated: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    public setOutdated(): void {
        this.outdated = true;
    }
}
