import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {ObjectType} from "../models/core/objectType";
import API from "../utils/api";

export type ApiServiceResponseType<T> = Promise<AxiosResponse<T>>;

export class ApiService {
    protected _api: AxiosInstance = API;

    get<T>(url: string, config?: AxiosRequestConfig): ApiServiceResponseType<T> {
        return this._api.get<T>(url, config);
    }
    post<T>(url: string, data?: ObjectType | FormData, config?: AxiosRequestConfig): ApiServiceResponseType<T> {
        return this._api.post<T>(url, data, config);
    }
    delete<T>(url: string, config?: AxiosRequestConfig): ApiServiceResponseType<T> {
        return this._api.delete<T>(url, config)
    }
}

export const apiService =  new ApiService();
