import React, { Context } from "react";
import { CartCheckoutAddress } from "../../../models/cart/checkout/address/cart.checkout.address";
import { Address } from "../../../models/addresses/address";
import { CartCheckoutShippingQuery } from "../../../models/cart/checkout/shipping/cart.checkout.shipping";
import { AddressesService } from "../../../services/addresses/addresses.service";
import { CartCheckoutStore } from "./cart.checkout.store";
import { CartCheckoutService } from "../../../services/cart/checkout/cart.checkout.service";
import { generateApplyRequest } from "../../../models/cart/helper/cart.helper";
import { NotCompletedOrder } from "../../../models/cart/checkout/not.completed.order";
import {Modal} from "antd";
import {TFunction} from "react-i18next";

export class CartCheckoutController {
    constructor(
        private readonly _cartCheckoutStore: CartCheckoutStore,
        private readonly _cartCheckoutService: CartCheckoutService,
        private readonly _addressesService: AddressesService,
        private onOrderCompleted: () => void
    ) {
        this.updateShippingAddresses = this.updateShippingAddresses.bind(this);
        this.onLngChange = this.onLngChange.bind(this);
        this.closeNotValidItemsModal = this.closeNotValidItemsModal.bind(this);
        this.openNotValidItemsModal = this.openNotValidItemsModal.bind(this);
        this.onNotValidItemsModalConfirm = this.onNotValidItemsModalConfirm.bind(this);
        this.onNotValidItemsModalCancel = this.onNotValidItemsModalCancel.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.moveOrderToCart = this.moveOrderToCart.bind(this);
        this.closeCancelOrderModal = this.closeCancelOrderModal.bind(this);
        this.init();
    }

    public onLngChange(): void {
        this.reloadApplyCart();
    }

    private async reloadApplyCart(): Promise<void> {
        this._cartCheckoutStore.setLoading(true);
        this._cartCheckoutStore.setNotCompletedOrders(await this._cartCheckoutService.getNotCompletedOrders());
        this._cartCheckoutStore.setLoading(false);
    }

    private async init(): Promise<void> {
        this._cartCheckoutStore.setLoading(true);
        this._cartCheckoutStore.setNotCompletedOrders(await this._cartCheckoutService.getNotCompletedOrders());
        this._cartCheckoutStore.addActiveOrderCollapse(this._cartCheckoutStore.notCompletedOrders[0]?.id);
        const addresses = await this._addressesService.getAddresses();
        if (addresses.length === 0) {
            this._cartCheckoutStore.setAllFieldsValid(false);
            this._cartCheckoutStore.setLoading(false);
            return;
        }
        this._cartCheckoutStore.setShippingAddresses(
            this._cartCheckoutStore.notCompletedOrders,
            addresses.filter((item) => item.type === "shipping")
        );
        this._cartCheckoutStore.setLoading(false);
    }

    public setRelatedOrderNumberValue(order: NotCompletedOrder, value: string): void {
        this._cartCheckoutStore.setRelatedOrderNumber(value, order.id);
    }

    public setOrderCommentValue(order: NotCompletedOrder, value: string): void {
        this._cartCheckoutStore.setOrderComment(value, order.id);
    }

    private static generateShippingDataParams({ vat, customsCode, country, index }: CartCheckoutAddress) {
        const shippingDataParams: { [key: string]: any } = {};
        const shippingData: { [key: string]: any } = { vat, customsCode, index, country: country.id };

        for (const item in shippingData) {
            shippingDataParams["shippingData[" + item + "]"] = shippingData[String(item)];
        }
        return shippingDataParams;
    }

    private resetAddresses(order: NotCompletedOrder, type: string): void {
        if (type === "shipping") {
            const uncheckedShippingAddresses = this._cartCheckoutStore.shippingAddresses[order.id].map((item) => ({
                ...item,
                checked: false,
                default: false,
            }));
            this._cartCheckoutStore.updateMapItem(
                this._cartCheckoutStore.shippingAddresses,
                order.id,
                uncheckedShippingAddresses
            );
            return;
        }
    }

    public async addCustomAddress(values: CartCheckoutAddress, order: NotCompletedOrder): Promise<void> {
        this.resetAddresses(order, values.type);
        if (values.type === "shipping") {
            this._cartCheckoutStore.addShippingAddress(order.id, values);
            await this._cartCheckoutStore.updateNotCompletedOrder(
                await this._cartCheckoutService.getCalculatedNotCompletedOrder(
                    order.id,
                    CartCheckoutController.generateShippingDataParams(values)
                )
            );
            await this.updateShipping(order, values.country.id, values.index);
        }
    }

    public async addNewAddress(values: Address): Promise<void> {
        const newAddress = await this._addressesService.createAddress(values);
        this._cartCheckoutStore.notCompletedOrders.forEach((order) => {
            this.addCustomAddress({ ...newAddress, checked: true, custom: false }, order);
        });
    }

    private updateAddressesData(
        addresses: { [K: string]: CartCheckoutAddress[] },
        order: NotCompletedOrder,
        checked: boolean,
        index: number
    ) {
        const items = addresses[order.id].map((address) => ({ ...address, checked: false }));
        this._cartCheckoutStore.updateMapItem(addresses, order.id, items);
        this._cartCheckoutStore.updateMapArrayItem(addresses, order.id, index, {
            ...addresses[order.id][index],
            checked: checked,
        });
        if (addresses[order.id].every((item) => !item.checked)) {
            this._cartCheckoutStore.updateMapArrayItem(addresses, order.id, index, {
                ...addresses[order.id][index],
                checked: true,
            });
        }
    }

    public async updateShippingAddresses(
        order: NotCompletedOrder,
        address: CartCheckoutAddress,
        checked: boolean
    ): Promise<void> {
        const oldAddress = this._cartCheckoutStore.shippingAddresses[order.id].find((address) => address.checked);
        const oldCountry = oldAddress!.country.id;
        const oldIndex = oldAddress!.index;

        const addressIndex = this._cartCheckoutStore.shippingAddresses[order.id].indexOf(address);
        if (
            addressIndex !== -1 &&
            !this._cartCheckoutStore.shippingAddresses[order.id].every((item) => !item.checked)
        ) {
            this.updateAddressesData(this._cartCheckoutStore.shippingAddresses, order, checked, addressIndex);

            const newAddress = this._cartCheckoutStore.shippingAddresses[order.id][addressIndex];
            const newCountry = newAddress.country.id;
            const newIndex = newAddress.index;

            if (newCountry !== oldCountry || oldIndex !== newIndex) {
                await this.updateShipping(order, newCountry, newIndex);
                this._cartCheckoutStore.updateNotCompletedOrder(
                    await this._cartCheckoutService.getCalculatedNotCompletedOrder(
                        order.id,
                        CartCheckoutController.generateShippingDataParams(address)
                    )
                );
            }
        }
    }

    public async getShipping(order: NotCompletedOrder) {
        this._cartCheckoutStore.setShippingsNotFound(this._cartCheckoutStore.notCompletedOrders, false);
        const defaultAddress = this._cartCheckoutStore.shippingAddresses[order.id].find((address) => address.checked);
        const query: CartCheckoutShippingQuery = {
            country: defaultAddress!.country.id,
            index: defaultAddress!.index
        };
        const shippings = (await this._cartCheckoutService.getShippings(order.id, query)).map((shipping, index) => ({
            ...shipping,
            checked: index === 0,
        }));
        this._cartCheckoutStore.updateMapItem(this._cartCheckoutStore.shippings, order.id, shippings);

        if (!this._cartCheckoutStore.shippings[order.id].length) {
            this._cartCheckoutStore.updateMapItem(this._cartCheckoutStore.shippingsNotFound, order.id, true);
            this._cartCheckoutStore.setLoading(true);
        }
    }

    public async updateShipping(order: NotCompletedOrder, newCountry: string, newIndex: string): Promise<void> {
        const query: CartCheckoutShippingQuery = {
            index: newIndex,
            country: newCountry,
        };
        const shippings = (await this._cartCheckoutService.getShippings(order.id, query)).map((shipping, index) => ({
            ...shipping,
            checked: index === 0,
        }));
        this._cartCheckoutStore.updateMapItem(this._cartCheckoutStore.shippings, order.id, shippings);
    }

    public selectShippingWithConfirm(order: NotCompletedOrder, shippingId: string, checked: boolean, onSelectPopUpText: string, t: TFunction<"translation">): void {
        if (onSelectPopUpText) {
            Modal.confirm({
                title: t("SHIPPING.CONFIRM_CHANGE.TITLE"),
                content: t(onSelectPopUpText),
                onOk: () => this.selectShipping(order, shippingId, checked)
            })
            return;
        }

        this.selectShipping(order, shippingId, checked);
    }

    public selectShipping(order: NotCompletedOrder, shippingId: string, checked: boolean): void {
        const shippingIndex = this._cartCheckoutStore.shippings[order.id].findIndex(
            (shipping) => shipping.id === shippingId
        );
        if (shippingIndex !== -1) {
            const items = this._cartCheckoutStore.shippings[order.id].map((shipping) => ({
                ...shipping,
                checked: false,
            }));
            this._cartCheckoutStore.updateMapItem(this._cartCheckoutStore.shippings, order.id, items);
            this._cartCheckoutStore.updateMapArrayItem(this._cartCheckoutStore.shippings, order.id, shippingIndex, {
                ...this._cartCheckoutStore.shippings[order.id][shippingIndex],
                checked: checked,
            });

            if (this._cartCheckoutStore.shippings[order.id].every((item) => !item.checked)) {
                this._cartCheckoutStore.updateMapArrayItem(this._cartCheckoutStore.shippings, order.id, shippingIndex, {
                    ...this._cartCheckoutStore.shippings[order.id][shippingIndex],
                    checked: true,
                });
            }
        }
    }

    public async createOrder(order: NotCompletedOrder) {
        this._cartCheckoutStore.setCheckoutButtonDisabled(true);
        const requestData = generateApplyRequest({
            relatedOrderNumber: this._cartCheckoutStore.relatedOrderNumber[order.id],
            shippingAddresses: this._cartCheckoutStore.shippingAddresses[order.id],
            shippingCouriers: this._cartCheckoutStore.shippings[order.id],
            orderComment: this._cartCheckoutStore.orderComment[order.id]
        });
        this._cartCheckoutStore.setOrdersCheckoutRequest(order.id, requestData);
        try {
            if (order.items.some((item) => !item.valid)) {
                this._cartCheckoutStore.setNotValidItemsModalItem(order);
                this.openNotValidItemsModal();
            } else {
                await this.completeCreatingOrder(order);
            }
        } catch (e) {
            this._cartCheckoutStore.setCheckoutButtonDisabled(false);
        }
    }

    private async completeCreatingOrder(order: NotCompletedOrder) {
        await this._cartCheckoutService.completeOrder(
            order.id,
            this._cartCheckoutStore.ordersCheckoutRequests[order.id]
        );
        this._cartCheckoutStore.setCheckoutButtonDisabled(false);

        setTimeout(() => {
            this._cartCheckoutStore.setOrderResult(order.id, true);
            this.onOrderCompleted();

            setTimeout(() => {
                const resultOrderIndex = this._cartCheckoutStore.notCompletedOrders.indexOf(order);
                if (resultOrderIndex !== -1) {
                    const nextOrder = this._cartCheckoutStore.notCompletedOrders[resultOrderIndex + 1];
                    this.openCollapse(nextOrder);
                }
            }, 200);
        }, 600);
    }

    public async onCancelOrder(order: NotCompletedOrder) {
        this._cartCheckoutStore.setCancelModalShown(true);
        this._cartCheckoutStore.setCancellingOrder(order);
        this._cartCheckoutStore.setOrdersCancelling(order.id, true);
    }

    public async cancelOrder() {
        await this._cartCheckoutService.cancelOrder(this._cartCheckoutStore.cancellingOrder!.id);
        this.deleteOrder(this._cartCheckoutStore.cancellingOrder!);
        this._cartCheckoutStore.setCancellingOrder(null);
        this._cartCheckoutStore.setCancelModalShown(false);
    }

    public async moveOrderToCart() {
        await this._cartCheckoutService.cancelOrder(this._cartCheckoutStore.cancellingOrder!.id, { move: "1" });
        this.deleteOrder(this._cartCheckoutStore.cancellingOrder!);
        this._cartCheckoutStore.setCancellingOrder(null);
        this._cartCheckoutStore.setCancelModalShown(false);
    }

    private deleteOrder(order: NotCompletedOrder): void {
        const index = this._cartCheckoutStore.notCompletedOrders.indexOf(order);
        const newOrders = [...this._cartCheckoutStore.notCompletedOrders];
        this._cartCheckoutStore.setOrdersCancelling(order.id, false);
        newOrders.splice(index, 1);
        if (index !== -1) {
            this._cartCheckoutStore.setNotCompletedOrders(newOrders);
            this.onOrderCompleted();
        }
    }

    public closeCancelOrderModal(): void {
        this._cartCheckoutStore.setCancelModalShown(false);
        this._cartCheckoutStore.setOrdersCancelling(this._cartCheckoutStore.cancellingOrder!.id, false);
        this._cartCheckoutStore.setCancellingOrder(null);
    }

    public async onNotValidItemsModalConfirm() {
        await this.completeCreatingOrder(this._cartCheckoutStore.notValidItemsModalItem!);
        this._cartCheckoutStore.setNotValidItemsModalItem(null);
        this._cartCheckoutStore.setNotValidItemsModalShown(false);
    }

    public async onNotValidItemsModalCancel() {
        await this.onCancelOrder(this._cartCheckoutStore.notValidItemsModalItem!);
        this._cartCheckoutStore.setNotValidItemsModalItem(null);
        this._cartCheckoutStore.setNotValidItemsModalShown(false);
    }

    public closeNotValidItemsModal(): void {
        this._cartCheckoutStore.setNotValidItemsModalShown(false);
    }

    public openNotValidItemsModal(): void {
        this._cartCheckoutStore.setNotValidItemsModalShown(true);
    }

    public toggleOrderCollapse(order: NotCompletedOrder): void {
        if (this._cartCheckoutStore.activeOrderCollapse.includes(order.id)) {
            this._cartCheckoutStore.removeActiveOrderCollapse(order.id);
            return;
        }
        this._cartCheckoutStore.addActiveOrderCollapse(order.id);
    }

    public openCollapse(order: NotCompletedOrder): void {
        if (order && !this._cartCheckoutStore.activeOrderCollapse.includes(order.id)) {
            this._cartCheckoutStore.addActiveOrderCollapse(order.id);
        }
    }
}

export const CartCheckoutControllerContext = React.createContext<null | CartCheckoutController>(
    null
) as Context<CartCheckoutController>;
