import React from "react";
import {Input, Form} from "antd";
import {NumberInput} from "../number-input/number.input";
import {useTranslation} from "react-i18next";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text";
    record: any;
    index: number;
    children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = (
    {
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }
) => {
    const inputNode = inputType === "number" ? <NumberInput/> : <Input/>;
    const {t} = useTranslation('translation', {useSuspense: false});
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{margin: 0}}
                    rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
