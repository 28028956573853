import {SystemUser} from "../user/user";

export enum PlannerTaskPriority
{
    LOW = 'low',
    MIDDLE = 'middle',
    HIGH = 'high',
    EMERGENCY = 'emergency'
}

export enum PlannerTaskType
{
    TASK = 'task',
    ISSUE = 'issue'
}

export enum PlannerTaskSystemStatus
{
    PENDING = 'pending',
    TODO = 'todo',
    IN_PROGRESS = 'in_progress',
    ON_REVIEW = 'on_review',
    DONE = 'done',
    CANCELLED = 'cancelled'
}

export interface PlannerTask
{
    id: number;
    priority: PlannerTaskPriority;
    name: string;
    description: string;
    status: PlannerTaskSystemStatus;
    realStartDate: string;
    realEndDate: string;
    author: SystemUser;
    createdAt: string;
}

export interface PlannerTaskComment
{
    content: string;
    author: SystemUser;
    createdAt: string;
}

export interface PlannerDepartment {
    id: number;
    name: string;
}