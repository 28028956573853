import React, {useCallback} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {announcementsService} from "../../../services/announcements/announcements.service";
import {Paginator} from "../../core/commons/paginator/paginator";
import {Announcement} from "../../../models/announcements/announcement";
import {Date} from "../../core/commons/date/date";
import "./announcements.list.view.scss"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Alert} from "antd";

export const AnnouncementsListView: React.FC<ComponentPropsFromRoute> = ({name, requiredConfigs}) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    if (!requiredConfigs) {
        throw new Error('Failed to get config');
    }

    const dataLoader = useCallback(
        (request: any) => announcementsService.getAnnouncements(request, requiredConfigs.type),
        []
    );

    return (
        <>
            <Paginator
                dataLoader={dataLoader}
                noDataMessage={<Alert message={t("ANNOUNCEMENTS.NO_DATA")} />}
            >
                {(values: Announcement[]) => (
                    values.map((value, key) => (
                        <div className={"announcement"} key={key}>
                            <div className={"announcement-text"}>
                                <Link to={"/announcement/" + value.id}>
                                    {value.shortMessage}
                                </Link>
                            </div>
                            <div className={"announcement-date"}>
                                <Date dateString={value.createdAt} showTime={true} />
                            </div>
                        </div>
                    ))
                )}
            </Paginator>
        </>
    );
}