import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Comment, List, Typography} from "antd";
import {ClaimCommentsForm} from "./claim.comments.form";
import {Claim, ClaimComment} from "../../../../models/claims/claims";
import {claimService} from "../../../../services/claims/claims.service";

export interface ClaimCommentsFormProps {
    claim: Claim;
}

export const ClaimComments: React.FC<ClaimCommentsFormProps> = ({claim}) => {
    const {t} = useTranslation('translation', {useSuspense: false});

    const [comments, setComments] = useState<ClaimComment[]>([]);

    const onComment = (comment: ClaimComment): void => {
        setComments([...comments, comment])
    }

    useEffect(() => {
        claimService.getClaimComments(claim.id).then(comments => setComments(comments));
    }, [claim.id]);

    return (
        <>
            <Typography.Title level={3} style={comments.length ? {marginBottom: 10} : undefined}>{t("COMMENTS.TITLE")}</Typography.Title>

            {comments.length ? (
                <List
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={comments}
                    renderItem={comment => (
                        <li>
                            <Comment
                                author={comment.user.firstName + " " + comment.user.lastName}
                                content={comment.text}
                                datetime={comment.createdAt}
                            />
                        </li>
                    )}
                />
            ) : null}

            <ClaimCommentsForm claim={claim} onCompleted={onComment} />
        </>
    );
};
