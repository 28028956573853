import {action, makeObservable, observable} from "mobx";

export class ExpadedTableStore {
    @observable
    public columnsSettingsModalShown: boolean = false;

    @observable
    public selectedColumns: string[] = !this.pageKey ? this.defaultColumns.map(item => item.value).filter(item => !this.defaultHiddenColumns?.includes(item)) : (localStorage[this.pageKey] ? JSON.parse(localStorage[this.pageKey]) : this.defaultColumns.map(item => item.value).filter(item => !this.defaultHiddenColumns?.includes(item)));


    constructor(
        public defaultColumns: {title: string, value: string}[],
        public pageKey?: string,
        public defaultHiddenColumns?: string[]
    ) {
        makeObservable(this);
    }

    @action
    public setColumnsSettingsModalShown(value: boolean): void {
        this.columnsSettingsModalShown = value;
    }

    @action
    public setSelectedColumns(value: string[]): void {
        this.selectedColumns = value;
    }
}