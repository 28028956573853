import React from "react";
import {Skeleton} from "antd";
import {useTranslation} from "react-i18next";
import {useRequiredLayers} from "../../../../utils/context";
import {InvoiceStoreContext} from "../invoice.store";
import {InvoiceControllerContext} from "../invoice.controller";
import {Amount} from "../../../../models/amount/amount";
import {displayPrice} from "../../../../models/amount/amount.helper";
import {DetailsTableFooter} from "../../../core/commons/details-table-footer/details.table.footer";
import { Link } from "react-router-dom";
import {
    generateOrderDetailsTableFooterDataForInvoice
} from "../../../../models/invoices/helper/invoices.helper";
import {observer} from "mobx-react";
import {ColumnsType} from "antd/lib/table";
import {ExpandedTable} from "../../../core/commons/expanded-table/expanded.table";
import {InvoiceItem} from "../../../../models/invoices/invoice";

export const InvoiceDetails: React.FC = observer(() => {
    const {store} = useRequiredLayers(InvoiceStoreContext, InvoiceControllerContext);
    const {t} = useTranslation("translation", {useSuspense: false});

    const columns: ColumnsType<InvoiceItem> = [
        // {
        //     title: t("CLIENT.ORDER.NUMBER"),
        //     dataIndex: "referenceOrderNumber",
        //     key: "invoice_order_detail_referenceOrderNumber",
        //     width: 120
        // },
        {
            title: t("ORDERS.ORDER.ID"),
            dataIndex: "referenceOrderId",
            key: "invoice_order_detail_referenceOrderId",
            width: 120,
            render: item => item ? <Link className="link" to={"/orders/" + item}>{item}</Link> : null
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "invoice_order_detail_articleName",
            width: "135px",
            render: (article: string) => {
                return article ? article.toUpperCase() === "DELIVERY" ? t("DELIVERY.TITLE") : (
                    <Link
                        className="link"
                        to={"/catalog/article/" + article}
                        style={{whiteSpace: "nowrap"}}
                    >
                        {article}
                    </Link>
                ) : null
            }
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "invoice_order_detail_description",
            width: "300px"
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "invoice_order_detail_quantity",
            width: "120px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "invoice_order_detail_price",
            width: 130,
            render: (price: Amount) => <span>{displayPrice(price)}</span>
            // render: (price: Amount | undefined, row: OrderDataItems) => price ? displayOrderDetailsPrice({type: row.priceType, price}, t("PRICES." + row.priceType.toUpperCase())) : null
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "invoice_order_detail_totalPrice",
            width: 135,
            render: (price: Amount) => <span>{displayPrice(price)}</span>
        },
    ];
    const columnsConfig = [
        {
            title: "ORDERS.ORDER.ID",
            key: "invoice_order_detail_referenceOrderId"
        },

        {
            title: "ARTICLES.TITLE_SINGLE",
            key: "invoice_order_detail_articleName"
        },
        {
            title: "DESCRIPTION",
            key: "invoice_order_detail_description"
        },
        {
            title: "QUANTITY",
            key: "invoice_order_detail_qty"
        },
        {
            title: "PRICE",
            key: "invoice_order_detail_price"
        },
        {
            title: "TOTAL_PRICE",
            key: "invoice_order_detail_totalPrice"
        },
    ];

    return (
        <ExpandedTable<InvoiceItem>
            title="ORDERS.ORDER.DETAILS.TITLE"
            pageKey="invoice"
            columns={columns}
            columnsConfig={columnsConfig}
            tableData={store.loading ? [] : store.invoice.items}
            tableLoading={store.loading}
            tableFooter={store.loading ?
                <Skeleton active/> :
                <DetailsTableFooter data={generateOrderDetailsTableFooterDataForInvoice(store?.invoice)}/>
            }
        />
    );
});
