import React, {useCallback, useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {CatalogSearch} from "../search/catalog.search";
import {CatalogProductList} from "../product/list/catalog.product.list";
import {PaginatedRequest} from "../../../models/requests/paginated-request/paginated.request";
import {CatalogArticle} from "../../../models/articles/catalog/search";
import {Pagination} from "../../../models/requests/paginated-result/paginated.result";
import {SearchParams} from "../model/search";
import {useHistory} from "react-router";
import {useURLParams} from "../../../hooks/url-params.hook";
import {articlesService} from "../../../services/articles/articles.service";
import {serializeValue, urlParamsToInitialValues, valuesToPaginatedRequest} from "./catalog.functions";
import {CatalogSearchResult} from "../search-result/catalog.search-result";
import i18n from "i18next";

export const Catalog: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const history = useHistory();

    const {urlParams, setURLParams} = useURLParams()

    const [request, setRequest] = useState<PaginatedRequest | null>(null);

    const [strictItems, setStrictItems] = useState<CatalogArticle[]>([]);
    const [items, setItems] = useState<CatalogArticle[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [loading, setLoading] = useState<boolean>(false);

    const [view, setView] = useState<"table" | "cards">("cards");

    const update = useCallback((newRequest?: PaginatedRequest) => {
        if (newRequest) {
            if (request && newRequest.isEqual(request)) {
                return
            }
            setRequest(newRequest);
        } else {
            newRequest = request || undefined;
        }

        if (newRequest) {
            setLoading(true);
            articlesService.search(newRequest).then(result => {
                setItems(result.items)
                setPagination(result.meta)
                setLoading(false);
            })
        }
    }, [request])

    const reload = useCallback(() => {
        update();
    }, [update])

    const onPageChange = (page: number, pageSize: number) => {
        setURLParams(urlParams => {
            if (urlParams.limit && pageSize !== parseInt(urlParams.limit as string)) {
                page = 1;
            }
            return {...urlParams, page: page, limit: pageSize}
        })
    }

    const updateSearchParams = (params: SearchParams) => {
        const result: any = {...params};
        delete result.searchString;

        if (params.searchString) {
            const [t, i, s, v] = params.searchString;
            if (t === "f") {
                history.push('/catalog/article/' + s)
                return
            }
            result.searchString = serializeValue(params.searchString);
        }

        setURLParams(urlParams => ({...result, page: urlParams.page || 1, limit: urlParams.limit || 20}))
    }

    useEffect(() => {
        const newRequest = valuesToPaginatedRequest(urlParams);
        if (newRequest) {
            update(newRequest);
        }

    }, [urlParams, update])

    useEffect(() => {
        if (request && request.filters.searchString) {
            articlesService.strictSearch(request.filters.searchString).then(items => setStrictItems(items));
        } else {
            setStrictItems([]);
        }
    }, [request])

    useEffect(() => {
        i18n.on("languageChanged", reload);
        return () => i18n.off("languageChanged", reload)
    }, [reload]);

    return (
        <>
            <CatalogSearch
                onChange={updateSearchParams}
                defaultValues={urlParamsToInitialValues(urlParams)}
            />
            <div className="catalog_content_block">
                <CatalogProductList loading={loading} onViewChange={setView} pagination={pagination} />
                {pagination ? (
                    <CatalogSearchResult loading={loading} view={view} strictItems={strictItems} items={items} pagination={pagination} onPageChange={onPageChange} />
                ) : null}
            </div>
        </>
    )
}