import {Warehouse} from "../../models/warehoues/warehouse";
import {apiService} from "../api.service";


export class WarehousesService {
    public async getWarehouses(): Promise<Warehouse[]> {
        return (await apiService.get<Warehouse[]>("warehouses/all")).data;
    }
}

export const warehousesService = new WarehousesService();
