import {WishlistService} from "../../../services/wishlist/wishlist.service";
import {WishListStore} from "./wish-list.store";
import React, {Context} from "react";
import {WishlistItem} from "../../../models/wishlist/wishlist.item";
import {WarehousesService} from "../../../services/warehouses/warehouses.service";

export class WishListController {
    constructor(
        private readonly _wishlistStore: WishListStore,
        private readonly _wishlistService: WishlistService,
        private readonly _warehousesService: WarehousesService
    ) {
        this.onLngChange = this.onLngChange.bind(this);
        this.loadData();
    }

    public onLngChange(): void {
        this.reloadData();
    }
    
    private async reloadData(): Promise<void> {
        this._wishlistStore.wishListDataHandler.setLoading(true);
        await this.loadItems();
        this._wishlistStore.wishListDataHandler.setLoading(false);
    }

    private async loadItems(): Promise<void> {
        await this._wishlistStore.wishListDataHandler.reloadItems(this._wishlistStore.wishListRequest);
    }

    public async removeItem(item: WishlistItem): Promise<void> {
        this._wishlistStore.wishListDataHandler.setLoading(true);
        await this._wishlistService.removeWishListItem(item.article.id);
        this._wishlistStore.wishListDataHandler.removeItem(item);
        this._wishlistStore.wishListDataHandler.setLoading(false);
    }

    public async clearWishList(): Promise<void> {
        this._wishlistStore.wishListDataHandler.setLoading(true);
        await this._wishlistService.clearList("wishlist");
        await this._wishlistStore.wishListDataHandler.setResult({meta: {page:1, total: 1, totalPages:1, limit: 20}, items: []});
        this._wishlistStore.wishListDataHandler.setLoading(false);
    }

    public onArticleCheck(articles: number[]): void {
        this._wishlistStore.setSelectedItems(articles);
    }

    public async addToCartItems(): Promise<void> {
        await this._wishlistService.takeToCart({items: this._wishlistStore.selectedItems}, "wishlist");
        await this._wishlistStore.wishListDataHandler.reloadItems(this._wishlistStore.wishListRequest);
    }

    private async loadData(): Promise<void> {
        this._wishlistStore.wishListDataHandler.setLoading(true);
        await this.loadItems();
        this._wishlistStore.setWarehouses(await this._warehousesService.getWarehouses())
        this._wishlistStore.wishListDataHandler.setLoading(false);
    }
}

export const WishlistControllerContext = React.createContext<null | WishListController>(null) as Context<WishListController>;
