import { downloadBlobFile } from "../../../../utils/download.blob";
import { Messages } from "./export.blob";
import { ExportBlobStore } from "./export.blob.store";

export class ExportBlobController<V> {
    private messageKey: string = "exportMessage";

    constructor(
        private readonly _messages: Messages,
        private _exportBlobStore: ExportBlobStore,
        private onSubmit: (values: V) => Promise<any>,
        private onInit?: () => Promise<any> | any
    ) {
        this._exportBlobStore.setDefaultValues(null);
        this.init();
    }

    private async init() {
        if (this.onInit) {
            this._exportBlobStore.setLoading(true);
            this._exportBlobStore.setDefaultValues({ ...(await this.onInit()) });
            this._exportBlobStore.setLoading(false);
        }
    }

    public async submit(values: V) {
        this._messages.loading("DOWNLOAD.BEGINS", this.messageKey, 6000);
        try {
            const { data: res, headers }: { data: Blob; headers: any } = await this.onSubmit(values);
            const [_, fileName] = headers["content-disposition"].split("; ");

            downloadBlobFile(res, fileName.split("=")[1].replace(/"/g, ""));

            this._messages.success("SUCCESS", this.messageKey, 3);
            this._exportBlobStore.setDefaultValues(null);
        } catch (e) {
            this._messages.error("ERROR", this.messageKey, 3);
        }
    }
}
