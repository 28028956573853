import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {Access} from "../../../../models/accesses/access";
import {useTranslation} from "react-i18next";
import {AccessesControllerContext} from "../accesses.controller";
import {Button, Card, Col, Popconfirm, Space, Tooltip, Typography} from "antd";
import {CustomCard} from "../../../core/commons/custom-card/custom.card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {AccessesModal} from "../accesses-modal/accesses.modal";
import {Date} from "../../../core/commons/date/date";

interface AccessItemProps {
    access: Access
}
export const AccessItem: React.FC<AccessItemProps> = observer(({access}) => {
    const controller = useContext(AccessesControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    const cardRowsFirst = [
        {key: "email"},
        {key: "createdAt", label: "CREATED_AT"}
    ]
    const cardData = {
        email: access.user.email,
        createdAt: <Date dateString={access.user.createdAt!} showTime={false}/>
    }

    return (
        <>
            <Col className="card-col"  xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card
                    className={"card"}
                    title={<Typography.Title level={5}>{access.user.firstName + " " + access.user.lastName}</Typography.Title>}
                    extra={
                        <Space direction="horizontal" size={[5, 5]} align="center" style={{width: "auto"}}>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    size={"small"}
                                    className={"card-btn btn"}
                                    onClick={() => setModalShown(true)}
                                    type="default"
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                                />
                            </Tooltip>
                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => controller.removeAccess(access)}
                                placement="top"
                                okText={t("YES")}
                                cancelText={t("NO")}
                            >
                                <Tooltip placement="topRight" title={t("REMOVE")}>
                                    <Button
                                        className={"card-btn btn-remove btn"}
                                        type="default"

                                        icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                    />
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    }
                >
                    <CustomCard data={cardData} rowKeys={cardRowsFirst}/>
                    <table style={{marginTop: 10}}>
                        <thead>
                        <tr style={{width: "100%"}}>
                            <td style={{padding: "1px 20px 1px 1px", width: "50%"}}>{t("SYSTEM")}:</td>
                            <td style={{padding: "1px 3px 1px 1px", width: "50%"}}>{t("GROUP.TITLE")}:</td>
                        </tr>
                        </thead>
                        <tbody>
                        {access.systemAccesses.map(item => (
                            <tr key={item.system.id} style={{width: "100%"}}>
                                <td style={{padding: "1px 20px 1px 1px", color: "#939393", width: "50%"}}>{item.system.name}:</td>
                                <td style={{padding: "1px 3px 1px 1px", color: "#939393", width: "50%"}}>{item.group.name}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Card>
            </Col>
            <AccessesModal
                access={access}
                visible={modalShown}
                onClose={() => setModalShown(false)}
            />
        </>
    );
})