import {ContactsStore} from "./contacts.store";
import {ContactsService} from "../../../services/contacts/contacts.service";
import {Contact} from "../../../models/contacts/contact";
import React from "react";

export class ContactsController {
    constructor(
        private readonly _contactsStore: ContactsStore,
        private readonly _contactsService: ContactsService
    ) {
        this.loadData();
    }

    public async createContact(values: Contact): Promise<void> {
        this._contactsStore.setContacts([...this._contactsStore.contacts, await this._contactsService.postContact(values)]);
    }

    public async editContact(values: Contact, id: number): Promise<void> {
        const editedContact = await this._contactsService.postContact(values, id);
        const index = this._contactsStore.contacts.findIndex(item => item.id === id);
        if (index !== -1 && index) {
            this._contactsStore.editContact({...editedContact}, index)
        }
    }

    public async removeContact(contact: Contact): Promise<void> {
        const index = this._contactsStore.contacts.findIndex(item => item.id === contact.id);
        const newContacts = [...this._contactsStore.contacts];
        if (index >= 0) {
            await this._contactsService.deleteContact(contact.id);
            newContacts.splice(index, 1);
            this._contactsStore.setContacts(newContacts);
        }
    }

    private async loadData(): Promise<void> {
        this._contactsStore.setLoading(true);
        this._contactsStore.setContacts(await this._contactsService.getContacts());
        this._contactsStore.setLoading(false);
    }
}

export const ContactsControllerContext = React.createContext<null | ContactsController>(null) as React.Context<ContactsController>