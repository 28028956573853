import React from "react";
import {AddressesStore} from "./addresses.store";
import {AddressesService} from "../../../services/addresses/addresses.service";
import {Address} from "../../../models/addresses/address";

export class AddressesController {
    constructor(
        private readonly _addressesStore: AddressesStore,
        private readonly _addressesService: AddressesService
    ) {
        this.loadData();
    }

    public async setDefaultAddress(address: Address): Promise<void> {
        this.resetDefaultAddressByType(address.type);
        const index = this._addressesStore.addresses.findIndex(item => item.id === address.id);
        if (index !== -1 && index !== undefined) {
            await this._addressesService.setDefaultAddress(address.id);
            this._addressesStore.editAddress({...address, default: true}, index);
        }
    }

    private resetDefaultAddressByType(type: string): void {
        this._addressesStore.setAddresses(this._addressesStore.addresses.map(address => {
            if (address.type === type) {
                return {
                    ...address,
                    default: false
                }
            }
            return address;
        }));
    }

    public async createAddress(values: Address): Promise<void> {
        this._addressesStore.setAddresses([...this._addressesStore.addresses, await this._addressesService.createAddress(values)]);
    }

    public async editAddress(values: any, id: number): Promise<number> {
        const {address, resultCode} = await this._addressesService.editAddress(values, id);
        const index = this._addressesStore.addresses.findIndex(item => item.id === id);
        if (index >= 0) {
            this._addressesStore.editAddress(address, index);
        }

        return resultCode;
    }

    public async removeAddress(address: Address): Promise<void> {
        const index = this._addressesStore.addresses.findIndex(item => item.id === address.id);
        const newAddresses: Address[] = [...this._addressesStore.addresses];
        if (index >= 0) {
            await this._addressesService.removeAddress(address.id);
            newAddresses.splice(index, 1);
            this._addressesStore.setAddresses(newAddresses);
        }
    }

    private async loadData(): Promise<void> {
        this._addressesStore.setLoading(true);
        this._addressesStore.setAddresses(await this._addressesService.getAddresses());
        this._addressesStore.setLoading(false);
    }
}

export const AddressesControllerContext = React.createContext<null | AddressesController>(null) as React.Context<AddressesController>