import React, {useState} from "react";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {OrderComments} from "./order-comments/order.comments";
import {OrderCredentials} from "./order-credentials/order.credentials";
import {OrderHeader} from "./order-header/order.header";
import {Space} from "antd";
import {IdParams} from "../../../models/core/route-params/id-params/id.params";
import {OrderDataStore, OrderDataStoreContext} from "./order.data.store";
import {OrderDataController, OrderDataControllerContext} from "./order.data.controller";
import {ordersService} from "../../../services/orders/orders.service";
import "./order.scss";
import {Details} from "./order-details/order.details";
import {OrderShipments} from "./order-shipments/order.shipments";

export const Order: React.FC = observer(() => {
    const {id} = useParams<IdParams>();
    const [store] = useState(() => new OrderDataStore());
    const [controller] = useState(() => new OrderDataController(id, store, ordersService));

    return (
        <OrderDataStoreContext.Provider value={store}>
            <OrderDataControllerContext.Provider value={controller}>
                <div className="page_content order">
                    <Space direction="vertical" size={[30, 30]} className="content">
                        <OrderHeader/>
                        <OrderComments/>
                        <OrderCredentials/>
                        <OrderShipments/>
                        <Details/>
                    </Space>
                </div>
            </OrderDataControllerContext.Provider>
        </OrderDataStoreContext.Provider>
    );
})
