import React, {useEffect, useState} from "react";
import {WidgetProps} from "../../../../../core/ui/widget/widget";
import {useTranslation} from "react-i18next";
import {Select} from "antd";
import {articlesService} from "../../../../../../services/articles/articles.service";
import {Manufacturer} from "../../../../../../models/articles/manufacturers/manufacturer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface UIInputWidgetManufacturerProps extends WidgetProps {
    onClear?: () => void;
    onSelect?: () => void;
}

export const UIInputWidgetManufacturer: React.FC<UIInputWidgetManufacturerProps> = ({value, onChange, multiple, onClear, onSelect}) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const [values, setValues] = useState<Manufacturer[]>([]);

    useEffect(() => {
        articlesService.getManufacturers().then(values => setValues(values))
    }, [])

    return (
        <Select
            suffixIcon={<FontAwesomeIcon icon={["fas", "angle-down"]} />}
            bordered={false}
            className="selects_block_select"
            dropdownMatchSelectWidth={200}
            showSearch
            optionFilterProp={"children"}
            onSelect={onSelect}
            onClear={onClear}
            allowClear
            placeholder={t("ARTICLES.MANUFACTURER")}
            value={value}
            onChange={onChange}
        >
            {values.map(({id, name}) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
        </Select>
    )
}