import React from "react";
import "./catalog.product.cart.scss"
import {Col, Row, Space} from "antd";
import {CatalogProductCartProductInfo} from "./product-info/catalog.product.cart.product-info";
import {CatalogProductCartStockInfo} from "./stock-info/catalog.product.cart.stock-info";
import {CatalogProductCartProductInCartQty} from "./in-cart-qty/catalog.product.cart.in-cart-qty";
import {CatalogProductCartPrice} from "./price/catalog.product.cart.price";
import {CatalogProductCartProductAddToWishlist} from "./add-to-wishlist/catalog.product.cart.add-to-wishlist";
import {CatalogArticle} from "../../../../models/articles/catalog/search";
import {CatalogProductCartImage} from "./image/catalog.product.cart.image";
import {Link} from "react-router-dom";

interface CatalogProductCartProps {
    article: CatalogArticle;
}

export const CatalogProductCart: React.FC<CatalogProductCartProps> = ({article}) => {

    return (
        <div className={"catalog_content_card"}>
            <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
                    <span className={"content-card-header"}>
                        <Link to={"/catalog/article/" + article.name}>{article.name} - {article.description}</Link>
                    </span>
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                    <CatalogProductCartProductAddToWishlist
                        article={article}
                    />
                </Col>
            </Row>
            <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7} style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                    <CatalogProductCartImage article={article} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <CatalogProductCartStockInfo
                        article={article}
                    />
                    <CatalogProductCartProductInfo
                        title={"Product info"}
                        values={article.attributes.map(v => ({title: v.name, description: v.value}))}
                    />
                    <CatalogProductCartProductInfo
                        title={"Compatibility info"}
                        values={article.analogues.map(v => ({title: v.brand, description: v.name, highlighted: v.matched}))}
                    />
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                    <Space direction={"vertical"}>
                        <CatalogProductCartPrice
                            article={article}
                        />
                        <CatalogProductCartProductInCartQty
                            article={article}
                        />
                    </Space>
                </Col>
            </Row>
        </div>
    )
}