import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { MergedInvoiceStore, MergedInvoiceStoreContext } from "./merged.invoice.store";
import { MergedInvoiceController } from "./merged.invoice.controller";
import { mergedInvoicesService } from "../../../services/invoices/merged.invoices.service";
import { MergedInvoiceHeader } from "./merged-invoice-header/merged.invoice.header";
import { Space } from "antd";
import { MergedInvoiceCredentials } from "./merged-invoice-credentials/merged.invoice.credentials";
import { MergedInvoiceIncludedInvoices } from "./merged-invoice-included-invoices/merged.invoice.included.invoices";
import { MergedInvoiceDetails } from "./merged-invoice-details/merged.invoice.details";
import { RootStoreContext } from "../../../stores/root/root";
import { isAllowed } from "../../../utils/helpers";
import { MergedInvoiceInvludedOrders } from "./merged-invoice-included-orders/merged.invoice.included.orders";

export const MergedInvoice: React.FC = observer(() => {
    const { id } = useParams<{ id: string }>();
    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);
    const [store] = useState(() => new MergedInvoiceStore());
    const [controller] = useState(() => new MergedInvoiceController(id, store, mergedInvoicesService));

    return (
        <MergedInvoiceStoreContext.Provider value={store}>
            <div className="page_content invoice">
                <Space direction="vertical" size={[30, 30]} className="content">
                    <MergedInvoiceHeader />
                    <MergedInvoiceCredentials />
                    {isAllowed(attributes, "merged_invoice_list") ? (
                        <MergedInvoiceInvludedOrders />
                    ) : (
                        <MergedInvoiceIncludedInvoices />
                    )}

                    <MergedInvoiceDetails />
                </Space>
            </div>
        </MergedInvoiceStoreContext.Provider>
    );
});
