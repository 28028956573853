import {ComponentPropsFromRoute} from "../../../routers/routers";
import React, {useMemo} from "react";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import {Space, Typography} from "antd";
import {FilterDataController} from "../../../controllers/filtered-table/filter.data.controller";
import {BackorderItem} from "../../../models/backorder/backorder.item";
import {backorderService} from "../../../services/backorder/backorder.service";
import {useTranslation} from "react-i18next";
import {columnsFactory} from "./backorder-list.columns";

interface BackorderListProps extends ComponentPropsFromRoute {}

export const BackorderList: React.FC<BackorderListProps> = () => {

    const {t} = useTranslation("translation", {useSuspense: false});

    const dataHandler: FilterDataController<BackorderItem> = useMemo(() => new FilterDataController(request => backorderService.getList(request)), []);

    const columns = useMemo(() => columnsFactory(t), []);

    return (
        <>
            <FilteredTable
                columns={columns}
                dataHandler={dataHandler}
            />
        </>
    )
}