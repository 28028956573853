import {RegistrationService} from "../../../services/registration/registration.service";
import {RegistrationValues} from "../../../models/registration/registration.values";
import React, {Context} from "react";
import {AuthStore} from "../../../stores/auth/auth.store";

export class RegistrationController {
    constructor(
        private readonly _registrationService: RegistrationService,
        private readonly _authStore: AuthStore
    ) {
    }

    public async register(values: RegistrationValues, checked: boolean): Promise<void> {
        if (checked) {
            values = {...values, deliveryAddress: values.billingAddress};
        }
        await this._registrationService.signup(values);
        localStorage.setItem("registered", "1");
        this._authStore.setRegistered(true);
    }
}

export const RegistrationControllerContext = React.createContext<null | RegistrationController>(null) as Context<RegistrationController>;
