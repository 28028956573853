import React, { useContext, useState } from "react";
import { Integration } from "../../../../models/integrations/integration";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Select, Tooltip, Typography } from "antd";
import { NumberInput } from "../../../core/commons/number-input/number.input";
import { ErrorResponseData } from "../../../../models/core/response";
import { AxiosError } from "axios";
import { IntegrationsStoreContext } from "../integrations.store";
import { useRequiredLayers } from "../../../../utils/context";
import { IntegrationsControllerContext } from "../integrations.controller";
import { renderSortButtons } from "../../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootStoreContext } from "../../../../stores/root/root";

interface MyProfileIntegrationModalProps {
    visible: boolean;
    onClose: () => void;
    item?: Integration;
}
export const IntegrationsModal: React.FC<MyProfileIntegrationModalProps> = observer(({ visible, onClose, item }) => {
    const { t } = useTranslation("translation", { useSuspense: false });
    const {
        langStore: { locales },
    } = useContext(RootStoreContext);
    const { store, controller } = useRequiredLayers(IntegrationsStoreContext, IntegrationsControllerContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [connectionTestButtonLoading, setConnectionTestButtonLoading] = useState<boolean>(false);
    const [integrationType, setIntegrationType] = useState<string>(item ? item.type : "ftp_rrp");
    const [form] = Form.useForm();

    const [alertData, setAlertData] = useState<{ result: boolean; error: string } | null>(null);

    const separateWarehousesDefaultFields = [
        { name: "ID", visibleName: "ID" },
        { name: "Reference", visibleName: "Reference" },
        { name: "Description", visibleName: "Description" },
        { name: "Price", visibleName: "Price" },
        { name: "RRP Price", visibleName: "RRP Price" },
        { name: "HS code", visibleName: "HS code" },
        { name: "EAN code", visibleName: "EAN code" },
        { name: "Stock", visibleName: "Amount" }
    ];

    const allWarehousesDefaultFields = [
        { name: "ID", visibleName: "ID" },
        { name: "Reference", visibleName: "Reference" },
        { name: "Description", visibleName: "Description" },
        { name: "Price", visibleName: "Price" },
        { name: "RRP Price", visibleName: "RRP Price" },
        { name: "HS code", visibleName: "HS code" },
        { name: "EAN code", visibleName: "EAN code" },
        ...store.warehouses.map((warehouse, index) => ({
            name: "Stock_" + warehouse.id,
            visibleName: warehouse.name,
            sort: index,
        }))
    ];

    const defaultValues: any = item
        ? {
              ...item,
              fields: item.fields
                  .slice()
                  .sort((a, b) => a.sort! - b.sort!)
                  .map(({ name, visibleName, sort }) => ({
                      name,
                      visibleName,
                      sort,
                  })),
          }
        : {
              connectionType: "ftp",
              type: "ftp_rrp",
              ftpPort: 21,
              ftpPassword: "",
              ftpPathToFolder: "",
              active: true,
              // fileName: "febest_products_data_{warehouse_id}.csv",
              fileLanguage: "en",
              fileName: "",
              fileColumnSeparator: ";",
              filePriceSeparator: ",",
              fileAddDateLine: false,
              fileAddSeparatorLine: false,
              frequency: 6,
              fields: allWarehousesDefaultFields,
          };

    if (item && item.type === "ftp_separate_warehouses") {
        defaultValues.excludedWarehouses = item.excludedWarehouses.map((item) => item.id);
    }

    const checkPort = (_: any, value: string) => {
        if (!value) {
            return Promise.reject(new Error(t("FROM.ERROR.PLEASE_ENTER_VALUE")));
        }
        if (parseInt(value, 10) < 1 || parseInt(value, 10) > 65535) {
            return Promise.reject(new Error(t("CLIENT.INTEGRATIONS.FTP_PORT_INVALID")));
        }
        return Promise.resolve();
    };

    const getWarehouseName = (stockFieldName: string): string => {
        const id = stockFieldName.replace("Stock_", "");
        const warehouse = store.warehouses.find((warehouse) => warehouse.id === id);
        if (warehouse) {
            return warehouse.name;
        }
        return "";
    };

    const onSubmit = (values: any): Promise<any> =>
        item ? controller.updateIntegration(values, item.id) : controller.createIntegration({ ...values });

    // "ftp_separate_warehouses" - CSV FTP Separate Warehouses Integration
    // "ftp_rrp" - CSV FTP All Warehouses Integration

    const separateWarehousesFieldsOptions = [
        { value: "ID", label: "ID" },
        { value: "Reference", label: "Reference" },
        { value: "Description", label: "Description" },
        { value: "Price", label: "Price" },
        { value: "RRP Price", label: "RRP Price" },
        { value: "HS code", label: "HS code" },
        { value: "EAN code", label: "EAN code" },
        { value: "Stock", label: "Stock" },
        { value: "Net Weight", label: "Net Weight"},
        { value: "Gross Weight", label: "Gross Weight"},
        { value: "Width",  label: "Width"},
        { value: "Height", label: "Height"},
        { value: "Length", label: "Length"},
        { value: "Multiplicity", label: "Multiplicity" },
        { value: "", label: "Custom" },
    ];

    const allWarehousesFieldsOptions = [
        { value: "ID", label: "ID" },
        { value: "Reference", label: "Reference" },
        { value: "Description", label: "Description" },
        { value: "Price", label: "Price" },
        { value: "RRP Price", label: "RRP Price" },
        { value: "HS code", label: "HS code" },
        { value: "EAN code", label: "EAN code" },
        ...store.warehouses.map((warehouse) => ({
            value: "Stock_" + warehouse.id,
            label: "Stock_" + warehouse.id,
        })),
        { value: "Net Weight", label: "Net Weight"},
        { value: "Gross Weight", label: "Gross Weight"},
        { value: "Width",  label: "Width"},
        { value: "Height", label: "Height"},
        { value: "Length", label: "Length"},
        { value: "Multiplicity", label: "Multiplicity" },
        { value: "", label: "Custom" },
    ];

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            confirmLoading={loading}
            title={t(item ? "CLIENT.INTEGRATIONS.EDIT" : "CLIENT.INTEGRATIONS.ADD")}
            okText={t(item ? "SAVE" : "ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                setAlertData(null);
                onClose();
                form.resetFields();
            }}
            width={600}
            style={{ top: 10 }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"integrations_form"}
                initialValues={defaultValues}
                onFinish={() =>
                    form.validateFields().then((values) => {
                        setLoading(true);
                        onSubmit(values)
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                }
                            )
                            .then(
                                () => {},
                                (e) => form.setFields(e.response?.data?.fields)
                            )
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("CLIENT.INTEGRATIONS.NAME")}
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("CLIENT.INTEGRATIONS.NAME")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="frequency"
                            label={t("CLIENT.INTEGRATIONS.PERIODICITY") + " (" + t("HOURS") + ")"}
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                        >
                            <NumberInput disabled={loading} placeholder={t("CLIENT.INTEGRATIONS.PERIODICITY")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="active"
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                            valuePropName="checked"
                        >
                            <Checkbox disabled={loading}>{t("CLIENT.INTEGRATIONS.IS_ACTIVE")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Typography.Title level={5}>{t("CLIENT.INTEGRATIONS.CREDENTIALS.TITLE")}</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="connectionType" label={t("CLIENT.INTEGRATIONS.FTP_CONNECTION.TYPE")}>
                            <Select
                                showSearch
                                allowClear
                                loading={loading}
                                optionFilterProp={"children"}
                                placeholder={t("CLIENT.INTEGRATIONS.FTP_CONNECTION.TYPE")}
                            >
                                {store.integrationConnectionTypes.map(({ value, label }) => (
                                    <Select.Option value={value} key={value}>
                                        {label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="ftpServer">
                            <Input disabled={loading} type="text" addonBefore={t("CLIENT.INTEGRATIONS.FTP_SERVER")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="ftpPort" rules={[{ required: true, validator: checkPort }]}>
                            <Input disabled={loading} type="number" addonBefore={t("CLIENT.INTEGRATIONS.FTP_PORT")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="ftpUser">
                            <Input disabled={loading} type="text" addonBefore={t("CLIENT.INTEGRATIONS.FTP_USER")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="ftpPassword"
                            // rules={[{required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" addonBefore={t("CLIENT.INTEGRATIONS.FTP_PASSWORD")} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="ftpPathToFolder">
                            <Input
                                disabled={loading}
                                type="text"
                                addonBefore={t("CLIENT.INTEGRATIONS.FTP_PATH_TO_FOLDER")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="ftpSSL" valuePropName="checked">
                            <Checkbox disabled={loading}>{t("CLIENT.INTEGRATIONS.FTP_SLL_STATUS")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="ftpPassiveMode" valuePropName="checked">
                            <Checkbox disabled={loading}>{t("CLIENT.INTEGRATIONS.FTP_PASSIVE_MODE")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Button
                                loading={connectionTestButtonLoading}
                                disabled={loading}
                                type="primary"
                                onClick={() => {
                                    setConnectionTestButtonLoading(true);
                                    const {
                                        connectionType,
                                        ftpServer,
                                        ftpPort,
                                        ftpUser,
                                        ftpPassword = "",
                                        ftpPathToFolder,
                                        ftpSSL,
                                        ftpPassiveMode,
                                    } = form.getFieldsValue();
                                    controller
                                        .testIntegrationConnection(
                                            {
                                                connectionType,
                                                ftpServer,
                                                ftpPort,
                                                ftpUser,
                                                ftpPassword,
                                                ftpPathToFolder,
                                                ftpSSL,
                                                ftpPassiveMode,
                                            },
                                            item ? item.id : undefined
                                        )
                                        .then(
                                            (data) => {
                                                setAlertData(data.data);
                                                setConnectionTestButtonLoading(false);
                                            },
                                            (e: AxiosError<ErrorResponseData>) => {
                                                if (e.response?.data.fields) {
                                                    form.setFields(e.response?.data?.fields);
                                                    setConnectionTestButtonLoading(false);
                                                }
                                            }
                                        )
                                        .then(
                                            () => {},
                                            (e) => form.setFields(e.response?.data?.fields)
                                        );
                                }}
                            >
                                {t("CLIENT.INTEGRATIONS.FTP_CHECK_CONNECTION")}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        {alertData !== null ? (
                            <Form.Item>
                                {alertData.result ? (
                                    <Alert
                                        onClose={() => setAlertData(null)}
                                        message={t("CLIENT.INTEGRATIONS.FTP_CONNECTION_SUCCESS")}
                                        type="success"
                                        showIcon
                                        closable
                                    />
                                ) : (
                                    <Alert
                                        onClose={() => setAlertData(null)}
                                        message={t("ERROR")}
                                        type="error"
                                        showIcon
                                        closable
                                        description={alertData.error}
                                    />
                                )}
                            </Form.Item>
                        ) : null}
                    </Col>
                    <Col span={24}>
                        <Typography.Title level={5}>{t("CLIENT.INTEGRATIONS.FILE_SETTINGS")}</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="type"
                            label={t("CLIENT.INTEGRATIONS.TYPE")}
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp={"children"}
                                disabled={loading}
                                placeholder={t("CLIENT.INTEGRATIONS.TYPE")}
                                onChange={(value: string) => {
                                    setIntegrationType(value);
                                    const formFields = form.getFieldsValue();
                                    const integrationFields = formFields.fields;
                                    if (integrationFields) {
                                        form.setFieldsValue({
                                            ...formFields,
                                            fields:
                                                value === "ftp_rrp"
                                                    ? allWarehousesDefaultFields
                                                    : separateWarehousesDefaultFields,
                                        });
                                    }
                                }}
                            >
                                <Select.Option value={"ftp_separate_warehouses"}>
                                    CSV FTP Separate Warehouses Integration
                                </Select.Option>
                                <Select.Option value={"ftp_rrp"}>CSV FTP All Warehouses Integration</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, nextValues, info) => prevValues.type !== nextValues.type}
                        >
                            {({ getFieldValue }) =>
                                getFieldValue("type") === "ftp_separate_warehouses" ? (
                                    <Alert
                                        style={{ marginBottom: 24 }}
                                        message={t("CLIENT.INTEGRATIONS.FTP_FILE_NAME_WARNING")}
                                        type={"warning"}
                                    />
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="fileName"
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                addonBefore={t("CLIENT.INTEGRATIONS.FTP_FILE_NAME")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="fileColumnSeparator"
                            // label={t("CLIENT.INTEGRATIONS.TYPE")}
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                addonBefore={t("CLIENT.INTEGRATIONS.FTP_COLUMN_SEPARATOR")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="filePriceSeparator"
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                        >
                            <Input
                                disabled={loading}
                                type="text"
                                addonBefore={t("CLIENT.INTEGRATIONS.FTP_PRICE_SEPARATOR")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="fileAddSeparatorLine"
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                            valuePropName="checked"
                        >
                            <Checkbox disabled={loading}>
                                {t("CLIENT.INTEGRATIONS.FTP_FILE_ADD_SEPARATOR_LINE")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="fileAddDateLine"
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                            valuePropName="checked"
                        >
                            <Checkbox disabled={loading}>{t("CLIENT.INTEGRATIONS.FTP_FILE_ADD_DATE_LINE")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="fileLanguage"
                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                            label={t("LANGUAGES.SINGLE_TITLE")}
                        >
                            <Select disabled={loading} placeholder={t("LANGUAGES.SINGLE_TITLE")}>
                                {locales.map((locale) => (
                                    <Select.Option value={locale} key={locale}>
                                        {locale.toUpperCase()}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.List
                            name={"fields"}
                            rules={[
                                {
                                    validator: async (rule, names) => {
                                        try {
                                            if (!names || names.length === 0) {
                                                return Promise.reject(t("FROM.ERROR.PLEASE_ADD_VALUE"));
                                            }
                                        } catch (error) {
                                            throw new Error(String(error));
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Row>
                                        <Col span={4} />
                                        <Col span={9}>
                                            <span style={{ fontWeight: "bold" }}>{t("COLUMN.VALUE")}</span>
                                        </Col>
                                        <Col span={9}>
                                            <span style={{ fontWeight: "bold" }}>{t("COLUMN.NAME")}</span>
                                        </Col>
                                    </Row>
                                    {fields.map((field, index) => (
                                        <Row justify="space-between" gutter={[5, 0]} key={field.key} align="top">
                                            <Form.Item noStyle shouldUpdate={true}>
                                                {({ getFieldValue, setFieldsValue }) => (
                                                    <>
                                                        {renderSortButtons(
                                                            form,
                                                            index,
                                                            fields.length,
                                                            loading,
                                                            "fields",
                                                            "fields"
                                                        )}
                                                        <Col span={9}>
                                                            <Form.Item
                                                                {...field}
                                                                fieldKey={[field.key, "name"]}
                                                                name={[field.name, "name"]}
                                                            >
                                                                {getFieldValue(["fields", index, "name"]) !==
                                                                    undefined &&
                                                                (getFieldValue(["fields", index, "name"]) === "" ||
                                                                    // form.getFieldInstance(["fields", index, "name"]).label === "Custom" &&
                                                                    ![
                                                                        ...separateWarehousesFieldsOptions,
                                                                        ...allWarehousesFieldsOptions,
                                                                    ]
                                                                        .map((item) => item.value)
                                                                        .includes(
                                                                            getFieldValue(["fields", index, "name"])
                                                                        )) ? (
                                                                    <Input
                                                                        disabled={loading}
                                                                        type="text"
                                                                        placeholder="Custom"
                                                                    />
                                                                ) : (
                                                                    <Select
                                                                        showSearch
                                                                        optionFilterProp={"children"}
                                                                        disabled={loading}
                                                                        options={
                                                                            integrationType === "ftp_rrp"
                                                                                ? allWarehousesFieldsOptions
                                                                                : separateWarehousesFieldsOptions
                                                                        }
                                                                        onSelect={(value: string) => {
                                                                            const fields: {
                                                                                name: string;
                                                                                visibleName: string;
                                                                            }[] = form.getFieldsValue().fields;
                                                                            const fieldItem = fields[index];

                                                                            fields[index] = {
                                                                                ...fieldItem,
                                                                                visibleName:
                                                                                    fieldItem.name === "Stock"
                                                                                        ? "Amount"
                                                                                        : fieldItem.name.includes(
                                                                                              "Stock_"
                                                                                          )
                                                                                        ? getWarehouseName(
                                                                                              fieldItem.name
                                                                                          )
                                                                                        : value,
                                                                            };
                                                                            setFieldsValue({
                                                                                ...form.getFieldsValue(),
                                                                                fields,
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={9}>
                                                            <Form.Item
                                                                {...field}
                                                                fieldKey={[field.key, "visibleName"]}
                                                                name={[field.name, "visibleName"]}
                                                            >
                                                                <Input disabled={loading} type="text" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={2}>
                                                            <Form.Item {...field}>
                                                                <Tooltip placement="topRight" title={t("DELETE")}>
                                                                    <Button
                                                                        className="form-list-btn"
                                                                        disabled={loading}
                                                                        type="default"
                                                                        onClick={() => remove(field.name)}
                                                                        icon={
                                                                            <FontAwesomeIcon icon={["fas", "times"]} />
                                                                        }
                                                                        danger
                                                                    />
                                                                </Tooltip>
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )}
                                            </Form.Item>
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            disabled={loading}
                                            onClick={() => {
                                                add({});
                                            }}
                                            block
                                            icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                        >
                                            {t("CLIENT.INTEGRATIONS.ADD_FIELD")}
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    {integrationType === "ftp_separate_warehouses" ? (
                        <Col span={24}>
                            <Form.Item label={t("CLIENT.INTEGRATIONS.EXCLUDED_WAREHOUSES")} name={"excludedWarehouses"}>
                                <Select
                                    mode="multiple"
                                    disabled={loading}
                                    placeholder={t("CLIENT.INTEGRATIONS.EXCLUDED_WAREHOUSES")}
                                    showSearch
                                    optionFilterProp={"children"}
                                >
                                    {store.warehouses.map(({ id, name }) => (
                                        <Select.Option key={id} value={id}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : null}
                </Row>
            </Form>
        </Modal>
    );
});
