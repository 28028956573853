import React, { RefObject, useRef } from "react";
import { NotCompletedOrder } from "../../../../../../models/cart/checkout/not.completed.order";
import { CartCheckoutAddress } from "../../../../../../models/cart/checkout/address/cart.checkout.address";
import { observer } from "mobx-react";
import { Card, Checkbox, notification, Row, Typography } from "antd";
import { CustomCard } from "../../../../../core/commons/custom-card/custom.card";
import { useTranslation } from "react-i18next";

interface CartCheckoutAddressesCardProps {
    order: NotCompletedOrder;
    address: CartCheckoutAddress;
    onAddressCardClick: (order: NotCompletedOrder, address: CartCheckoutAddress, checked: boolean) => Promise<void>;
}
export const CartCheckoutAddressesCard: React.FC<CartCheckoutAddressesCardProps> = observer(
    ({ order, address, onAddressCardClick }) => {
        const checkboxRef = useRef(null) as RefObject<any>;
        const { t } = useTranslation("translation", { useSuspense: false });

        const cardRows = [
            { key: "companyName", label: "BUSINESS_NAME" },
            { key: "firstName" },
            { key: "lastName" },
            { key: "vat", label: "VAT.TITLE" },
            { key: "customsCode", label: "EORI" },
            { key: "regCode", label: "REG_NUMBER" },
            { key: "country" },
            { key: "region" },
            { key: "city" },
            { key: "address" },
            { key: "index" },
            { key: "phone" },
            { key: "email" },
        ];

        const openSuccessNotification = (type: "success" | "error", message: string): void => {
            notification[type]({
                message: t(type.toUpperCase()),
                description: message,
            });
        };

        return (
            <Card
                hoverable
                className="card"
                onClick={async () => {
                    await onAddressCardClick(order, address, !address.checked);
                    if (address.type === "shipping") {
                        openSuccessNotification("success", t("CART.DATA_UPDATED.SUCCESS"));
                    }
                }}
                title={
                    <Row align={"middle"} justify={"space-between"}>
                        <Typography.Text title={address.name || address.city} ellipsis={true}>
                            {address.name || address.city}
                        </Typography.Text>
                        <Checkbox className="large" ref={checkboxRef} checked={address.checked} />
                    </Row>
                }
            >
                <CustomCard data={{ ...address, country: address.country.originalName }} rowKeys={cardRows} />
            </Card>
        );
    }
);
