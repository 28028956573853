import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {columnsFactory} from "./request.list.columns";
import {ColumnsType} from "antd/es/table";
import i18n from "i18next";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../../utils/table";
import {FilterDataController} from "../../../../controllers/filtered-table/filter.data.controller";
import FilteredTable from "../../../core/commons/filtered-table/filtered.table";
import {productionService} from "../../../../services/production/production.service";
import {Application} from "../../../../models/production/application";

export const ProductionRequestList: React.FC<ComponentPropsFromRoute> = ({name, requiredConfigs}) => {

    const {t} = useTranslation("translation", {useSuspense: false});

    const columns: ColumnsType<any> = useMemo(() => columnsFactory(t), [i18n.language]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<any>(columns), [i18n.language])

    const dataHandler: FilterDataController<Application> = useMemo(() => new FilterDataController(request => productionService.getApplications(request, requiredConfigs!.type)), [requiredConfigs]);

    return (
        <FilteredTable
            columns={columns}
            columnsConfigs={{columns: columnsConfigs, pageKey: name!}}
            dataHandler={dataHandler}
        />
    );
}
