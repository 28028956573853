import React, {useEffect, useState} from "react";
import {Button, Popconfirm, Row, Space, Tooltip, Typography} from "antd";
import {observer} from "mobx-react";
import {WishListStore} from "./wish-list.store";
import {WishListController} from "./wish-list.controller";
import {WishlistItem, WishlistItemArticle} from "../../../models/wishlist/wishlist.item";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import {FilterKey} from "../../../models/filtered-table/filtered.table";
import {wishlistService} from "../../../services/wishlist/wishlist.service";
import {warehousesService} from "../../../services/warehouses/warehouses.service";
import i18n from "i18next";
import {Link} from "react-router-dom";

export const WishlistList: React.FC = observer(() => {
    const [store] = useState(() => new WishListStore());
    const [controller] = useState(() => new WishListController(store, wishlistService, warehousesService));
    const {t} = useTranslation("translation", {useSuspense: false});

    useEffect(() => {
        i18n.on("languageChanged", controller.onLngChange);
        return () => i18n.off("languageChanged", controller.onLngChange)
    }, []);

    const columns: any = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "article",
            key: "article",
            render: (item: WishlistItemArticle) => item.name !== "DELIVERY" ? <Link to={"/catalog/article/" + item.name} className="link article-link">{item.name}</Link> : item.name
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "article",
            key: "article",
            render: (item: WishlistItemArticle) => <>{item.description}</>
        },

    ];

    store.warehouses.forEach(warehouse => {
        if (warehouse.type !== "consignation") {
            columns.push({
                title: warehouse.name,
                dataIndex: warehouse.id,
                key: warehouse.id,
                render: (_: any, row: WishlistItem) => {
                    const foundWarehouse = row.article.stocks.find(item => item.warehouse.id === warehouse.id);
                    return foundWarehouse ? foundWarehouse.amount : "";
                }
            });
        }
    });

    columns.push({
        title: "",
        dataIndex: "remove",
        key: "remove",
        width: "50px",
        render: (_: any, item: WishlistItem) => (
            <Popconfirm
                title={t("ARE_YOU_SURE")}
                onConfirm={() => controller.removeItem(item)}
                placement="left"
                okText={t("YES")}
                cancelText={t("NO")}
            >
                <Tooltip placement="topRight" title={t("REMOVE")}>
                    <Button className="btn-remove btn" type="default" size={"small"} icon={<FontAwesomeIcon icon={["fas", "times"]}/>}/>
                </Tooltip>
            </Popconfirm>
        )
    });

    const rowSelection: any = {
        type: "checkbox",
        width: 80,
        onChange: (selectedRowKeys: number[], selectedRows: WishlistItem[]) => {
            controller.onArticleCheck(selectedRowKeys);
        },
        getCheckboxProps: (record: WishlistItem) => ({
            disabled: record.article.stocks.every(item => item.amount === 0), // Column configuration not to be checked
            // name: record.article.name,
        }),
    };

    const filters: FilterKey[] = [
        {
            key: "name",
            label: t("ARTICLES.TITLE_SINGLE"),
            type: "string"
        },
        {
            key: "description",
            label: t("DESCRIPTION"),
            type: "string"
        }
    ];

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Typography.Title level={1} className="main-title">{t("WISH_LIST.TITLE")}</Typography.Title>
                <Space className="wish-list-wrapper" direction="vertical" size={[20, 20]} style={{width: "100%"}}>
                    <Row justify="space-between" style={{width: "100%"}}>
                        <Button
                            disabled={!store.wishListDataHandler.items.length || !store.selectedItems.length}
                            onClick={() => controller.addToCartItems()}
                            type="primary"
                        >
                            {t("WISH_LIST.ADD_TO_BASKET")}
                        </Button>
                        <Button
                            disabled={!store.wishListDataHandler.items.length}
                            danger
                            type="primary"
                            onClick={() => controller.clearWishList()}
                        >
                            {t("WISH_LIST.CLEAR")}
                        </Button>
                    </Row>
                    <FilteredTable
                        columns={columns}
                        filterKeys={filters}
                        rowSelection={{
                            type: "checkbox",
                            selectedRowKeys: store.selectedItems,
                            ...rowSelection,
                        }}
                        dataHandler={store.wishListDataHandler}
                    />

                </Space>
            </Space>
        </div>
    );
});
