import React from "react";
import {ClaimItemAcceptStatus, claimItemAcceptStatusColor} from "../../../models/claims/claims";
import {StatusTag} from "../../core/commons/status/status";

interface ClaimsItemAcceptStatusProps {
    status: ClaimItemAcceptStatus;
    large?: boolean;
}
export const ClaimsItemAcceptStatus: React.FC<ClaimsItemAcceptStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<ClaimItemAcceptStatus> status={status} colorsMap={claimItemAcceptStatusColor} large={large} prefix="CLAIMS.CLAIM.ITEM_ACCEPT.STATUS"/>
    )
}
