import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CartStoreContext, CartCreateStore } from "../cart.create.store";
import { Alert } from "antd";
import { useRequiredLayers } from "../../../../utils/context";
import {
    CartCreateController,
    CartCreateControllerContext,
} from "../cart.create.controller";

export const CartCreateMissingItemsAlert: React.FC = observer(() => {
    const { t } = useTranslation("translation", { useSuspense: false });
    const { store } = useRequiredLayers<
        CartCreateStore,
        CartCreateController
    >(CartStoreContext, CartCreateControllerContext);

    if (!store.missingItemsAlertVisible) {
        return null;
    }

    return (
        <Alert
            type="warning"
            message={t("ORDERS.CREATE.MISSING_ITEMS.TITLE")}
            showIcon
        />
    );
});
