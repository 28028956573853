import React from "react";
import {useTranslation} from "react-i18next";
import {Claim} from "../../../../../models/claims/claims";
import {ClaimPart} from "../../part/claims.part";
import {Col, Row} from "antd";
export interface ClaimPartsKanbanViewProps {
    claim: Claim;
    onUpdate: () => void;
}

export const ClaimPartsKanbanView: React.FC<ClaimPartsKanbanViewProps> = ({claim, onUpdate}) => {
    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <>
            <Row gutter={[20, 20]}>
                {claim.items.map((item, key) => (
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} key={key}>
                        <ClaimPart onUpdate={onUpdate} item={item} key={key} number={key+1} />
                    </Col>
                ))}
            </Row>
        </>
    );
}