import { IntegrationsStore } from "./integrations.store";
import { IntegrationsService } from "../../../services/integrations/integrations.service";
import React from "react";
import { Integration, IntegrationConnectionTestValues } from "../../../models/integrations/integration";
import { WarehousesService } from "../../../services/warehouses/warehouses.service";

export class IntegrationsController {
    constructor(
        private readonly _integrationsStore: IntegrationsStore,
        private readonly _integrationsService: IntegrationsService,
        private readonly _warehousesService: WarehousesService
    ) {
        this.createIntegration = this.createIntegration.bind(this);
        this.updateIntegration = this.updateIntegration.bind(this);
        this.getData();
    }

    public async uploadIntegrationData(integration: Integration): Promise<void> {
        return await this._integrationsService.uploadIntegrationData(integration.id);
    }

    public async createIntegration(values: Integration): Promise<any> {
        const integrationWithSortedFields = IntegrationsController.getIntegrationValuesWithSortedFields(values);
        this._integrationsStore.setIntegrations([
            ...this._integrationsStore.integrations,
            await this._integrationsService.postIntegration(integrationWithSortedFields),
        ]);
    }

    public async updateIntegration(values: Integration, id: number): Promise<any> {
        const integrationWithSortedFields = IntegrationsController.getIntegrationValuesWithSortedFields(values);
        const updatedIntegration = await this._integrationsService.postIntegration(integrationWithSortedFields, id);
        const index = this._integrationsStore.integrations.findIndex((item) => item.id === id);
        if (index !== -1 && index) {
            this._integrationsStore.editIntegration(index, updatedIntegration);
        }
    }

    private static getIntegrationValuesWithSortedFields(values: Integration): Integration {
        return {
            ...values,
            fields: values.fields.map((field, index: number) => ({
                ...field,
                sort: index,
            })),
        };
    }

    public async removeIntegration(integration: Integration): Promise<void> {
        const index = this._integrationsStore.integrations.findIndex((item) => item.id === integration.id);
        const newIntegrations: Integration[] = [...this._integrationsStore.integrations];
        if (index !== -1 && index !== undefined) {
            await this._integrationsService.removeIntegration(integration.id);
            newIntegrations.splice(index, 1);
            this._integrationsStore.setIntegrations(newIntegrations);
        }
    }

    public async testIntegrationConnection(values: IntegrationConnectionTestValues, id?: number): Promise<any> {
        return await this._integrationsService.testIntegrationConnection(values, id);
    }

    private async getData(): Promise<void> {
        this._integrationsStore.setLoading(true);
        this._integrationsStore.setWarehouses(await this._warehousesService.getWarehouses());
        this._integrationsStore.setIntegrations(await this._integrationsService.getIntegrations());
        this._integrationsStore.setLoading(false);
    }
}

export const IntegrationsControllerContext = React.createContext<null | IntegrationsController>(
    null
) as React.Context<IntegrationsController>;
