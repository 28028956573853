import {action, computed, makeObservable, observable} from "mobx";
import {Invoice} from "../../../models/invoices/invoice";
import React, {Context} from "react";
import {CreditInvoice} from "../../../models/invoices/credit-invoice/credit.invoice";

export class CreditInvoiceStore {
    @observable
    private _isLoading: boolean = false;

    @observable
    private _invoiceData: CreditInvoice | null = null;

    constructor() {
        makeObservable(this);
    }

    private set isLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }

    private set invoiceData(value: CreditInvoice) {
        this._invoiceData = value;
    }

    @computed
    public get invoice(): CreditInvoice {
        return this._invoiceData!;
    }

    @action
    public setInvoice(value: CreditInvoice): void {
        this.invoiceData = value;
    }
}

export const CreditInvoiceStoreContext = React.createContext<null | CreditInvoiceStore>(null) as Context<CreditInvoiceStore>;
