import {action, computed, makeObservable, observable} from "mobx";
import {Contact} from "../../../models/contacts/contact";
import React from "react";

export class ContactsStore {
    @observable
    private _contacts: Contact[] = [];

    @observable
    private _isLoading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    private set profileContacts(value: Contact[]) {
        this._contacts = value;
    }

    private set isDataLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @computed
    public get contacts(): Contact[] {
        return this._contacts;
    }

    @action
    public setContacts(value: Contact[]): void {
        this.profileContacts = value;
    }

    @action
    public editContact(value: Contact, index: number): void {
        this._contacts[index] = value;
    }

    @action
    public setLoading(value: boolean): void {
        this.isDataLoading = value;
    }
}

export const ContactsStoreContext = React.createContext<null | ContactsStore>(null) as React.Context<ContactsStore>;