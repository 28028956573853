import React, {useState} from "react";
import {Button, Col, Row, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/fontawesome-svg-core";

type TabView = "list" | "kanban";

interface TableViewSwitcherProps {
    listView: React.ReactNode;
    kanbanView: React.ReactNode;
    extra?: React.ReactNode;
    defaultView: TabView;
    disabled: boolean;
}

const tabViewIconsMap: Record<TabView, IconName> = {
    kanban: "border-all",
    list: "list",
}

const TableViewSwitcherButton: React.FC<{view: TabView, selectedView: TabView, onSelected: (view: TabView) => void }>
    = ({view, selectedView, onSelected}) => {
    return <Button
        className={"free_button"}
        type={view === selectedView ? "primary" : "default"}
        icon={<FontAwesomeIcon icon={["fas", tabViewIconsMap[view]]} size={"lg"}/>}
        onClick={() => onSelected(view)}
    />;
}

export const TableViewSwitcher: React.FC<TableViewSwitcherProps> = ({listView, kanbanView, extra, defaultView, disabled}) => {

    const [selectedView, setSelectedView] = useState<TabView>(defaultView);

    const views: TabView[] = ["kanban", "list"];

    return <Row
        gutter={[0, 5]}
        justify={"space-between"}
        wrap
    >
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} flex={1}>
            {extra}
        </Col>
        {!disabled ? (
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} flex={1} style={{textAlign: "right"}}>
            <Space size={[5, 5]} style={{width: "auto"}} >
                {views.map(view => (
                    <TableViewSwitcherButton key={view} view={view} selectedView={selectedView} onSelected={view => setSelectedView(view)} />))
                }
            </Space>
        </Col>): null}
        <Col span={24}>
            {selectedView === "list" ? listView : kanbanView}
        </Col>
    </Row>;
}