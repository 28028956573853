import {ExportBlob} from "../../core/commons/export-blob/export.blob";
import {Checkbox, Col, DatePicker, Form, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useForm} from "antd/es/form/Form";
import React, {useState} from "react";
import {ExportFormat} from "../../documents/documents.store";
import {documentsService} from "../../../services/documents/documents.service";
import moment from "moment";

export interface OrdersExportModalProps {
    onClose: () => void;
    subPage: string;
}

const subPageTypeMap: Record<string, string> = {
    'final-invoices'  : 'merged-invoices',
    'credit-invoices' : 'credit-invoices',
}

export const OrdersExportModal: React.FC<OrdersExportModalProps> = ({onClose, subPage}) => {

    const { t } = useTranslation("translation", { useSuspense: false });
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [form] = useForm();
    const type = subPageTypeMap[subPage];

    return (
        <ExportBlob<{ values: any }>
            title="DOWNLOAD.DO_DOWNLOAD"
            okText="DOWNLOAD.DO_DOWNLOAD"
            submitting={submitting}
            setSubmitting={setSubmitting}
            form={form}
            onSubmit={values => documentsService.downloadDocumentList(type, values)}
            onClose={() => onClose()}
            onInit={() => {
                return Promise.resolve({
                    date: [moment().startOf('month'), moment()],
                    config: {
                        format: 'csv',
                        withHeaders: true
                    }
                })
            }}
        >
            <Row gutter={[5, 5]}>
                <Col span={24}>
                    <Form.Item
                        name={["date"]}
                        label={t("DATE")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <DatePicker.RangePicker />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={["config", "withHeaders"]} valuePropName={"checked"}>
                        <Checkbox disabled={submitting}>{t("DOWNLOAD.WITH_HEADERS")}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={["config", "format"]}
                        label={t("DOWNLOAD.FORMAT")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select disabled={submitting} placeholder={t("DOWNLOAD.FORMAT")}>
                            {[ExportFormat.XLSX, ExportFormat.XLS, ExportFormat.CSV].map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </ExportBlob>
    )
}