import {ParsedQuery} from "query-string";
import {PaginatedRequest} from "../../../models/requests/paginated-request/paginated.request";
import {FieldType, queryParamsGetter, Schema} from "../../../utils/query-params-getter";
import {SearchParams} from "../model/search";
import {splitWithRemainder} from "../../../utils/string";

const schema: Schema = {
    manufacturer: FieldType.INTEGER,
    model: FieldType.INTEGER,
    engine: FieldType.INTEGER,
    group: FieldType.INTEGER,
    subgroup: FieldType.INTEGER,
    searchString: v => parseValue(v)
}

export const urlParamsToInitialValues = (pq: ParsedQuery<string | number>): SearchParams => {
    return queryParamsGetter(pq, schema)
}

export const valuesToPaginatedRequest = (params: ParsedQuery<string | number>): PaginatedRequest => {

    const newRequest = new PaginatedRequest();

    const result: any = urlParamsToInitialValues(params);
    delete result.searchString;

    if (params.page) {
        newRequest.page = parseInt(params.page as string)
    }

    if (params.limit) {
        newRequest.limit = parseInt(params.limit as string)
    }

    if (params.searchString) {
        const [t, i, s, v] = parseValue(params.searchString as string);
        if (t === 'q') {
            result.searchString = v;
        } else if (t === 'a') {
            result.cross = i;
        } else if (t === 'f') {
            throw new Error('Failed')
        }
    }

    newRequest.filters = result;

    return newRequest;
}

export const parseValue = (value: string): SearchValue => {
    const [t, i, s, v] = splitWithRemainder(value, SP, 4);
    return [t as SearchValueType, parseInt(i), s, v];
}

export const serializeValue = (value: SearchValue): string => {
    return value[0] + SP + value[1].toString() + SP + value[2] + SP + value[3];
}

export type SearchValueType = "a" | "f" | "q";

export type SearchValue = [SearchValueType, number, string, string];

const SP = "...";
