import {action, computed, makeObservable, observable} from "mobx";

export class ForgotPasswordCompleteStore {
    @observable
    private _isInited: boolean = false;

    @observable
    private _isCompleted: boolean = false;

    constructor() {
        makeObservable(this);
    }

    private set isInited(value: boolean) {
        this._isInited = value
    }

    @computed
    public get inited(): boolean {
        return this._isInited;
    }

    private set isCompleted(value: boolean) {
        this._isCompleted = value;
    }

    @computed
    public get completed(): boolean {
        return this._isCompleted;
    }

    @action
    public setCompleted(value: boolean): void {
        this.isInited = true;
        this.isCompleted = value;
    }
}