import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {Space} from "antd";
import {ArticleMain} from "./article-main/article.main";
import {ArticleComparison} from "./article-comparison/article.comparison";
import {ArticleVehicles} from "./article-vehicles/article.vehicles";
import {IdParams} from "../../models/core/route-params/id-params/id.params";
import {ArticleStore, ArticleStoreContext} from "./article.store";
import {ArticleController, ArticleControllerContext} from "./article.controller";
import {articlesService} from "../../services/articles/articles.service";
import {warehousesService} from "../../services/warehouses/warehouses.service";
import i18n from "i18next";
import "./article.scss";
import {useTranslation} from "react-i18next";

export const Article: React.FC = observer(() => {
    const {t} = useTranslation('translation', {useSuspense: false});

    const {id} = useParams<IdParams>();
    const [store] = useState(() => new ArticleStore());
    const [controller] = useState(() => new ArticleController(id, store, articlesService, warehousesService));

    useEffect(() => {
        i18n.on("languageChanged", controller.onLngChange);
        return () => i18n.off("languageChanged", controller.onLngChange)
    }, []);

    useEffect(() => {
        if (store.article) {
            document.title = "FEBEST - " + store.article.name + " - " + store.article.description;
        }
    }, [store.article]);

    return (
        <ArticleStoreContext.Provider value={store}>
            <ArticleControllerContext.Provider value={controller}>
                <div className="article page_content">
                    <Space direction="vertical" size={[30, 30]} className="content">
                        <ArticleMain />
                        <ArticleComparison data={store.oem} header={t("ARTICLES.COMPATIBLE_OEM")} />
                        <ArticleComparison data={store.analogues} header={t("ARTICLES.COMPATIBLE_ANALOGUES")} />
                        {store.vehicles.length ? (
                            <ArticleVehicles/>
                        ) : null}
                    </Space>
                </div>
            </ArticleControllerContext.Provider>
        </ArticleStoreContext.Provider>
    );
});
