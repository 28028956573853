import React, {useContext, useEffect, useState} from "react";
import {Button, Form, message, Modal, Row, Space} from "antd";
import {useTranslation} from "react-i18next";
import {RootStoreContext} from "../../../../stores/root/root";
import { ExportBasicFields } from "./basic-fields/export.basic.fields";
import {ExportStore} from "./export.store";


interface ExportProps {
    title: string
    isShown: boolean,
    withBasicExportFields: boolean,
    config: {
        link: string,
        query?: {[k: string]: any}
    }
    onClose: () => void,
    hasFiltersParams?: boolean,
    additionalDefaultValues?: {[k: string]: any}
    children?: React.ReactNode
}

export const Export: React.FC<ExportProps> = (
    {
        title,
        isShown,
        onClose,
        withBasicExportFields = true,
        config,
        additionalDefaultValues = {},
        hasFiltersParams,
        children
    }) => {
    const {authStore} = useContext(RootStoreContext);
    const [store] = useState(() => new ExportStore(config.link, authStore.token, additionalDefaultValues, hasFiltersParams));
    const {t} = useTranslation("translation", {useSuspense: false});
    const [form] = Form.useForm();


    useEffect(() => {
        store.getFilters(config.query);
    }, [config.query]);

    const isFormInvalid = () => form.getFieldsError().some((item) => item.errors.length > 0);

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={isShown}
            title={t(title)}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
            width={500}
            footer={false}
            centered
        >
            <Form
                onValuesChange={() => form.submit()}
                layout="vertical"
                form={form}
                initialValues={{...store.defaultValue, ...additionalDefaultValues}}
            >
                <Row gutter={[5, 5]}>
                    {withBasicExportFields ? <ExportBasicFields/> : null}
                    {children}
                </Row>
                <Space style={{justifyContent: "flex-end", width: "100%"}} direction={"horizontal"} size={[5, 5]}>
                    <Button
                        onClick={() => {
                            onClose();
                            form.resetFields();
                        }}
                    >
                        {t("CANCEL")}
                    </Button>
                    <Form.Item
                        noStyle
                        shouldUpdate
                    >
                        {(values) => (
                            !isFormInvalid() ? (
                                <a
                                    onClick={() => {
                                        message.success(t("DOWNLOAD.BEGINS"));
                                        onClose();
                                    }}
                                    href={store.getDownloadLink(values.getFieldsValue())}
                                    className="ant-btn ant-btn-primary">
                                    {t("DOWNLOAD.DO_DOWNLOAD")}
                                </a>
                            ) : <Button disabled>{t("DOWNLOAD.DO_DOWNLOAD")}</Button>
                        )}
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};
