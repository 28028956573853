import React, {RefObject, useRef} from "react";
import {observer} from "mobx-react";
import {Card, Checkbox, Typography} from "antd";
import {displayPrice} from "../../../../../../models/amount/amount.helper";
import {CartCheckoutShipping} from "../../../../../../models/cart/checkout/shipping/cart.checkout.shipping";
import {useRequiredLayers} from "../../../../../../utils/context";
import {CartCreateStore, CartStoreContext} from "../../../../create/cart.create.store";
import {CartCheckoutController, CartCheckoutControllerContext} from "../../../cart.checkout.controller";
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../core/commons/custom-card/custom.card";
import {NotCompletedOrder} from "../../../../../../models/cart/checkout/not.completed.order";


interface CartCheckoutShippingsCardProps {
    shipping: CartCheckoutShipping,
    order: NotCompletedOrder
}
export const CartCheckoutShippingsCard: React.FC<CartCheckoutShippingsCardProps> = observer(({order, shipping}) => {
    const {controller} = useRequiredLayers<CartCreateStore, CartCheckoutController>(CartStoreContext, CartCheckoutControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});
    const checkboxRef = useRef(null) as RefObject<any>

    const cardRows = [
        {key: "estimateType", label: "SHIPPING.TYPE"},
        {key: "estimate", label: "SHIPPING.TIME"},
        {key: "packType", label: "SHIPPING.PACK_TYPE"},
    ];

    if (shipping.price.showCost) {
        cardRows.unshift({key: "price", label: "SHIPPING.PRICE"});
    }

    const cardData = {...shipping, price: shipping.price.cost.value === 0 ? t('FREE') : displayPrice(shipping.price.cost)}

    return (
        <Card
            hoverable
            className="card"
            onClick={() => controller.selectShippingWithConfirm(order, shipping.id, !shipping.checked, shipping.price.onSelectPopUpText, t)}
            style={{height: "100%"}}
            extra={<Checkbox
                className="large"
                ref={checkboxRef}
                checked={shipping.checked}
            />}
            title={<Typography.Text title={shipping.name} ellipsis={true}>{shipping.name}</Typography.Text>}
        >
            {shipping.id === "delay" ? (
                <>({t("SHIPPING.MERGED_SHIPPING.DESCRIPTION")})</>
            ) : shipping.id === "custom" ? (
                <>({t("SHIPPING.CUSTOM.DESCRIPTION")})</>
            ) : (
                <>
                    {shipping.price.costDescription ? (
                        <Typography.Paragraph type={"secondary"}>{shipping.price.costDescription}</Typography.Paragraph>
                    ) : null}
                    <CustomCard data={cardData} rowKeys={cardRows}/>
                </>
            )}
        </Card>
    )
});
