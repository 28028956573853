import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {BackorderItem} from "../../models/backorder/backorder.item";

export class BackorderService {
    public async getList(request: PaginatedRequest): Promise<PaginatedResult<BackorderItem>> {
        return (await apiService.get<PaginatedResult<BackorderItem>>(
            "cart/backorder-items",
            {
                params: request.toParams()
            }
        )).data;
    }
}

export const backorderService = new BackorderService();

