import { makeObservable, observable, computed, action } from "mobx";
export class ExportBlobStore {
    @observable
    public defaultValues?: any;

    @observable
    private _loading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    public set loading(value: boolean) {
        this._loading = value;
    }

    @computed
    public get loading(): boolean {
        return this._loading;
    }

    @action
    public setLoading(value: boolean): void {
        this.loading = value;
    }

    @action
    public setDefaultValues(value: any): void {
        this.defaultValues = value;
    }
}
