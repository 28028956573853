import React from "react";
import {Select} from "antd";
import {PlannerTaskPriority} from "../../../../../../models/issues/issues";
import {WidgetProps} from "../../../../../core/ui/widget/widget";

export const UIInputWidgetPriority: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {Object.values(PlannerTaskPriority).map(priority => (
                <Select.Option value={priority}>{priority.capitalizeFirstLetter()}</Select.Option>
            ))}
        </Select>
    )
}