export enum FieldType {
    STRING,
    INTEGER
}

export interface Schema {
    [fieldName: string]: ((value: any) => any) | FieldType
}

export const queryParamsGetter = (params: Record<string, any>, schema: Schema): Record<string, any> => {
    const value: any = {}
    Object.keys(params).forEach(key => {
        if (schema[key] !== undefined) {
            const rawValue = params[key];
            const type = schema[key];
            if (typeof type === 'function' ) {
                value[key] = type(rawValue);
            } else {
                if (typeof rawValue === 'string') {
                    if (type === FieldType.INTEGER) {
                        value[key] = parseInt(rawValue);
                    } else if (type === FieldType.STRING) {
                        value[key] = rawValue;
                    }
                }
            }
        }
    })

    return value;
}