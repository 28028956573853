import {ExportFields} from "../../../../models/export/export";

export class ExportStore {

    private filters: {[k: string]: any} = {};

    public defaultValue = {
        withHeaders: true,
        separator: ";",
        escapeChar: "\\",
        enclosure: "\"",
        format: "xlsx"
    };

    constructor(
        private link: string,
        private token: string,
        private additionalDefaultValues: {[k: string]: any},
        private hasFiltersParams: boolean | undefined,
    ) {

    }

    public getFilters(value: {[k: string]: any} = {}): void {
        this.setFilters(value);
    }


    private setFilters(value: {[k: string]: any}) {
        this.filters = value;
    }

    private generateExportQueryString(values: ExportFields): string {
        const out: string[] = [];

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (Array.isArray(values[key])) {
                    values[key].forEach((item: any) => {
                        out.push("export[" + key + "][]=" + item);
                    });
                } else {
                    out.push("export[" + key + "]=" + values[key]);
                }
            }

        }
        return out.join("&");
    }

    private convertBooleanValuesToString(values: {[k: string]: any} = {}): any {
        const obj: {[k: string]: any} = {};
        Object.keys(values).forEach(key => {
            if (values[key] === true) {
                obj[key] = "1";
            } else if (values[key] === false) {
                obj[key] = "0";
            } else {
                obj[key] = values[key];
            }
        });
        return obj;
    }

    private generateParamsQueryString(values: {[k: string]: any} = {}): string {
        const out: string[] = [];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if(Array.isArray(values[key])) {
                    values[key].forEach((item: any) => {
                        out.push(key + "[]=" + item);
                    });
                } else {
                    out.push(key + "=" + values[key]);
                }
            }
        }
        return out.join("&");
    }

    public getDownloadLink(values: any) {
        const exportQueryString = this.generateExportQueryString({...this.convertBooleanValuesToString(values)});
        const url = this.generateParamsQueryString(this.filters);
        return this.link + "/export?" + exportQueryString + "&" + url + "&token=" + this.token;
    }

}
