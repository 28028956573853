import {InvoiceStore} from "./invoice.store";
import {InvoicesService} from "../../../services/invoices/invoices.service";
import React, {Context} from "react";

export class InvoiceController {
    constructor(
        private readonly _id: string,
        private readonly _invoiceStore: InvoiceStore,
        private readonly _invoicesService: InvoicesService
    ) {
        this.loadData();
    }

    private async loadData(): Promise<void> {
        this._invoiceStore.setLoading(true);
        this._invoiceStore.setInvoice(await this._invoicesService.getInvoice(this._id));
        this._invoiceStore.setLoading(false);
    }
}

export const InvoiceControllerContext = React.createContext<null | InvoiceController>(null) as Context<InvoiceController>;
