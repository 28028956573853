import React from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Col, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";

const { Title } = Typography;

interface WikiDefaultPageProps extends ComponentPropsFromRoute {
}

export const WikiDefaultPage: React.FC<WikiDefaultPageProps> = () => {

    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <Row wrap={false} gutter={[40, 0]}>
            <Col flex="auto">
                {t("WIKI.MAIN.CONTENT")}
            </Col>
        </Row>
    )
}