import {FilterKey} from "../../../models/filtered-table/filtered.table";
import {TFunction} from "react-i18next";

export const filterKeysFactory = (t: TFunction<"translation">): FilterKey[] => {
    return [
        {
            key: "priority",
            label: t('PLANNER.TASK.FIELDS.PRIORITY'),
            type: "string"
        },
        {
            key: "name",
            label: t('PLANNER.TASK.FIELDS.NAME'),
            type: "string"
        },
        {
            key: "status",
            label: t('PLANNER.TASK.FIELDS.STATUS'),
            type: "string"
        },
        {
            key: "realStartDate",
            label: t('PLANNER.TASK.FIELDS.REAL_START_DATE'),
            type: "string"
        },
        {
            key: "realEndDate",
            label: t('PLANNER.TASK.FIELDS.REAL_END_DATE'),
            type: "string"
        }
    ]
}