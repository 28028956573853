import {action, computed, makeObservable, observable} from "mobx";

export class ForgotPasswordStore {
    @observable
    private _inputDisabled: boolean = false;

    @observable
    private _emailSent: boolean = false;

    constructor() {
        makeObservable(this)
    }

    private set inputDisabled(value: boolean) {
        this._inputDisabled = value;
    }

    protected set emailSent(value: boolean) {
        this._emailSent = value;
    }

    @computed
    public get disabled(): boolean {
        return this._inputDisabled;
    }

    @computed
    public get sent(): boolean {
        return this._emailSent;
    }

    @action
    public setDisabled(value: boolean): void {
        this.inputDisabled = value;
    }

    @action
    public setSent(value: boolean): void {
        this.emailSent = value;
    }
}