import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { Row, Upload, message, Space, Skeleton, notification, Alert } from "antd";
import { CartCreateWarehouses } from "./cart-create-warehouses/cart.create.warehouses";
import { CartCreateStore, CartStoreContext } from "./cart.create.store";
import { CartCreateController, CartCreateControllerContext } from "./cart.create.controller";
import { CartCreateTable } from "./cart-create-table/cart.create.table";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { warehousesService } from "../../../services/warehouses/warehouses.service";
import { articlesService } from "../../../services/articles/articles.service";
import { ImportResponseError } from "../../../models/import/import.response";
import { IconType } from "antd/lib/notification";
import { addressesService } from "../../../services/addresses/addresses.service";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { cartCreateService } from "../../../services/cart/create/cart.create.service";
import { CartCreateMissingItems } from "./cart-create-missing-items/cart.create.missing.items";
import { RootControllersContext } from "../../../controllers/root/root.controllers";
import { CartCreateMissingItemsAlert } from "./cart-create-missing-items-alert/cart.create.missing.items.alert";
import "./cart.create.scss";
import {AnnouncementsInCart} from "../../announcements/in-cart/announcements.in-cart";

interface CartCreateProps extends ComponentPropsFromRoute {}

export const CartCreate: React.FC<CartCreateProps> = observer(({ name, requiredConfigs }) => {
    const {
        accountController: { updateNotCompletedOrdersAmount },
    } = useContext(RootControllersContext);
    const [store] = useState(() => new CartCreateStore(requiredConfigs!.type));
    const [controller] = useState(
        () =>
            new CartCreateController(
                store,
                cartCreateService,
                warehousesService,
                articlesService,
                addressesService,
                updateNotCompletedOrdersAmount
            )
    );
    const { t } = useTranslation("translation", { useSuspense: false });

    const openNotificationWithErrors = ({ status, errors }: { status: IconType; errors: ImportResponseError[] }) => {
        notification[status]({
            message: t(status.toUpperCase()),
            description: (
                <Space style={{ width: "100%" }} direction={"vertical"} size={[10, 10]}>
                    {errors.map((error) => (
                        <Alert
                            closable={errors.length > 1}
                            style={{ width: "100%" }}
                            type={status}
                            message={"At " + error.line + ": " + error.content + " (" + error.message + ")"}
                        />
                    ))}
                </Space>
            ),
            duration: 1000,
            className: "cart-notification",
        });
    };

    const key = "upload";
    const props = {
        name: "file",
        multiple: false,
        showUploadList: false,
        action: "cart/import",
        customRequest: async (x: any) => {
            try {
                message.loading({ content: t("LOADING") + "...", key });
                const result = await cartCreateService.importFile(x.file, store.type, (percent) => x.onProgress({ percent }));
                x.onSuccess(result);
            } catch (e) {
                x.onError(e);
                message.error({ content: t("UPLOAD.FAIL"), key, duration: 5 });
            }
        },
        async onChange(info: any) {
            const { status } = info.file;
            if (status !== "uploading") {
                console.log(info);
            }
            if (status === "done") {
                await controller.reloadCart();
                message.success({
                    content: t("UPLOAD.SUCCESS"),
                    key,
                    duration: 2,
                });
            } else if (status === "error") {
                message.error({ content: t("UPLOAD.FAIL"), key, duration: 5 });
            }
        },
        onSuccess: async (res: any) => {
            if (res.status !== "ok") {
                await controller.reloadCart();
                openNotificationWithErrors(res);
                return;
            }
            await controller.reloadCart();
            message.success({ content: t("UPLOAD.SUCCESS"), key, duration: 2 });
        },
        onError: (err: any, body: any) => {
            console.log(err, body);
        },
    };

    return (
        <CartCreateControllerContext.Provider value={controller}>
            <CartStoreContext.Provider value={store}>
                <Space direction="vertical" size={[20, 20]} style={{ width: "100%" }}>
                    {store.loading ? null : <AnnouncementsInCart type={store.type} />}
                    {store.missingItems.length > 0 ? (
                        <CartCreateMissingItems />
                    ) : (
                        <>
                            <Row style={{ width: "100%" }}>
                                <Space direction="vertical" style={{ width: "100%" }} size={[10, 10]}>
                                    {store.type === 'regular' ? (
                                        <>
                                            {store.loading ? (
                                                <Space direction="vertical" size={[5, 5]}>
                                            <span
                                                style={{
                                                    marginRight: "auto",
                                                    display: "block",
                                                }}
                                            >
                                                {t("ORDERS.CREATE.CALCULATE_BY")}:
                                            </span>
                                                    <Space direction="horizontal" size={[10, 10]} wrap>
                                                        <Skeleton.Button active shape="square" />
                                                        <Skeleton.Button active shape="square" />
                                                        <Skeleton.Button active shape="square" />
                                                        <Skeleton.Button active shape="square" />
                                                    </Space>
                                                </Space>
                                            ) : (
                                                <CartCreateWarehouses />
                                            )}
                                        </>
                                    ) : null}
                                    <CartCreateMissingItemsAlert />
                                    <CartCreateTable />
                                </Space>
                            </Row>
                            <Row justify="center">
                                <div className="cart-upload upload-wrapper">
                                    <Upload.Dragger disabled={store.loading} style={{ width: "100%" }} {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <FontAwesomeIcon icon={["fas", "file-upload"]} size={"4x"} />
                                        </p>
                                        <p className="ant-upload-text cart-upload-text">{t("UPLOAD.TEXT")}</p>
                                        <p className="ant-upload-text cart-upload-text cart-upload-info">
                                            {t("ORDERS.CREATE.UPLOAD.INFO_TEXT")}
                                        </p>
                                    </Upload.Dragger>
                                </div>
                            </Row>
                        </>
                    )}
                </Space>
            </CartStoreContext.Provider>
        </CartCreateControllerContext.Provider>
    );
});
