import {apiService} from "../api.service";
import {Country} from "../../models/country/country";

export class CountriesService {
    public async getCountries(): Promise<Country[]> {
        return (await apiService.get<Country[]>("countries")).data
    }
}

export const countiesService = new CountriesService();
