import React, {RefObject, useContext, useEffect, useRef, useState} from "react";
import { NavigationMobileMenu } from "./navigation-mobile-menu/navigation.mobile.menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row, Select } from "antd";
import { routers } from "../../routers/routers";
import { observer } from "mobx-react";
import { RootControllers, RootControllersContext } from "../../controllers/root/root.controllers";
import { useTranslation } from "react-i18next";
import { useRequiredLayers } from "../../utils/context";
import { RootStore, RootStoreContext } from "../../stores/root/root";
import { NavigationItem } from "./navigation-item/navigation.item";
import { NavigationItemWithImg } from "./navigation-item/with-img/navigation.item.with.img";
import "./navigation.scss";
import { NavigationBtnItem } from "./navigation-btn-item/navigation.btn.item";
import { FakerModal } from "../core/commons/faker/faker.modal";
import { NavigationFlagIcon } from "./navigation-flag/navigation.flag.icon";
import {isAllowed} from "../../utils/helpers";

export const Navigation: React.FC = observer(() => {
    const {
        store: { langStore, authStore },
        controller: { loginController },
    } = useRequiredLayers<RootStore, RootControllers>(RootStoreContext, RootControllersContext);
    const { t, i18n } = useTranslation("translation", { useSuspense: false });
    const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
    const [langMenuOpened, setLangMenuOpened] = useState<boolean>(false);
    const [currencyMenuOpened, setCurrencyMenuOpened] = useState<boolean>(false);
    const [roleSwitcherMenuOpened, setRoleSwitcherMenuOpened] = useState<boolean>(false);

    const [fakerModalShown, setFakerModalShown] = useState<boolean>(false);

    const langMenuWrapper = useRef<HTMLElement>(null) as RefObject<any>;
    const currencyMenuWrapper = useRef<HTMLElement>(null) as RefObject<any>;
    const roleSwitcherWrapper = useRef<HTMLElement>(null) as RefObject<any>;

    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);

    useEffect(() => {
        const closePopupHandler = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!langMenuWrapper?.current?.contains(target)) {
                setLangMenuOpened(false);
            }
            if (!currencyMenuWrapper?.current?.contains(target)) {
                setCurrencyMenuOpened(false);
            }
            if (!roleSwitcherWrapper?.current?.contains(target)) {
                setRoleSwitcherMenuOpened(false);
            }
        };

        document.addEventListener("mousedown", closePopupHandler);

        return () => {
            document.removeEventListener("mousedown", closePopupHandler);
        };
    }, []);

    const openMobileMenu = (): void => {
        window.document.body.style.position = "relative";
        window.document.body.style.overflowY = "hidden";
        setMobileMenuOpened(true);
    };

    const closeMobileMenu = (): void => {
        window.document.body.style.position = "static";
        window.document.body.style.overflowY = "auto";
        setMobileMenuOpened(false);
    };

    return (
        <header className="page_header_container">
            <Row>
                <NavigationItemWithImg path={"/"} branch={authStore.branch}/>
                <NavigationFlagIcon branch={authStore.branch}/>
                <Row align="middle" justify="center" className="page_header_container-main-links-container">
                    {routers.map((route, index) =>
                        route.viewInNavigationPanel ? (
                            <>
                                {isAllowed(attributes, route.access) ? (
                                    <div key={index} style={{display: "flex"}}>
                                        <NavigationItem
                                            path={route.path}
                                            name={route.systemName}
                                            minimized={route.hiddenTitleInNavigationPanel}
                                            icon={route.icon!}
                                            className={"page_header_container-link"}
                                        />
                                    </div>
                                ) : null}
                                {route.separated ? (
                                    <div key={index + "s"} style={{display: "flex"}}>
                                        <span className="page_header_container_separator"/>
                                    </div>
                                ) : null}
                            </>
                        ) : null
                    )}
                </Row>
                {authStore.attributes.includes("faker") || authStore.isUserFaked ? (
                    <NavigationBtnItem
                        name={(authStore.user!.firstName[0] + authStore.user!.lastName[0]).toUpperCase()}
                        className={"page_header_container-link"}
                        onClick={() => setFakerModalShown(true)}
                        danger={authStore.isUserFaked}
                    />
                ) : null}
                {authStore.accesses.length > 1 ? (
                    <div
                        ref={roleSwitcherWrapper}
                        onClick={() => setRoleSwitcherMenuOpened(!roleSwitcherMenuOpened)}
                        className="page_header_language-wrapper"
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                setRoleSwitcherMenuOpened(!roleSwitcherMenuOpened);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={["fas", "briefcase"]} style={{marginRight: 0}} size={"lg"}/>
                        <Select<number>
                            open={roleSwitcherMenuOpened}
                            className="page_header_language-select"
                            value={authStore.getAccess()}
                            onChange={(value: number) => {
                                loginController.switchRole(value)
                            }}
                            bordered={false}
                            getPopupContainer={() => roleSwitcherWrapper.current}
                        >
                            {authStore.accesses.map((access) => (
                                <Select.Option key={access.id} value={access.id}>
                                    {access.businessName}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                ) : null}
                {authStore.currencyCodes.length > 1 ? (
                    <div
                        ref={currencyMenuWrapper}
                        onClick={() => setCurrencyMenuOpened(!currencyMenuOpened)}
                        className="page_header_language-wrapper"
                        onKeyDown={(event) => {
                            if (event.keyCode === 13) {
                                setCurrencyMenuOpened(!currencyMenuOpened);
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={["fas", "coins"]} style={{marginRight: 0}} size={"lg"}/>
                        <Select<string>
                            open={currencyMenuOpened}
                            className="page_header_language-select"
                            value={authStore.selectedCurrencyCode}
                            onChange={(value: string) => {
                                loginController.setCurrencyCode(value)
                            }}
                            bordered={false}
                            getPopupContainer={() => currencyMenuWrapper.current}
                        >
                            {authStore.currencyCodes.map((code) => (
                                <Select.Option key={code} value={code}>
                                    {code.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                ) : null}
                <div
                    ref={langMenuWrapper}
                    onClick={() => setLangMenuOpened(!langMenuOpened)}
                    className="page_header_language-wrapper"
                    onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                            setLangMenuOpened(!langMenuOpened);
                        }
                    }}
                >
                    <FontAwesomeIcon icon={["fas", "globe-americas"]} style={{marginRight: 0}} size={"lg"}/>
                    <Select
                        open={langMenuOpened}
                        className="page_header_language-select"
                        value={i18n.language}
                        onChange={(value: string) => {
                            i18n.changeLanguage(value);
                            setLangMenuOpened(false);
                        }}
                        bordered={false}
                        getPopupContainer={() => langMenuWrapper.current}
                    >
                        {langStore.locales.map((locale) => (
                            <Select.Option key={locale} value={locale}>
                                {locale.toUpperCase()}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <Button
                    onClick={loginController.logout}
                    icon={<FontAwesomeIcon icon={["fas", "power-off"]}/>}
                    className={"page_header_container-link log-out-link ws_button"}
                />
                <Button
                    onClick={openMobileMenu}
                    className="page_header_hamburger ws_button"
                    icon={<FontAwesomeIcon icon={["fas", "bars"]}/>}
                />
                <NavigationMobileMenu onClose={closeMobileMenu} opened={mobileMenuOpened}/>
            </Row>
            <FakerModal visible={fakerModalShown} onClose={() => setFakerModalShown(false)}/>
        </header>
    );
});
