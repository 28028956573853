import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Alert} from "antd";
import "./version-checker.scss"
import {RootStoreContext} from "../../../../stores/root/root";
import {Trans, useTranslation} from "react-i18next";

export const VersionChecker: React.FC = observer(() => {

    const {commonStore} = useContext(RootStoreContext);

    const {t} = useTranslation("translation", {useSuspense: false});

    if (commonStore.outdated) {
        return (
            <Alert
                className={"version-checker"}
                message={t('VERSION.UPDATED.TITLE')}
                description={
                    <Trans
                        t={t}
                        i18nKey={t('VERSION.UPDATED.CONTENT')}
                        components={[
                            <a href={""}>{t('VERSION.UPDATED.ACTION')}</a>
                        ]}
                    />
                }
                type="warning"
                showIcon
            />
        );
    }

    return null;
});
