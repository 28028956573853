import React from "react";
import {Typography, Comment, List, Skeleton} from "antd";
import {generateCommentsData} from "../../../../models/orders/order-data/order-data-helper/order.data.helper";
import {OrderCommentsForm} from "./order-comments-form/order.comments.form";
import {observer} from "mobx-react";
import {useRequiredLayers} from "../../../../utils/context";
import {OrderDataStore, OrderDataStoreContext} from "../order.data.store";
import {OrderDataController, OrderDataControllerContext} from "../order.data.controller";
import {useTranslation} from "react-i18next";
import "./order.comments.scss";

export const OrderComments: React.FC = observer(() => {
    const {t} = useTranslation("translation", {useSuspense: false});
    const {
        store,
        controller
    } = useRequiredLayers<OrderDataStore, OrderDataController>(OrderDataStoreContext, OrderDataControllerContext);

    const commentsData = generateCommentsData(store.comments, controller.deleteComment, t("REMOVE"));


    return (
        <div className="order_comments">
            <Typography.Title level={3} style={store.comments.length ? {marginBottom: 0} : undefined}>{t("COMMENTS.TITLE")}</Typography.Title>

            {store.comments.length ? (
                <List
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={commentsData}
                    renderItem={item => (
                        <li className="comment-list-item">
                            <Comment
                                content={item.content}
                                datetime={item.datetime}
                            />
                        </li>
                    )}
                />
            ) : null}
            {store.isLoading ? (
                <>
                    <Skeleton active paragraph={{rows: 2}}/>
                    <Skeleton.Input active size="large"/>
                </>
            ) : <OrderCommentsForm/>}
        </div>
    );
});
