import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Checkbox, Form, Space} from "antd";
import {useRequiredLayers} from "../../../../utils/context";
import {CartCreateStore, CartStoreContext} from "../cart.create.store";
import {CartCreateController, CartCreateControllerContext} from "../cart.create.controller";
import {useTranslation} from "react-i18next";
import {Warehouse} from "../../../../models/warehoues/warehouse";
import "./cart.create.warehouses.scss";
import {RootStoreContext} from "../../../../stores/root/root";
import {isAllowed} from "../../../../utils/helpers";

export const CartCreateWarehouses: React.FC = observer(() => {
    const {
        store,
        controller
    } = useRequiredLayers<CartCreateStore, CartCreateController>(CartStoreContext, CartCreateControllerContext);

    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);

    const {t} = useTranslation("translation", {useSuspense: false});
    const defaultValues: {warehouses: string[], useBackorder: boolean} = {warehouses: store.defaultWarehouses, useBackorder: store.useBackorder};

    const isItemDisabled = (warehouse: Warehouse): boolean => store.defaultWarehouses ? store.defaultWarehouses.length === 1 && store.defaultWarehouses[0] === warehouse.id : false;

    const [form] = Form.useForm();
    return (
        <Form
            form={form}
            size={"middle"}
            layout="horizontal"
            name={"cart_form"}
            initialValues={defaultValues}
            onFinish={() => form
                .validateFields()
                .then(values => {
                    controller.updateCart(values);
                })
            }
        >
            <table className={"edit-form-table"}>
                <tbody>
                {store.type === 'regular' ? (
                    <tr>
                        <td className={"label"}>
                            {t("ORDERS.CREATE.CALCULATE_BY")}:
                        </td>
                        <td>
                            <Form.Item name={"warehouses"} noStyle>
                                <Checkbox.Group>
                                    {store.warehousesAll.map(warehouse => (
                                        <Checkbox
                                            disabled={isItemDisabled(warehouse)}
                                            className="orders-edit-form-checkbox"
                                            key={warehouse.id}
                                            value={warehouse.id}
                                            onChange={(e) => {
                                                if (!e.target.checked && form.getFieldValue("warehouses").length === 1) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                form.submit();
                                            }}
                                        >
                                            <span className="basket-warehouses-name">{warehouse.name}</span>
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </td>
                    </tr>
                ) : null}

                {isAllowed(attributes, 'order_backorder') ? (
                    <tr>
                        <td></td>
                        <td>
                            <Form.Item noStyle name={"useBackorder"} valuePropName={"checked"}>
                                <Checkbox
                                    onChange={() => form.submit()}>{t("ORDER.CREATE.USE_BACKORDER")}</Checkbox>
                            </Form.Item>
                        </td>
                    </tr>
                ) : null}
                </tbody>
            </table>
        </Form>
    );
});
