import React, {useContext} from "react";
import {observer} from "mobx-react";
import {NotCompletedOrder} from "../../../../models/cart/checkout/not.completed.order";
import {CartCheckoutOrderBody} from "../cart-checkout-order-body/cart.checkout.order.body";
import {CartCheckoutResult} from "../cart-checkout-result/cart.checkout.result";
import {CartCheckoutStoreContext} from "../cart.checkout.store";


interface CartCheckoutOrderProps {
    order: NotCompletedOrder,
}

export const CartCheckoutOrder: React.FC<CartCheckoutOrderProps> = observer(({order}) => {
    const store = useContext(CartCheckoutStoreContext);

    return (
        <>
            {!store.ordersResult[order.id] ? (
                <CartCheckoutOrderBody order={order}/>
            ) : (
                <CartCheckoutResult order={order}/>
            )}
        </>
    );
});
