import React from "react";
import {useTranslation} from "react-i18next";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Space, Typography} from "antd";
import {Link} from "react-router-dom";
import {RouteTabs} from "../../../core/commons/route-tabs/route.tabs";

export const ProductionRequestListWrapper: React.FC<ComponentPropsFromRoute> = ({name, routeChildren}) => {

    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Typography.Title level={1} className="main-title">
                    {t("PRODUCTION_REQUEST.LIST.TITLE")}
                </Typography.Title>
                <Space direction={"horizontal"} size={[10, 10]}>
                    <>
                        <Link to="/production/create" className="ant-btn ant-btn-primary">
                            {t("PRODUCTION_REQUEST.CREATE.TITLE")}
                        </Link>
                    </>
                </Space>
                <RouteTabs routes={routeChildren!} />
            </Space>
        </div>
    );
}
