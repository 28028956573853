import {TFunction} from "i18next";
import {FilterKey} from "../../../models/filtered-table/filtered.table";
import {ClaimStatus, ClaimType} from "../../../models/claims/claims";

export const filterKeysFactory = (t: TFunction): FilterKey[] => {
    return [
        {
            key: 'type',
            label: t('TYPE'),
            type: "select",
            options: Object.values(ClaimType).map(value => {
                return {
                    label: t('CLAIMS.CLAIM.TYPE.' + value.toUpperCase()),
                    value: value
                }
            })
        },
        {
            key: 'statuses',
            label: t('STATUS'),
            type: "select",
            options: Object.values(ClaimStatus).map(value => {
                return {
                    label: t('CLAIMS.CLAIM.STATUS.' + value.toUpperCase()),
                    value: value
                }
            })
        },
        {
            key: 'documentNumber',
            label: t('DOCUMENT_NUMBER'),
            type: "string"
        },
        {
            key: 'subtotal',
            label: t('TOTAL_PRICE'),
            type: "int"
        },
        {
            key: 'createdAt',
            label: t('CREATED_AT'),
            type: "date"
        }
    ];
}