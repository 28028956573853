import React from "react";
import {useTranslation} from "react-i18next";

export interface YesNoProps {
    value: boolean;
}

export const YesNo: React.FC<YesNoProps> = ({value}) => {
    const {t} = useTranslation("translation", {useSuspense: false});

    return t(value ? 'YES' : 'NO');
}