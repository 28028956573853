
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {AxiosInstance} from "axios";
import API from "../../utils/api";
import {Application} from "../../models/production/application";

export class ProductionService {
    private api: AxiosInstance = API;

    getApplications(request: PaginatedRequest, type: string): Promise<PaginatedResult<Application>> {
        request.addFilter('type', type);
        return this.api
            .get<PaginatedResult<Application>>("production/application", {params: request.toParams()})
            .then((x) => x.data);
    }

    getApplication(id: number): Promise<Application> {
        return this.api
            .get<Application>("production/application/" + id)
            .then((x) => x.data);
    }

    sendOfferApplication(req: object): Promise<Application> {
        return this.api
            .post<Application>("production/application/offer", req)
            .then((x) => x.data);

    }

    sendOrderApplication(req: object): Promise<Application> {
        return this.api
            .post<Application>("production/application/order", req)
            .then((x) => x.data);

    }
}

export const productionService = new ProductionService();
