import {Link} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {PlannerTask} from "../../../models/issues/issues";
import {TaskStatus} from "../common/status/task.status";
import {Date} from "../../core/commons/date/date";
import {TFunction} from "react-i18next";

export const columnsFactory = (t: TFunction<"translation">): ColumnsType<PlannerTask> => {
    return [
        {
            key: 'name',
            dataIndex: 'name',
            title: t('PLANNER.TASK.FIELDS.NAME'),
            render: (v, i) => <Link to={"/issues/" + i.id}>{v}</Link>
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: t('PLANNER.TASK.FIELDS.STATUS'),
            render: v => <TaskStatus status={v} />
        },
        {
            key: 'realStartDate',
            dataIndex: 'realStartDate',
            title: t('PLANNER.TASK.FIELDS.ESTIMATED_START_DATE'),
            render: v => <Date dateString={v} showTime />
        },
        {
            key: 'realEndDate',
            dataIndex: 'realStartDate',
            title: t('PLANNER.TASK.FIELDS.ESTIMATED_END_DATE'),
            render: v => <Date dateString={v} showTime />
        },
    ]
}