import {action, computed, makeObservable, observable} from "mobx";
import React, {Context} from "react";
import {Article} from "../../models/articles/article";
import {ArticleVehicle} from "../../models/articles/vehicle/vehicle";
import {Warehouse} from "../../models/warehoues/warehouse";

export class ArticleStore {

    @observable
    private _loading: boolean = false;

    @observable
    private _article: Article | null = null;

    @observable
    private _vehicles: ArticleVehicle[] = [];

    @observable
    private _articleOem: {brandName: string, names: string[]}[] = [];

    @observable
    private _articleAnalogues: {brandName: string, names: string[]}[] = [];

    @observable
    private _warehouses: Warehouse[] = [];

    @observable
    private _modalImageUrl: string = "";

    constructor() {
        makeObservable(this)
    }

    private set modalImageUrl(value: string) {
        this._modalImageUrl = value;
    }

    private set allWarehouses(value: Warehouse[]) {
        this._warehouses = value;
    }

    private set currentArticle(value: Article | null) {
        this._article = value;
    }

    private set articleVehicles(value: ArticleVehicle[]) {
        this._vehicles = value;
    }

    private set articleOem(value: {brandName: string, names: string[]}[]) {
        this._articleOem = value;
    }

    private set articleAnalogues(value: { brandName: string; names: string[] }[]) {
        this._articleAnalogues = value;
    }

    private set isLoading(value: boolean) {
        this._loading = value;
    }

    @computed
    public get warehouses(): Warehouse[] {
        return this._warehouses;
    }

    @computed
    public get article(): Article {
        return this._article!;
    }

    @computed
    public get vehicles(): ArticleVehicle[] {
        return this._vehicles;
    }

    @computed
    public get oem(): {brandName: string, names: string[]}[] {
        return this._articleOem;
    }

    @computed
    public get analogues(): { brandName: string; names: string[] }[] {
        return this._articleAnalogues;
    }

    @computed
    public get loading(): boolean {
        return this._loading;
    }

    @action
    public setImagePath(value: string): void {
        this.modalImageUrl = value;
    }

    @action
    public setArticle(value: Article | null): void {
        this.currentArticle = value;
    }

    @action
    public setVehicles(value: ArticleVehicle[]): void {
        this.articleVehicles = value;
    }

    @action
    public setOem(value: {brandName: string, names: string[]}[]): void {
        this.articleOem = value;
    }

    @action
    public setAnalogues(value: {brandName: string, names: string[]}[]): void {
        this.articleAnalogues = value;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }

    @action
    public setWarehouses(value: Warehouse[]): void {
        this.allWarehouses = value;
    }
}


export const ArticleStoreContext = React.createContext<null | ArticleStore>(null) as Context<ArticleStore>;
