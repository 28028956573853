import {TFunction} from "react-i18next";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Application, ApplicationStatus} from "../../../../models/production/application";
import {Date} from "../../../core/commons/date/date";
import {ApplicationsStatus} from "../common/status/application.status";
import {Link} from "react-router-dom";

export const columnsFactory = (t: TFunction<"translation">): ColumnsType<Application> => {
    return [
        {
            title: t("PRODUCTION_REQUEST.FIELD.OE_NUMBER.TITLE"),
            dataIndex: "OENumber",
            key: "OENumber",
            render: (number: number, item) => <Link className={"link"} to={"/production/application/" + item.id}>{number}</Link>,
            width: '150px'
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: (item: ApplicationStatus) => <ApplicationsStatus status={item} />
        },
        {
            title: t("PRODUCTION_REQUEST.FIELD.DESCRIPTION.TITLE"),
            dataIndex: "description",
            key: "description",
            render: (item: string) => item,
            width: '550px'
        },
        {
            title: t("PRODUCTION_REQUEST.FIELD.QTY.TITLE"),
            dataIndex: "qty",
            key: "qty",
            render: (item: string) => item
        },
        {
            title: t("PRODUCTION_REQUEST.FIELD.PRICE.TITLE"),
            dataIndex: "price",
            key: "price",
            render: (item: string) => item
        },
        {
            title: t("DATE"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item: string) => <Date dateString={item} showTime />
        }
    ];
}