import React, {useMemo, useState} from "react";
import {CatalogArticle, CatalogArticleAnalogue} from "../../../../models/articles/catalog/search";
import {Pagination} from "../../../../models/requests/paginated-result/paginated.result";
import {useTranslation} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import {Button, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Amount} from "../../../../models/amount/amount";
import {displayPrice} from "../../../../models/amount/amount.helper";
import {Link} from "react-router-dom";
import {ArticleCardPopup} from "../../../article/article-card-popover/catalog.card.popup";

interface CatalogCartsViewProps {
    strictItems: CatalogArticle[];
    items: CatalogArticle[];
    pagination: Pagination;
    onPageChange: (page: number, size: number) => void;
}

export const CatalogTableView: React.FC<CatalogCartsViewProps> = ({items, strictItems, pagination, onPageChange}) => {

    const {t} = useTranslation("translation", {useSuspense: false});
    const [articleId, setArticleId] = useState<number | null>(null);

    const warehouses = useMemo(() => {
        const result = new Set<string>();
        [...items, ...strictItems].forEach(item => item.stock.forEach(stockItem => result.add(stockItem.warehouse)))
        return result;
    }, [items, strictItems])

    const columns: ColumnsType<CatalogArticle> = [
        {
            title: "",
            dataIndex: "addToCart",
            key: "addToCart",
            width: "50px",
            className: "catalog-table-btn",
            render: (_: any, row: CatalogArticle) => (
                <div style={{position: "relative"}}>
                    <Button
                        onMouseDown={() => setArticleId(row.id)}
                        className={row.inCartQty > 0 ? "ws_button catalog_button catalog_button_active" : "ws_button catalog_button"}
                        icon={<FontAwesomeIcon icon={["fas", "shopping-cart"]}/>}
                    />
                    {row.id === articleId ? (
                        <ArticleCardPopup
                            article={row}
                            addToCart={() => null}
                            deleteFromCart={() => null}
                            close={() => setArticleId(null)}
                            inCartQty={row.inCartQty}
                        />
                    ) : null}
                </div>
            )
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "name",
            key: "name",
            width: "100px",
            render: (item: string) => <span style={{whiteSpace: "nowrap"}}>{item}</span>
        },
        {
            title: t("ARTICLES.NAME"),
            dataIndex: "description",
            key: "description",
            className: "catalog-table-description",
            render: (item: string, row: CatalogArticle) => <Link className="link" to={"catalog/article/" + row.id}>{item}</Link>
        },
        {
            title: t("PRICE"),
            dataIndex: "amount",
            key: "amount",
            render: (price: Amount) => <>{displayPrice(price)}</>
        },
    ];

    warehouses.forEach(warehouse => {
        columns.push({
            title: warehouse,
            dataIndex: warehouse,
            key: warehouse,
            render: (_: any, row: CatalogArticle) => {
                const foundWarehouse = row.stock.find(item => item.warehouse === warehouse);
                return foundWarehouse ? foundWarehouse.amount : "0";
            }
        });
    });

    columns.push(...[
        {
            title: t("ARTICLES.COMPATIBLE_OEM"),
            dataIndex: "analogues",
            key: "analogues",
            render: (item: CatalogArticleAnalogue[]) => item.length ? <><span style={{fontWeight: "bold"}}>{item[0].brand}</span>: { item[0].name}</> : null
        },
        {
            title: "",
            key: "addToWishList",
            width: "50px",
            className: "catalog-table-btn",
            render: (_: any, row: CatalogArticle) => (
                <Button
                    onClick={() => null}
                    style={{marginLeft: "auto"}}
                    className={row.inWishList ? "ws_button catalog_button catalog_button_active" : "ws_button catalog_button"}
                    icon={<FontAwesomeIcon icon={["fas", "star"]}/>}
                />
            )
        }]);


    return (
        <div className="catalog_view_table_container">
            <Table
                columns={columns}
                rowKey={"id"}
                dataSource={[...strictItems, ...items]}
                scroll={{x: true}}
                pagination={{
                    position: ["bottomRight"],
                    total: pagination?.total,
                    current: pagination?.page,
                    pageSize: pagination?.limit,
                    showQuickJumper: true,
                    onChange: onPageChange,
                    showTotal: (total) => "Total items: " + total
                }}
            />
        </div>
    );
}