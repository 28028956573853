import React, {Context} from "react";
import {CreditInvoiceStore} from "./invoice.store";
import {CreditInvoicesService} from "../../../services/invoices/credit.invoices.service";

export class CreditInvoiceController {
    constructor(
        private readonly _id: string,
        private readonly _invoiceStore: CreditInvoiceStore,
        private readonly _invoicesService: CreditInvoicesService
    ) {
        this.loadData();
    }

    private async loadData(): Promise<void> {
        this._invoiceStore.setLoading(true);
        this._invoiceStore.setInvoice(await this._invoicesService.getDocument(this._id));
        this._invoiceStore.setLoading(false);
    }
}

export const CreditInvoiceControllerContext = React.createContext<null | CreditInvoiceController>(null) as Context<CreditInvoiceController>;
