import React, {useContext} from "react";
import {observer} from "mobx-react";
import {DocumentsStoreContext} from "../documents.store";
import {useTranslation} from "react-i18next";
import {Date} from "../../core/commons/date/date";
import {RootStoreContext} from "../../../stores/root/root";
import "./documents.download.button.scss";


interface DocumentsDownloadButtonProps {
    fileKey: string,
    customLink?: string
}
export const DocumentsDownloadButton: React.FC<DocumentsDownloadButtonProps> = observer(({fileKey, customLink}) => {
    const store = useContext(DocumentsStoreContext);
    const {t} = useTranslation("translation", {useSuspense: false});

    const url = customLink ? customLink : "/api/v3/files/" + fileKey

    if (!store.documents.hasOwnProperty(fileKey) && !customLink) {
        return null;
    }

    if (customLink) {
        return (
            <a className="documents-link"
               href={url}
            >
                {t("DOCUMENTS." + fileKey.toUpperCase().replaceAll("-", "_"))}
            </a>
        );
    }

    return (
        <a className="documents-link"
           href={url}
        >
            {t("DOCUMENTS." + fileKey.toUpperCase().replaceAll("-", "_"))} (<Date dateString={store.documents[fileKey].date}/>)
        </a>
    );
});
