import React, {useContext, useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {RouteTabs} from "../../core/commons/route-tabs/route.tabs";
import {Button, Row, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {RootStoreContext} from "../../../stores/root/root";
import {observer} from "mobx-react";
import {isAllowed} from "../../../utils/helpers";
import {OrdersExportModal} from "../orders-export/orders.export";
import {useLocation} from "react-router-dom";

export const OrdersMain: React.FC<ComponentPropsFromRoute> = observer(({ name, routeChildren }) => {

    const { t } = useTranslation("translation", { useSuspense: false });
    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);

    const [subPage, setSubPage] = useState<string>('');
    const [exportModalShown, setExportModalShown] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
        setSubPage(location.pathname.split('/').pop() || '');
    }, [location])

    const hiddenTabs = [];
    if (isAllowed(attributes, "merged_invoice_list")) {
        hiddenTabs.push("invoices")
    } else {
        hiddenTabs.push("merged-invoices");
    }

    return <>
        <div className="page_content">
            <Space direction="vertical" size={[30, 30]} className="content">
                <Row justify="space-between" align="middle">
                    <Typography.Title
                        level={1}
                        className="main-title"
                        style={{marginBottom: 30}}
                    >
                        {t("ORDERS.TITLE")} & {t("DOCUMENTS.TITLE")}

                    </Typography.Title>
                    <RouteTabs
                        routes={routeChildren!}
                        hiddenTabs={hiddenTabs}
                        tabBarExtraContent={
                            <>
                                {['final-invoices', 'credit-invoices'].indexOf(subPage) !== -1 ? (
                                    <Button
                                        onClick={() => setExportModalShown(true)}
                                    >
                                        {t('DOWNLOAD.DO_DOWNLOAD')}
                                    </Button>
                                ) : null}
                            </>
                        }
                    />
                    {exportModalShown ? (
                        <OrdersExportModal subPage={subPage} onClose={() => setExportModalShown(false)} />
                    ) : null}
                </Row>
            </Space>
        </div>
    </>
})