import React from "react";
import {useTranslation} from "react-i18next";
import "./registration.modal.scss";


export const RegistrationModal: React.FC = () => {
    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <div className="registration-modal">
            <div className="registration-alert alert">
                <p>{t("WS.REGISTRATION.SUCCESS")}</p>
                <p>{t("WS.REGISTRATION.SUCCESS_TEXT")}</p>
            </div>
        </div>
    )
}
