import React, {Context} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {Warehouse} from "../../../models/warehoues/warehouse";
import {FilterDataController} from "../../../controllers/filtered-table/filter.data.controller";
import {wishlistService} from "../../../services/wishlist/wishlist.service";
import {WishlistItem} from "../../../models/wishlist/wishlist.item";
import {PaginatedRequest} from "../../../models/requests/paginated-request/paginated.request";

export class WishListStore {

    @observable
    private _stocks: Warehouse[] = [];

    @observable
    private _selectedArticles: number[] = [];

    @observable
    private _selectedMissingItems: number[] = [];

    @observable
    private _isAddAvailableModalShown: boolean = false;

    @observable
    private _isExportAllModalShown: boolean = false;

    public wishListRequest = new PaginatedRequest();
    public wishListDataHandler: FilterDataController<WishlistItem>;

    constructor() {
        makeObservable(this);
        this.wishListDataHandler = new FilterDataController(request => wishlistService.getWishlist(request));
    }

    private set stocks(value: Warehouse[]) {
        this._stocks = value;
    }

    private set selectedArticles(value: number[]) {
        this._selectedArticles = value;
    }

    private set selectedMissingArticles(value: number[]) {
        this._selectedMissingItems = value
    }

    private set isAddAvailableModalShown(value: boolean) {
        this._isAddAvailableModalShown = value;
    }

    private set isExportAllModalShown(value: boolean) {
        this._isExportAllModalShown = value;
    }

    @computed
    public get warehouses(): Warehouse[] {
        return this._stocks;
    }

    @computed
    public get selectedItems(): number[] {
        return this._selectedArticles;
    }

    @computed
    public get selectedMissingItems(): number[] {
        return this._selectedMissingItems;
    }

    @computed
    public get addAvailableModalShown(): boolean {
        return this._isAddAvailableModalShown;
    }

    @computed
    public get exportAllModalShown(): boolean {
        return this._isExportAllModalShown;
    }


    @action
    public setWarehouses(value: Warehouse[]): void {
        this.stocks = value;
    }

    @action
    public setSelectedItems(value: number[]): void {
        this.selectedArticles = value;
    }

    @action
    public setSelectedMissingItems(value: number[]): void {
        this.selectedMissingArticles = value;
    }

    @action
    public setAddAvailableModalShown(value: boolean): void {
        this.isAddAvailableModalShown = value;
    }

    @action
    public setExportAllModalShown(value: boolean): void {
        this.isExportAllModalShown = value;
    }
}

export const WishListStoreContext = React.createContext<null | WishListStore>(null) as Context<WishListStore>;
