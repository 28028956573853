import {CatalogCartsView} from "../view/carts/catalog.carts.view";
import {CatalogTableView} from "../view/table/catalog.table.view";
import {CatalogArticle} from "../../../models/articles/catalog/search";
import {Pagination} from "../../../models/requests/paginated-result/paginated.result";
import React from "react";
import {PageLoader} from "../../core/commons/page-loader/page-loader";

interface CatalogSearchResultProps {
    loading: boolean;
    view: "table" | "cards";
    strictItems: CatalogArticle[];
    items: CatalogArticle[];
    pagination: Pagination;
    onPageChange: (page: number, size: number) => void;
}

export const CatalogSearchResult: React.FC<CatalogSearchResultProps> = ({loading, view, strictItems, items, pagination, onPageChange}) => {

    if (loading) {
        return <PageLoader />
    }

    return (
        <>
            {view === 'cards' ? (
                <CatalogCartsView strictItems={strictItems} items={items} pagination={pagination} onPageChange={onPageChange} />
            ) : (
                <CatalogTableView strictItems={strictItems} items={items} pagination={pagination} onPageChange={onPageChange} />
            )}
        </>
    )
}