import React, {RefObject, useRef} from "react";
import {observer} from "mobx-react";
import {Row} from "antd";
import {useTranslation} from "react-i18next";
import {NotCompletedOrder} from "../../../../models/cart/checkout/not.completed.order";

interface CartCheckoutResultProps {
    order: NotCompletedOrder
}
export const CartCheckoutResult: React.FC<CartCheckoutResultProps> = observer(({order}) => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const rowRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;


    return (
        <Row ref={rowRef} style={{backgroundColor: "rgba(0, 0, 0, 0.05)", padding: "12px 16px", scrollMargin: 65}}>
                <span style={{fontSize: 20, fontWeight: "bold"}}>
                    {t("ORDERS.ORDER.TITLE") + " #" + order.id} ({order.warehouse.name})
                </span>
            <a
                target="_blank"
                href={"/order/" + order.id}
                className="ant-btn ant-btn-primary ant-btn-lg"
                style={{margin: "3px 0px 0px 25px", fontSize: 13, lineHeight: "21px", height: 25 }}
            >
                {t("ORDERS.ORDER_LINK")}
            </a>
        </Row>
    );
});
