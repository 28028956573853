import React from "react";
import {StatusTag} from "../../../../core/commons/status/status";
import {ApplicationStatus, applicationStatusColor} from "../../../../../models/production/application";

interface ApplicationsStatusProps {
    status: ApplicationStatus;
    large?: boolean;
}
export const ApplicationsStatus: React.FC<ApplicationsStatusProps> = ({status, large = false}) => {
    return (
        <StatusTag<ApplicationStatus> status={status} colorsMap={applicationStatusColor} large={large} prefix="PRODUCTION_REQUEST.STATUS"/>
    )
}
