import React from "react";
import {Link} from "react-router-dom";

interface LinkTextProps {
    href: string;
    children?: React.ReactNode;
}

const LinkText: React.FC<LinkTextProps> = ({href, children}) => {
    return <Link to={href || ""}>{children}</Link>;
}
export default LinkText;