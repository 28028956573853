import React, {useContext, useEffect} from "react";
import {Alert, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {useTranslation} from "react-i18next";
import {useForm} from "antd/es/form/Form";
import {StepperContext, StepperContextValue} from "../../../../core/commons/stepper/stepper";
import {CreateClaimItem} from "../../../../../models/claims/claims";

interface ClarificationStepProps {

}

export const ClarificationStep: React.FC<ClarificationStepProps> = () => {

    const { t } = useTranslation("translation", { useSuspense: false });

    const [form] = useForm();

    const ctx = useContext<StepperContextValue<CreateClaimItem>>(StepperContext);

    useEffect(() => {
        ctx.setOnStepComplete(async () => {
            await form.validateFields();
            form.submit();
        })
    }, [ctx, form]);

    return (
        <Form
            form={form}
            initialValues={ctx.data.clarification}
            layout="vertical"
            onFinish={values => {
                ctx.data.clarification = values;
            }}
        >
            <Row gutter={10} style={{paddingBottom: 20}}>
                <Col span={24}>
                    <Alert message={"Optional you can add clarification data before submit application. It is not required now, but may be requested by the manager later and delay the processing time."} />
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={6}>
                    <Form.Item
                        label={t('CLAIMS.CLAIM.FITTING_DATE')}
                        name={['fittingDate']}
                    >
                        <DatePicker style={{width: "100%"}} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t('MILLAGE')}
                        name={['fittingOnMillage']}
                    >
                        <InputNumber style={{width: "100%"}} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t('CLAIMS.CLAIM.PROBLEM_DATE')}
                        name={['problemDate']}
                    >
                        <DatePicker style={{width: "100%"}} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t('MILLAGE')}
                        name={['problemOnMillage']}
                    >
                        <InputNumber style={{width: "100%"}} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={6}>
                    <Form.Item
                        label={t('BRAND')}
                        name={['carBrand']}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t('MODEL')}
                        name={['carModel']}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t('CLAIMS.CLAIM.CLARIFICATION')}
                        name={['carModelClarification']}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={t('YEAR')}
                        name={['carModelYear']}
                    >
                        <InputNumber style={{width: "100%"}} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item
                        name={['hasCrashedBoxRetention']}
                        valuePropName={"checked"}
                    >
                        <Checkbox>{t('CLAIMS.CLAIM.CRASHED_BOX')}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={24}>
                    <Form.Item
                        name={['hasBadPackingRetention']}
                        valuePropName={"checked"}
                    >
                        <Checkbox>{t('CLAIMS.CLAIM.BAD_PACKING')}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}