import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Button, Card, Col, Row, Skeleton} from "antd";
import {AccessItem} from "./access-item/access.item";
import {AccessesModal} from "./accesses-modal/accesses.modal";
import {AccessesStore, AccessesStoreContext} from "./accesses.store";
import {AccessesController, AccessesControllerContext} from "./accesses.controller";
import {accessesService} from "../../../services/accesses/accesses.service";
import {systemsService} from "../../../services/systems/systems.service";

export const Accesses: React.FC = observer(() => {
    const [store] = useState(() => new AccessesStore());
    const [controller] = useState(() => new AccessesController(store, accessesService, systemsService));
    const {t} = useTranslation('translation', {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    return (
        <AccessesStoreContext.Provider value={store}>
            <AccessesControllerContext.Provider value={controller}>
                <Row className="row-no-margin" gutter={[20, 20]}>
                    <Col span={24}>
                        {store.loading ? (
                            <Skeleton.Button active/>
                        ) : (

                            <Button type="primary" onClick={() => setModalShown(true)}>
                                {t("ADD")}
                            </Button>
                        )}
                    </Col>
                    {store.loading ? (
                        <Col className="card-col" xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Card
                                className="card"
                                bordered={false}
                                size="small"
                                title={<Skeleton active paragraph={{rows: 1}}/>}
                            >
                                <Skeleton active paragraph={{rows: 3, width: "100%"}}/>
                            </Card>
                        </Col>
                    ) : store.accesses.map(item => (
                        <AccessItem key={item.id} access={item}/>
                    ))}
                </Row>
                <AccessesModal
                    visible={modalShown}
                    onClose={() => setModalShown(false)}
                />
            </AccessesControllerContext.Provider>
        </AccessesStoreContext.Provider>
    );
})