import { DocumentsMap } from "../../models/documents/documents";
import { apiService } from "../api.service";

export class DocumentsService {
    public async getDocuments(): Promise<DocumentsMap> {
        return (await apiService.get<DocumentsMap>("files")).data;
    }

    public async getExportConfig(): Promise<any> {
        return (await apiService.get<any>("articles/export/config")).data;
    }

    public async exportArticles(values: any): Promise<any> {
        return await apiService.post<any>("articles/export", values, { responseType: "blob" });
    }

    public async exportNextMonthArticles(values: any): Promise<any> {
        return await apiService.post<any>("articles/next-price/export", values, { responseType: "blob" });
    }

    public async downloadDocumentList(type: string, params: any): Promise<any> {
        return await apiService.post<any>("documents/" + type + "/download", {
            config: params.config,
            from: params.date[0], until: params.date[1]
        }, {
            responseType: "blob"
        });
    }
}

export const documentsService = new DocumentsService();
