import React from "react";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import styles from "./page-loader.module.scss"

export const PageLoader: React.FC = () => {

    return (
        <div className="container">
            <div className={styles.loader}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
            </div>
        </div>
    )
}
