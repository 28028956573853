import {WishlistService} from "../../../services/wishlist/wishlist.service";
import {MissingItem} from "../../../models/wishlist/missing.item";
import React, {Context} from "react";
import {MissingItemsStore} from "./missing.items.store";
import {WarehousesService} from "../../../services/warehouses/warehouses.service";

export class MissingItemsController {
    constructor(
        private readonly _wishlistStore: MissingItemsStore,
        private readonly _wishlistService: WishlistService,
        private readonly _warehousesService: WarehousesService
    ) {
        this.addToCartItems = this.addToCartItems.bind(this);
        this.hideAllItems = this.hideAllItems.bind(this);
        this.openAddAllAvailableToCartModal = this.openAddAllAvailableToCartModal.bind(this);
        this.closeAddAllAvailableToCartModal = this.closeAddAllAvailableToCartModal.bind(this);
        this.openExportModal = this.openExportModal.bind(this);
        this.closeExportModal = this.closeExportModal.bind(this);
        this.onLngChange = this.onLngChange.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.createReservation = this.createReservation.bind(this);
        this.createOrderWithoutReservation = this.createOrderWithoutReservation.bind(this);
        this.createOrderWithFilters = this.createOrderWithFilters.bind(this);
        this.createReservationWithFilters = this.createReservationWithFilters.bind(this);
        this.createOrderWithoutReservationWithFilters = this.createOrderWithoutReservationWithFilters.bind(this);
        this.hideItem = this.hideItem.bind(this);
        this.unhideItem = this.unhideItem.bind(this);
        this.loadData();
    }
    
    public onLngChange(): void {
        this.loadData();
    }

    public onArticleCheck(articles: number[]): void {
        this._wishlistStore.setSelectedMissingItems(articles)
    }

    public async addToCartItems(): Promise<void> {
        await this._wishlistService.takeToCart({items: this._wishlistStore.selectedMissingItems}, "missing-items");
        await this._wishlistStore.missingItemsDataHandler.reloadItems(this._wishlistStore.missingItemsRequest);
    }
    
    public openAddAllAvailableToCartModal(): void {
        this._wishlistStore.setAddAvailableModalShown(true);
    }

    public closeAddAllAvailableToCartModal(): void {
        this._wishlistStore.setAddAvailableModalShown(false);
    }
    public async addAllToCart(values: {warehouses: number[]}): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        await this._wishlistService.takeAllToCart(values);
        this._wishlistStore.setAddAvailableModalShown(false);
        this._wishlistStore.missingItemsDataHandler.reloadItems(this._wishlistStore.missingItemsRequest);
        this._wishlistStore.missingItemsDataHandler.setLoading(false);
    }

    public async createOrder(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.createOrder(this._wishlistStore.selectedMissingItems);
            this._wishlistStore.setSelectedItems([]);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async createOrderWithoutReservation(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.createOrderWithoutReservation(this._wishlistStore.selectedMissingItems);
            this._wishlistStore.setSelectedItems([]);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async createReservation(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.createReservation(this._wishlistStore.selectedMissingItems);
            this._wishlistStore.setSelectedItems([]);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async createOrderWithFilters(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.createOrderWithFilters(this._wishlistStore.missingItemsDataHandler.lastRequest?.toFilters());
            this._wishlistStore.setSelectedItems([]);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async createOrderWithoutReservationWithFilters(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.createOrderWithoutReservationWithFilters(this._wishlistStore.missingItemsDataHandler.lastRequest?.toFilters());
            this._wishlistStore.setSelectedItems([]);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async createReservationWithFilters(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.createReservationWithFilters(this._wishlistStore.missingItemsDataHandler.lastRequest?.toFilters());
            this._wishlistStore.setSelectedItems([]);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async hideItem(id: number): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.hideItem(id);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async hideAllItems(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.hideAllItems();
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public async unhideItem(id: number): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        try {
            await this._wishlistService.unhideItem(id);
            await this._wishlistStore.missingItemsDataHandler.reloadWithLastRequest();
        }
        finally {
            this._wishlistStore.missingItemsDataHandler.setLoading(false);
        }
    }

    public openExportModal(): void {
        this._wishlistStore.setExportAllModalShown(true);
    }
    public closeExportModal(): void {
        this._wishlistStore.setExportAllModalShown(false);
    }

    private async loadItems(): Promise<void> {
        await this._wishlistStore.missingItemsDataHandler.reloadItems(this._wishlistStore.missingItemsRequest);
    }

    private async loadData(): Promise<void> {
        this._wishlistStore.missingItemsDataHandler.setLoading(true);
        await this.loadItems();
        this._wishlistStore.setWarehouses(await this._warehousesService.getWarehouses())
        this._wishlistStore.missingItemsDataHandler.setLoading(false);
    }
}

export const MissingItemsControllerContext = React.createContext<MissingItemsController | null>(null) as Context<MissingItemsController>;
