import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, Redirect} from "react-router-dom";
import {Row, Select, Space, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logo from "./febest_ws_logo_red.svg";
import {RegistrationForm} from "./registration-form/registration.form";
import {useRequiredLayers} from "../../../utils/context";
import {RootStore, RootStoreContext} from "../../../stores/root/root";
import {RootControllers, RootControllersContext} from "../../../controllers/root/root.controllers";
import {observer} from "mobx-react";
import {RegistrationController, RegistrationControllerContext} from "./registation.controller";
import {registrationService} from "../../../services/registration/registration.service";

import "./registration.scss";
import {RegistrationModal} from "./registration-modal/registration.modal";
import {useHistory} from "react-router";


export const Registration: React.FC = observer(() => {
    const {t, i18n} = useTranslation("translation", {useSuspense: false});
    const {store: {langStore, authStore}} = useRequiredLayers<RootStore, RootControllers>(RootStoreContext, RootControllersContext);
    const [controller] = useState(() => new RegistrationController(registrationService, authStore));
    const [redirect, setRedirect] = useState<boolean>(false);

    useEffect(() => {
        if (authStore.isLoggedIn) {
            setRedirect(true)
        }
    }, [])

    if (redirect) {
        return <Redirect to="/"/>
    }

    return (
        <RegistrationControllerContext.Provider value={controller}>
            <header>
                <Row justify="space-between" align="middle" className="registration_header">
                    <Link to="/">
                        <img src={logo} alt=""/>
                    </Link>
                    <div className="registration_language-select-wrapper">
                        <FontAwesomeIcon icon={["fas", "globe-americas"]} style={{marginRight: 7.5}} size={"lg"}/>
                        <Select
                            value={i18n.language}
                            onChange={(value: string) => i18n.changeLanguage(value)}
                            dropdownMatchSelectWidth={60}
                            showArrow={false}
                            bordered={false}
                            // dropdownAlign={"center"}
                            className="registration_language-select"
                        >
                            {langStore.locales.map(key => (
                                <Select.Option style={{textAlign: "center"}} key={key} value={key}>{key.toUpperCase()}</Select.Option>
                            ))}
                        </Select>
                    </div>
                </Row>
            </header>
            <main className="page_content">
                <div className="registration_content content">
                    <Space direction="vertical" size={[30, 30]}>
                        <Space direction="vertical" size={0}>
                            <Typography.Title level={1} className="registration_title">
                                {t("WHOLESALE.REGISTRATION.TITLE")}
                            </Typography.Title>
                            <p className="registration_description">
                                {t("WHOLESALE.REGISTRATION.DESCRIPTION")}
                            </p>
                        </Space>
                        {!authStore.registered ? (
                            <RegistrationForm/>
                        ) : (
                            <RegistrationModal/>
                        )}
                    </Space>
                </div>
            </main>
        </RegistrationControllerContext.Provider>
    );
});
