import { Warehouse } from "../../warehoues/warehouse";
import { CartCheckoutAddress } from "../checkout/address/cart.checkout.address";
import { OrdersCheckoutValues } from "../checkout/apply-values/apply.values";
import { NotCompletedItem, NotCompletedOrder } from "../checkout/not.completed.order";
import { OrdersCheckoutRequest } from "../checkout/request/apply.request";
import { CartCheckoutTableItem } from "../checkout/table-data/cart.checkout.table.data";
import { CartCreate, CartCreateItem } from "../create/cart.create";
import { CartCreateTableLastRecord, CartCreateTableRecord } from "../create/records/records";
import { CartCreateMissingItem } from "../create/result/cart.create.result";

export const createArticleRequest = ({ article, qty }: { article: { value: number }; qty: number }) => {
    return {
        article: article.value,
        qty,
    };
};

const generateItemData = (item: CartCreateItem, warehouses: Warehouse[], defaultWarehouses: string[]) => {
    const resultItem: CartCreateTableRecord = {
        id: item.article.id,
        article_name: item.article.name,
        article_description: item.article.description,
        price: item.calculatedPrice,
        quantity: { qty: item.qty, requestedQty: item.requestedQty },
        total_amount: item.totalAmount,
    };

    let stocks: { [stock: string]: {stockAmount: number, backorderAmount: number} } = {};

    item.stocks.forEach((stock) => {
        stocks[stock.warehouse.id] = {stockAmount: stock.stockAmount, backorderAmount: stock.backorderAmount};
    });

    warehouses.forEach((warehouse) => {
        if (defaultWarehouses.includes(warehouse.id)) {
            resultItem[warehouse.id] = stocks[warehouse.id] || {stockAmount: 0, backorderAmount: 0};
        }
    });

    return resultItem;
};

export const generateData = (basketPreview: CartCreate, warehouses: Warehouse[], defaultWarehouses: string[]) => {
    const res: CartCreateTableRecord[] & CartCreateTableLastRecord[] = basketPreview.items.map((item) =>
        generateItemData(item, warehouses, defaultWarehouses)
    );

    res.push({
        id: "footer",
        quantity: {
            qty: basketPreview.qty,
            requestedQty: basketPreview.requestedQty,
        },
        total_amount: basketPreview.total,
    });

    return res;
};

export const generateCartCheckoutTableItem = (order: NotCompletedItem): CartCheckoutTableItem => {
    return {
        key: order.article.id,
        articleName: order.article.name,
        articleDescription: order.article.description,
        price: order.calculatedPrice,
        quantity: order.qty,
        totalPrice: order.totalAmount,
        valid: order.valid,
    };
};

export const generateCartApplyTableData = (order: NotCompletedOrder): CartCheckoutTableItem[] => {
    return order.items.map((item) => generateCartCheckoutTableItem(item));
};

const generateApplyRequestAddresses = (addresses: CartCheckoutAddress[]) => {
    const address = addresses.find((item) => item.checked);
    const addressId = address && !address.custom ? address.id : undefined;
    const customAddress: any = !addressId ? addresses.find((item) => item.custom) : undefined;

    if (customAddress) {
        delete customAddress.custom;
        delete customAddress.checked;
        delete customAddress.default;
        delete customAddress.type;
    }
    return {
        id: addressId,
        customAddress: customAddress ? { ...customAddress, country: customAddress?.country?.id } : undefined,
    };
};
export const generateApplyRequest = ({
    relatedOrderNumber,
    shippingAddresses,
    shippingCouriers,
    orderComment,
}: OrdersCheckoutValues): OrdersCheckoutRequest => {
    const shippingAddressData = generateApplyRequestAddresses(shippingAddresses);
    return {
        shippingAddress: shippingAddressData.id,
        customShippingAddress: shippingAddressData.customAddress,
        shippingCourier: shippingCouriers ? shippingCouriers.find((item) => item.checked)!.id : null,
        relatedOrderNumber,
        orderComment,
    };
};

// export const generateCreateOrderRequestQuery = ({cart, warehouses}: {cart: CartCreate, warehouses: string[]}): OrdersCartRequestQuery => {
//     return  {
//         warehouses,
//         items: cart.items.map(item => ({article: item.article.id, qty: item.requestedQty, customAmountValue: item.calculatedPrice.type === "custom_price" ? item.calculatedPrice.amount.value : undefined})),
//     }
// }

export const generateCartMissingItemsTableDataItem = (item: CartCreateMissingItem): any => {
    return {
        id: item.article.id,
        article_name: item.article.name,
        article_description: item.article.description,
        price: item.calculatedPrice,
        quantity: item.qty,
        total_amount: item.totalAmount,
    };
};
