import {action, makeObservable, observable} from "mobx";
import {PaginatedResult, Pagination} from "../../models/requests/paginated-result/paginated.result";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";

export class FilterDataController<T> {
    @observable
    public items: T[] = [];

    @observable
    public loading: boolean = false;

    @observable
    public pagination?: Pagination;

    public lastRequest: PaginatedRequest | null = null;

    constructor(public dataLoader: (request: PaginatedRequest) => Promise<PaginatedResult<T>>) {
        makeObservable(this);
        this.dataLoader = (request) => {
            this.lastRequest = request;
            return dataLoader(request);
        }
    }

    @action
    addItem(item: T): void {
        this.items.push(item);
        this.pagination!.limit += 1;
    }

    @action
    removeItem(item: T): void {
        const index = this.items.indexOf(item);
        if (index !== -1) {
            this.items.splice(index, 1);
            this.pagination!.limit -= 1;
            this.pagination!.total -= 1;
        }
    }

    @action
    updateItem(item: T, updatedItem: T): void {
        const index = this.items.indexOf(item);
        if (index !== -1) {
            this.items[index] = updatedItem;
        }
    }

    @action
    setResult(result: PaginatedResult<T>) {
        this.items = result.items;
        this.pagination = result.meta;
    }

    @action
    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    async reloadItems(request: PaginatedRequest) {
        this.setLoading(true);
        let result = await this.dataLoader(request);
        this.setResult(result);
        this.setLoading(false);
    }

    async reloadWithLastRequest() {
        this.lastRequest && await this.reloadItems(this.lastRequest);
    }
}
