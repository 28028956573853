import {InvoicesTableItem, Invoice} from "../../models/invoices/invoice";
import {apiService} from "../api.service";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";

export class InvoicesService {
    public async getInvoices(request: PaginatedRequest): Promise<PaginatedResult<InvoicesTableItem>> {
        return (await apiService.get<PaginatedResult<InvoicesTableItem>>(
            "documents/invoices",
            {
                params: request.toParams()
            }
        )).data;
    }

    public async getInvoice(id: string): Promise<Invoice> {
        return (await apiService.get<Invoice>("documents/invoices/" + id)).data;
    }
}

export const invoicesService = new InvoicesService();
