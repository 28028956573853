import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {IdParams} from "../../../models/core/route-params/id-params/id.params";
import {Announcement} from "../../../models/announcements/announcement";
import {announcementsService} from "../../../services/announcements/announcements.service";
import {Date} from "../../core/commons/date/date";
import {Alert, Space} from "antd";
import {CartCreateType} from "../../../models/cart/create/cart.create";

interface AnnouncementsInCartProps {
    type: CartCreateType;
}

export const AnnouncementsInCart: React.FC<AnnouncementsInCartProps> = ({type}) => {

    const {id} = useParams<IdParams>();

    const [announcements, setAnnouncements] = useState<Announcement[]>([]);

    useEffect(() => {
        if (type === "reservation") {
            setAnnouncements([{
                id: 0,
                shortMessage: "Goods in Transit",
                message: "Please keep in mind few things about Goods in Transit:\n" +
                    "\n" +
                    "We cannot guarantee that all ordered items will be supplied, and that all ordered items will be supplied in the requested quantity. There are several reasons for them - a scrap stock or missing can be discovered upon arrival etc.\n" +
                    "\n" +
                    "If you place an order for several items which are now in transit, our B2B system will automatically create several orders, and each order will be bound to the expected goods arrival date. Of course, if you want us to merge all orders for Goods in Transit, we can wait as all of them arrive, and merge them into one order.\n" +
                    "\n" +
                    "Expected date of arrival is an approximate date - according do our experience, maritime delivery date can vary from expected date by 10-20 days (in both directions - goods can arrive earlier or later)\n" +
                    "\n" +
                    "Please feel free to ask more questions via email - sales@febest.eu",
                createdAt: "2022-07-01"
            }])
        } else {
            announcementsService.getInCartAnnouncements().then(announcements => setAnnouncements(announcements));
        }
    }, [id]);

    return (
        <Space size={[10, 10]} direction={"vertical"}>
            {announcements.length
                ? announcements.map(({ shortMessage, message, createdAt, id }, key) => (
                    <Alert
                        key={key}
                        message={
                            <div className={"announcement"}>
                                <div className={"announcement-title"}>
                                    <h2>{shortMessage}</h2>
                                </div>
                                <div className={"announcement-date"}>
                                    <Date dateString={createdAt} showTime={true} />
                                </div>
                                <div className={"announcement-text"}>
                                    {message.split('\n').map(function(item, key) {
                                        return (
                                            <span key={key}>
                                        {item}
                                                <br/>
                                    </span>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    />
                  ))
                : null}
        </Space>
    );
}