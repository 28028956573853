import React from 'react';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

type Props = {
    dateString: string,
    showTime?: boolean,
    withSeconds?: boolean
    noStyle?: boolean
};

export const Date: React.FC<Props> = ({dateString, showTime, withSeconds, noStyle}) => {
    const getTimezone = (): string => {
        dayjs.extend(utc)
        dayjs.extend(timezone)
        const userTimezone = dayjs.tz.guess();
        let format = showTime ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY";
        if (showTime && withSeconds) {
            format = format + ":ss";
        }
        return dayjs.tz(dayjs(dateString), userTimezone).format(format);
    }

    if (noStyle) {
        return <>{getTimezone()}</>
    }

    return (
        <span style={{whiteSpace: "pre"}}>
            {getTimezone()}
        </span>
    );
}
