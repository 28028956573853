import { Form, message, Modal, Spin } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { AxiosError } from "axios";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorResponseData } from "../../../../models/core/response";
import { ExportBlobController } from "./export.blob.controller";

import { ExportBlobStore } from "./export.blob.store";
export type Messages = Record<
    "loading" | "success" | "error",
    (content: string, key: string, duration?: number) => void
>;

interface ExportBlobProps<V = any> {
    form: FormInstance;
    title: string;
    okText: string;
    submitting: boolean;
    onSubmit: (values: V) => Promise<any>;
    onClose: () => void;
    setSubmitting: (value: boolean) => void;
    onInit?: () => Promise<any> | any;
}
export const ExportBlob = observer(
    <V extends object>({
        children,
        form,
        title,
        okText,
        submitting,
        onSubmit,
        setSubmitting,
        onInit,
        onClose,
    }: PropsWithChildren<ExportBlobProps<V>>) => {
        const { t } = useTranslation("translation", { useSuspense: false });
        const messages: Messages = {
            loading: (content, key) => {
                message.loading({ content: t(content), key });
            },
            success: (content, key, duration) => {
                message.success({ content: t(content), key, duration });
            },
            error: (content, key, duration) => {
                message.error({ content: t(content), key, duration });
            },
        };
        const [store] = useState(() => new ExportBlobStore());
        const [controller] = useState(() => new ExportBlobController(messages, store, onSubmit, onInit));

        useEffect(() => {
            if (!store.loading && store.defaultValues) {
                form.setFieldsValue(store.defaultValues);
            }
        }, [store.defaultValues, store.loading, form]);

        if (store.loading) {
            return <Spin />;
        }

        if (!store.defaultValues) {
            return null;
        }

        return (
            <Modal
                visible={true}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={submitting}
                title={t(title)}
                okText={t(okText)}
                cancelText={t("CANCEL")}
                onOk={() => form.submit()}
                onCancel={() => {
                    onClose();
                    form.resetFields();
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name={"integrations_form"}
                    initialValues={{ ...store.defaultValues }}
                    onFinish={() =>
                        form.validateFields().then((values) => {
                            setSubmitting(true);
                            controller
                                .submit(values)
                                .then(
                                    () => {
                                        form.resetFields();
                                        return onClose();
                                    },
                                    (e: AxiosError<ErrorResponseData>) => {
                                        if (e.response?.data.fields) {
                                            form.setFields(e.response?.data?.fields);
                                        }
                                    }
                                )
                                .then(
                                    () => {},
                                    (e) => form.setFields(e.response?.data?.fields)
                                )
                                .finally(() => setSubmitting(false));
                        })
                    }
                >
                    {children}
                </Form>
            </Modal>
        );
    }
);
