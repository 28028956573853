import React from "react";
import {useTranslation} from "react-i18next";
import {Collapse, Row, Table} from "antd";
import {useRequiredLayers} from "../../../utils/context";
import {ArticleStore, ArticleStoreContext} from "../article.store";
import {ArticleController, ArticleControllerContext} from "../article.controller";


export const ArticleVehicles: React.FC = () => {
    const {store} = useRequiredLayers<ArticleStore, ArticleController>(ArticleStoreContext, ArticleControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});

    const columns = [
        {
            title: t("NAME"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("YEARS"),
            dataIndex: "years",
            key: "years",
            width: "130px",
            render: (item: string) => <span style={{whiteSpace: "nowrap"}}>{item}</span>
        },
        {
            title: t("ARTICLES.SUBMODEL"),
            dataIndex: "engine",
            key: "engine",
        },
        {
            title: t("ARTICLES.FUEL_TYPE"),
            dataIndex: "fuelType",
            key: "fuelType"
        }
    ]

    return (
        <Row>
            <Collapse className="article_vehicle-collapse article_collapse" style={{width: "100%"}} expandIconPosition="right" bordered={false}>
                <Collapse.Panel key={0} header={t("ARTICLES.USED_IN")}>
                    {store.vehicles.map((vehicle, index) => (
                        <Collapse className="article_collapse-inner" key={vehicle.name} expandIconPosition="right" bordered={false}>
                            <Collapse.Panel key={index} header={<span style={{fontWeight: "bold"}}>{vehicle.name}</span>}>
                                {vehicle.models.map((model, i) => (
                                    <Collapse className="article_collapse-inner article_collapse-model" key={model.name} expandIconPosition="right" bordered={false}>
                                        <Collapse.Panel key={i} header={model.name}>
                                            <Table
                                                rowKey={"number"}
                                                columns={columns}
                                                dataSource={model.variants}
                                                scroll={{x: 700}}
                                                pagination={false}
                                            />
                                        </Collapse.Panel>
                                    </Collapse>
                                ))}
                            </Collapse.Panel>
                        </Collapse>
                    ))}
                </Collapse.Panel>
            </Collapse>
        </Row>
    );
}
