import React from "react";
import {Space, Typography} from "antd";
import "./missing.items.scss";
import {RouteTabs} from "../../core/commons/route-tabs/route.tabs";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useTranslation} from "react-i18next";
export const MissingItemsWrapper: React.FC<ComponentPropsFromRoute> = ({routeChildren}) => {

    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Typography.Title level={1} className="main-title">{t("Missing Items")}</Typography.Title>
                <RouteTabs routes={routeChildren!} />
            </Space>
        </div>
    );
};
