import {RegistrationValues} from "../../models/registration/registration.values";
import {apiService} from "../api.service";

export class RegistrationService {
    public async signup(values: RegistrationValues): Promise<void> {
        await apiService.post("signup", {...values});
    }
}

export const registrationService = new RegistrationService();
