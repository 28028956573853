import { Warehouse } from "./../../models/warehoues/warehouse";
import { action, computed, makeObservable, observable } from "mobx";
import { DocumentsMap } from "../../models/documents/documents";
import React, { Context } from "react";

export enum ExportFormat {
    CSV = "csv",
    XLS = "xls",
    XLSX = "xlsx",
}

export enum ExportModalType {
    BASIC = "basic",
    NEXT_MONTH = "next_month"
}

export class DocumentsStore {
    @observable
    private _exportModalShown: boolean = false;

    public exportModalType: ExportModalType = ExportModalType.BASIC;

    @observable
    private _isLoading: boolean = false;

    @observable
    private _documentsMap: DocumentsMap = {};

    @observable
    private _submitting: boolean = false;

    @observable
    private _warehouses: Warehouse[] = [];

    public formats: ExportFormat[] = [ExportFormat.XLSX, ExportFormat.XLS, ExportFormat.CSV];

    public options: { value: string; label: string }[] = [
        { value: "id", label: "ID" },
        { value: "reference", label: "Reference" },
        { value: "description", label: "Description" },
        { value: "oem", label: "OEM" },
        { value: "cars", label: "Cars"},
        { value: "hs_code", label: "HS code" },
        { value: "ean_code", label: "EAN code" },
        { value: "price", label: "Price" },
        { value: "price_rrp", label: "Price RRP (EUR)" },
        { value: "future_price", label: "Future Price"},
        { value: "future_price_applied_date", label: "Future Price Applied Date"},
        { value: "stock", label: "Stock"},
        { value: "net_weight", label: "Net Weight" },
        { value: "gross_weight", label: "Gross weight"},
        { value: "width",  label: "Width"},
        { value: "height", label: "Height"},
        { value: "length", label: "Length"},
        { value: "category", label: "Category"},
        { value: "subcategory", label: "Subcategory"},
        { value: "multiple", label: "Multiplicity"},
        { value: "", label: "Custom" },
    ];

    constructor() {
        makeObservable(this);
    }

    public set warehouses(value: Warehouse[]) {
        this._warehouses = value;
    }

    @computed
    public get warehouses(): Warehouse[] {
        return this._warehouses;
    }

    @action
    public setWarehouses(value: Warehouse[]): void {
        this.warehouses = value;
    }

    public set submitting(value: boolean) {
        this._submitting = value;
    }

    @computed
    public get submitting(): boolean {
        return this._submitting;
    }

    @action
    public setSubmitting(value: boolean) {
        this.submitting = value;
    }

    public set exportModalShown(value: boolean) {
        this._exportModalShown = value;
    }

    @computed
    public get exportModalShown(): boolean {
        return this._exportModalShown;
    }

    @action
    public setExportModalShown(value: boolean, type: ExportModalType = ExportModalType.BASIC): void {
        this.exportModalShown = value;
        this.exportModalType = type;
    }

    private set documentsMap(value: DocumentsMap) {
        this._documentsMap = value;
    }

    @computed
    public get documents(): DocumentsMap {
        return this._documentsMap;
    }

    @action
    public setDocuments(value: DocumentsMap): void {
        this.documentsMap = value;
    }

    private set isLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }
}

export const DocumentsStoreContext = React.createContext<null | DocumentsStore>(null) as Context<DocumentsStore>;
