import React, {useState} from "react";
import {observer} from "mobx-react";
import {NotCompletedOrder} from "../../../../../models/cart/checkout/not.completed.order";
import {Button, Col, Row, Space, Tooltip, Typography} from "antd";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {CartCheckoutAddressesModal} from "./cart-checkout-addressess-modal/cart.checkout.addresses.modal";
import {CartCheckoutAddressesCard} from "./cart-checkout-addresses-card/cart.checkout.addresses.card";
import {CartCheckoutAddress} from "../../../../../models/cart/checkout/address/cart.checkout.address";



interface CartCheckoutAddressesProps {
    order: NotCompletedOrder,
    title: string,
    addresses: CartCheckoutAddress[],
    type: "billing" | "shipping",
    onCardClick: (order: NotCompletedOrder, address: CartCheckoutAddress, checked: boolean) => Promise<void>
}
export const CartCheckoutAddresses: React.FC<CartCheckoutAddressesProps> = observer(({order, title, type, addresses, onCardClick}) => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    return (
        <Space direction={"vertical"} style={{width: "100%"}} size={[0, 0]} >
            <Row>
                <Typography.Title level={3}>{t(title)}</Typography.Title>
                <Tooltip key={2} placement="topLeft" title={t("ADDRESS_ADD")}>
                    <Button
                        style={{margin: "3px 0 0 5px"}}
                        className="btn"
                        type="default"
                        size={"small"}
                        onClick={() => setModalShown(true)}
                        icon={<FontAwesomeIcon icon={["fas", "plus"]}/>}
                    />
                </Tooltip>
            </Row>
            <Row gutter={[30, 30]}>
                {addresses ? addresses.map(address => (
                    <Col className="card-col" key={address.id} xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}>
                        <CartCheckoutAddressesCard onAddressCardClick={onCardClick} order={order} address={address}/>
                    </Col>
                )) : null}
            </Row>
            <CartCheckoutAddressesModal type={type} order={order} visible={modalShown} onClose={() => setModalShown(false)}/>
        </Space>
    );
});
