import React from "react";
import {observer} from "mobx-react";
import {CartCheckoutStore, CartCheckoutStoreContext} from "../cart.checkout.store";
import {Button, Modal, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useRequiredLayers} from "../../../../utils/context";
import {CartCheckoutController, CartCheckoutControllerContext} from "../cart.checkout.controller";

export const CartCheckoutNotValidItemsModal: React.FC = observer(() => {
    const {store, controller} = useRequiredLayers<CartCheckoutStore, CartCheckoutController>(CartCheckoutStoreContext, CartCheckoutControllerContext);
    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <Modal
            centered
            maskClosable={false}
            visible={store.notValidItemsModalShown}
            title={t("ORDERS.NOT_VALID_ITEMS.ALERT.TITLE")}
            onCancel={controller.closeNotValidItemsModal}
            footer={
                <Row justify="end">
                    <Button type="primary" onClick={controller.onNotValidItemsModalConfirm}>
                        {t("ORDERS.CREATE.CONFIRM")}
                    </Button>
                    <Button danger type="primary" onClick={controller.onNotValidItemsModalCancel}>
                        {t("ORDERS.CANCEL.TITLE")}
                    </Button>
                </Row>
            }
        >
            <Typography.Paragraph>
                {t("ORDERS.NOT_VALID_ITEMS.ALERT.TEXT")}
            </Typography.Paragraph>
        </Modal>
    )
})