import {AxiosResponse} from "axios";
import {ImportResponse} from "../../../models/import/import.response";
import {apiService} from "../../api.service";
import {CartCreate, CartCreateType} from "../../../models/cart/create/cart.create";
import {AddArticleQuery} from "../../../models/cart/create/add-article-query/add.article.query";
import {OrdersCartRequestQuery} from "../../../models/cart/create/query/cart.create.query";
import {CartCreateResult} from "../../../models/cart/create/result/cart.create.result";

export class CartCreateService {
    public async getPreview(warehouses: string[], useBackorder: boolean, type: CartCreateType): Promise<CartCreate> {
        return (await apiService.get<CartCreate>(
            "cart",
            {
                params: {type, warehouses, useBackorder}
            })).data;
    }
    public async addToCart(data: AddArticleQuery): Promise<void> {
        await apiService.post("cart", {...data});
    }

    public async clearCart(): Promise<void> {
        return (await apiService.delete<void>("cart")).data;
    }

    public async deleteItem(id: number | string, type: CartCreateType): Promise<void> {
        return (await apiService.delete<void>("cart/" + type + "/" + id)).data;
    }

    public async updateItem(id: number | string, data: {qty: number, type: CartCreateType}): Promise<void> {
        return (await apiService.post<void>("cart/" + id, {...data})).data;
    }

    public async importFile(file: File, type: CartCreateType, onProgress: (percent: number) => void): Promise<AxiosResponse<ImportResponse>> {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        return await apiService.post<AxiosResponse<ImportResponse>>("cart/import", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: event => onProgress((event.loaded / event.total) * 100)
        }).then(x => x.data)
    };

    public async createOrder(request: OrdersCartRequestQuery): Promise<CartCreateResult> {
        return (await apiService.post<CartCreateResult>("orders", {...request})).data;
    }

    public async moveToMissingItems(articles: number[]): Promise<void> {
        return (await apiService.post<void>("/cart/missing-items/move", {articles})).data;
    }
}

export const cartCreateService = new CartCreateService();