import React, {useMemo} from "react";
import {columnsFactory} from "./my-issues.list.columns";
import {Button, Space, Typography} from "antd";
import {Link} from "react-router-dom";
import {filterKeysFactory} from "./my-issues.list.filter-groups";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import {FilterDataController} from "../../../controllers/filtered-table/filter.data.controller";
import {issuesService} from "../../../services/issues/issues.service";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {useTranslation} from "react-i18next";

export const IssuesList: React.FC<ComponentPropsFromRoute> = () => {

    const {t} = useTranslation("translation", {useSuspense: false});
    const columns = useMemo(() => columnsFactory(t), []);
    const keys = useMemo(() => filterKeysFactory(t), []);
    const handler = useMemo(() => new FilterDataController(r => issuesService.getIssues(r)), [])

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} style={{ width: "100%" }} className="content">
                <Typography.Title level={1} className="main-title">
                    {t("PLANNER.MY-ISSUES.TITLE")}
                </Typography.Title>
                <Space direction={"horizontal"} size={[10, 10]}>
                    <>
                        <Link to={"/issues/create"}>
                            <Button type={"primary"}>
                                {t("CREATE")}
                            </Button>
                        </Link>
                    </>
                </Space>
                <FilteredTable
                    dataHandler={handler}
                    columns={columns}
                    filterKeys={keys}
                />
            </Space>
        </div>
    )
}
