import React, {useState} from "react";
import "./catalog.product.cart.in-cart-qty.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CatalogArticle} from "../../../../../models/articles/catalog/search";
import {articlesService} from "../../../../../services/articles/articles.service";
import {notifications} from "../../../../../services/notifications/notifications";

interface CatalogProductCartProductInCartQtyProps {
    article: CatalogArticle;
}

export const CatalogProductCartProductInCartQty: React.FC<CatalogProductCartProductInCartQtyProps> = ({article}) => {

    const [value, setValue] = useState<number>(article.multiple);
    const [actuallyValue, setActuallyValue] = useState<number>(article.inCartQty);

    const increaseValue = () => {
        setValue(oldValue => oldValue + article.multiple)
    }

    const decreaseValue = () => {
        setValue(oldValue => oldValue > article.multiple ? oldValue - article.multiple : oldValue)
    }

    const updateActuallyValue = () => {
        setValue(oldValue => {
            setActuallyValue(oldActuallyValue => {
                const newValue = oldActuallyValue + oldValue;
                articlesService
                    .addToCart({article: article.id, qty: newValue, type: "regular"})
                    .then(() => {
                        notifications.successfully();
                    })
                return newValue;
            });
            return 1;
        })
    }

    return (
        <div className={"catalog-product-in-cart-qty"}>
            <div className={"catalog-product-in-cart-buttons"}>
                <div className={"catalog-product-in-cart-button"} onClick={decreaseValue}>
                    -
                </div>
                <div className={"catalog-product-in-cart-value"}>
                    {value}
                </div>
                <div className={"catalog-product-in-cart-button"} onClick={increaseValue}>
                    +
                </div>
            </div>
            <div className={"catalog-product-update-basket"} onClick={updateActuallyValue}>
                Add to Basket
                &nbsp;&nbsp;
                <FontAwesomeIcon
                    className="warehouses_col-icon"
                    icon={["fas", "shopping-cart"]}
                />
            </div>
            {actuallyValue ? (
                <div className={"catalog-product-already-in-basket"}>
                    In cart: {actuallyValue}
                </div>
            ) : null}
        </div>
    )
}