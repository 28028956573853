import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Form, Input, Button, notification} from "antd";
import {UserOutlined, LockOutlined} from "@ant-design/icons";
import {Link, Redirect} from "react-router-dom";
import {ErrorResponseData} from "../../../models/core/response";
import {AxiosError} from "axios";
import "./login.scss";
import {RootControllers, RootControllersContext} from "../../../controllers/root/root.controllers";
import {useTranslation} from "react-i18next";
import {useRequiredLayers} from "../../../utils/context";
import {RootStore, RootStoreContext} from "../../../stores/root/root";
import {LoginWrapper} from "./login.wrapper";

export const Login: React.FC = observer(() => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const {
        store: {authStore},
        controller: {loginController}
    } = useRequiredLayers<RootStore, RootControllers>(RootStoreContext, RootControllersContext);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [form] = Form.useForm();

    function handleKeyUp(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            form.submit();
        }
    }

    useEffect(() => {
        if (authStore.isLoggedIn) {
            setRedirect(true)
        }
        window.addEventListener("keyup", handleKeyUp);
        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [])


    if (redirect) {
        return <Redirect to="/"/>
    }

    return (
        <LoginWrapper title="LOGIN.TITLE">
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                layout="vertical"
                onFinish={() =>
                    form.validateFields()
                        .then(values => {
                            loginController.login(values)
                                .then(() => form.resetFields())
                                .catch((e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                    if (e.response?.status === 401) {
                                        notification.error({
                                            message: t("ERROR"),
                                            description: t("LOGIN.INVALID")
                                        });
                                    }
                                })
                                .then(() =>
                                    (e: any) => form.setFields(e.response?.data?.fields))
                        })
                }
            >
                <div className="login-form-items">
                    <Form.Item
                        name="username"
                        rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                    >
                        <Input
                            disabled={authStore.logging}
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder={t("USERNAME")}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                    >
                        <Input
                            disabled={authStore.logging}
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder={t("PASSWORD.TITLE")}
                        />
                    </Form.Item>
                    <Form.Item noStyle>
                        <Link
                            to="/forgot-password"
                            className="login-forgot-password"
                        >
                            {t("FORGOT_PASSWORD.TITLE")}
                        </Link>
                    </Form.Item>
                    <Form.Item style={{marginBottom: 10}}>
                        <Button onClick={() => form.submit()} loading={authStore.logging} type="primary"
                                style={{width: "100%"}}>{t("LOGIN.LOG_IN")}</Button>
                    </Form.Item>
                </div>
            </Form>
            {/*// <span style={{textAlign: "center", display: "block", marginBottom: 10}}>{t("OR")}</span>*/}
            <Link to="/sign-up" className="ant-btn register-btn" style={{width: "100%"}}>{t("SIGN_UP")}</Link>
        </LoginWrapper>
    );
});
