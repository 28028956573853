import {Button, Space, Tooltip} from "antd";
import {Date} from "../../../../components/core/commons/date/date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OrderComment, OrderDataItem, OrderDetailsData} from "../order.data";


export const generateCommentsData = (comments: OrderComment[], removeAction: (comment: OrderComment) => Promise<void>, removeText: string) => {
    return comments.map(comment => ({
        content: (
            <p className="order-comment-content">{comment.message}</p>
        ),
        datetime: (
            <Space align="center" size={[10, 10]} direction="horizontal">
                <Tooltip title={<Date dateString={comment.createdAt} showTime/>}>
                    <Date dateString={comment.createdAt} showTime/>
                </Tooltip>
                <Tooltip key={1} placement="topRight" title={removeText}>
                    <Button
                        onClick={async () => await removeAction(comment)}
                        type="default"
                        size={"small"}
                        className="btn-remove btn"
                        icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                    />
                </Tooltip>
            </Space>
        ),
    }));
};

export const generateDetailsData = (orderItems: OrderDataItem[]): OrderDetailsData[] => {
    return orderItems.map(item => ({
        id: item.id,
        articleName: item.articleName,
        articleDescription: item.description,
        price: item.price,
        priceType: item.priceType,
        clientPrice: item.clientPrice,
        qtyRequested: item.qtyRequested,
        quantity: item.qty,
        totalPrice: item.totalPrice
    }))
}

