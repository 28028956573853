import {action, computed, makeObservable, observable} from "mobx";
import {Invoice} from "../../../models/invoices/invoice";
import React, {Context} from "react";

export class InvoiceStore {
    @observable
    private _isLoading: boolean = false;

    @observable
    private _invoiceData: Invoice | null = null;

    constructor() {
        makeObservable(this);
    }

    private set isLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }

    private set invoiceData(value: Invoice) {
        this._invoiceData = value;
    }

    @computed
    public get invoice(): Invoice {
        return this._invoiceData!;
    }

    @action
    public setInvoice(value: Invoice): void {
        this.invoiceData = value;
    }
}

export const InvoiceStoreContext = React.createContext<null | InvoiceStore>(null) as Context<InvoiceStore>;
