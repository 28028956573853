import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Claim, ClaimItem} from "../../../../../models/claims/claims";
import {Modal, Table} from "antd";
import {columnsFactory} from "./claim.parts.columns";
import i18n from "i18next";
import {ClaimPart} from "../../part/claims.part";

export interface ClaimPartsListViewProps {
    claim: Claim;
    onUpdate: () => void;
}

export const ClaimPartsListView: React.FC<ClaimPartsListViewProps> = ({claim, onUpdate}) => {
    const {t} = useTranslation("translation", {useSuspense: false});

    const [previewItem, setPreviewItem] = useState<ClaimItem|null>(null);

    const openItemCart = (item: ClaimItem) => {
        setPreviewItem(item);
    }

    const closeItemCart = () => {
        setPreviewItem(null);
    }

    const columns = useMemo(() => columnsFactory(t, openItemCart), [i18n.language]);

    return (
        <>
            <Table
                dataSource={claim.items}
                columns={columns}
                pagination={false}
            />
            <Modal
                width={800}
                visible={!!previewItem}
                maskClosable={false}
                onCancel={() => closeItemCart()}
                closable={false}
                className={"modal-without-borders"}
                destroyOnClose
                footer={false}
                bodyStyle={{padding: 0}}
            >
                <>{previewItem ? (
                    <ClaimPart item={previewItem} onUpdate={onUpdate} />): null}</>
            </Modal>
        </>
    );
}