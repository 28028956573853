import React, {useState} from "react";
import {Button, Form, Input, Space, Typography} from "antd";
import {UIInputWidgetPriority} from "../ui/input/widget/priority/ui.input.widget.priority";
import {UIInputWidgetDepartment} from "../ui/input/widget/department/ui.input.widget.department";
import {issuesService} from "../../../services/issues/issues.service";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {MarkdownTextarea} from "../../core/markdown/markdown-textarea/markdown-textarea";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";

export const CreateIssue: React.FC<ComponentPropsFromRoute> = () => {

    const {t} = useTranslation("translation", {useSuspense: false});
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const history = useHistory();

    const initialValues = {}

    return (
            <div className="page_content">
                <Space direction="vertical" size={[30, 30]} style={{ width: "100%" }} className="content">
                    <Typography.Title level={1} className="main-title">
                        {t("PLANNER.TASK.NEW_ISSUE.TITLE")}
                    </Typography.Title>
                    <Form
                        form={form}
                        layout="vertical"
                        name={"planner_task_form"}
                        initialValues={initialValues}
                        onFinish={() => form
                            .validateFields()
                            .then(async values => {
                                setLoading(true);
                                try {
                                    const createdTask = await issuesService.createIssue(values);
                                    history.push('/issues/' + createdTask.id);
                                } catch (e: any) {
                                    form.setFields(e.response?.data?.fields);
                                } finally {
                                    setLoading(false);
                                }
                            })
                        }
                    >
                        <Form.Item
                            label={t("PLANNER.TASK.FIELDS.NAME")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <Input disabled={loading} />
                        </Form.Item>
                        <Form.Item
                            label={t("PLANNER.TASK.FIELDS.DESCRIPTION")}
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <MarkdownTextarea
                                fileUploader={f => Promise.resolve<[string, string]>(["x", "x"])}
                                height={"400px"}
                                disabled={loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("PLANNER.TASK.FIELDS.DEPARTMENTS")}
                            name="department"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <UIInputWidgetDepartment />
                        </Form.Item>
                        <Form.Item
                            label={t("PLANNER.TASK.FIELDS.PRIORITY")}
                            name="priority"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "FROM.ERROR.PLEASE_SELECT_VALUE"
                                    ),
                                },
                            ]}
                        >
                            <UIInputWidgetPriority />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type={"primary"}
                                onClick={() => form.submit()}
                                disabled={loading}
                            >
                                {t("SUBMIT")}
                            </Button>
                        </Form.Item>
                    </Form>
                </Space>
            </div>
    )
}
