import React, {useState} from "react";
import {Col, Row, Typography} from "antd";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {WikiCategoryTree} from "../tree/tree";
import {WikiTreeItem} from "../../../models/wiki/wiki";
import './main.scss';
import {ExtendedSwitch} from "../../core/route/extended.switch";
import {useTranslation} from "react-i18next";

interface WikiMainProps extends ComponentPropsFromRoute {
}

export const WikiMain: React.FC<WikiMainProps> = ({routeChildren}) => {

    const [overloadNodes, setOverloadNodes] = useState<WikiTreeItem[]>([]);
    const {t} = useTranslation('translation', {useSuspense: false});

    return (
        <div className="page_content">
            <Row className="content wiki-content" style={{width: "100%", marginRight: 0, marginLeft: 0}} gutter={[20, 20]}
                 align="stretch">
                <Col span={24}>
                    <Typography.Title
                        level={1}
                        className="main-title"
                        style={{marginBottom: 20}}
                    >
                        Knowledge base
                    </Typography.Title>
                    <Row gutter={[25, 25]}>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={24} xs={24}>
                            <WikiCategoryTree overloadNodes={overloadNodes} />
                        </Col>
                        <Col xxl={19} xl={19} lg={19} md={19} sm={24} xs={24}>
                            <ExtendedSwitch routers={routeChildren!} setOverloadNodes={setOverloadNodes} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
