import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {Space, Alert} from "antd";
import {CartCheckoutOrder} from "./cart-checkout-order/cart.checkout.order";
import {CartCheckoutController, CartCheckoutControllerContext} from "./cart.checkout.controller";
import {CartCheckoutOrderBodyLoader} from "./cart-checkout-order-body/cart-checkout-order-body-loader/cart.checkout.order.body.loader";
import {addressesService} from "../../../services/addresses/addresses.service";
import {cartCheckoutService} from "../../../services/cart/checkout/cart.checkout.service";
import {CartCheckoutStore, CartCheckoutStoreContext} from "./cart.checkout.store";
import {CartCheckoutNotValidItemsModal} from "./cart-checkout-not-valid-modal/cart.checkout.not.valid.items.modal";
import {CartCheckoutCancelOrderModal} from "./cart-checkout-cancel-order-modal/cart.checkout.cancel.order.modal";
import {RootControllersContext} from "../../../controllers/root/root.controllers";
import {useTranslation} from "react-i18next";
import Title from "antd/es/typography/Title";

export const CartCheckout: React.FC = observer(() => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const {accountController} = useContext(RootControllersContext);
    const [store] = useState(() => new CartCheckoutStore());
    const [controller] = useState(() => new CartCheckoutController(store, cartCheckoutService, addressesService, accountController.decreaseNotCompletedOrdersAmount));

    return (
        <CartCheckoutStoreContext.Provider value={store}>
            <CartCheckoutControllerContext.Provider value={controller}>
                <Space direction="vertical" size={[20, 20]} style={{width: "100%"}}>
                    {store.loading ? <CartCheckoutOrderBodyLoader/> : (store.notCompletedOrders.length > 0 ? (
                        <Space direction={"vertical"} size={[40, 40]}>
                            {store.todayOrders.length > 0 ? (
                                <Space direction={"vertical"}>
                                    {store.upToThisDayOrders.length > 0 ? (
                                        <Title level={3}>{t("ORDERS.NOT_COMPLETED_ORDERS.PART.TODAY")}</Title>
                                    ) : null}
                                    {store.todayOrders.map(order => <CartCheckoutOrder key={order.id} order={order} />)}
                                </Space>
                            ) : null}
                            {store.upToThisDayOrders.length > 0 ? (
                                <Space direction={"vertical"}>
                                    {store.todayOrders.length > 0 ? (
                                        <Title level={3}>{t("ORDERS.NOT_COMPLETED_ORDERS.PART.UP_TO_THIS_DAY")}</Title>
                                    ) : null}
                                    {store.upToThisDayOrders.map(order => <CartCheckoutOrder key={order.id} order={order} />)}
                                </Space>
                            ) : null}
                        </Space>
                    ) : (
                        <Alert type="info" message={t("CLIENT.NO_NOT_COMPLETED_ORDERS")} />
                    ))}
                </Space>
                <CartCheckoutNotValidItemsModal/>
                <CartCheckoutCancelOrderModal/>
            </CartCheckoutControllerContext.Provider>
        </CartCheckoutStoreContext.Provider>
    );
});
