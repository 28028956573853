import { AccessesStore } from "./accesses.store";
import { AccessesService } from "../../../services/accesses/accesses.service";
import { Access } from "../../../models/accesses/access";
import React from "react";
import { SystemsService } from "../../../services/systems/systems.service";
import { AccessFormValues } from "../../../models/accesses/access.form.values";
import { AccessRequest } from "../../../models/accesses/access.request";

export class AccessesController {
    constructor(
        private readonly _accessesStore: AccessesStore,
        private readonly _accessesService: AccessesService,
        private readonly _systemsService: SystemsService
    ) {
        this.getData();
    }
    private async getData(): Promise<void> {
        this._accessesStore.setLoading(true);
        this._accessesStore.setSystemGroups(await this._systemsService.getSystemGroups());
        this._accessesStore.setAccesses(await this._accessesService.getAccesses());
        this._accessesStore.setLoading(false);
    }

    public async createAccess(values: AccessRequest): Promise<void> {
        this._accessesStore.setAccesses([
            ...this._accessesStore.accesses,
            await this._accessesService.postAccess(values),
        ]);
    }

    public async editAccess(values: AccessRequest, access: Access): Promise<void> {
        const editedAccess = await this._accessesService.postAccess(values, access.id);
        const index = this._accessesStore.accesses.findIndex((item) => item.id === access.id);
        if (index >= 0) {
            this._accessesStore.editAccess(index, editedAccess);
        }
    }

    private static createAccessRequest = ({
        firstName,
        lastName,
        email,
        password,
        systemAccesses,
        sendPassword,
    }: AccessFormValues): AccessRequest => {
        return {
            user: {
                firstName,
                lastName,
                email,
                password,
                sendPassword,
            },
            systemAccesses,
        };
    };

    public async removeAccess(access: Access): Promise<void> {
        const index = this._accessesStore.accesses.findIndex((item) => item.id === access.id);
        const newAccesses: Access[] = [...this._accessesStore.accesses];
        if (index !== -1 && index !== undefined) {
            await this._accessesService.removeAccess(access.id);
            newAccesses.splice(index, 1);
            this._accessesStore.setAccesses(newAccesses);
        }
    }
}

export const AccessesControllerContext = React.createContext<null | AccessesController>(
    null
) as React.Context<AccessesController>;
