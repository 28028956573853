import {Address, EditAddressResult} from "../../models/addresses/address";
import {apiService} from "../api.service";

export class AddressesService {
    public async getAddresses(): Promise<Address[]> {
        return (await apiService.get<Address[]>("addresses")).data;
    }

    public async setDefaultAddress(id: number): Promise<void> {
        await apiService.post("addresses/" + id + "/default");
    }

    public async editAddress(values: Address, id: number): Promise<EditAddressResult> {
        return (await apiService.post<EditAddressResult>("addresses/" + id, {...values})).data;
    }

    public async createAddress(values: Address, id?: number): Promise<Address> {
        return (await apiService.post<Address>("addresses", {...values})).data;
    }

    public async removeAddress(id: number): Promise<void> {
        await apiService.delete("addresses/" + id);
    }
}

export const addressesService = new AddressesService();