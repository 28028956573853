import React, {useState} from "react";
import {observer} from "mobx-react";
import {Button, Card, Col, Row, Skeleton, Typography} from "antd";
import {ContactItem} from "./contact-item/contact.item";
import {ContactsModal} from "./contacts-modal/contacts.modal";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContactsStore, ContactsStoreContext} from "./contacts.store";
import {ContactsController, ContactsControllerContext} from "./contacts.controller";
import {contactsService} from "../../../services/contacts/contacts.service";


export const Contacts: React.FC = observer(() => {
    const [store] = useState(() => new ContactsStore());
    const [controller] = useState(() => new ContactsController(store, contactsService));
    const {t} = useTranslation('translation', {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    return (
        <ContactsStoreContext.Provider value={store}>
            <ContactsControllerContext.Provider value={controller}>
                <Row className="row-no-margin" gutter={[20, 20]}>
                    <Col span={24}>
                        {store.loading ? (
                            <Skeleton.Button active/>
                        ) : (

                            <Button type="primary" onClick={() => setModalShown(true)}>
                                {t("ADD")}
                            </Button>
                        )}
                    </Col>
                    {store.loading ? (
                        <Col className="card-col" xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Card
                                className="card"
                                bordered={false}
                                size="small"
                                title={<Skeleton active paragraph={{rows: 1}}/>}
                            >
                                <Skeleton active paragraph={{rows: 3, width: "100%"}}/>
                            </Card>
                        </Col>
                    ) : store.contacts.length ? store.contacts.map(contact => (
                        <ContactItem key={contact.id} contact={contact}/>
                    )) : t("CONTACTS.NO_DATA")}
                </Row>
                <ContactsModal visible={modalShown} onClose={() => setModalShown(false)} />
            </ContactsControllerContext.Provider>
        </ContactsStoreContext.Provider>
    );
})
