import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {WishlistItem} from "../../models/wishlist/wishlist.item";
import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {MissingItem} from "../../models/wishlist/missing.item";

export class WishlistService {
    public async getWishlist(request: PaginatedRequest): Promise<PaginatedResult<WishlistItem>> {
        return (await apiService.get<PaginatedResult<WishlistItem>>(
            "cart/wishlist",
            {
                params: request.toParams()
            }
        )).data;
    }
    public async getMissingItems(request: PaginatedRequest): Promise<PaginatedResult<MissingItem>> {
        return (await apiService.get<PaginatedResult<MissingItem>>(
            "cart/missing-items",
            {
                params: request.toParams()
            }
        )).data;
    }

    public async hideItem(id: number): Promise<void> {
        await apiService.post("cart/missing-items/" + id + "/hide");
    }

    public async hideAllItems(): Promise<void> {
        await apiService.delete("cart/missing-items");
    }

    public async unhideItem(id: number): Promise<void> {
        await apiService.delete("cart/missing-items/" + id + "/hide");
    }

    public async removeWishListItem(id: number): Promise<void> {
        await apiService.delete("cart/wishlist/" + id);
    }

    public async takeToCart(data: {items: number[]}, path: "wishlist" | "missing-items"): Promise<void> {
        await apiService.post("cart/" + path + "/take", {...data})
    }
    public async takeAllToCart(data: {warehouses: number[]}) {
        return apiService.post(
            "/cart/missing-items/move/warehouses",
            {...data}
        );
    }

    public async clearList(path: string): Promise<void> {
        await apiService.delete("cart/" + path);
    }

    createOrder(items: number[]): Promise<any> {
        return apiService.post("cart/missing-items/create-order", {items: items})
    }

    createOrderWithFilters(filters: any): Promise<any> {
        return apiService.post("cart/missing-items/create-order/filter", {}, {params: filters})
    }

    createOrderWithoutReservation(items: number[]): Promise<any> {
        return apiService.post("cart/missing-items/create-order/without-reservation", {items: items})
    }

    createOrderWithoutReservationWithFilters(filters: any): Promise<any> {
        return apiService.post("cart/missing-items/create-order/filter/without-reservation", {}, {params: filters})
    }

    createReservation(items: number[]): Promise<any> {
        return apiService.post("cart/missing-items/create-order/reservation", {items: items})
    }

    createReservationWithFilters(filters: any): Promise<any> {
        return apiService.post("cart/missing-items/create-order/filter/reservation", {}, {params: filters})
    }
}

export const wishlistService = new WishlistService();

