import React from "react";
import {Button, Col, Descriptions, Row, Space, Tag, Typography, Skeleton, Divider, Alert} from "antd";
import {observer} from "mobx-react";
import {useRequiredLayers} from "../../../utils/context";
import {ArticleStore, ArticleStoreContext} from "../article.store";
import {ArticleController, ArticleControllerContext} from "../article.controller";
import {useTranslation} from "react-i18next";
import price_label from "../../catalog/images/price-label.svg";
import {displayPrice} from "../../../models/amount/amount.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Date} from "../../core/commons/date/date";
import {AddToCart} from "../article-common/add-to-cart/add-to-cart";
import {ProductImage} from "../../catalog/product/common/image/product.image";

interface ArticleMainProps {
}

export const ArticleMain: React.FC<ArticleMainProps> = observer(() => {
    const {
        store,
        controller
    } = useRequiredLayers<ArticleStore, ArticleController>(ArticleStoreContext, ArticleControllerContext);

    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <>
            {store.loading ? (
                <div className="article_title-loader">
                    <Skeleton active paragraph={{rows: 2}} title={false}/>
                </div>
            ) : (
                <Typography.Title
                    className="article_title"
                    level={1}
                >
                    {store.article.name} / <span>{store.article.description}</span>
                </Typography.Title>
            )}
            <Row justify="space-between" className="article_info">
                <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                    <Space style={{width: "100%"}} align={"center"} direction="vertical"
                           className="article_info_img-block">
                        <div className="article_info_img-main">
                            {store.loading ? (
                                <Skeleton.Image/>
                            ) : <ProductImage
                                sku={store.article.name}
                                size={"200"}
                                scheme={false}
                                width={200}
                                height={200}
                                previews={[
                                    {size: "1600", scheme: false},
                                    {size: "640", scheme: true},
                                ]}
                            />
                            }
                        </div>
                        <Space className="article_img_thumbs" direction="horizontal" size={[20, 20]}>
                            {store.loading ? (
                                <>
                                    <Skeleton.Image/>
                                    <Skeleton.Image/>
                                </>
                            ) : (
                                <>
                                    <Space
                                        direction="horizontal"
                                        size={[40, 0]}
                                    >
                                        <ProductImage
                                            sku={store.article.name}
                                            size={"200"}
                                            scheme={false}
                                            width={115}
                                            height={115}
                                            previews={[
                                                {size: "1600", scheme: false},
                                            ]}
                                        />
                                        <ProductImage
                                            sku={store.article.name}
                                            size={"200"}
                                            scheme={true}
                                            width={115}
                                            height={115}
                                            previews={[
                                                {size: "640", scheme: true},
                                            ]}
                                        />
                                    </Space>
                                </>
                            )}
                        </Space>
                    </Space>
                </Col>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Row className="article_buttons-wrapper" justify="start" align="middle" gutter={[5, 5]}>
                            {store.loading ? (
                                <Space style={{marginBottom: 20, width: "100%"}} direction="horizontal" size={[5, 5]}>
                                    <Skeleton.Button active/>
                                    <Skeleton.Button active/>
                                    <Skeleton.Button active/>
                                </Space>
                            ) : (
                                <>
                                    {store.article.multiple > 1 ? (
                                        <Col span={24}>
                                            <Alert
                                                message={t('ARTICLE.WITH_MULTIPLIER.DESCRIPTION', {multiple: store.article.multiple})}
                                                type="info"
                                                showIcon
                                            />
                                        </Col>
                                    ) : null}
                                    <Col span={24}>
                                        <Row justify={"space-between"}>
                                            <Col className={"cart-button-wrapper"} span={20}>
                                                <Space direction={"horizontal"}>
                                                    <div className="price-wrapper">
                                                        <img src={price_label} alt={String(store.article.amount.value)}/>
                                                        <span>{displayPrice(store.article.amount)} <span className="price-description">({t("PRICING.EXCL_VAT")})</span></span>
                                                    </div>
                                                </Space>
                                            </Col>
                                            <Col style={{textAlign: "center"}}>
                                                <Button
                                                    onClick={async () => {
                                                        if (store.article.inWishList) {
                                                            await controller.deleteFromWishList(store.article);
                                                            return;
                                                        }
                                                        await controller.addToWishList(store.article);
                                                    }}
                                                    className={store.article.inWishList ? "ws_button catalog_button catalog_button_active article-wish-list-btn" : "ws_button catalog_button article-wish-list-btn"}
                                                    icon={<FontAwesomeIcon icon={["fas", "star"]}/>}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            )}
                            <Col span={24}>
                                <div className="article_stocks-wrapper">
                                    <div className="article_stocks_icon-wrapper">
                                        <FontAwesomeIcon
                                            style={{marginBottom: -5}}
                                            className="warehouses_col-icon"
                                            icon={["fas", "cubes"]} size={"lg"}
                                        />
                                    </div>
                                    <div className="article-stocks">
                                        {store.loading ? (
                                            <Space direction="horizontal" size={[5, 5]} style={{width: "100%"}} wrap>
                                                <Skeleton.Button active/>
                                                <Skeleton.Button active/>
                                                <Skeleton.Button active/>
                                                <Skeleton.Button active/>
                                                <Skeleton.Button active/>
                                            </Space>
                                        ) : store.article.stock.map((stock, index) => (
                                            <Tag
                                                className={stock.amount === 0 ? "no-stock" : undefined}
                                                key={index}
                                                title={stock.warehouse}
                                            >
                                                <strong>{stock.amount}</strong><span>({stock.warehouse})</span>
                                            </Tag>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            {!store.loading ? (
                                <Col span={24}>
                                    <AddToCart
                                        icon={["fas", "shopping-cart"]}
                                        title={t("CART.ADD_TO_CART")}
                                        inCartTitle={t("CART.IN_CART")}
                                        goToTitle={t("CART.PROCEED_TO_CHECKOUT")}
                                        goToLink={"/cart"}
                                        article={store.article}
                                        inCart={store.article.inCartQty > 0}
                                        inCartQty={store.article.inCartQty}
                                        addToCart={controller.addToCart}
                                        removeFromCart={controller.deleteFromCart}
                                    />
                                </Col>
                            ) : null}
                            {!store.loading && store.article.inTransit ? (
                                <>
                                    <Col span={24}>
                                        <div className="article_stocks-wrapper" style={{marginTop: 20}}>
                                            <div className="article_stocks_icon-wrapper">
                                                <FontAwesomeIcon
                                                    style={{marginBottom: -5}}
                                                    className="warehouses_col-icon"
                                                    icon={["fas", "truck"]} size={"lg"}
                                                />
                                            </div>
                                            <div className="article-stocks">
                                                <>
                                                    <Tag key={1}>
                                                        <>
                                                            <strong>{store.article.inTransit.qty}</strong>
                                                            <span>(Will arrive approximately <Date dateString={store.article.inTransit.minimalDate} noStyle />
                                                                {store.article.inTransit.maximalDate !== store.article.inTransit.minimalDate ? (
                                                                    <> ... <Date dateString={store.article.inTransit.maximalDate} noStyle /></>
                                                                ) : null}
                                                                )
                                                        </span>
                                                        </>
                                                    </Tag>
                                                </>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <AddToCart
                                            icon={["fas", "truck"]}
                                            title={t("CART.ADD_TO_PRE_ORDER")}
                                            inCartTitle={t("CART.IN_PRE_ORDER")}
                                            goToTitle={t("CART.PROCEED_TO_PRE_ORDER")}
                                            goToLink={"/cart/reservation"}
                                            article={store.article}
                                            inCart={store.article.inReservationQty >0}
                                            inCartQty={store.article.inReservationQty}
                                            addToCart={controller.addToReservation}
                                            removeFromCart={controller.deleteFromReservation}
                                            additionalClass={"dark"}
                                        />
                                    </Col>
                                </>
                            ) : null}
                        </Row>

                            {store.loading ? (
                                <Skeleton active paragraph={{rows: 5}}/>
                            ) : (
                                <>
                                    <Divider />

                                    <Descriptions className="article_description" title={t("ARTICLES.ATTRIBUTES.TITLE")} column={1} bordered>
                                        {store.article.attributes.length ? (
                                            <>
                                                {store.article.attributes.map(attribute => (
                                                    <Descriptions.Item key={attribute.name}
                                                                       label={attribute.name}>{attribute.value}</Descriptions.Item>
                                                ))}
                                            </>
                                        ) : null}
                                    </Descriptions>
                                    {store.article.attributes.length === 0 ? (
                                        <Alert
                                            message={t("ARTICLE.NO_ATTRIBUTES.TITLE")}
                                            description={t("ARTICLE.NO_ATTRIBUTES.MESSAGE")}
                                            type="info"
                                            showIcon
                                        />
                                    ) : null}
                                </>
                            )}
                    </Space>
                </Col>
            </Row>
        </>
    );
});
