import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import {StepConfig, Stepper} from "../../core/commons/stepper/stepper";
import {ClarificationStep} from "./steps/clarification/clarification.step";
import {CreateClaimItem} from "../../../models/claims/claims";
import {SelectSkuStep} from "./steps/select-sku/select-sku.step";
import {DescriptionStep} from "./steps/description/description.step";

interface ClaimsCreateModalProps {
    onClose: () => void;
    onComplete: (item: CreateClaimItem) => void;

}

export const ClaimsCreateModal: React.FC<ClaimsCreateModalProps> = ({onClose, onComplete}) => {
    const { t } = useTranslation("translation", { useSuspense: false });

    const data: CreateClaimItem = {}

    const steps: StepConfig<{}>[] = [
        {
            title: 'Select parts',
            description: '',
            component: SelectSkuStep,
            hideControls: true
        },
        {
            title: 'Description',
            description: '',
            component: DescriptionStep,
        },
        {
            title: 'Clarification',
            description: 'installation data, etc',
            component: ClarificationStep
        },
    ]

    useEffect(() => {
    }, [])

    return (
        <Modal
            title={t('CLAIMS.CREATE.ADD_ITEM')}
            width={700}
            visible={true}
            maskClosable={false}
            destroyOnClose
            footer={false}
            onCancel={onClose}
        >
            <Stepper
                steps={steps}
                data={data}
                onFinish={data => onComplete(data)}
            />
        </Modal>
    );
}
