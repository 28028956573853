import React, {useState} from "react";
import {observer} from "mobx-react";
import {Button, Card, Col, message, notification, Popconfirm, Space, Tooltip, Typography} from "antd";
import {CustomCard} from "../../../core/commons/custom-card/custom.card";
import {useTranslation} from "react-i18next";
import {Integration} from "../../../../models/integrations/integration";
import {useRequiredLayers} from "../../../../utils/context";
import {IntegrationsStoreContext} from "../integrations.store";
import {IntegrationsControllerContext} from "../integrations.controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {IntegrationsModal} from "../integrations-modal/integrations.modal";


interface IntegrationItemProps {
    integration: Integration
}
export const IntegrationItem: React.FC<IntegrationItemProps> = observer(({integration}) => {
    const {store, controller} = useRequiredLayers(IntegrationsStoreContext, IntegrationsControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});
    const [isModalShown, setIsModalShown] = useState<boolean>(false);

    const cardRows = [
        {key: "type"},
        {key: "lastUploadedAt", label: t("CLIENT.INTEGRATIONS.LAST_UPLOADED_AT"), isDate: true},
        {key: "scheduledUploadAt", label: t("CLIENT.INTEGRATIONS.SCHEDULED_UPLOADED_AT"), isDate: true}
    ]

    const onUploadBtnClick = async () => {
        message.info(t("UPLOAD.BEGINS"), 1.5);
        try {
            await controller.uploadIntegrationData(integration);
            notification.success({message: t("SUCCESS"), description: t("CLIENT.INTEGRATIONS.UPLOAD.SUCCESS")})
        } catch (e) {

        }
    }
    return (
        <>
            <Col className="card-col" xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card
                    className="card"
                    title={<Typography.Title title={integration.name} ellipsis level={5}>{integration.name}</Typography.Title>}
                    extra={
                        <Space direction="horizontal" size={[5, 5]}>
                            <Tooltip placement="topRight" title={t("UPLOAD.TITLE")}>
                                <Button
                                    className={"card-btn btn"}
                                    onClick={onUploadBtnClick}
                                    type="default"
                                    size={"small"}
                                    icon={<FontAwesomeIcon icon={["fas", "upload"]}/>}
                                />
                            </Tooltip>
                            <Tooltip placement="topRight" title={t("EDIT")}>
                                <Button
                                    className={"card-btn btn"}
                                    onClick={() => setIsModalShown(true)}
                                    type="default"
                                    size={"small"}
                                    icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                                />
                            </Tooltip>
                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => controller.removeIntegration(integration)}
                                placement="top"
                                okText={t("YES")}
                                cancelText={t("NO")}
                            >
                                <Tooltip placement="topRight" title={t("REMOVE")}>
                                    <Button
                                        size={"small"}
                                        className={"card-btn btn-remove btn"}
                                        type="default"
                                        icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                    />
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    }
                >
                    <CustomCard data={integration} rowKeys={cardRows}/>
                </Card>
                <IntegrationsModal
                    item={integration}
                    visible={isModalShown}
                    onClose={() => setIsModalShown(false)}
                />
            </Col>
        </>
    );
});