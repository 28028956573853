import { action, makeObservable, observable } from "mobx";
import { PaginatedRequest } from "../../../../models/requests/paginated-request/paginated.request";
import { FilterDataController } from "../../../../controllers/filtered-table/filter.data.controller";
import { FilterValues, FilterItemState } from "../../../../models/filtered-table/filtered.table";
import * as queryString from "querystring";

export class FilteredTableStore<T> {
    @observable
    public loading: boolean = true;

    @observable
    public total: number = 0;

    @observable
    public columnsSettingsModalShown: boolean = false;

    @observable
    public selectedColumns: string[] = !this.pageKey
        ? this.defaultColumns.map((item) => item.value)
        : localStorage[this.pageKey]
        ? JSON.parse(localStorage[this.pageKey])
        : this.defaultColumns.map((item) => item.value);

    @observable
    public limitVariants: string[] = ["10", "20", "30", "40", "50", "100"];

    public filters: FilterItemState[] = [];

    public request = new PaginatedRequest();

    constructor(
        public dataHandler: FilterDataController<T>,
        public defaultColumns: { title: string; value: string }[],
        public pageKey?: string
    ) {
        makeObservable(this);
        this.setPage = this.setPage.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.reloadDataByPopState = this.reloadDataByPopState.bind(this);
        this.openColumnsSettingsModal = this.openColumnsSettingsModal.bind(this);
        this.closeColumnsSettingsModal = this.closeColumnsSettingsModal.bind(this);
        this.selectColumns = this.selectColumns.bind(this);
        this.updateRequestParamsFromURL();
        this.reloadData();
    }

    @action
    private setColumnsSettingsModalShown(value: boolean): void {
        this.columnsSettingsModalShown = value;
    }

    @action
    private setSelectedColumns(value: string[]): void {
        this.selectedColumns = value;
    }

    public selectColumns({ columns }: { columns: string[] }): void {
        this.setSelectedColumns(columns);
        if (this.pageKey) {
            localStorage.setItem(this.pageKey, JSON.stringify(columns));
        }
    }

    public openColumnsSettingsModal(): void {
        this.setColumnsSettingsModalShown(true);
    }

    public closeColumnsSettingsModal(): void {
        this.setColumnsSettingsModalShown(false);
    }

    @action
    private setLoading(status: boolean) {
        this.loading = status;
    }

    @action
    public setPage(page: number) {
        this.request.page = page;
        this.updateUrlQueryString();
        this.reloadData();
    }

    @action
    public setLimit(limit: number) {
        this.request.limit = limit;
        this.updateUrlQueryString();
        this.reloadData();
    }

    public async reloadData() {
        this.setLoading(true);
        await this.dataHandler.reloadItems(this.request);
        this.setLoading(false);
    }

    public setFilters(filters: FilterItemState[]) {
        this.request.filters = this.prepareFiltersForRequest(filters);
        this.request.page = 1;
        this.filters = filters;
        this.updateUrlQueryString();
        this.reloadData();
    }

    @action
    public reloadDataByPopState() {
        this.updateRequestParamsFromURL();
        this.reloadData();
    }

    private prepareFiltersForRequest(filters: FilterItemState[]): FilterValues {
        const filterValues: FilterValues = {};
        filters.forEach((tag) => {
            if (tag.type === "int" || tag.type === "string") {
                if (tag.queryType === "=") {
                    filterValues[tag.key] = tag.values;
                } else {
                    filterValues[tag.key] = tag.queryType + tag.values;
                }
            } else if (tag.type === "select" || tag.type === "groupedSelect") {
                filterValues[tag.key] = tag.values;
            } else if (tag.type === "date") {
                filterValues[tag.key] = tag.values[0] + "--" + tag.values[1];
            } else if (tag.type === "boolean") {
                filterValues[tag.key] = tag.values;
            }
        });

        return filterValues;
    }

    private updateRequestParamsFromURL() {
        const params: any = queryString.parse(window.location.search.substr(1));

        const urlFilters: FilterItemState[] = this.decodeFilterParams(params.filter);

        if (Array.isArray(urlFilters) && urlFilters.length > 0) {
            this.request.filters = this.prepareFiltersForRequest(urlFilters);
            this.filters = urlFilters;
        } else {
            this.request.filters = {};
            this.filters = [];
        }

        this.request.page = params.page ? parseInt(params.page) : 1;
        this.request.limit = params.limit ? parseInt(params.limit) : 10;
    }

    private updateUrlQueryString() {
        const link = queryString.stringify({
            filter: this.encodeFilterParams(this.filters),
            page: this.request.page,
            limit: this.request.limit,
        });

        window.history.pushState("", "", "?" + link);
    }

    private encodeFilterParams(inputFilters: FilterItemState[]): string {
        const parts: string[] = [];
        inputFilters.forEach((filter) => {
            const value = Array.isArray(filter.values) ? filter.values.join("::") : filter.values;
            parts.push(`${filter.key};${filter.queryType};${filter.type};${value}`);
        });

        return parts.join("|||");
    }

    private decodeFilterParams(value: string): FilterItemState[] {
        const inputFilters: FilterItemState[] = [];
        let filterValues: string[] = [];
        if (value) {
            filterValues = value.split("|||");
        }

        if (filterValues.length > 0) {
            filterValues.forEach((implodedValue) => {
                const values = implodedValue.split(";");
                if (Array.isArray(filterValues) && values.length === 4) {
                    inputFilters.push({
                        key: values[0],
                        queryType: values[1],
                        values: values[3].includes("::")
                            ? values[3].split("::")
                            : values[2] === "select" || values[2] === "groupedSelect"
                            ? [values[3]]
                            : values[3],
                        type: values[2],
                    });
                }
            });
        }

        return inputFilters;
    }
}
