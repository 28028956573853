import {TFunction} from "react-i18next";
import {ColumnsType} from "antd/es/table";
import React from "react";
import {Date} from "../../../core/commons/date/date";
import {TableShipment} from "./order.shipments.model";

export const columnsFactory = (t: TFunction<"translation">): ColumnsType<TableShipment> => {
    return  [
        {
            title: t("NUMBER"),
            dataIndex: "reference",
            key: "reference"
        },
        {
            title: t("COURIER.TITLE"),
            dataIndex: "carrier",
            key: "carrier",
        },
        {
            title: t("SHIPMENTS.PARCELS.TRACK_CODE"),
            dataIndex: "trackCode",
            key: "trackCode",
            render: (trackCode: string, row) => (
                <a href={row.trackingUrl} target="_blank" rel="noopener noreferrer">
                    {trackCode}
                </a>
            ),
        },
        {
            title: t("SHIPMENTS.PARCELS.NUMBER"),
            dataIndex: "number",
            key: "number",
        },
        {
            title: t("SHIPPED_AT"),
            dataIndex: "packedAt",
            key: "packedAt",
            render: (dateString: string | null) => {
                return dateString ? <Date dateString={dateString} showTime={false} /> : null;
            },
        }
    ];
}