import React, {useContext} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Space, Alert, Typography} from "antd";
import {ComponentPropsFromRoute} from "../../routers/routers";
import {RootStoreContext} from "../../stores/root/root";
import {isAllowed} from "../../utils/helpers";
import "./cart.scss";
import {NotCompletedOrderAmount} from "../core/commons/not-completed-order-ammount/not.completed.order.amount";
import {RouteTabs} from "../core/commons/route-tabs/route.tabs";


interface BasketProps extends ComponentPropsFromRoute {}
export const Cart: React.FC<BasketProps> = observer(({routeChildren, name}) => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const {authStore} = useContext(RootStoreContext);

    if (!isAllowed(authStore.attributes, "order_management")) {
        return (
            <div className="page_content">
                <Space direction="vertical" size={[20, 20]} className="content">
                    <Alert message={t("BASKET.ORDERS_PLACEMENT_NOT_AVAILABLE")} type="error"/>
                </Space>
            </div>
        )
    }

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Typography.Title level={1} className="main-title">{t("CART.TITLE")}</Typography.Title>
                <NotCompletedOrderAmount/>
                <RouteTabs
                    routes={routeChildren!}
                    hiddenTabs={['apply_order']}
                    withoutRootTabPath={true}
                />
            </Space>
        </div>
    );
});
