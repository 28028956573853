import React from "react";
import {Button, Row, Typography, Skeleton, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./catalog.product.list.scss";
import {Pagination} from "../../../../models/requests/paginated-result/paginated.result";

interface CatalogProductListProps {
    loading: boolean;
    onViewChange: (view: "cards" | "table") => void;
    pagination?: Pagination;
}

export const CatalogProductList: React.FC<CatalogProductListProps> = ({loading, onViewChange, pagination}) => {

    return (
        <Row justify="space-between" align="middle" className="catalog_view_panel">
            {loading ? (
                <Skeleton.Button active style={{marginLeft: 10, height: 30}}/>
            ) : (
                <Typography.Text className="catalog_view_panel_count">
                    {pagination ? (
                        <>{(pagination.limit * pagination.page) - pagination.limit + 1}-{pagination.limit * pagination.page} / {pagination.total}</>
                    ) : null}
                </Typography.Text>
            )}
            <>
                <Button
                    onClick={() => onViewChange("cards")}
                    className="catalog_view_panel_button ws_button"
                    icon={<FontAwesomeIcon icon={["fas", "list"]} size={"lg"}/>}
                    style={{marginLeft: "auto"}}
                />
                <Button
                    onClick={() => onViewChange("table")}
                    className="catalog_view_panel_button ws_button"
                    icon={<FontAwesomeIcon icon={["fas", "bars"]} size={"lg"}/>}
                />
            </>
        </Row>
    );
}
