import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {Access} from "../../../../models/accesses/access";
import {useTranslation} from "react-i18next";
import {Checkbox, Col, Form, FormInstance, Input, Modal, Row, Tooltip, Typography} from "antd";
import {ErrorResponseData} from "../../../../models/core/response";
import {AxiosError} from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {AccessesControllerContext} from "../accesses.controller";
import {SystemAccess} from "../system-access/system.access";
import {useRequiredLayers} from "../../../../utils/context";
import {AccessesStoreContext} from "../accesses.store";
import {generatePassword} from "../../../../utils/helpers";

interface AccessesModalProps {
    visible: boolean;
    onClose: () => void;
    access?: Access
}
export const AccessesModal: React.FC<AccessesModalProps> = observer(({visible, onClose, access}) => {
    const {store, controller} = useRequiredLayers(AccessesStoreContext, AccessesControllerContext);
    const {t} = useTranslation("translation", {useSuspense: false});
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const setGeneratedPassword = (form: FormInstance) => {
        const generatedPassword = generatePassword();
        form.setFieldsValue({user: {password: generatedPassword}});
    };


    let defaultValues: any;

    if (access) {
        defaultValues = {
            user: {
                ...access.user,
                sendPassword: true,
            },
            systemAccesses: access.systemAccesses.map(item => ({system: item.system.id, group: item.group.id}))
        };
        // defaultValues.group = access.group.id;
    } else {
        defaultValues = {
            user: {
                sendPassword: true
            },
            systemAccesses: store.systemWithGroups[0] ? [
                {system: store.systemWithGroups[0].id, group: store.systemWithGroups[0].groups[0]?.id}
            ] : []
        };
    }


    const onSubmit = (values: any): Promise<any> => {
        console.log(values);
        return access ? controller.editAccess(values, access) : controller.createAccess({...values})
    };


    return (
        <Modal
            visible={visible}
            maskClosable={false}
            confirmLoading={loading}
            title={access ? t("ACCOUNTS.EDIT") : t("ACCOUNTS.ADD")}
            okText={access ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"accounts_form"}
                initialValues={defaultValues}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        onSubmit({
                            ...values,
                            user: {
                                ...values.user,
                                password: values.user.password ? values.user.password.trim() : "",
                                sendPassword: values.user.sendPassword === undefined ? false : values.user.sendPassword,
                            }
                        })
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {
                            }, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name={["user", "firstName"]}
                            label={t("FIRSTNAME")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={["user", "lastName"]}
                            label={t("LASTNAME")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} placeholder={t("LASTNAME")} type={"text"}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={["user", "email"]}
                            label={t("EMAIL")}
                            rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                        >
                            <Input disabled={loading} placeholder={t("EMAIL")} type={"text"}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={["user", "password"]}
                            label={t("PASSWORD.TITLE")}
                        >
                            <Input.Password
                                disabled={loading}
                                placeholder={t("PASSWORD.EDIT")}
                                allowClear
                                addonAfter={
                                    <Tooltip title={t("PASSWORD.GENERATE")}>
                                        <FontAwesomeIcon
                                            onClick={() => setGeneratedPassword(form)}
                                            style={{cursor: "pointer"}} icon={["fas", "dice"]}
                                        />
                                    </Tooltip>
                                }
                                iconRender={visible => (
                                    visible ? (
                                        <Tooltip title={t("PASSWORD.HIDE")}>
                                            <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye-slash"]}/>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title={t("PASSWORD.SHOW")}>
                                            <FontAwesomeIcon style={{marginLeft: "3px"}} icon={["fas", "eye"]}/>
                                        </Tooltip>
                                    )
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, nextValues) => prevValues !== nextValues}
                        >
                            {({getFieldValue}) => getFieldValue(["user", "password"]) ? (
                                <Form.Item name={["user", "sendPassword"]} valuePropName={"checked"}>
                                    <Checkbox disabled={loading}>{t("PASSWORD.SEND_PASSWORD")}</Checkbox>
                                </Form.Item>
                            ) : null}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Typography.Title level={5}>{t("USER.PROFILE.SYSTEM_ACCESSES")}</Typography.Title>
                    </Col>
                    <Col span={24}>
                        <SystemAccess
                            loading={loading}
                            form={form}
                            defaultSelectedSystems={access ? access!.systemAccesses.map(item => item.system.id) : []}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
})