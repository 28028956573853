import { Alert, Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react";
import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import {columnsFactory} from "./order.shipments.columns";
import {ordersService} from "../../../../services/orders/orders.service";
import {convertDataForTable} from "./order.shipments.table-hepler";
import {TableShipment} from "./order.shipments.model";
import {useParams} from "react-router-dom";
import {IdParams} from "../../../../models/core/route-params/id-params/id.params";

export const OrderShipments: React.FC = observer(() => {
    const { t } = useTranslation("translation", { useSuspense: false });
    const {id} = useParams<IdParams>();

    const columns: ColumnsType<TableShipment> = useMemo(() => columnsFactory(t), []);

    const [shipments, setShipments] = useState<TableShipment[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        ordersService.getOrderShipments(parseInt(id)).then(s => {
            setShipments(convertDataForTable(s))
            setLoading(false)
        });
    }, [id])

    if (loading) {
        return <></>
    }

    return (
        <Space direction="vertical" size={[0, 0]}>
            <Typography.Title level={3}>{t("SHIPMENTS.TITLE")}</Typography.Title>
            {shipments.length === 0 ? (
                <Alert type="info" message={t("SHIPMENTS.NO_SHIPMENTS")} />
            ) : (
                <Table columns={columns} dataSource={shipments} pagination={false} />
            )}
        </Space>
    );
});
