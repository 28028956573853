import {OrdersListStore} from "./orders.list.store";
import {OrdersService} from "../../../services/orders/orders.service";
import {PaginatedRequest} from "../../../models/requests/paginated-request/paginated.request";

export class OrdersListController {
    constructor(
        private readonly _ordersListStore: OrdersListStore,
        private readonly _ordersListService: OrdersService
    ) {
        this.setRequest = this.setRequest.bind(this);
    }

    public setRequest(value: PaginatedRequest): void {
        this._ordersListStore.setRequest(value);
    }

}
