import {action, computed, makeObservable, observable} from "mobx";
import {AccountWithMeta} from "../../models/account/account";
import {accountService} from "../../services/account/account.service";

export class AccountStore {
    @observable
    private accountDataWithMeta: AccountWithMeta | null = null;

    private accountDataWithMetaInited: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    public setAccountDataWithMeta(value: AccountWithMeta): void {
        this.accountDataWithMeta = value;
    }

    @computed
    public get accountDetails(): AccountWithMeta | null {
        this.loadAccountDetails();
        return this.accountDataWithMeta;
    }

    private async loadAccountDetails() {
        if (this.accountDataWithMetaInited) {
            return;
        }
        this.accountDataWithMetaInited = true;
        this.setAccountDataWithMeta(await accountService.getAccountDetails())
    }
}