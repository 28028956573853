import API from "../../utils/api";
import {AxiosInstance} from "axios";
import {WikiArticle, WikiTreeItem} from "../../models/wiki/wiki";

export class WikiService {
    api: AxiosInstance = API;

    getTreeRoot(): Promise<WikiTreeItem[]> {
        return this.api.get<WikiTreeItem[]>('wiki/tree').then(x => x.data);
    }

    getTreeChildren(parentId: number): Promise<WikiTreeItem[]> {
        return this.api.get<WikiTreeItem[]>('wiki/tree/' + parentId).then(x => x.data);
    }

    getCategories(): Promise<WikiTreeItem[]> {
        return this.api.get<WikiTreeItem[]>('wiki/tree/categories').then(x => x.data);
    }

    getArticle(id: number): Promise<WikiArticle> {
        return this.api.get<WikiArticle>('wiki/article/' + id).then(x => x.data);
    }

    downloadArticleAsPDF(id: number): Promise<{blob: Blob, filename: string}> {
        return this.api.get<Blob>('wiki/article/' + id + '/pdf', {responseType: 'blob'}).then(resp => {
            return {
                blob: resp.data,
                filename: resp.headers["content-disposition"].split("filename=")[1]
            };
        });
    }

    downloadArticleAsHTML(id: number): Promise<{blob: Blob, filename: string}> {
        return this.api.get<Blob>('wiki/article/' + id + '/html', {responseType: 'blob'}).then(resp => {
            return {
                blob: resp.data,
                filename: resp.headers["content-disposition"].split("filename=")[1]
            };
        });
    }

    uploadFile(file: File): Promise<[string, string]> {
        const formData = new FormData();
        formData.append("file", file);

        return this.api
            .post('wiki/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}, onUploadProgress: (event: any) => {console.log(event)}})
            .then(result => {
                return [
                    result.data.filename,
                    result.data.filepath
                ];
            });
    }
}

export const wikiService = new WikiService();
