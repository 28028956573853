import React, {useContext} from "react";
import {observer} from "mobx-react";
import {RootStoreContext} from "../../../../stores/root/root";
import {Link, useLocation} from "react-router-dom";
import {Alert} from "antd";
import {useTranslation} from "react-i18next";

export const NotCompletedOrderAmount: React.FC = observer(() => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const {accountStore: {accountDetails}} = useContext(RootStoreContext);
    const {pathname} = useLocation();

    if (accountDetails?.meta.notCompletedOrders === 0 || pathname === "/cart/checkout") {
        return null;
    }

    return (
        <Alert
            showIcon
            type="info"
            description={
                <Link to="/cart/checkout">
                    {t("CLIENT.CONTINUE_CHECKOUT")}
                </Link>
            }
            message={
                t(
                    "CLIENT.HAS_ADVANCED_ORDERS_WS",
                    {amount: accountDetails?.meta.notCompletedOrders}
                )
            }
        />
    )
})