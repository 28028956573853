import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Menu, Row, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import { ordersService } from "../../../services/orders/orders.service";
import { Amount } from "../../../models/amount/amount";
import { displayPrice } from "../../../models/amount/amount.helper";
import { Date } from "../../core/commons/date/date";
import { useTranslation } from "react-i18next";
import { FilterKey } from "../../../models/filtered-table/filtered.table";
import { OrderStatus, OrderStatusType, statusColor } from "../order-status/order.status";
import { Warehouse } from "../../../models/warehoues/warehouse";
import { warehousesService } from "../../../services/warehouses/warehouses.service";
import { OrdersListStore } from "./orders.list.store";
import { OrdersListController } from "./orders.list.controller";
import { observer } from "mobx-react";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { NotCompletedOrderAmount } from "../../core/commons/not-completed-order-ammount/not.completed.order.amount";
import { ColumnsType } from "antd/lib/table";
import { OrderDocument } from "../../../models/documents/documents";
import {OrderUser} from "../../../models/orders/order/order";

interface OrdersListProps extends ComponentPropsFromRoute {}
export const OrdersList: React.FC<OrdersListProps> = observer(({ name, requiredConfigs }) => {

    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [store] = useState(() => new OrdersListStore(requiredConfigs!.type));
    const [controller] = useState(() => new OrdersListController(store, ordersService));

    useEffect(() => {
        try {
            warehousesService.getWarehouses().then((warehouses) => setWarehouses(warehouses));
        } catch (e) {
            console.log(e);
        }
    }, []);

    const { t } = useTranslation("translation", { useSuspense: false });
    const columns: ColumnsType<OrderDocument> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: string) => (
                <Link className="link" to={"/order/" + id}>
                    {id}
                </Link>
            ),
        },
        {
            title: t("ORDERS.STATUS.TITLE"),
            dataIndex: "status",
            key: "orders_status",
            render: (status: OrderStatusType) => <OrderStatus status={status} />,
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "orders_grandTotal",
            render: (total: Amount) => <>{displayPrice(total)}</>,
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "orders_warehouse",
            render: (warehouse: Warehouse) => <>{warehouse.name}</>,
        },
        {
            title: t("ORDERS.ALT_NUMBER"),
            dataIndex: "clientOrderId",
            key: "orders_clientOrderId",
        },
        {
            title: t("ORDERS.USER"),
            dataIndex: "user",
            key: "orders_user",
            render: (user: OrderUser) => user.lastName + " " + user.firstName,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "orders_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
    ];
    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "ORDERS.STATUS.TITLE",
            key: "orders_status",
        },
        {
            title: "GRAND_TOTAL_PRICE",
            key: "orders_grandTotal",
        },
        {
            title: "WAREHOUSES.WAREHOUSE_TITLE",
            key: "orders_warehouse",
        },
        {
            title: "ORDERS.ALT_NUMBER",
            key: "orders_clientOrderId",
        },
        {
            title: "ORDERS.USER",
            key: "orders_user",
        },
        {
            title: "CREATED_AT",
            key: "orders_createdAt",
        },
    ];
    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "warehouses",
            label: t("WAREHOUSES.WAREHOUSE_TITLE"),
            type: "select",
            options: warehouses.map((item) => ({ label: item.name, value: item.id })),
        },
        {
            key: "status",
            label: t("ORDERS.STATUS.TITLE"),
            type: "select",
            options: Object.keys(statusColor).map((item) => ({
                value: item,
                label: t("ORDERS.ORDER.STATUS." + item.toUpperCase()),
            })),
        },
        {
            key: "grandTotal",
            label: t("GRAND_TOTAL_PRICE"),
            type: "int",
        },
        {
            key: "clientOrderId",
            label: t("ORDERS.ALT_NUMBER"),
            type: "string",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];


    return (
        <Space direction={"vertical"} size={[15, 15]} className="content-inner">
            <Space direction={"horizontal"} size={[10, 10]}>
                {}
            </Space>
            {!store.dataHandler.loading ? <NotCompletedOrderAmount /> : null}
            <FilteredTable
                rowKey="id"
                filterKeys={filters}
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: name! }}
                getRequest={controller.setRequest}
                dataHandler={store.dataHandler}
            />
        </Space>
    );
});
