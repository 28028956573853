import {Checkbox, Col, Form, Input, Select} from "antd";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {formatsService} from "../../../../../services/formats/formats.service";

export const ExportBasicFields: React.FC = observer(() => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const [formats, setFormats] = useState<string[]>([]);

    useEffect(() => {
        formatsService.getFormats().then(items => setFormats(items));
    }, [])

    return (
        <>
            <Col span={24}>
                <Form.Item
                    name="withHeaders"
                    valuePropName={"checked"}
                >
                    <Checkbox>{t("DOWNLOAD.WITH_HEADERS")}</Checkbox>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    name="format"
                    label={t("DOWNLOAD.FORMAT")}
                    rules={[{required: true, message: t('FROM.ERROR.PLEASE_SELECT_VALUE')}]}
                >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp={"children"}
                    >
                        {formats.map(item => (
                            <Select.Option key={item} value={item}>{item.toUpperCase()}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={((prevValues, nextValues) => prevValues.format !== nextValues.format)}>
                {({getFieldValue}) => getFieldValue("format") === "csv" ? (
                    <>
                        <Col span={12}>
                            <Form.Item
                                name="separator"
                                label={t("DOWNLOAD.COLUMN_SEPARATOR")}
                                rules={[{required: true, message: t('DOWNLOAD.FORM.FIELD_INVALID_VALUE'), max: 1, min: 1}]}
                            >
                                <Input type={"text"} placeholder={t("DOWNLOAD.COLUMN_SEPARATOR")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="escapeChar"
                                label={t("DOWNLOAD.ESCAPE_CHAR")}
                                rules={[{required: true, message: t('DOWNLOAD.FORM.FIELD_INVALID_VALUE'), max: 1, min: 1}]}
                            >
                                <Input type={"text"} placeholder={t("DOWNLOAD.ESCAPE_CHAR")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="enclosure"
                                label={t("DOWNLOAD.ENCLOSURE")}
                                rules={[{required: true, message: t('DOWNLOAD.FORM.FIELD_INVALID_VALUE'), max: 1, min: 1}]}
                            >
                                <Input type={"text"} placeholder={t("DOWNLOAD.ENCLOSURE")}/>
                            </Form.Item>
                        </Col>
                    </>
                ) : null}
            </Form.Item>
        </>
    )
})
