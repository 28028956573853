import React from "react";
import {useTranslation} from "react-i18next";
import {Col, Descriptions, Row} from "antd";
import {displayPrice, splitNumber} from "../../../../models/amount/amount.helper";
import {DetailsTableFooterData} from "../../../../models/details-table-footer/details.table.footer";

import "./details.table.footer.scss";


interface DetailsTableFooterProps {
    data: DetailsTableFooterData
}

export const DetailsTableFooter: React.FC<DetailsTableFooterProps> = (
    {
        data: {
            totalAmount,
            totalWeight,
            grandTotalAmount,
            taxPercent,
            taxAmount,
            qty
        }
    }) => {
    const {t} = useTranslation('translation', {useSuspense: false});

    return (
        <Row justify={"end"}>
            <Col xxl={10} xl={10} md={12} sm={24} xs={24}>
                <Descriptions
                    size={"small"}
                    layout={"horizontal"}
                    contentStyle={{fontWeight: "bold"}}
                    column={1}
                    className="table-footer-descriptions"

                >
                    <Descriptions.Item  label={t("SHIPPED_QUANTITY")}>{splitNumber(qty) + t("PCS")}</Descriptions.Item>
                    <Descriptions.Item label={t("TOTAL_WEIGHT")}>{String(totalWeight).replaceAll(".", ",")} ({t("KG")})</Descriptions.Item>
                    <Descriptions.Item label={t("TOTAL_PRICE")}>{displayPrice(totalAmount)}</Descriptions.Item>
                    <Descriptions.Item label={"VAT(" + taxPercent + "%)"}>{displayPrice(taxAmount)}</Descriptions.Item>
                    <Descriptions.Item label={t("GRAND_TOTAL_PRICE")}>{displayPrice(grandTotalAmount)}</Descriptions.Item>
                </Descriptions>
            </Col>
        </Row>
    )
}
