import {Amount} from "./amount";

const currencies: { [K: string]: string } = {
    EUR: '€',
    GBP: '₤',
    USD: '$',
}


export const splitNumber = (inputNumber: number | string): string => {
    return typeof inputNumber === "string" ? inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : String(inputNumber).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


export const displayPrice = ({value, currencyCode}: Amount): JSX.Element => {
    if (currencies[currencyCode.toUpperCase()]) {
        return <span style={{whiteSpace: "nowrap"}}>{splitNumber(value.toFixed(2)).replace(".", ",") + " " + currencies[currencyCode.toUpperCase()]}</span>
    }
    return <span style={{whiteSpace: "nowrap"}}>{splitNumber(value.toFixed(2)).replace(".", ",") + " " +  currencyCode.toUpperCase()}</span>
};
