import {Contact} from "../../models/contacts/contact";
import {apiService} from "../api.service";

export class ContactsService {
    public async getContacts(): Promise<Contact[]> {
        return (await apiService.get<Contact[]>("contacts")).data;
    }

    public async postContact(values: Contact, id?: number): Promise<Contact> {
        const url = id ? "contacts/" + id : "contacts";
        return (await apiService.post<Contact>(url, {...values})).data;
    }

    public async deleteContact(id: number): Promise<void> {
        await apiService.delete("contacts/" + id);
    }
}

export const contactsService = new ContactsService();