import i18n from "i18next";
import backend from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(backend)
    .use(detector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "en",
        // fallbackLng: langStore.keys,
        supportedLngs: ["en", "ru", "fr", "de", "dg", "it", "sr", "pl"],
        backend: {
            backends: [LocalStorageBackend],
            loadPath: "/i18n/{{lng}}.json",
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
