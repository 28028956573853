import React, { useContext, useState } from "react";
import { MergedInvoiceStoreContext } from "../merged.invoice.store";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Menu, Skeleton, Space, Typography } from "antd";
import { observer } from "mobx-react";
import { Export } from "../../../core/commons/export/export";
import { RootStoreContext } from "../../../../stores/root/root";
import { isAllowed } from "../../../../utils/helpers";

export const MergedInvoiceHeader: React.FC = observer(() => {
    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);
    const store = useContext(MergedInvoiceStoreContext);
    const { t } = useTranslation("translation", { useSuspense: false });
    const [exportModalShown, setExportModalShown] = useState<boolean>(false);

    return (
        <>
            <Space direction="vertical" size={[50, 30]} style={{ width: "100%" }}>
                {store.loading ? (
                    <Skeleton active paragraph={{ rows: 1 }} title={false} />
                ) : (
                    <Typography.Title level={1} className="main-title">
                        {t(
                            isAllowed(attributes, "merged_invoice_list")
                                ? "INVOICES.INVOICE.TITLE"
                                : "INVOICE.MERGED.TITLE"
                        )}{" "}
                        {store.invoice.docNumber}
                    </Typography.Title>
                )}
                {store.loading ? (
                    <Space wrap direction="horizontal" size={[10, 5]}>
                        <Skeleton.Button active />
                    </Space>
                ) : (
                    <Space wrap direction={"horizontal"} size={[10, 5]}>
                        <Dropdown
                            trigger={["click"]}
                            overlay={
                                <Menu style={{ textAlign: "left" }}>
                                    <Menu.Item key={1}>
                                        <a
                                            style={{ textAlign: "left" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={"ant-btn ant-btn-link"}
                                            href={"/documents/" + store.invoice!.id}
                                        >
                                            {t("DOCUMENTS.FILES.SHOW_PDF")}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={2} onClick={() => setExportModalShown(true)}>
                                        {t("DOWNLOAD.CSV_EXCEL")}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                        </Dropdown>
                        <>
                            {store.invoice?.packingLists.length > 0 ? (
                                <>
                                    {store.invoice?.packingLists.length > 1 ? (
                                        <Dropdown
                                            placement={"bottomRight"}
                                            trigger={["click"]}
                                            overlay={
                                                <Menu>
                                                    {store.invoice.packingLists.map((packingList) => (
                                                        <Menu.Item key={packingList.id} style={{ padding: "4px 15px" }}>
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={"/documents/" + packingList.id}
                                                            >
                                                                {packingList.docNumber}
                                                            </a>
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            }
                                        >
                                            <Button>{t("ORDERS.ORDER.VIEW.PACKING_LIST")}</Button>
                                        </Dropdown>
                                    ) : (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={"ant-btn"}
                                            href={"/documents/" + store.invoice?.packingLists[0].id}
                                        >
                                            {t("ORDERS.ORDER.VIEW.PACKING_LIST")}
                                        </a>
                                    )}
                                </>
                            ) : null}
                        </>
                    </Space>
                )}
            </Space>

            {store.invoice ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={exportModalShown}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/documents/merged-invoices/" + store.invoice!.id,
                    }}
                    onClose={() => setExportModalShown(false)}
                />
            ) : null}
        </>
    );
});
