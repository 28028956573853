import React from "react";
import {Col, Divider, Form, Row} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./catalog.search.scss"
import {UIInputWidgetManufacturer} from "../ui/input/widget/manufacturer/ui.input.widget.manufacturer";
import {UIInputWidgetSearch} from "../ui/input/widget/search/ui.input.widget.search";
import {UIInputWidgetModel} from "../ui/input/widget/model/ui.input.widget.model";
import {UIInputWidgetEngine} from "../ui/input/widget/engine/ui.input.widget.engine";
import {UIInputWidgetGroup} from "../ui/input/widget/group/ui.input.widget.group";
import {UIInputWidgetSubGroup} from "../ui/input/widget/subgroup/ui.input.widget.subgroup";
import {SearchParams} from "../model/search";

interface CatalogSearchProps {
    onChange: (params: SearchParams) => void;
    defaultValues: any;
}

export const CatalogSearch: React.FC<CatalogSearchProps> = ({onChange, defaultValues}) => {

    const [form] = Form.useForm();

    return (
        <div className="catalog_search_block_container">
            <div id="catalog_search_block" className="catalog_search_block">
                <Form
                    form={form}
                    style={{width: "100%"}}
                    initialValues={defaultValues}
                    onFinish={(values) => {
                        onChange(values);
                    }}
                >
                <Row className="search_block">
                    <Form.Item name="searchString" noStyle>
                        <UIInputWidgetSearch
                            onChange={() => form.submit()}
                        />
                    </Form.Item>
                </Row>
                <div className="selects_block">
                    <Row gutter={[0, 10]} style={{width: "100%"}} align="middle" justify="space-between">
                        <Col className="selects-col-icon" xxl={5} xl={5} lg={5} md={5} sm={8} xs={8}>
                            <FontAwesomeIcon color={"#D7D7D7"} icon={["fas", "car"]} style={{marginRight: 7.5}} size={"lg"}/>
                            <Form.Item name="manufacturer" noStyle>
                                <UIInputWidgetManufacturer
                                    onClear={() => form.setFieldsValue({engine: undefined, model: undefined})}
                                    onChange={() => form.submit()}                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(v1, v2) => v1.manufacturer !== v2.manufacturer}
                            >
                                {() => (
                                    <Form.Item noStyle name="model">
                                        <UIInputWidgetModel
                                            manufacturerId={form.getFieldValue('manufacturer')}
                                            onClear={() => form.setFieldsValue({engine: undefined})}
                                            onChange={() => form.submit()}
                                        />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={8} xs={8}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(v1, v2) => v1.model !== v2.model}
                            >
                                {() => (
                                    <Form.Item noStyle name="engine">
                                        <UIInputWidgetEngine
                                            modelId={form.getFieldValue('model')}
                                            onChange={() => form.submit()}
                                        />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                        <Divider
                            orientation={"center"}
                            type={"vertical"}
                            className="selects_block_divider selects_block_divider_vertical"
                        />
                        <Divider
                            orientation={"center"}
                            type={"horizontal"}
                            className="selects_block_divider selects_block_divider_horizontal"
                        />
                        <Col className="selects-col-icon" xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <FontAwesomeIcon color={"#D7D7D7"} icon={["fas", "cubes"]} style={{marginRight: 7.5}} size={"lg"}/>
                            <Form.Item noStyle name="group">
                                <UIInputWidgetGroup
                                    onChange={() => form.submit()}
                                    onClear={() => form.setFieldsValue({subgroup: undefined})}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(v1, v2) => v1.group !== v2.group}
                            >
                                {() => (
                                    <Form.Item noStyle name="subgroup">
                                        <UIInputWidgetSubGroup
                                            groupId={form.getFieldValue('group')}
                                            onChange={() => form.submit()}
                                        />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                </Form>
            </div>
        </div>
    )
}