import {AccountStore} from "../../stores/account/account.store";
import React, {Context} from "react";

export class AccountController {
    constructor(private readonly _accountStore: AccountStore) {
        this.decreaseNotCompletedOrdersAmount = this.decreaseNotCompletedOrdersAmount.bind(this);
        this.updateNotCompletedOrdersAmount = this.updateNotCompletedOrdersAmount.bind(this);
    }

    public decreaseNotCompletedOrdersAmount(): void {
        if (this._accountStore.accountDetails) {
            const {data, meta} = this._accountStore.accountDetails
            this._accountStore.setAccountDataWithMeta({data, meta: {...meta, notCompletedOrders: meta.notCompletedOrders - 1}});
        }
    }

    public updateNotCompletedOrdersAmount(amount: number): void {
        if (this._accountStore.accountDetails) {
            const {data, meta} = this._accountStore.accountDetails;
            this._accountStore.setAccountDataWithMeta({data, meta: {...meta, notCompletedOrders: amount + meta.notCompletedOrders}});
        }
    }
}

export const AccountControllerContext = React.createContext<null | AccountController>(null) as Context<AccountController>;