import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {Button, Card, Col, Row, Typography, Skeleton} from "antd";
import {AddressItem} from "./address-item/address.item";
import {AddressesModal} from "./addressess-modal/addresses.modal";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AddressesStoreContext} from "./addresses.store";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {AddressType} from "../../../models/addresses/address";

interface AddressesProps extends ComponentPropsFromRoute {
}

export const Addresses: React.FC<AddressesProps> = observer(({requiredConfigs}) => {
    const store = useContext(AddressesStoreContext);
    const {t} = useTranslation('translation', {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    return (
        <>
            <Row className="row-no-margin" gutter={[20, 20]}>
                {store.loading ? (
                    <Col className="card-col" xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Card
                            className="card"
                            bordered={false}
                            size="small"
                            title={<Skeleton active paragraph={{rows: 1}}/>}
                        >
                            <Skeleton active paragraph={{rows: 5, width: "100%"}}/>
                        </Card>
                    </Col>
                ) : store.addresses.filter(address => address.type === requiredConfigs?.type).map(address => (
                    <AddressItem key={address.id} address={address}/>
                ))}
                {requiredConfigs?.type === AddressType.SHIPPING ? (
                    <Col span={24}>
                        {store.loading ? (
                            <Skeleton.Button active/>
                        ) : (

                            <Button type="primary" onClick={() => setModalShown(true)}>
                                {t("ADD")}
                            </Button>
                        )}
                    </Col>
                ) : null}
            </Row>
            <AddressesModal
                type={requiredConfigs?.type}
                visible={modalShown}
                onClose={() => setModalShown(false)}
            />
        </>
    );
});
