import React from "react";
import {CatalogArticle} from "../../../../models/articles/catalog/search";
import {Pagination} from "../../../../models/requests/paginated-result/paginated.result";
import {useTranslation} from "react-i18next";
import {CatalogCartsViewItemsBlock} from "./items-block/catalog.carts.view.items-block";

interface CatalogCartsViewProps {
    strictItems: CatalogArticle[];
    items: CatalogArticle[];
    pagination: Pagination;
    onPageChange?: (page: number, size: number) => void;
}

export const CatalogCartsView: React.FC<CatalogCartsViewProps> = ({items, strictItems, pagination, onPageChange}) => {

    const {t} = useTranslation("translation", {useSuspense: false});

    return (
        <>
            <CatalogCartsViewItemsBlock items={strictItems} />
            {strictItems.length && items.length ? (
                <h2 style={{marginTop: 20}}>{t("CATALOG.ALSO_INTERESTED")}</h2>
            ) : null}
            <CatalogCartsViewItemsBlock items={items} pagination={pagination} onPageChange={onPageChange} />
        </>
    )
}