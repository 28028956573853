import { Button, Col, FormInstance, Space, Typography } from "antd";
import { displayPrice } from "../models/amount/amount.helper";
import { Amount } from "../models/amount/amount";
import React from "react";
import { Warehouse } from "../models/warehoues/warehouse";
import { chain, differenceBy, isNil } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortDirection } from "./sort";
import { SystemWithGroups } from "../models/system/system.groups";
import { Group } from "../models/articles/groups/group";
import queryString, { ParsedUrlQueryInput } from "querystring";

export const isAllowed = (accesses: string[], access: string | undefined): boolean =>
    !access ? true : accesses.includes(access);

export interface PriceConfig {
    price: Amount;
}
export const displayCustomPrice = ({ price }: PriceConfig): JSX.Element => {
    return (
        <Space direction="horizontal" align={"center"} style={{ justifyContent: "flex-end" }}>
            <Space direction="vertical" style={{ textAlign: "right" }}>
                <>{displayPrice(price)}</>
            </Space>
        </Space>
    );
};

export const getNotUsedWarehouses = (formValues: any, warehouses: Warehouse[]): Warehouse[] => {
    const usedWarehouses = chain(formValues)
        .omitBy((item) => isNil(item) || isNil(item.warehouse))
        .map((item) => ({ id: item.warehouse }))
        .value();
    return differenceBy(warehouses, usedWarehouses, "id");
};

const sortWarehouses = (
    form: FormInstance,
    formListIndex: number,
    direction: string,
    name: string | string[],
    fieldValue: string
) => {
    form.setFieldsValue({
        [fieldValue]: sortDirection(form.getFieldValue(name), formListIndex, direction),
    });
};

export const renderSortButtons = (
    form: FormInstance,
    index: number,
    length: number,
    disabled: boolean,
    name: string | string[],
    fieldValue: string
): JSX.Element => {
    if (length <= 1) {
        return (
            <Col span={4}>
                <Button
                    type="default"
                    icon={<FontAwesomeIcon icon={["fas", "arrow-down"]} />}
                    style={{ marginRight: "auto", width: "100%" }}
                    className="btn-flex-center sort-btn"
                    disabled
                />
            </Col>
        );
    }
    if (index === 0) {
        return (
            <Col span={4}>
                <Button
                    className="form-list-btn sort-btn"
                    type="default"
                    icon={<FontAwesomeIcon icon={["fas", "arrow-down"]} />}
                    disabled={disabled}
                    onClick={() => sortWarehouses(form, index, "down", name, fieldValue)}
                />
            </Col>
        );
    }
    if (index === length - 1) {
        return (
            <Col span={4}>
                <Button
                    className="form-list-btn sort-btn"
                    type="default"
                    icon={<FontAwesomeIcon icon={["fas", "arrow-up"]} />}
                    disabled={disabled}
                    onClick={() => sortWarehouses(form, index, "up", name, fieldValue)}
                />
            </Col>
        );
    }
    return (
        <>
            <Col span={2}>
                <Button
                    className="form-list-btn sort-btn"
                    type="default"
                    icon={<FontAwesomeIcon icon={["fas", "arrow-up"]} />}
                    disabled={disabled}
                    onClick={() => sortWarehouses(form, index, "up", name, fieldValue)}
                />
            </Col>
            <Col span={2}>
                <Button
                    className="form-list-btn sort-btn"
                    type="default"
                    icon={<FontAwesomeIcon icon={["fas", "arrow-down"]} />}
                    disabled={disabled}
                    onClick={() => sortWarehouses(form, index, "down", name, fieldValue)}
                />
            </Col>
        </>
    );
};

export const getGroupsBySystemId = (system: { system: string }, groups: SystemWithGroups[]): Group[] => {
    if (system) {
        const systemWithGroup = groups.find((item) => item.id === system.system);
        if (systemWithGroup) {
            return systemWithGroup.groups;
        }
    }
    return [];
};

export const generatePassword = () => {
    const length = 16,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
};

export const getDataFromUrl = <T extends Object>(): T => {
    const params: any = queryString.parse(window.location.search.substr(1));
    return params as T;
};

export const updateUrlQueryString = (queryString: string): void => {
    window.history.pushState("", "", "?" + queryString);
};

export const getStringifiedQueryParams = (object: ParsedUrlQueryInput): string => {
    return queryString.stringify(object);
};
