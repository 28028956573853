import {library} from '@fortawesome/fontawesome-svg-core';

import {
    faPowerOff,
    faShoppingCart,
    faBookOpen,
    faTimes,
    faFolderOpen,
    faAddressCard,
    faTablet,
    faClock,
    faBars,
    faSearch,
    faCar,
    faCubes,
    faList,
    faStar,
    faCaretDown,
    faGlobeAmericas,
    faMinus,
    faPlus,
    faPencilAlt,
    faCheck,
    faFilter,
    faFileUpload,
    faUpload,
    faArrowUp,
    faArrowDown,
    faPlusCircle,
    faEye,
    faDice,
    faEyeSlash,
    faCog,
    faBorderAll,
    faExclamationTriangle,
    faUndo,
    faBriefcase,
    faFrown,
    faCoins,
    faTruck,
    faLink,
    faFilePdf,
    faCode,
    faArrowRight,
    faTag,
    faPeopleArrows,
    faBoxTissue,
    faTasks,
    faSpinner,
    faWindowClose,
    faCouch,
    faBolt,
    faUserTimes,
    faUserPlus,
    faPrint,
    faBook,
    faItalic,
    faBold,
    faListOl,
    faListUl,
    faCheckCircle, faQuoteLeft, faTable, faInfo, faQuestion, faExclamation, faHSquare, faCircleInfo, faAngleDown
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faPowerOff,
    faShoppingCart,
    faBookOpen,
    faTimes,
    faFolderOpen,
    faAddressCard,
    faTablet,
    faClock,
    faBars,
    faSearch,
    faCar,
    faCubes,
    faList,
    faStar,
    faCaretDown,
    faGlobeAmericas,
    faMinus,
    faPlus,
    faPencilAlt,
    faCheck,
    faFilter,
    faFileUpload,
    faUpload,
    faArrowUp,
    faArrowDown,
    faPlusCircle,
    faEye,
    faDice,
    faEyeSlash,
    faCog,
    faBorderAll,
    faExclamationTriangle,
    faUndo,
    faBriefcase,
    faFrown,
    faCoins,
    faTruck,
    faLink,
    faFilePdf,
    faCode,
    faArrowRight,
    faTag,
    faPeopleArrows,
    faBoxTissue,
    faTasks,
    faSpinner,
    faWindowClose,
    faCouch,
    faBolt,
    faUserTimes,
    faUserPlus,
    faPrint,
    faBook,
    faItalic,
    faBold,
    faListOl,
    faListUl,
    faCheckCircle,
    faQuoteLeft,
    faCode,
    faTable,
    faInfo,
    faQuestion,
    faExclamation,
    faHSquare,
    faCircleInfo,
    faAngleDown
);
