import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {Button, Form, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ColumnsType} from "antd/lib/table";
import {FilterKey} from "../../../../models/filtered-table/filtered.table";
import {FilteredTableStore} from "./filtered.table.store";
import {FilterDataController} from "../../../../controllers/filtered-table/filter.data.controller";
import {PaginatedRequest} from "../../../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../../../models/requests/paginated-result/paginated.result";
import {EditableCell} from "../editable-cell/editable.cell";
import Filter from "./filter/filter";
import { TableRowSelection } from "antd/lib/table/interface";
import "./filter.table.scss";
import {ColumnsModal} from "../columns-modal/columns.modal";


interface FilteredTableProps<T = any, V = any> {
    isEditable?: boolean,
    onSave?: (id: number, values: V) => Promise<any>,
    onDelete?: (deletingItem: any) => Promise<any>,
    getEditValues?: (row: T) => any,
    getSaveValues?: (row: T) => any,
    editableRowKey?: string,
    rowSelection?: TableRowSelection<T>
    rowKey?: string;
    columns: ColumnsType<any>;
    filterKeys?: FilterKey[];
    dataHandler?: FilterDataController<any>;
    buttons?: JSX.Element[],
    loadDataCallback?: (request: PaginatedRequest) => Promise<PaginatedResult<any>>;
    tableProps?: any
    getRequest?: (request: PaginatedRequest) => void
    columnsConfigs?: {columns: {key: string, title: string}[], pageKey: string},
}



const FilteredTable: React.FC<FilteredTableProps> = observer((
    {
        isEditable,
        onSave,
        getEditValues,
        getSaveValues,
        onDelete,
        editableRowKey,
        rowSelection,
        rowKey = "id",
        filterKeys,
        dataHandler,
        columns,
        buttons,
        loadDataCallback,
        tableProps,
        getRequest,
        columnsConfigs
    }) => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const [store] = useState(() => {

        if (!dataHandler) {
            if (!loadDataCallback) {
                throw new Error('dataHandler or loadDataCallback must be set');
            }
            dataHandler = new FilterDataController(loadDataCallback);
        }

        return new FilteredTableStore(
            dataHandler,
            columns.map(item => ({
                title: String(item.title),
                value: String(item.key),
            })),
            columnsConfigs?.pageKey
        );
    });

    useEffect(() => {
        if (getRequest) {
            getRequest(store.request);
        }
    }, [store.request]);

    useEffect(() => {
        window.addEventListener('popstate', store.reloadDataByPopState)
        return () => {
            window.removeEventListener('popstate', store.reloadDataByPopState);
        }
    }, []);

    const getColumns = (allColumns: any[]): any => {
        const filteredColumns = allColumns.filter(item => store.selectedColumns.includes(item.key) && item.key !== "action");
        const actionColumn = allColumns.find(item => item.key === "action");
        if (actionColumn) {
            filteredColumns.push(actionColumn);
        }
        return filteredColumns ? filteredColumns : []
    }

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<number | null>(null);

    // RECORD - объект строки в таблице
    const isEditing = (record: any) => record.id === editingKey;

    const edit = (row: any ) => {
        form.setFieldsValue(getEditValues!(row));
        setEditingKey(row.id);
    };

    const save = async (id: number) => {
        try {
            const row = (await form.validateFields());
            await onSave!(id, getSaveValues!(row));
            setEditingKey(null);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };



    if (isEditable) {

        columns = columns.map(column => {
            if (column.key !== "action") {
                return column;
            }
            return {
                title: "ACTION",
                dataIndex: "action",
                key: "action",
                width: 100,
                render: (_: any, record: any) => {
                    const editable = isEditing(record);
                    if (record[editableRowKey!]) {
                        return (
                            <>
                                {editable ? (
                                    <Tooltip placement="topRight" title={t("SAVE")}>
                                        <Button
                                            className="btn"
                                            onClick={() => save(record.id)}
                                            type="default"
                                            icon={<FontAwesomeIcon icon={["fas", "check"]}/>}
                                        />
                                    </Tooltip>

                                ) : (
                                    <Tooltip placement="topRight" title={t("EDIT")}>
                                        <Button
                                            className="btn"
                                            disabled={editingKey !== null}
                                            onClick={() => edit(record)}
                                            type="default"
                                            size={"small"}
                                            icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]}/>}
                                        />
                                    </Tooltip>
                                )}
                                {" "}
                                {onDelete ? (
                                    <Popconfirm
                                        title={t("ARE_YOU_SURE")}
                                        onConfirm={() => onDelete(record)}
                                        placement="left"
                                        okText={t("YES")}
                                        cancelText={t("NO")}
                                    >
                                        <Tooltip placement="topRight" title={t("REMOVE")}>
                                            <Button
                                                className="btn-remove btn"
                                                disabled={editingKey !== null}
                                                type="default"
                                                size={"small"}
                                                icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                            />
                                        </Tooltip>
                                    </Popconfirm>
                                ) : null}
                            </>
                        )
                    }

                    return <></>
                }
            }
        })
    }


    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => ({
                record,
                inputType: "number",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <>
            <Space size={[0, 0]} direction={"vertical"} style={{width: "100%"}}>
                {buttons ? (
                    <Row gutter={[0, 15]}>
                        {buttons.map(btn => btn)}
                    </Row>
                ) : null}
                {filterKeys ? (
                    <Row  justify="space-between">
                        <Filter
                            filterKeys={filterKeys}
                            defaultValue={store.filters}
                            onUpdated={(filters) => store.setFilters(filters)}
                        />
                        {columnsConfigs ? (
                            <Button
                                onClick={store.openColumnsSettingsModal}
                                className={"table-columns-button"}
                                icon={<FontAwesomeIcon icon={["fas", "cog"]}/>}
                            />
                        ) : null}
                    </Row>
                ) : null}
                <Space direction="vertical" style={{width: "100%", maxWidth: "100%", overflowY: "auto"}}>
                    <Form form={form} component={false}>
                        <Table
                            {...tableProps}
                            rowSelection={rowSelection}
                            columns={isEditable ? getColumns(mergedColumns) : getColumns(columns)}
                            components={isEditable ? ({body: {cell: EditableCell}}) : undefined}
                            rowKey={rowKey}
                            dataSource={[...store.dataHandler.items]}
                            loading={store.dataHandler.loading}
                            scroll={{x: true}}
                            pagination={{
                                position: ["bottomLeft"],
                                total: store.dataHandler.pagination?.total,
                                current: store.dataHandler.pagination?.page,
                                pageSize: store.dataHandler.pagination?.limit,
                                pageSizeOptions: store.limitVariants,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                onChange: (page) => store.setPage(page),
                                onShowSizeChange: (_, size) => store.setLimit(size),
                                showTotal: (total) => t("TOTAL_ITEMS") + ": " + total
                            }}
                        />
                    </Form>
                </Space>
            </Space>
            {columnsConfigs ? (
                <ColumnsModal
                    visible={store.columnsSettingsModalShown}
                    columns={columnsConfigs.columns}
                    selectedColumns={store.selectedColumns}
                    selectColumns={store.selectColumns}
                    onClose={store.closeColumnsSettingsModal}
                />
            ) : null}
        </>
    );

});


export default FilteredTable;
