import React, {useState} from "react";
import {Button, Form, Input} from "antd";
import {ErrorResponseData} from "../../../../../models/core/response";
import {AxiosError} from "axios";
import {useTranslation} from "react-i18next";
import {useRequiredLayers} from "../../../../../utils/context";
import {OrderDataStore, OrderDataStoreContext} from "../../order.data.store";
import {OrderDataController, OrderDataControllerContext} from "../../order.data.controller";

export const OrderCommentsForm: React.FC = () => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const {controller} = useRequiredLayers<OrderDataStore, OrderDataController>(OrderDataStoreContext, OrderDataControllerContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [rowsNum, setRowsNum] = useState<number>(1);
    const [form] = Form.useForm();

    return (
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            name={"order_comment_form"}
            onFinish={() => form
                .validateFields()
                .then(values => {
                    setLoading(true);
                    controller.createComment(values)
                        .then(
                            () => {
                                form.resetFields();
                                setRowsNum(1)
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                        .then(() =>
                            (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Form.Item
                noStyle
                name="message"
                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
            >
                <Input.TextArea
                    rows={rowsNum}
                    onFocus={() => setRowsNum(5)}
                    onBlur={(event) => {
                        if (!event.target.value.trim()) {
                            setRowsNum(1);
                        }
                    }}
                />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={true}>
                {({getFieldValue}) => getFieldValue("message") ? (
                        <Form.Item>
                            <Button  style={{marginTop: 10}} htmlType="submit" loading={loading} type="primary">
                                {t("COMMENTS.ADD")}
                            </Button>
                        </Form.Item>
                    )
                    : null
                }
            </Form.Item>
        </Form>
    )
}
