import React, {useEffect} from "react";
import {Spin, Select, Typography} from "antd";

import {SelectProps} from 'antd/es/select';
import debounce from "lodash/debounce";
import {useTranslation} from "react-i18next";


export interface DebounceSelectProps<ValueType = any> extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
    minimalLettersAmount?: number
    initialOptionGetter?: (value: string) => ValueType,
    labelInValue?: boolean
    onOptionsUpdated?: (value: ValueType[]) => void
    preloadValue?: string;
}

export function DebounceSelect<ValueType extends { key?: string; label: React.ReactNode; value: any} = any>(
    {
        fetchOptions,
        minimalLettersAmount = 3,
        debounceTimeout = 200,
        initialOptionGetter,
        labelInValue = true,
        onOptionsUpdated,
        preloadValue,
        ...props
    }: DebounceSelectProps) {
    const {t} = useTranslation('translation', {useSuspense: false});
    const [awaitingInput, setAwaitingInput] = React.useState(true);
    const [options, setOptions] = React.useState<ValueType[]>();
    const [searchValue, setSearchValue] = React.useState<string>();

    const fetchRef = React.useRef(0);
    const textValueRef = React.useRef("");

    const debounceFetcher = React.useMemo(() => {
        let timer: NodeJS.Timer | undefined;
        const loadOptions = (value: string) => {
            setAwaitingInput(true);
            if (timer) {
                clearTimeout(timer);
            }
            if (value.length < minimalLettersAmount) {
                setOptions([]);
                return;
            }

            fetchRef.current += 1;
            const fetchId = fetchRef.current;

            fetchOptions(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                if (newOptions.length) {
                    applyValueToOptions(newOptions);
                    setOptions(newOptions);
                    onOptionsUpdated && onOptionsUpdated(newOptions);
                } else {
                    timer = setTimeout(() => {
                        setOptions([]);
                        setAwaitingInput(false);
                    }, 2000);
                }
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    const onInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" || e.key === "ArrowUp" || e.key === "ArrowDown") {
            return
        }

        const t = e.target as HTMLInputElement;
        if (t.value.length >= minimalLettersAmount) {
            textValueRef.current = t.value;
            let options: ValueType[] = [];
            applyValueToOptions(options);
            setOptions(options);
        }
    }

    const applyValueToOptions = (values: ValueType[]) => {
        if (initialOptionGetter) {
            values.unshift(initialOptionGetter(textValueRef.current))
        }
    }

    useEffect(() => {
        if (preloadValue) {
            setSearchValue(preloadValue)
        }
    }, [preloadValue]);

    return (
        <Select<ValueType>
            onKeyUp={e => onInputKeyDown(e)}
            showSearch
            labelInValue={labelInValue}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={!awaitingInput ?
                <Typography.Text>{t("NO_RESULTS")}</Typography.Text> :
                null
            }
            {...props}
            options={options}
            searchValue={searchValue}
        />
    );
}
