import React, {Context} from "react";
import {OrderDataStore} from "./order.data.store";
import {OrdersService} from "../../../services/orders/orders.service";
import {OrderComment} from "../../../models/orders/order-data/order.data";

export class OrderDataController {

    constructor(
        private id: string,
        private readonly _orderDataStore: OrderDataStore,
        private readonly _ordersService: OrdersService
    ) {
        this.loadOrder();
        this.deleteComment = this.deleteComment.bind(this);
        this.onLngChange = this.onLngChange.bind(this);
    }
    
    public onLngChange(): void {
        this.loadOrder();
    }

    public async loadOrder(): Promise<void> {
        this._orderDataStore.setLoading(true);
        this._orderDataStore.setOrderData(await this._ordersService.getOrder(this.id));
        this._orderDataStore.setOrderComments(this._orderDataStore.order!.comments);
        this._orderDataStore.setLoading(false);
    }

    public async deleteComment(comment: OrderComment): Promise<void> {
        await this._ordersService.removeComment(comment.id);
        this._orderDataStore.setOrderComments(this._orderDataStore.comments.filter(item => item.id !== comment.id))
    }

    public async createComment(values: {message: string}): Promise<void> {
        this._orderDataStore.addComment(await this._ordersService.postComment(this._orderDataStore.order!.id, values));
    }

    public async changeOrderStatus(status: string): Promise<void> {
        await this._ordersService.changeStatus({orders: [this._orderDataStore.order?.id!], status});
        this._orderDataStore.setOrderData(await this._ordersService.getOrder(this.id));
    }

    public openExportModal(id: number): void {
        this._orderDataStore.setExportInvoice(id);
        this._orderDataStore.setExportModalShown(true);
    }

    public closeExportModal = (): void => {
        this._orderDataStore.setExportModalShown(false);
        this._orderDataStore.setExportInvoice(null);
    }
}

export const OrderDataControllerContext = React.createContext<null | OrderDataController>(null) as Context<OrderDataController>;
