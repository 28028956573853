import React, {useMemo} from "react";
import {Button, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {columnsFactory} from "./in-transit.columns";
import {FilterDataController} from "../../controllers/filtered-table/filter.data.controller";
import FilteredTable from "../core/commons/filtered-table/filtered.table";
import {containersService} from "../../services/containers/containers.service";
import {FilterKey} from "../../models/filtered-table/filtered.table";
import {useHistory} from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InTransit: React.FC = () => {

    const h = useHistory();
    const {t} = useTranslation("translation", {useSuspense: false});

    const goToPreOrder = async () => {
        h.push("/cart/reservation")
    }

    const columns = useMemo(() => columnsFactory(t, (i, ui) => ctrl.updateItem(i, ui)), []);
    const ctrl = useMemo(() => new FilterDataController(r => containersService.getAllItems(r)), []);

    const filters: FilterKey[] = [
        {
            key: "name",
            label: t("ARTICLES.TITLE_SINGLE"),
            type: "string"
        },
        {
            key: "description",
            label: t("DESCRIPTION"),
            type: "string"
        }
    ];

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Typography.Title level={1} className="main-title">In transit</Typography.Title>
                <Space className="wish-list-wrapper" direction="vertical" size={[20, 20]} style={{width: "100%"}}>
                    <Button
                        icon={<FontAwesomeIcon icon={["fas", "truck"]} />}
                        type={"primary"}
                        onClick={() => goToPreOrder()}
                    >
                        &nbsp;&nbsp;{t("IN_TRANSIT.GO_TO_PREORDER_CART")}
                    </Button>
                    <FilteredTable
                        columns={columns}
                        filterKeys={filters}
                        dataHandler={ctrl}
                    />
                </Space>
            </Space>
        </div>
    );
};
