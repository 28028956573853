import React, {useMemo} from "react";
import { Space } from "antd";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { useTranslation } from "react-i18next";
import { FilterKey } from "../../../models/filtered-table/filtered.table";
import { observer } from "mobx-react";
import {convertTableColumnsToColumnConfigs} from "../../../utils/table";
import {columnsFactory} from "./credit.invoices.list.columns";
import {creditInvoicesService} from "../../../services/invoices/credit.invoices.service";

interface CreditInvoicesListProps extends ComponentPropsFromRoute {}
export const CreditInvoicesList: React.FC<CreditInvoicesListProps> = observer(({ name }) => {
    const { t } = useTranslation("translation", { useSuspense: false });

    const columns = useMemo(() => columnsFactory(t), [])

    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "number",
            label: t("INVOICES.NUMBER"),
            type: "string",
        },
        {
            key: "clientName",
            label: t("CLIENT.NAME"),
            type: "string",
        },
        {
            key: "grandTotal",
            label: t("TOTAL_AMOUNT"),
            type: "int",
        },
        {
            key: "subtotal",
            label: t("TOTAL_PRICE"),
            type: "int",
        },
        {
            key: "subtotalVat",
            label: "VAT",
            type: "int",
        },
        {
            key: "date",
            label: t("DATE"),
            type: "date",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];

    return (
        <Space direction="vertical" size={[30, 30]} className="content-inner">
            <FilteredTable
                columns={columns}
                columnsConfigs={{ columns: convertTableColumnsToColumnConfigs(columns), pageKey: name! }}
                filterKeys={filters}
                loadDataCallback={(request) => creditInvoicesService.getAll(request)}
            />
        </Space>
    );
});
