import React, {useEffect, useState} from "react";
import {Badge, Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ContainerItem} from "../../models/container/container-item";
import {articlesService} from "../../services/articles/articles.service";
import {ArticleCardPopup} from "../article/article-card-popover/catalog.card.popup";

interface InTransitToCartProps {
    item: ContainerItem;
    updateItem: (item: ContainerItem, updatedItem: ContainerItem) => void;
}

export const InTransitToCart: React.FC<InTransitToCartProps> = ({item, updateItem}) => {

    const [popupShown, setPopupShown] = useState<boolean>(false);

    const setNewQty = (newQty: number) => {
        updateItem(item, {
            ...item,
            inCartQty: newQty,
            inCart: newQty > 0
        })
    }

    const addToCart = (newQty: number) => {
        articlesService.addToCart({article: item.id, qty: newQty, type: "reservation"});
    }

    const removeFromCart = () => {
        articlesService.removeReservationFromCart(item.id);
    }

    // useEffect(() => {
    //     setInterval(() => {
    //         setNewQty(item.in_cart_qty > 0 ? 0 : 1)
    //     }, 5000)
    // }, [item])

    return (
        <div style={{position: "relative"}}>
            <Badge
                count={item.inCartQty}
                style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    fontWeight: "bold",
                    border: "1px solid #f93822",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "none",
                    padding: 4,
                    fontSize: item.inCartQty >= 100 ? 9 : 12
                }}
                offset={[item.inCartQty ? -13 : -14, 30]}
            >
                <Button
                    onMouseDown={() => setPopupShown(true)}
                    className={
                        item.inCart
                            ? "ws_button catalog_button catalog_button_active cart-btn in-cart"
                            : "ws_button catalog_button cart-btn"
                    }
                    icon={<FontAwesomeIcon icon={["fas", "shopping-cart"]}/>}
                />
            </Badge>
            {popupShown ? (
                <ArticleCardPopup
                    article={item}
                    inCartQty={item.inCartQty}
                    addToCart={
                        (_: any, newQty: number) => ({
                            client: () => setNewQty(newQty),
                            back: () => addToCart(newQty),
                        })
                    }
                    deleteFromCart={
                        (_: any, newQty: number) => ({
                            client: () => setNewQty(newQty),
                            back: () => removeFromCart(),
                        })
                    }
                    close={() => setPopupShown(false)}
                />
            ) : null}
        </div>
    )
}