import React from "react";
import "./catalog.product.cart.image.scss"
import {CatalogArticle} from "../../../../../models/articles/catalog/search";
import {ProductImage} from "../../common/image/product.image";

interface CatalogProductCartImageProps {
    article: CatalogArticle;
}

export const CatalogProductCartImage: React.FC<CatalogProductCartImageProps> = ({article}) => {

    return (
        <div className={"product-info-image"}>
            <ProductImage
                sku={article.name}
                size={"200"}
                scheme={false}
                width={140}
                height={140}
                previews={[
                    {size: "1600", scheme: false},
                    {size: "640", scheme: true},
                ]}
            />
        </div>
    )
}