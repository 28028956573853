import React, { RefObject, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useRequiredLayers } from "../../../../utils/context";
import { useVT } from "virtualizedtableforantd4";
import { Link } from "react-router-dom";
import { Button, Col, Row, Typography, Table, Input, Space, Tag, Collapse } from "antd";
import { CartCheckoutShippings } from "./cart-checkout-shippings/cart.checkout.shippings";
import { CartCheckoutAddresses } from "./cart-checkout-addresses/cart.checkout.addresses";
import { displayPrice } from "../../../../models/amount/amount.helper";
import { DetailsTableFooter } from "../../../core/commons/details-table-footer/details.table.footer";
import { generateCartApplyTableData } from "../../../../models/cart/helper/cart.helper";
import { NotCompletedOrder } from "../../../../models/cart/checkout/not.completed.order";
import { Amount } from "../../../../models/amount/amount";
import { CartCheckoutController, CartCheckoutControllerContext } from "../cart.checkout.controller";
import { CartCheckoutStore, CartCheckoutStoreContext } from "../cart.checkout.store";
import { CartCheckoutTableItem } from "../../../../models/cart/checkout/table-data/cart.checkout.table.data";
import i18n from "i18next";
import "./cart.checkout.order.body.scss";
import { CartCreateCalculatedPrice } from "../../../../models/cart/create/cart.create";
import moment from "moment";
import {DoubleDetailsTableFooter} from "../../../core/commons/double-details-table-footer/double-details.table.footer";

interface CartCheckoutOrderBodyProps {
    order: NotCompletedOrder;
}
export const CartCheckoutOrderBody: React.FC<CartCheckoutOrderBodyProps> = observer(({ order }) => {
    const { store, controller } = useRequiredLayers<CartCheckoutStore, CartCheckoutController>(
        CartCheckoutStoreContext,
        CartCheckoutControllerContext
    );
    const { t } = useTranslation("translation", { useSuspense: false });
    const [_, setDisabled] = useState<boolean>(false);
    const y = 500;
    const [vt] = useVT(() => ({ scroll: { y }, debug: true }), []);
    const [applyDisabled] = useState<boolean>(order.items.every((item) => !item.valid));
    const rowRef = useRef<HTMLSpanElement>() as RefObject<HTMLSpanElement>;

    useEffect(() => {
        i18n.on("languageChanged", controller.onLngChange);
        return () => i18n.off("languageChanged", controller.onLngChange);
    }, []);

    const getRowTextColor = (row: CartCheckoutTableItem) => (row.valid ? undefined : "#bdbdbd");

    const columns: any[] = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "articleName",
            width: "130px",
            render: (name: string, row: CartCheckoutTableItem) =>
                !row.valid ? (
                    <span
                        style={{
                            whiteSpace: "nowrap",
                            color: getRowTextColor(row),
                        }}
                    >
                        {name}
                    </span>
                ) : name ? name.toUpperCase() === "DELIVERY" ? t("DELIVERY.TITLE") : (
                    <Link className="link" to={"/catalog/article/" + name}>
                        {name}
                    </Link>
                ) : null,

        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "articleDescription",
            key: "articleDescription",
            width: "300px",
            render: (description: string, row: CartCheckoutTableItem) =>
                row.valid ? (
                    description
                ) : (
                    <Space direction="vertical" size={[5, 5]}>
                        <Typography.Text style={{ color: "#bdbdbd" }} delete>
                            {description}
                        </Typography.Text>
                        <Tag color={"error"}>{t("ARTICLES.ARTICLE_NOT_AVAILABLE")}</Tag>
                    </Space>
                ),
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            align: "right",
            width: "150px",
            render: (item: CartCreateCalculatedPrice) => (item ? displayPrice(item.amount) : null),
        },
        {
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "quantity",
            width: "100px",
            render: (item: string, row: CartCheckoutTableItem) => (
                <span style={{ color: getRowTextColor(row) }}>{item}</span>
            ),
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "totalPrice",
            width: "150px",
            render: (item: Amount | undefined, row: CartCheckoutTableItem) =>
                item ? <span style={{ color: getRowTextColor(row) }}>{displayPrice(item)}</span> : null,
        },
    ];

    return (
        <>
            <Collapse
                key={order.id}
                expandIconPosition="right"
                bordered={false}
                activeKey={store.activeOrderCollapse}
                onChange={() => controller.toggleOrderCollapse(order)}
            >
                <Collapse.Panel
                    key={order.id}
                    className="cart-collapse-panel"
                    header={
                        <span
                            ref={rowRef}
                            style={{
                                fontWeight: "bold",
                                fontSize: 20,
                                scrollMargin: 80,
                            }}
                        >
                            {t("ORDERS.ORDER.TITLE") + " #" + order.id} ({order.warehouse.name})
                        </span>
                    }
                    extra={<>{moment(order.date).fromNow()}</>}
                >
                    <Row style={{ width: "100%" }} gutter={[0, 50]}>
                        <Col span={24}>
                            <Table
                                rowKey={"key"}
                                columns={columns}
                                dataSource={generateCartApplyTableData(order)}
                                loading={store.loading}
                                components={vt}
                                scroll={{ x: true, y }}
                                pagination={false}
                                footer={() => (
                                    <DoubleDetailsTableFooter
                                        backorderSummary={order.backorderSummary}
                                        stockSummary={order.stockSummary}
                                        vatPercent={order.vatPercent}
                                    />
                                )}
                            />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={3}>{t("CART.ASSOCIATED_NUMBER.TITLE")}</Typography.Title>
                            <Input
                                value={store.relatedOrderNumber[order.id]}
                                onChange={(event) => controller.setRelatedOrderNumberValue(order, event.target.value)}
                                type="text"
                                maxLength={50}
                                placeholder={t("CART.ASSOCIATED_NUMBER.DESCRIPTION")}
                            />
                        </Col>
                        <Col span={24}>
                            <CartCheckoutAddresses
                                title={"CART.DELIVERY.ADDRESSES"}
                                type={"shipping"}
                                order={order}
                                addresses={store.shippingAddresses[order.id]}
                                onCardClick={controller.updateShippingAddresses}
                            />
                        </Col>
                        {order.type !== 'backorder' ? (
                            <Col span={24}>
                                {store.shippingAddresses[order.id] ? <CartCheckoutShippings order={order} /> : null}
                            </Col>
                        ) : null}
                        <Col span={24}>
                            <Typography.Title level={3}>{t("ORDERS.COMMENT")}</Typography.Title>
                            <Input.TextArea
                                value={store.orderComment[order.id]}
                                onChange={(event) => controller.setOrderCommentValue(order, event.target.value)}
                                rows={3}
                                maxLength={150}
                                allowClear
                            />
                        </Col>
                        <Col span={24}>
                            <Space
                                style={{
                                    display: "flex",
                                    width: "max-content",
                                    margin: "0 auto",
                                }}
                                direction="horizontal"
                                size={[10, 10]}
                                wrap
                            >
                                <Button
                                    disabled={applyDisabled || store.checkoutButtonDisabled}
                                    type="primary"
                                    onClick={async () => {
                                        setDisabled(true);
                                        await controller.createOrder(order);
                                        rowRef.current?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "start",
                                        });
                                    }}
                                >
                                    {t("ORDERS.CREATE.FROM_WAREHOUSE") + " " + order.warehouse.name}
                                </Button>
                                <Button
                                    danger
                                    style={{ width: "100%" }}
                                    onClick={() => controller.onCancelOrder(order)}
                                    disabled={store.ordersCancelling[order.id]}
                                >
                                    {t("ORDERS.CANCEL.TITLE")}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        </>
    );
});
