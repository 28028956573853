import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {
    MergedInvoice,
    MergedInvoiceTableItem,
    SearchedMergedInvoice
} from "../../models/invoices/merged-invoice/merged.invoice";

export class MergedInvoicesService {
    public async getMergedInvoices(request: PaginatedRequest, defaultFilters?: {[key: string]: string | string[]}): Promise<PaginatedResult<MergedInvoiceTableItem>> {
        if (defaultFilters) {
            Object.keys(defaultFilters).forEach(key => {
                request.addFilter(key, defaultFilters[key]);
            })
        }
        return (await apiService.get<PaginatedResult<MergedInvoiceTableItem>>(
            "documents/merged-invoices",
            {
                params: request.toParams()
            }
        )).data;
    }

    public async searchMergedInvoices(number: string): Promise<SearchedMergedInvoice[]> {
        return (await apiService.get<SearchedMergedInvoice[]>("documents/merged-invoices/search?number=" + number)).data;
    }

    public async getMergedInvoice(id: string): Promise<MergedInvoice> {
        return (await apiService.get<MergedInvoice>("documents/merged-invoices/" + id)).data;
    }
}

export const mergedInvoicesService = new MergedInvoicesService();
