import React from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {MergedInvoice} from "../../../models/invoices/merged-invoice/merged.invoice";

export class MergedInvoiceStore {
    @observable
    public _mergedInvoice: MergedInvoice | null = null;

    @observable
    public _isLoading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    private set isLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }

    private set mergedInvoice(value: MergedInvoice) {
        this._mergedInvoice= value
    }

    @computed
    public get invoice(): MergedInvoice {
        return this._mergedInvoice!;
    }

    @action
    public setInvoice(value: MergedInvoice): void {
        this.mergedInvoice = value;
    }
}

export const MergedInvoiceStoreContext = React.createContext<null | MergedInvoiceStore>(null) as React.Context<MergedInvoiceStore>;