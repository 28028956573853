import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "antd";
import {Claim} from "../../../../models/claims/claims";
import {ClaimPartsKanbanView} from "./kanban/claim.parts.kanban";
import {ClaimPartsListView} from "./list/claim.parts.list";
import {TableViewSwitcher} from "../../../core/commons/table-view-switcher/table-view-switcher";

export interface ClaimPartsProps {
    claim: Claim;
    onUpdate: () => void;
}

export const ClaimParts: React.FC<ClaimPartsProps> = ({claim, onUpdate}) => {
    const {t} = useTranslation("translation", {useSuspense: false});

    const limitExceeded = claim.items.length > 10;

    return (
        <>
            <TableViewSwitcher
                listView={
                    <ClaimPartsListView claim={claim} onUpdate={onUpdate} />
                }
                kanbanView={
                    <ClaimPartsKanbanView claim={claim} onUpdate={onUpdate} />
                }
                extra={
                    <Typography.Title level={3}>{t("CLAIMS.CLAIM.PARTS")}</Typography.Title>
                }
                defaultView={limitExceeded ? "list" : "kanban"}
                disabled={limitExceeded}
            />
        </>
    );
}