import React from "react";
import {Collapse, Row, Space, Table, Skeleton} from "antd";
import {useRequiredLayers} from "../../../utils/context";
import {ArticleStore, ArticleStoreContext} from "../article.store";
import {ArticleController, ArticleControllerContext} from "../article.controller";
import {useTranslation} from "react-i18next";

import "./article.comparison.scss";
import {observer} from "mobx-react";

interface ArticleComparisonProps {
    data: {brandName: string, names: string[]}[];
    header: string;
}

export const ArticleComparison: React.FC<ArticleComparisonProps> = observer(({data, header}) => {
    const {
        store
    } = useRequiredLayers<ArticleStore, ArticleController>(ArticleStoreContext, ArticleControllerContext);
    const {t} = useTranslation('translation', {useSuspense: false});
    const columns = [
        {
            title: t("ARTICLES.MANUFACTURER"),
            dataIndex: "brandName",
            key: "brandName",
            width: "25%"
        },
        {
            title: t("ARTICLES.OE_NUMBER"),
            dataIndex: "names",
            key: "names",
            render: (names: string[]) => names.join(" | ")
        }
    ];

    if (store.loading) {
        return (
            <Space className="article_collapse-loader" direction="vertical" size={[30, 30]}>
                <Skeleton.Button active/>
                <Skeleton.Button active/>
            </Space>
        )
    }

    if (data.length === 0) {
        return null;
    }

    return (
        <Row>
            <Collapse className="article_comparison-collapse article_collapse" expandIconPosition="right" bordered={false}>
                <Collapse.Panel key={0} header={header}>
                    <Table
                        className="comparison-table"
                        rowKey={"brandName"}
                        columns={columns}
                        dataSource={data}
                        scroll={{x: true}}
                        pagination={false}
                    />
                </Collapse.Panel>
            </Collapse>
        </Row>
    );
});
