import React, { useState } from "react";
import { Button, Dropdown, Menu, Skeleton, Space, Typography } from "antd";
import { useRequiredLayers } from "../../../../utils/context";
import { InvoiceStoreContext } from "../invoice.store";
import { InvoiceControllerContext } from "../invoice.controller";
import { useTranslation } from "react-i18next";
import { Export } from "../../../core/commons/export/export";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

interface InvoiceHeaderProps {
    invoiceId: string;
}

export const InvoiceHeader: React.FC<InvoiceHeaderProps> = observer(({ invoiceId }) => {
    const { store } = useRequiredLayers(InvoiceStoreContext, InvoiceControllerContext);
    const { t } = useTranslation("translation", { useSuspense: false });
    const [exportModalShown, setExportModalShown] = useState<boolean>(false);

    return (
        <>
            <Space direction="vertical" size={[50, 30]} style={{ width: "100%" }}>
                {store.loading ? (
                    <Skeleton active paragraph={{ rows: 1 }} title={false} />
                ) : (
                    <Typography.Title level={1} className="main-title">
                        {t("INVOICES.INVOICE.TITLE")} #{invoiceId}
                    </Typography.Title>
                )}
                {store.loading ? (
                    <Space wrap direction="horizontal" size={[10, 5]}>
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                    </Space>
                ) : (
                    <Space wrap direction={"horizontal"} size={[10, 5]}>
                        <Dropdown
                            trigger={["click"]}
                            overlay={
                                <Menu>
                                    {store.invoice?.orders && store.invoice.orders.length
                                        ? store.invoice.orders.map((order) => (
                                              <Menu.Item key={order}>
                                                  <Link
                                                      className={"ant-btn ant-btn-link"}
                                                      to={"/orders/" + order}
                                                      style={{ textAlign: "left" }}
                                                  >
                                                      {order}
                                                  </Link>
                                              </Menu.Item>
                                          ))
                                        : null}
                                </Menu>
                            }
                        >
                            <Button type="primary">{t("ORDERS.LIST")}</Button>
                        </Dropdown>
                        {store.invoice?.packingListDocument ? (
                            <a
                                target="_blank"
                                className="ant-btn"
                                href={"/documents/" + store.invoice?.packingListDocument.id}
                                rel="noopener noreferrer"
                            >
                                {t("ORDERS.ORDER.VIEW.PACKING_LIST")}
                            </a>
                        ) : null}

                        <Dropdown
                            placement={"bottomRight"}
                            trigger={["click"]}
                            overlay={
                                <Menu style={{ textAlign: "left" }}>
                                    <Menu.Item key={1}>
                                        <a
                                            style={{ textAlign: "left" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={"ant-btn ant-btn-link"}
                                            href={"/documents/" + store.invoice!.id}
                                        >
                                            {t("DOCUMENTS.FILES.SHOW_PDF")}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key={2} onClick={() => setExportModalShown(true)}>
                                        {t("DOWNLOAD.CSV_EXCEL")}
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                        </Dropdown>
                        {store.invoice?.mergedInvoiceDocument ? (
                            <Link
                                to={"/final-invoices/" + store.invoice?.mergedInvoiceDocument.id}
                                className="ant-btn"
                            >
                                {t("INVOICE.MERGED.TITLE")}
                            </Link>
                        ) : null}
                    </Space>
                )}
            </Space>
            {store.invoice ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={exportModalShown}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/documents/invoices/" + store.invoice!.id,
                    }}
                    onClose={() => setExportModalShown(false)}
                />
            ) : null}
        </>
    );
});
