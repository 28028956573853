import React, {PropsWithChildren, useState, useEffect, useCallback, useMemo} from "react";
import {Tree} from "antd";
import {wikiService} from "../../../services/wiki/wiki.service";
import {WikiTreeItem} from "../../../models/wiki/wiki";
import {useHistory} from "react-router";
import i18n from "i18next";

interface TreeNode {
    title: string;
    key: number;
    isLeaf: boolean;
    children: TreeNode[];
    parent: TreeNode|null;
}

const buildTreeWithUpdateMap = (items: WikiTreeItem[], map: Map<number, TreeNode>, parent: TreeNode|null = null): TreeNode[] => {
    return items.map(item => {
        const node = {title: item.title, key: item.id, isLeaf: item.leaf, children: [], parent};
        map.set(item.id, node);
        return node;
    })
}

interface WikiCategoryTreeProps {
    overloadNodes: WikiTreeItem[];
}

export const WikiCategoryTree: React.FC<PropsWithChildren<WikiCategoryTreeProps>> = ({overloadNodes}) => {

    const history = useHistory();

    const onSelect = useCallback((selectedKeys: React.Key[]) => {
        selectedKeys[0] && history.push('/wiki/page/' + selectedKeys[0]);
    }, []);

    const treeMap = useMemo(() => new Map<number, TreeNode>(), []);

    const [treeData, setTreeData] = useState<TreeNode[]>();

    const reload = () => {
        treeMap.clear();
        wikiService
            .getTreeRoot()
            .then(items => {
                setTreeData(buildTreeWithUpdateMap(items, treeMap))
            });
    }

    useEffect(() => {
        reload();
    }, [])

    useEffect(() => {
        if (treeData) {
            overloadNodes.map(node => {
                const foundNode = treeMap.get(node.id);
                if (foundNode) {
                    if (foundNode.parent?.key !== node.parent) {
                        const index = foundNode.parent?.children.indexOf(foundNode);
                        if (-1 !== index) {
                            foundNode.parent?.children.splice(index!, 1);
                            treeMap.delete(node.id);
                        }
                    }
                    foundNode.title = node.title;
                    foundNode.isLeaf = node.leaf;
                } else {
                    if (node.parent) {
                        const parentFoundNode = treeMap.get(node.parent);
                        if (parentFoundNode) {
                            const newNode = {title: node.title, key: node.id, isLeaf: node.leaf, children: [], parent: parentFoundNode};
                            parentFoundNode.children.push(newNode);
                            treeMap.set(node.id, newNode);

                        }
                    } else {
                        const newNode = {title: node.title, key: node.id, isLeaf: node.leaf, children: [], parent: null};
                        treeData.push(newNode);
                        treeMap.set(node.id, newNode);
                    }
                }
            });
            setTreeData([...treeData]);
        }
    }, [overloadNodes])

    useEffect(() => {
        i18n.on("languageChanged", reload);
        return () => i18n.off("languageChanged", reload);
    }, []);

    const treeDataLoader = (node: any): Promise<any> => {
        return wikiService
            .getTreeChildren(node.key)
            .then(items => {
                const treeDataCopy = [...treeData!];
                const foundNode = treeMap.get(node.key)
                if (foundNode) {
                    foundNode.children = buildTreeWithUpdateMap(items, treeMap);
                    setTreeData(treeDataCopy)
                }
            });
    };

    return (
        <Tree
            defaultExpandAll
            showIcon
            showLine
            onSelect={onSelect}
            loadData={treeDataLoader}
            treeData={treeData}
        />
    );

}