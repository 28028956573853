import {ExportBlob} from "../../core/commons/export-blob/export.blob";
import {Button, Checkbox, Col, Form, Input, Row, Select, Tooltip} from "antd";
import {renderSortButtons} from "../../../utils/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext} from "react";
import {DocumentsStore} from "../documents.store";
import {DocumentsController} from "../documents.controller";
import {useTranslation} from "react-i18next";
import {RootStoreContext} from "../../../stores/root/root";
import {observer} from "mobx-react";


export interface AvailabilityExportModalProps {
    store: DocumentsStore;
    controller: DocumentsController;
}

export const AvailabilityExportModal: React.FC<AvailabilityExportModalProps> = observer(({store, controller}) => {

    const { t } = useTranslation("translation", { useSuspense: false });
    const [form] = Form.useForm();
    const {
        langStore: { locales },
    } = useContext(RootStoreContext);

    const getOptionLabel = (name: string): string => {
        const option = store.options.find((item) => item.value === name);
        if (option) {
            return option.label;
        }
        return name;
    };

    return (
        <ExportBlob<{ values: any }>
            title="EXPORT.TITLE"
            okText="DOWNLOAD.DO_DOWNLOAD"
            submitting={store.submitting}
            form={form}
            setSubmitting={controller.setSubmitting}
            onInit={controller.getExportDefaultValues}
            onSubmit={controller.export}
            onClose={controller.closeModal}
        >
            <Row gutter={[5, 5]}>
                <Col span={24}>
                    <Form.Item
                        name="language"
                        label={t("LANGUAGES.SINGLE_TITLE")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select disabled={store.submitting} placeholder={t("LOCALE")}>
                            {locales.map((locale) => (
                                <Select.Option value={locale} key={locale}>
                                    {locale.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.List
                        name={"fields"}
                        rules={[
                            {
                                validator: async (rule, names) => {
                                    try {
                                        if (!names || names.length === 0) {
                                            return Promise.reject(t("FROM.ERROR.PLEASE_ADD_VALUE"));
                                        }
                                    } catch (error) {
                                        throw new Error(String(error));
                                    }
                                },
                            },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                <Row>
                                    <Col span={4} />
                                    <Col span={9}>
                                                        <span
                                                            style={{
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {t("COLUMN.NAME")}
                                                        </span>
                                    </Col>
                                    <Col span={9}>
                                                        <span
                                                            style={{
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {t("COLUMN.DATA_SOURCE")}
                                                        </span>
                                    </Col>
                                </Row>
                                {fields.map((field, index) => (
                                    <Row
                                        justify="space-between"
                                        gutter={[5, 0]}
                                        key={field.key}
                                        align="top"
                                    >
                                        <Form.Item noStyle shouldUpdate={true}>
                                            {({ getFieldValue, setFieldsValue }) => (
                                                <>
                                                    {renderSortButtons(
                                                        form,
                                                        index,
                                                        fields.length,
                                                        store.submitting,
                                                        "fields",
                                                        "fields"
                                                    )}

                                                    <Col span={9}>
                                                        <Form.Item
                                                            {...field}
                                                            fieldKey={[field.key, "visibleName"]}
                                                            name={[field.name, "visibleName"]}
                                                        >
                                                            <Input
                                                                placeholder={t("COLUMN.NAME")}
                                                                disabled={store.submitting}
                                                                type="text"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={9}>
                                                        <Form.Item
                                                            {...field}
                                                            fieldKey={[field.key, "name"]}
                                                            name={[field.name, "name"]}
                                                        >
                                                            {getFieldValue([
                                                                "fields",
                                                                index,
                                                                "name",
                                                            ]) !== undefined &&
                                                            (getFieldValue([
                                                                    "fields",
                                                                    index,
                                                                    "name",
                                                                ]) === "" ||
                                                                ![...store.options]
                                                                    .map((item) => item.value)
                                                                    .includes(
                                                                        getFieldValue([
                                                                            "fields",
                                                                            index,
                                                                            "name",
                                                                        ])
                                                                    )) ? (
                                                                <Input
                                                                    disabled={store.submitting}
                                                                    type="text"
                                                                    placeholder="Custom"
                                                                    autoFocus={true}
                                                                />
                                                            ) : (
                                                                <Select
                                                                    placeholder={t(
                                                                        "COLUMN.DATA_SOURCE"
                                                                    )}
                                                                    disabled={store.submitting}
                                                                    options={store.options}
                                                                    onSelect={(value: string) => {
                                                                        const fields: {
                                                                            name: string;
                                                                            visibleName: string;
                                                                        }[] =
                                                                            form.getFieldsValue()
                                                                                .fields;
                                                                        const fieldItem = fields[index];
                                                                        fields[index] = {
                                                                            ...fieldItem,
                                                                            visibleName: getOptionLabel(
                                                                                fieldItem.name
                                                                            ),
                                                                        };
                                                                        setFieldsValue({
                                                                            ...form.getFieldsValue(),
                                                                            fields,
                                                                        });
                                                                    }}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={2}>
                                                        <Form.Item {...field}>
                                                            <Tooltip
                                                                placement="topRight"
                                                                title={t("DELETE")}
                                                            >
                                                                <Button
                                                                    disabled={store.submitting}
                                                                    type="default"
                                                                    onClick={() => remove(field.name)}
                                                                    icon={
                                                                        <FontAwesomeIcon
                                                                            icon={["fas", "times"]}
                                                                        />
                                                                    }
                                                                    danger
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    className="btn-flex-center sort-btn"
                                                                />
                                                            </Tooltip>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            )}
                                        </Form.Item>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        disabled={store.submitting}
                                        onClick={() => {
                                            add({});
                                        }}
                                        block
                                        icon={<FontAwesomeIcon icon={["fas", "plus-circle"]} />}
                                    >
                                        {t("ADD_FIELD")}
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
                <Col span={24}>
                    <Form.Item name={["config", "withHeaders"]} valuePropName={"checked"}>
                        <Checkbox disabled={store.submitting}>{t("DOWNLOAD.WITH_HEADERS")}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={["config", "format"]}
                        label={t("DOWNLOAD.FORMAT")}
                        rules={[{ required: true, message: t("FROM.ERROR.PLEASE_SELECT_VALUE") }]}
                    >
                        <Select disabled={store.submitting} placeholder={t("DOWNLOAD.FORMAT")}>
                            {store.formats.map((item) => (
                                <Select.Option key={item} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="save" valuePropName={"checked"}>
                        <Checkbox disabled={store.submitting}>
                            {t("ARTICLES.EXPORT.SAVE_SETTINGS")}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </ExportBlob>
    )
})