import React, {useContext, useState} from "react";
import {Contact} from "../../../../models/contacts/contact";
import {Col, Input, Row, Modal, Form} from "antd";
import {useTranslation} from "react-i18next";
import {ErrorResponseData} from "../../../../models/core/response";
import {AxiosError} from "axios";
import {useRequiredLayers} from "../../../../utils/context";
import {ContactsControllerContext} from "../contacts.controller";

interface MyProfileContactsModalProps {
    contact?: Contact,
    visible: boolean,
    onClose: () => void,
    // onSubmit: (values: any) => Promise<void>
}

export const ContactsModal: React.FC<MyProfileContactsModalProps> = (
    {
        contact,
        visible,
        onClose,
        // onSubmit,
    }) => {
    const controller = useContext(ContactsControllerContext);

    const onSubmit = (values: Contact): Promise<void> => {
        return contact ? controller.editContact(values, contact.id) : controller.createContact(values)
    }

    const {t} = useTranslation('translation', {useSuspense: false});
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            maskClosable={false}
            confirmLoading={loading}
            title={contact ? t("CONTACTS.EDIT") : t("CONTACTS.ADD")}
            okText={contact ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"contacts_form"}
                initialValues={contact}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                            onSubmit(values)
                            .then(
                                () => {
                                    form.resetFields();
                                    onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {}, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10} >
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label={t("FIRSTNAME")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label={t("LASTNAME")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("LASTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="position"
                            label={t("POSITION")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("POSITION")}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label={t("EMAIL")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input disabled={loading} type="text" placeholder={t("EMAIL")}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="workPhone"
                            label={t("WORKPHONE")}
                            rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                        >
                            <Input disabled={loading} type="text" placeholder={"+37200000000"}/>
                        </Form.Item>
                    </Col><Col span={12}>
                    <Form.Item
                        name="mobilePhone"
                        label={t("MOBILEPHONE")}
                    >
                        <Input disabled={loading} type="text" placeholder={"+37200000000"}/>
                    </Form.Item>
                </Col>
                </Row>
            </Form>
        </Modal>
    );
}
