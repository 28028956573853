import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Alert, Button, Form, Input} from "antd";
import {ForgotPasswordStore} from "./forgot.password.store";
import {ForgotPasswordController} from "./forgot.password.controller";
import {LoginWrapper} from "../login.wrapper";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {forgotPasswordService} from "../../../../services/forgot-password/forgot.password.service";
import "./forgot.password.scss";
import {Link} from "react-router-dom";

export const ForgotPassword: React.FC = observer(() => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const [store] = useState(() => new ForgotPasswordStore());
    const [controller] = useState(() => new ForgotPasswordController(store, forgotPasswordService));
    const [form] = Form.useForm();

    return (
        <LoginWrapper title="FORGOT_PASSWORD.TITLE">
            {!store.sent ? (
                <>
                    <Form
                        form={form}
                        name="normal_login"
                        className="login-form"
                        layout="vertical"
                        onFinish={() =>
                            form.validateFields()
                                .then(values => {
                                    controller.sendEmail(values)
                                        .then(() => {
                                            form.resetFields();

                                        })
                                        .catch((e: AxiosError<ErrorResponseData>) => {
                                            if (e.response?.data.fields) {
                                                form.setFields(e.response?.data?.fields);
                                            }
                                        })
                                        .then(() =>
                                            (e: any) => form.setFields(e.response?.data?.fields))
                                })
                        }
                    >
                        <div className="login-form-items">
                            <Form.Item
                                name="username"
                                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                help={t("FORGOT_PASSWORD.HELP")}
                            >
                                <Input
                                    type="email"
                                    placeholder={t("EMAIL")}
                                    disabled={store.disabled}
                                    style={{marginBottom: 10}}
                                />
                            </Form.Item>
                            <Form.Item style={{marginBottom: 10, marginTop: 20}}>
                                <Button
                                    style={{width: "100%"}}
                                    onClick={() => form.submit()}
                                    loading={store.disabled}
                                    type="primary"
                                >
                                    {t("FORGOT_PASSWORD.SEND")}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                    {/*<span style={{textAlign: "center", display: "block", marginBottom: 10}}>{t("OR")}</span>*/}
                    <Link to="/login" className="ant-btn register-btn" style={{width: "100%"}}>{t("LOGIN.LOG_IN")}</Link>
                </>
            ) : (
                <Alert description={t("FORGOT_PASSWORD.SENT")} type="success" message={t("SUCCESS")} showIcon/>
            )}
        </LoginWrapper>
    );
})