import React, { Context } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import { Warehouse } from "../../../models/warehoues/warehouse";
import {CartCreate, CartCreateType} from "../../../models/cart/create/cart.create";
import {
    CartCreateTableLastRecord,
    CartCreateTableRecord,
} from "../../../models/cart/create/records/records";
import { CartCreateMissingItem } from "../../../models/cart/create/result/cart.create.result";

export class CartCreateStore {
    @observable
    private _fieldsAreValid: boolean = true;

    @observable
    private _loading: boolean = false;

    @observable
    private _createTableLoading: boolean = false;

    @observable
    private _warehousesAll: Warehouse[] = [];

    @observable
    private _useBackorder: boolean = false;

    @observable
    private _defaultWarehouses: string[] = [];

    @observable
    private _cart: CartCreate | null = null;

    @observable
    private _tableData: CartCreateTableRecord[] & CartCreateTableLastRecord[] =
        [];

    @observable
    private _urlQueryString?: string;

    @observable
    private _isCheckoutButtonDisabled: boolean = false;

    @observable
    private _missingItems: CartCreateMissingItem[] = [];

    @observable
    private readonly _type: CartCreateType;

    constructor(type: CartCreateType) {
        this._type = type;
        makeObservable(this);
    }

    private set fieldsAreValid(value: boolean) {
        this._fieldsAreValid = value;
    }

    private set isCheckoutButtonDisabled(value: boolean) {
        this._isCheckoutButtonDisabled = value;
    }

    private set urlQueryString(value: string) {
        this._urlQueryString = value;
    }

    private set cartWarehousesAll(value: Warehouse[]) {
        this._warehousesAll = value;
    }

    private set cartDefaultWarehouses(value: string[]) {
        this._defaultWarehouses = value;
    }

    private set useBackorder(value: boolean) {
        this._useBackorder = value;
    }

    private set cartCreate(value: CartCreate) {
        this._cart = value;
    }

    private set cartMissingItems(value: CartCreateMissingItem[]) {
        this._missingItems = value;
    }

    private set cartTableData(
        value: CartCreateTableRecord[] & CartCreateTableLastRecord[]
    ) {
        this._tableData = value;
    }

    private set isLoading(value: boolean) {
        this._loading = value;
    }

    private set createTableLoading(value: boolean) {
        this._createTableLoading = value;
    }

    @computed
    public get missingItems(): CartCreateMissingItem[] {
        return this._missingItems;
    }

    @computed
    public get hasAllFields(): boolean {
        return this._fieldsAreValid;
    }

    @computed
    public get urlString(): string | undefined {
        return this._urlQueryString;
    }

    @computed
    public get previewLoading(): boolean {
        return this._createTableLoading;
    }

    @computed
    public get warehousesAll(): Warehouse[] {
        return this._warehousesAll;
    }

    @computed
    public get defaultWarehouses(): string[] {
        return this._defaultWarehouses;
    }

    @computed
    public get useBackorder(): boolean {
        return this._useBackorder;
    }

    @computed
    public get cart(): CartCreate {
        return this._cart!;
    }

    @computed
    public get checkoutButtonDisabled(): boolean {
        return this._isCheckoutButtonDisabled;
    }

    @computed
    public get tableData(): CartCreateTableRecord[] &
        CartCreateTableLastRecord[] {
        return this._tableData;
    }

    @computed
    public get loading(): boolean {
        return this._loading;
    }

    @computed
    public get type(): CartCreateType {
        return this._type;
    }

    @action
    public setFieldsAreValid(value: boolean): void {
        this.fieldsAreValid = value;
    }

    @action
    public setCheckoutButtonDisabled(value: boolean): void {
        this.isCheckoutButtonDisabled = value;
    }

    @action
    public setUrlString(value: string): void {
        this.urlQueryString = value;
    }

    @action
    public setWarehousesAll(value: Warehouse[]): void {
        this.cartWarehousesAll = value;
    }

    @action
    public setUseBackorder(value: boolean): void {
        this.useBackorder = value;
    }

    @action
    public setDefaultWarehouses(value: string[]): void {
        this.cartDefaultWarehouses = value;
    }

    @action
    public setCart(value: CartCreate): void {
        this.cartCreate = value;
    }

    @action
    public setTableData(
        value: CartCreateTableRecord[] & CartCreateTableLastRecord[]
    ): void {
        this.cartTableData = value;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }

    @action
    public setPreviewLoading(value: boolean): void {
        this.createTableLoading = value;
    }

    @action
    public setMissingItems(value: CartCreateMissingItem[]): void {
        this.cartMissingItems = value;
    }

    @computed
    public get isMoveButtonDisabled(): boolean {
        if (this.cart) {
            return !this.cart.items.some((item) => item.qty === 0);
        }
        return true;
    }

    @computed
    public get missingItemsAlertVisible(): boolean {
        return (
            this.cart?.items.some((item) => item.qty !== item.requestedQty) ||
            false
        );
    }
}

export const CartStoreContext = React.createContext<null | CartCreateStore>(
    null
) as Context<CartCreateStore>;
