import {apiService} from "../api.service";
import {SearchedUser, SystemUserWithAttributes} from "../../models/user/user";

export class UserService {
    async getCurrentUserWithAttributes(): Promise<SystemUserWithAttributes> {
        return (await apiService.get<SystemUserWithAttributes>('loggedin')).data;
    }

    async searchUser(value: string, accountType?: string): Promise<SearchedUser[]> {
        return (await apiService.get<SearchedUser[]>(
            "users/search" + (accountType ? "/" + accountType : ""),
            {
                params: {
                    value
                }
            }
        )).data
    }

    async switchUser(value: string): Promise<void> {
        return (await apiService.post<void>("authentication/switch-user?username=" + value)).data;
    }

    async resetUser(): Promise<void> {
        return (await apiService.delete<void>("authentication/switch-user")).data;
    }
}

export const userService = new UserService();
