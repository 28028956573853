import React, {useState} from "react";
import {observer} from "mobx-react";
import {useTranslation, Trans} from "react-i18next";
import {Alert, Button, Card, Col, Row, Skeleton, Space, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IntegrationItem} from "./integration-item/integration-item";
import {IntegrationsModal} from "./integrations-modal/integrations.modal";
import {IntegrationsStore, IntegrationsStoreContext} from "./integrations.store";
import {IntegrationsController, IntegrationsControllerContext} from "./integrations.controller";
import {integrationService} from "../../../services/integrations/integrations.service";
import {warehousesService} from "../../../services/warehouses/warehouses.service";
import LinkText from "../../core/commons/link-text/link.text";

export const Integrations: React.FC = observer(() => {
    const [store] = useState(() => new IntegrationsStore());
    const [controller] = useState(() => new IntegrationsController(store, integrationService, warehousesService));
    const {t} = useTranslation('translation', {useSuspense: false});
    const [modalShown, setModalShown] = useState<boolean>(false);

    return (
        <IntegrationsStoreContext.Provider value={store}>
            <IntegrationsControllerContext.Provider value={controller}>
                <Row className="row-no-margin" gutter={[20, 20]}>
                    <Col span={24}>
                        <Space direction="vertical" size={[20, 20]} style={{width: "100%"}}>
                            {store.loading ? (
                                <>
                                    <Skeleton active paragraph={{rows: 3, width: "100%"}}/>
                                    <Skeleton active paragraph={{rows: 2, width: "100%"}}/>
                                </>
                            ) : (
                                <>
                                    <Alert
                                        showIcon
                                        type="info"
                                        message={t("CLIENT.INTEGRATIONS.ABOUT.FTP.TITLE")}
                                        description={t("CLIENT.INTEGRATIONS.ABOUT.FTP.TEXT")}
                                    />
                                    <Alert
                                        showIcon
                                        type="info"
                                        message={t("CLIENT.INTEGRATIONS.ABOUT.API.TITLE")}
                                        description={
                                            <Trans
                                                i18nKey="CLIENT.INTEGRATIONS.ABOUT.API.TEXT"
                                                t={t}
                                                components={[<LinkText href="/my-profile/accounts" />]}
                                            />
                                        }
                                    />
                                </>
                            )}
                        </Space>
                    </Col>
                    <Col span={24}>
                        {store.loading ? (
                            <Skeleton.Button active/>
                        ) : (

                            <Button type="primary" onClick={() => setModalShown(true)}>
                                {t("ADD")}
                            </Button>
                        )}
                    </Col>
                    {store.loading ? (
                        <Col className="card-col" xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Card
                                className="card"
                                bordered={false}
                                size="small"
                                title={<Skeleton active paragraph={{rows: 1}}/>}
                            >
                                <Skeleton active paragraph={{rows: 2, width: "100%"}}/>
                            </Card>
                        </Col>
                    ) : store.integrations.map(item => (
                        <IntegrationItem key={item.id} integration={item}/>
                    ))}
                </Row>
                <IntegrationsModal
                    visible={modalShown}
                    onClose={() => setModalShown(false)}
                />
            </IntegrationsControllerContext.Provider>
        </IntegrationsStoreContext.Provider>
    );
})