import React, {useState} from "react";
import {Button, Input, Form, Space, Checkbox} from "antd";
import {useTranslation} from "react-i18next";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {claimService} from "../../../../services/claims/claims.service";
import {Claim, ClaimComment} from "../../../../models/claims/claims";

export interface ClaimCommentsFormProps {
    claim: Claim;
    onCompleted: (comment: ClaimComment) => void;
}

export const ClaimCommentsForm: React.FC<ClaimCommentsFormProps> = ({claim, onCompleted}) => {
    const {t} = useTranslation("translation", {useSuspense: false});

    const [loading, setLoading] = useState<boolean>(false);
    const [rowsNum, setRowsNum] = useState<number>(1);
    const [form] = Form.useForm();

    return (
        <Form
            size={"middle"}
            form={form}
            layout="vertical"
            onFinish={() => form
                .validateFields()
                .then(values => {
                    setLoading(true);
                    claimService.addComment(claim.id, values)
                        .then(
                            (comment) => {
                                form.resetFields();
                                setRowsNum(1);
                                onCompleted(comment);
                            },
                            (e: AxiosError<ErrorResponseData>) => {
                                setLoading(false);
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            })
                        .then(() =>
                            (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Form.Item
                name="text"
                rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
            >
                <Input.TextArea
                    rows={rowsNum}
                    onFocus={() => setRowsNum(5)}
                    onBlur={(event) => {
                        if (!event.target.value.trim()) {
                            setRowsNum(1);
                        }
                    }}
                />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={true}>
                {({getFieldValue}) => getFieldValue("text") ? (
                        <Form.Item>
                            <Button htmlType="submit" loading={loading} type="primary">
                                {t("COMMENTS.ADD")}
                            </Button>
                        </Form.Item>
                    )
                    : null
                }
            </Form.Item>
        </Form>
    );
};
