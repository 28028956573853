import {Invoice} from "../invoice";
import {DetailsTableFooterData} from "../../details-table-footer/details.table.footer";
import {CreditInvoice} from "../credit-invoice/credit.invoice";

export const generateOrderDetailsTableFooterDataForInvoice = (invoice: Invoice): DetailsTableFooterData => {
    return {
        qty: invoice.items.length,
        taxPercent: invoice.vatPercent,
        totalWeight: invoice.totalNetWeight + " / " + invoice.totalGrossWeight,
        taxAmount: invoice.subtotalVat,
        totalAmount: invoice.subtotal,
        grandTotalAmount: invoice.grandTotal
    }
}

export const generateOrderDetailsTableFooterDataForCreditInvoice = (invoice: CreditInvoice): DetailsTableFooterData => {
    return {
        qty: invoice.items.length,
        taxPercent: invoice.vatPercent,
        totalWeight: 0,
        taxAmount: invoice.subtotalVat,
        totalAmount: invoice.subtotal,
        grandTotalAmount: invoice.grandTotal
    }
}

