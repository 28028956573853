import { action, computed, makeObservable, observable } from "mobx";
import { Integration, IntegrationConnectionType } from "../../../models/integrations/integration";
import React from "react";
import { Warehouse } from "../../../models/warehoues/warehouse";

export class IntegrationsStore {
    public integrationConnectionTypes: IntegrationConnectionType[] = [
        {
            value: "ftp",
            label: "FTP",
        },
        {
            value: "sftp",
            label: "SFTP",
        },
    ];

    @observable
    private _clientWarehouses: Warehouse[] = [];

    @observable
    private _isLoading: boolean = false;

    @observable
    private _clientIntegrations: Integration[] = [];

    constructor() {
        makeObservable(this);
    }

    private set isLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }

    private set clientIntegrations(value: Integration[]) {
        this._clientIntegrations = value;
    }

    @computed
    public get integrations(): Integration[] {
        return this._clientIntegrations;
    }

    @action
    public setIntegrations(value: Integration[]): void {
        this.clientIntegrations = value;
    }

    @action
    public editIntegration(index: number, value: Integration): void {
        this._clientIntegrations[index] = value;
    }

    private set clientWarehouses(value: Warehouse[]) {
        this._clientWarehouses = value;
    }

    @computed
    public get warehouses(): Warehouse[] {
        return this._clientWarehouses;
    }

    @action
    public setWarehouses(value: Warehouse[]): void {
        this.clientWarehouses = value;
    }
}

export const IntegrationsStoreContext = React.createContext<null | IntegrationsStore>(
    null
) as React.Context<IntegrationsStore>;
