import {action, computed, makeObservable, observable} from "mobx";
import {BasketApplyTableItem} from "../../../models/cart/checkout/records/basket.apply.table.data";
import {CartCheckoutAddress} from "../../../models/cart/checkout/address/cart.checkout.address";
import {CartCheckoutShipping} from "../../../models/cart/checkout/shipping/cart.checkout.shipping";
import {Address} from "../../../models/addresses/address";
import React from "react";
import {NotCompletedOrder} from "../../../models/cart/checkout/not.completed.order";
import {OrdersCheckoutRequest} from "../../../models/cart/checkout/request/apply.request";
import moment from "moment";

export class CartCheckoutStore {
    public defaultWarehouses: string[] = [];

    @observable
    public cancellingOrder: NotCompletedOrder | null = null;

    @observable
    public cancelOrderModalShown: boolean = false;

    @observable
    public ordersCancelling: {[orderId: number]: boolean} = {};

    @observable
    public applyButtonDisabled: boolean = false;

    @observable
    public checkoutButtonDisabled: boolean = false;

    @observable
    private _allFieldsValid: boolean = false;

    @observable
    private _loading: boolean = false;

    @observable
    public checkoutTableData: BasketApplyTableItem[] = [];

    @observable
    public notCompletedOrders: NotCompletedOrder[] = [];

    @observable
    public shippingAddresses: {[orderId: number]: CartCheckoutAddress[]} = {};

    @observable
    public agentShippingsNotFound: {[orderId: number]: boolean} = {};

    @observable
    public shippings: {[orderId: number]: CartCheckoutShipping[]} = {};

    @observable
    public shippingsNotFound: {[orderId: number]: boolean} = {};

    @observable
    public ordersResult: {[orderId: number]: boolean} = {};

    @observable
    public relatedOrderNumber: {[orderId: number]: string} = {};

    @observable
    public orderComment: {[orderId: number]: string} = {};

    @observable
    public notValidItemsModalShown: boolean = false;

    @observable
    public notValidItemsModalItem: NotCompletedOrder | null = null;

    @observable
    public activeOrderCollapse: number[] = [];

    ordersCheckoutRequests: {[orderId: number]: OrdersCheckoutRequest} = {};


    constructor() {
        makeObservable(this)
    }


    public setOrdersCheckoutRequest(orderId: number, value: OrdersCheckoutRequest): void {
        this.ordersCheckoutRequests[orderId] = value;
    }

    private set isLoading(value: boolean) {
        this._loading = value
    }

    private set allFieldsValid(value: boolean) {
        this._allFieldsValid = value;
    }

    @computed
    public get fieldsValid(): boolean {
        return this._allFieldsValid;
    }

    @computed
    public get loading(): boolean {
        return this._loading;
    }

    @action
    public addActiveOrderCollapse(orderId: number): void {
        this.activeOrderCollapse = [...this.activeOrderCollapse, orderId];
    }

    @action
    public removeActiveOrderCollapse(orderId: number): void {
        const index = this.activeOrderCollapse.indexOf(orderId);
        const oldActiveCollapsePanels = [...this.activeOrderCollapse];
        oldActiveCollapsePanels.splice(index, 1);
        if (index !== -1) {
            this.activeOrderCollapse = oldActiveCollapsePanels;
        }
    }

    @action
    public setCancellingOrder(value: null | NotCompletedOrder): void {
        this.cancellingOrder = value
    }

    @action
    public setCancelModalShown(value: boolean): void {
        this.cancelOrderModalShown = value;
    }

    @action
    public setOrdersCancelling(orderId: number, value: boolean): void {
        this.ordersCancelling[orderId] = value;
    }

    @action
    public setNotValidItemsModalItem(value: null | NotCompletedOrder): void {
        this.notValidItemsModalItem = value;
    }

    @action
    public setNotValidItemsModalShown(value: boolean): void {
        this.notValidItemsModalShown = value;
    }

    @action
    public setCheckoutButtonDisabled(value: boolean): void {
        this.checkoutButtonDisabled = value;
    }

    @action
    public setNotCompletedOrders(value: NotCompletedOrder[]): void {
        this.notCompletedOrders = value;
    }

    @action
    public updateNotCompletedOrder(newOrder: NotCompletedOrder): void {
        const index = this.notCompletedOrders.findIndex(item => item.id === newOrder.id);
        if (index !== -1) {
            this.notCompletedOrders[index] = newOrder;
        }
    }

    @action
    public removeNotCompletedOrder(order: NotCompletedOrder): void {
        const index = this.notCompletedOrders.indexOf(order);
        const newOrders = [...this.notCompletedOrders];
        newOrders.splice(index, 1);
        if (index !== -1) {
            this.setNotCompletedOrders(newOrders);
        }
    }

    @action
    public setAllFieldsValid(value: boolean): void {
        this.allFieldsValid = value;
    }

    @action
    public setLoading(value: boolean): void {
        this.isLoading = value;
    }


    @action
    public setShippingsNotFound(notCompletedOrders: NotCompletedOrder[], value: boolean): void {
        notCompletedOrders.forEach(order => {
            this.shippingsNotFound[order.id] = value;
        })
    }

    @action
    public setShippingAddresses(notCompletedOrders: NotCompletedOrder[], addresses: Address[]): void {
        notCompletedOrders.forEach(order => {
            this.shippingAddresses[order.id] = addresses.map(address => ({...address, checked: address.default, custom: false}));
        })
    }

    @action
    public addShippingAddress(orderId: number, address: CartCheckoutAddress): void {
        this.shippingAddresses[orderId].push(address);
    }

    @action
    public updateMapItem(map: {[key: number]: any}, key: number, items: any): void {
        map[key] = items;
    }
    @action
    public updateMapArrayItem(map: {[K: number]: CartCheckoutAddress[] | CartCheckoutShipping[]}, key: number, index: number, item: CartCheckoutAddress | CartCheckoutShipping): void {
        map[key][index] = item;
    }

    @action
    public setRelatedOrderNumber(value: string, orderId: number): void {
        this.relatedOrderNumber[orderId] = value;
    }

    @action
    public setOrderComment(value: string, orderId: number): void {
        this.orderComment[orderId] = value;
    }

    @action
    public setOrderResult(orderId: number, value: boolean): void {
        this.ordersResult[orderId] = value;
    }

    @action
    public resetOrderResult(value: any): void {
        this.ordersResult = value;
    }

    @computed
    get todayOrders(): NotCompletedOrder[] {
        return this.notCompletedOrders.filter(o => moment(o.date).isSame(moment(), 'day'));
    }

    @computed
    get upToThisDayOrders(): NotCompletedOrder[] {
        return this.notCompletedOrders.filter(o => !moment(o.date).isSame(moment(), 'day'));
    }
}

export const CartCheckoutStoreContext = React.createContext<CartCheckoutStore | null>(null) as React.Context<CartCheckoutStore>;
