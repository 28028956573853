import React, {useContext, useEffect, useState} from "react";
import {Address} from "../../../../models/addresses/address";
import {Col, Form, Input, Row, Modal, Select, Alert} from "antd";
import {useRequiredLayers} from "../../../../utils/context";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {RootStore, RootStoreContext} from "../../../../stores/root/root";
import {RootControllers, RootControllersContext} from "../../../../controllers/root/root.controllers";
import {useTranslation} from "react-i18next";
import {AddressesControllerContext} from "../addresses.controller";


interface MyProfileAddressesModalProps {
    type: string,
    address?: Address,
    visible: boolean,
    onClose: () => void,
}
export const AddressesModal: React.FC<MyProfileAddressesModalProps> = ({type, address, visible, onClose}) => {
    const controller = useContext(AddressesControllerContext);
    const {store: {langStore}, controller: {langController}} = useRequiredLayers<RootStore, RootControllers>(RootStoreContext, RootControllersContext);
    const {t} = useTranslation('translation', {useSuspense: false});
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [accountCreatedSuccess, setAccountCreatedSuccess] = useState<boolean>(false);

    useEffect(() => {
        langController.loadCountries();
    }, []);

    const onSubmit = async (values: any): Promise<void> => {
        if (address) {
            const code = await controller.editAddress(values, address.id)
            if (126 === code) {
                setAccountCreatedSuccess(true);
            } else {
                onClose();
            }
        } else {
            await controller.createAddress({...values, type})
        }
    }

    const defaultValues = {...address, country: address?.country.id};

    return (
        <Modal
            visible={visible}
            maskClosable={false}
            confirmLoading={loading}
            title={address ? t("ADDRESS_EDIT") : t("ADDRESS_ADD")}
            okText={address ? t("SAVE") : t("ADD")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            style={{top: 13}}
            okButtonProps={{disabled: accountCreatedSuccess}}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            {accountCreatedSuccess ? (
                <Alert
                    message="Success"
                    description={t("ADDRESSES.SEPARATED_ACCOUNT_CREATED")}
                    type="success"
                    showIcon
                />
            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    name={"address_form"}
                    initialValues={defaultValues}
                    onFinish={() => form
                        .validateFields()
                        .then(values => {
                            setLoading(true);
                            onSubmit({...values})
                                .then(() => {
                                    form.resetFields();
                                }, (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                                .then(() =>
                                    // onClose(),
                                    (e: any) => form.setFields(e.response?.data?.fields))
                                .finally(() => setLoading(false));
                        })
                    }
                >
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={t("NAME")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("NAME")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="vat"
                                label={"VAT"}
                            >
                                <Input disabled={loading} type="text" placeholder={"EE00000000"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="customsCode"
                                label={"EORI"}
                            >
                                <Input disabled={loading} type="text" placeholder={"EE00000000"}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="regCode"
                                label={t("REG_NUMBER")}
                            >
                                <Input disabled={loading} type="text" placeholder={"EE00000000"}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="companyName"
                                label={t("COMPANY_NAME")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("COMPANY_NAME")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="firstName"
                                label={t("FIRSTNAME")}
                            >
                                <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastName"
                                label={t("LASTNAME")}
                            >
                                <Input disabled={loading} type="text" placeholder={t("LASTNAME")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="country"
                                label={t("COUNTRY")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Select
                                    disabled={loading}
                                    showSearch
                                    optionFilterProp={"children"}
                                    placeholder={t("COUNTRY_SELECT")}
                                >
                                    {langStore.countries.map(({id, name}) => (
                                        <Select.Option key={id} value={id}>{name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="region"
                                label={t("REGION")}
                            >
                                <Input disabled={loading} type="text" placeholder={t("REGION")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="city"
                                label={t("CITY")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("CITY")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="index"
                                label={t("INDEX")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("INDEX")}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="address"
                                label={t("ADDRESS")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("ADDRESS")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                label={t("PHONE")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("PHONE")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label={t("EMAIL")}
                                rules={[{required: true, message: t('FROM.ERROR.PLEASE_ENTER_VALUE')}]}
                            >
                                <Input disabled={loading} type="text" placeholder={t("EMAIL")}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
        </Modal>
    );
}
