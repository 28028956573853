import React from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {Address} from "../../../models/addresses/address";

export class AddressesStore {
    @observable
    private _isLoading: boolean = false;

    @observable
    private _addresses: Address[] = [];

    constructor() {
        makeObservable(this);
    }

    private set profileAddresses(value: Address[]) {
        this._addresses = value;
    }

    private set isDataLoading(value: boolean) {
        this._isLoading = value;
    }

    @computed
    public get addresses(): Address[] {
        return this._addresses;
    }

    @computed
    public get loading(): boolean {
        return this._isLoading;
    }

    @action
    public setAddresses(value: Address[]): void {
        this.profileAddresses = value;
    }

    @action
    public setLoading(value: boolean): void {
        this.isDataLoading = value;
    }

    @action
    public editAddress(value: Address, index: number): void {
        this._addresses[index] = value;
    }
}

export const AddressesStoreContext = React.createContext<null | AddressesStore>(null) as React.Context<AddressesStore>