import React, {useCallback, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Button, Card, Col, Row, Space, Typography} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {PlannerTask} from "../../../models/issues/issues";
import {issuesService} from "../../../services/issues/issues.service";
import {MarkdownViewer} from "../../core/markdown/markdown-viewer/markdown-viewer";
import {TaskCommentsList} from "../comments/list/task.comments.list";
import {useTranslation} from "react-i18next";
import {TaskStatus} from "../common/status/task.status";
import {Date} from "../../core/commons/date/date";

export const IssueView: React.FC<ComponentPropsFromRoute> = () => {

    const {t} = useTranslation("translation", {useSuspense: false});

    const { id } = useParams<{id: string}>();

    const [task, setTask] = useState<PlannerTask>();

    const reload = useCallback(() => {
        issuesService.getIssue(parseInt(id!)).then(task => setTask(task))
    }, [id])

    useEffect(() => {
        reload();
    }, [])

    if (!task) {
        return <></>
    }

    return (
        <div className="page_content">
            <Space direction="vertical" size={[15, 15]} style={{ width: "100%" }} className="content">
                <Typography.Title level={1} className="main-title">
                    {task.name}
                </Typography.Title>
                <Space size={[10, 10]} direction={"vertical"}>
                    <Card
                        title={<Date dateString={task.createdAt} showTime />}
                        extra={
                            <TaskStatus status={task.status} />
                        }
                    >
                        <MarkdownViewer>
                            {task.description}
                        </MarkdownViewer>
                    </Card>
                    <TaskCommentsList task={task} />
                </Space>
            </Space>
        </div>
    )
}