import React, {useEffect, useState} from "react";
import {Pagination, Space} from "antd";
import {TaskComment} from "../comment/task.comments.comment";
import {TaskNewComment} from "../new-comment/task.comments.new-comment";
import {PlannerTask, PlannerTaskComment} from "../../../../models/issues/issues";
import {issuesService} from "../../../../services/issues/issues.service";
import {Pagination as P} from "../../../../models/requests/paginated-result/paginated.result";

interface TaskCommentsProps {
    task: PlannerTask;
}

export const TaskCommentsList: React.FC<TaskCommentsProps> = ({task}) => {

    const [comments, setComments] = useState<PlannerTaskComment[]>([]);
    const [pagination, setPagination] = useState<P>();

    useEffect(() => {
        reload();
    }, [])

    const reload = async () => {
        await issuesService.getComments(task.id).then(result => {
            setComments(result.items)
            setPagination(result.meta)
        })
    }

    const onNewComment = async (value: string) => {
        await issuesService.addComment(task.id, value);
        await reload();
    }

    if (!pagination) {
        return <></>
    }

    return (
        <Space direction={"vertical"} size={[20, 20]}>
            <TaskNewComment
                onSubmit={onNewComment}
            />
            {comments.map(comment => (
                <TaskComment
                    comment={comment}
                />
            ))}
            <Pagination
                simple
                defaultCurrent={pagination.page}
                total={pagination.totalPages || 1}
            />
        </Space>
    )
}