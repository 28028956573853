import {Link} from "react-router-dom";
import React from "react";
import {TFunction} from "react-i18next";
import {ContainerItem} from "../../models/container/container-item";
import {Date} from "../core/commons/date/date";
import {ColumnsType} from "antd/es/table";
import {InTransitToCart} from "./in-transit.to-cart";

export const columnsFactory = (t: TFunction, setUpdatedItem: (item: ContainerItem, updatedItem: ContainerItem) => void): ColumnsType<ContainerItem> => [
    {
        title: "",
        dataIndex: "id",
        key: "actions",
        render: (_: any, row: ContainerItem) => <InTransitToCart item={row} updateItem={setUpdatedItem} />
    },
    {
        title: t("ARTICLES.TITLE_SINGLE"),
        dataIndex: "articleName",
        key: "articleName",
        render: (item: string) => <Link to={"/catalog/article/" + item} className="link article-link">{item}</Link>
    },
    {
        title: t("DESCRIPTION"),
        dataIndex: "description",
        key: "description"
    },
    {
        title: t("QUANTITY"),
        dataIndex: "qty",
        key: "qty"
    },
    {
        title: t("ARRIVAL_DATE"),
        key: "date",
        render: (_: any, item: ContainerItem) => <>
            <Date dateString={item.minimalDate} />
            {item.maximalDate !== item.minimalDate ? (
                <> ... <Date dateString={item.maximalDate} /></>
            ) : null}
        </>
    }
];