
export const sortDirection = <T = any>(items: T[], formListIndex: number, direction: string): T[] => {
    return "up" === direction
        ? sortUp(items, formListIndex)
        : sortDown(items, formListIndex);
}

const sortDown = <T = any>(items: T[], formListIndex: number): T[] => {
    if (items.length <= formListIndex + 1) {
        return items;
    }
    const currentItem = items[formListIndex];
    items[formListIndex] = items[formListIndex + 1];
    items[formListIndex + 1] = currentItem;
    return items;
}

const sortUp = <T = any>(items: T[], formListIndex: number): T[] => {
    if (formListIndex < 1) {
        return items;
    }
    const currentItem = items[formListIndex];
    items[formListIndex] = items[formListIndex - 1];
    items[formListIndex - 1] = currentItem;
    return items;
}
