import React, {useState} from "react";
import {Image} from "antd/lib";
import placeholder from "../../../images/placeholder.png"

interface CatalogImagePreview {
    size: string
    scheme: boolean;
}

interface ProductImageProps extends CatalogImagePreview {
    sku: string;
    previews: CatalogImagePreview[];
    width: number;
    height: number;
}

function createUrl(sku: string, preview: CatalogImagePreview) {
    return "/product_photos/" + preview.size + (preview.scheme ? "/schemes/" : "/photos/") + sku + (preview.scheme ? ".png" : ".jpg");
}

export const ProductImage: React.FC<ProductImageProps> = (props) => {

    const {sku, width, height, previews} = props;
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Image
                alt={sku}
                width={width}
                height={height}
                src={createUrl(sku, props)}
                fallback={placeholder}
                onClick={() => setVisible(true)}
                preview={{ visible: false }}
            />
            <div style={{ display: 'none' }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                    {previews.map(preview =>(
                        <Image
                            alt={sku}
                            width={width}
                            height={height}
                            src={createUrl(sku, preview)}
                            fallback={placeholder}
                        />
                    ))}
                </Image.PreviewGroup>
            </div>
        </>

    )
}
