import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {PlannerDepartment, PlannerTask, PlannerTaskComment} from "../../models/issues/issues";

export class IssuesService {

    getIssues(request: PaginatedRequest): Promise<PaginatedResult<PlannerTask>> {
        return apiService.get<PaginatedResult<PlannerTask>>("issues", {
            params: request.toParams(),
        }).then(x => x.data);
    }

    createIssue(values: any): Promise<PlannerTask> {
        return apiService.post<PlannerTask>("issues", values).then(x => x.data);
    }

    getIssue(id: number): Promise<PlannerTask> {
        return apiService.get<PlannerTask>("issues/" + id).then(x => x.data);
    }

    getComments(id: number): Promise<PaginatedResult<PlannerTaskComment>> {
        return apiService.get<PaginatedResult<PlannerTaskComment>>("issues/" + id + "/comments").then(x => x.data);
    }

    addComment(id: number, comment: string): Promise<PaginatedResult<PlannerTaskComment>> {
        return apiService.post<PaginatedResult<PlannerTaskComment>>("issues/" + id + "/comments", {comment}).then(x => x.data);
    }

    getDepartments(): Promise<PlannerDepartment[]> {
        return apiService.get<PlannerDepartment[]>("issues/departments").then(x => x.data);
    }
}

export const issuesService = new IssuesService();
