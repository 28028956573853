import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "./febest_favicon.svg";
import { ReactComponent as UAIcon } from "./febest_ua.svg";
import "./with-image-item.scss";
interface NavigationItemWithImgProps {
    path: string;
    branch: string;
}

export const NavigationItemWithImg: React.FC<NavigationItemWithImgProps> = ({ path, branch }) => {
    return (
        <>
            <Link
                to={path}
                className={
                    branch === "fed" || branch === "fbg" || branch === "ged"
                        ? "page_header_container-link logo-link hover-white"
                        : "page_header_container-link logo-link"
                }
            >
                <div style={{ paddingTop: 5 }}>
                    {branch === "fed" || branch === "fbg" || branch === "ged" ? (
                        <UAIcon width={25} />
                    ) : (
                        <Icon width={25} />
                    )}
                    {/* <img src={img} alt={'FMS Logo'}/> */}
                </div>
            </Link>
        </>
    );
};
