import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Space} from "antd";
import {CreditInvoiceStore, CreditInvoiceStoreContext} from "./invoice.store";
import {CreditInvoiceController, CreditInvoiceControllerContext} from "./invoice.controller";
import {CreditInvoiceHeader} from "./invoice-header/invoice.header";
import {CreditInvoiceCredentials} from "./invoice-credentials/invoice.credentials";
import {CreditInvoiceDetails} from "./invoice-details/invoice.details";
import {creditInvoicesService} from "../../../services/invoices/credit.invoices.service";

export const CreditInvoice: React.FC = () => {
    const {id} = useParams<{id: string}>();
    const [store] = useState(() => new CreditInvoiceStore());
    const [controller] = useState(() => new CreditInvoiceController(id, store, creditInvoicesService));

    return (
        <CreditInvoiceStoreContext.Provider value={store}>
            <CreditInvoiceControllerContext.Provider value={controller}>
                <div className="page_content invoice">
                    <Space direction="vertical" size={[30, 30]} className="content">
                        <CreditInvoiceHeader invoiceId={id}/>
                        <CreditInvoiceCredentials/>
                        <CreditInvoiceDetails/>
                    </Space>
                </div>
            </CreditInvoiceControllerContext.Provider>
        </CreditInvoiceStoreContext.Provider>
    );
};
