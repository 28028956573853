import {action, computed, makeObservable, observable} from "mobx";
import React, {Context} from "react";
import {i18n} from "i18next";
import {Country} from "../../models/country/country";
// import {languagesService} from "../../services/languages/languages.service";
// import {countriesService} from "../../services/countries/countries.service";
// import {i18n} from "i18next";




export class LangStore {

    public i18n?: i18n;

    private _countriesInitialized: boolean = false;

    @observable
    private _locales: string[] = [];

    @observable
    private _countries: Country[] = [];

    constructor() {
        makeObservable(this);
    }

    public set countriesInitialized(value: boolean) {
        this._countriesInitialized = value;
    }
    public get countriesInitialized(): boolean {
        return this._countriesInitialized;
    }


    @computed
    public get locales(): string[] {
        return this._locales;
    }

    @action
    public setLocales(locales: string[]): void {
        this._locales = locales;
    }

    @computed
    public get countries(): Country[] {
        return this._countries;
    }

    @action
    public setCountries(countries: Country[]) {
        this._countries = countries;
    }

    @action
    public setI18n(value: i18n): void {
        this.i18n = value;
    }
}

export const langStoreContext = React.createContext<null | LangStore>(null) as Context<LangStore>;
