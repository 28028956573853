import React from "react";
import {Button, Col, Row, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./claims.create.select-type.scss"

export const ClaimsCreateSelectType: React.FC = () => {

    const { t } = useTranslation("translation", { useSuspense: false });

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Row justify="space-between" align="middle">
                    <Typography.Title level={1} className="main-title">
                        {t("CLAIMS.CREATE.SELECT_TYPE")}
                    </Typography.Title>
                </Row>
                <Row gutter={[10, 10]}>
                    <Col span={12}>
                        <Link to={"/claims/new/return"}>
                            <Button block className={"claim-btn return"} icon={<FontAwesomeIcon icon="undo" />}>
                                {t("CLAIMS.CLAIM.TYPE.RETURN")}
                            </Button>
                        </Link>
                    </Col>
                    <Col span={12}>
                        <Link to={"/claims/new/reclamation"}>
                            <Button block className={"claim-btn reclamation"} icon={<FontAwesomeIcon icon="exclamation-triangle" />}>
                                {t("CLAIMS.CLAIM.TYPE.RECLAMATION")}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Space>
        </div>
    )
}