import React, {useState} from "react";
import "./catalog.product.cart.add-to-wishlist.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CatalogArticle} from "../../../../../models/articles/catalog/search";
import {articlesService} from "../../../../../services/articles/articles.service";
import {notifications} from "../../../../../services/notifications/notifications";

interface CatalogProductCartProductAddToWishlistProps {
    article: CatalogArticle;
}

export const CatalogProductCartProductAddToWishlist: React.FC<CatalogProductCartProductAddToWishlistProps> = ({article}) => {

    const [inWishlist, setInWishlist] = useState(article.inWishList);

    const onClick = () => {
        setInWishlist(inWishlist => {
            if (inWishlist) {
                articlesService
                    .removeFromWishList(article.id)
                    .then(() => notifications.successfully())
            } else {
                articlesService
                    .addToWishList(article.id)
                    .then(() => notifications.successfully())
            }
            return !inWishlist;
        });
    }

    return (
        <div
            className={"catalog-product-add-to-wishlist" + (inWishlist ? ' in-list' : '')}
            onClick={onClick}
        >
            {inWishlist ? (
                <>In with list</>
            ) : (
                <>Add to wish list</>
            )}

            &nbsp;&nbsp;
            <FontAwesomeIcon
                className="warehouses_col-icon"
                icon={["fas", "star"]}
            />
        </div>
    )
}