import {apiService} from "../api.service";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {
    AddToCartArticleData,
    Article,
    ArticleIdentification,
    SearchedAnalogue,
    SearchedArticle
} from "../../models/articles/article";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {Manufacturer} from "../../models/articles/manufacturers/manufacturer";
import {Group} from "../../models/articles/groups/group";
import {Model} from "../../models/articles/models/model";
import {Submodel} from "../../models/articles/submodels/submodel";
import {Subgroup} from "../../models/articles/subgroup/subgroup";
import {ArticleVehicle} from "../../models/articles/vehicle/vehicle";
import {CatalogArticle} from "../../models/articles/catalog/search";

export class ArticlesService {

    public async getArticle(id: string): Promise<Article> {
        return (await apiService.get<Article>("articles/" + id)).data;
    }
    public async getArticleVehicles(id: string): Promise<ArticleVehicle[]> {
        return (await apiService.get<ArticleVehicle[]>("articles/" + id + "/vehicles")).data;
    }


    public async getManufacturers(): Promise<Manufacturer[]> {
        return (await apiService.get<Manufacturer[]>("vehicle/manufacturers")).data;
    }

    public async getManufacturerModels(manufacturerId: number): Promise<Model[]> {
        return (await apiService.get<Model[]>("vehicle/model/manufacturer/" + manufacturerId)).data;
    }

    public async getSubmodels(modelId: number): Promise<Submodel[]> {
        return (await apiService.get<Submodel[]>("vehicle/engine/model/" + modelId)).data;
    }

    public async getGroups(): Promise<Group[]> {
        return (await apiService.get<Group[]>("article/groups")).data;
    }

    public async getSubgroups(groupId: number): Promise<Subgroup[]> {
        return (await apiService.get<Subgroup[]>("article/group/" + groupId)).data;
    }

    public async searchArticles(value: string, hidden: boolean = false): Promise<ArticleIdentification[]> {
        return (await apiService.get<ArticleIdentification[]>(
            "articles/simple-search",
            {
                params: {
                    value,
                    hidden
                }
            }
        )).data
    }


    public async shortSearch(value: string): Promise<SearchedArticle[]> {
        return (await apiService.get<SearchedArticle[]>(
            "articles/short-search",
            {
                params: {
                    "filter[value]": value
                }
            }
        )).data
    }

    public async strictSearch(value: string): Promise<CatalogArticle[]> {
        return (await apiService.get<CatalogArticle[]>(
            "articles/strict-search", {
                params: {
                    value: value
                }
            }
        )).data;
    }

    public async search(request: PaginatedRequest): Promise<PaginatedResult<CatalogArticle>> {
        return (await apiService.get<PaginatedResult<CatalogArticle>>(
            "articles/search",
            {
                params: request.toParams()
            }
        )).data;
    }

    public async handleArticleFound(value: string): Promise<void> {
        await apiService.post("articles/" + value + "/found");
    }

    public async getAnalogue(id: string): Promise<SearchedAnalogue> {
        return (await apiService.get<SearchedAnalogue>("articles/analogues/" + id)).data;
    }

    public async addToWishList(id: number): Promise<void> {
        await apiService.post("cart/wishlist", {article: id});
    }

    public async removeFromWishList(id: number): Promise<void> {
        await apiService.delete("cart/wishlist/" + id);
    }

    public async addToCart(data: AddToCartArticleData): Promise<void> {
        await apiService.post("cart", {...data});
    }

    public async removeFromCart(id: number): Promise<void> {
        await apiService.delete("cart/" + id);
    }

    public async removeReservationFromCart(id: number): Promise<void> {
        await apiService.delete("cart/reservation/" + id);
    }
}
export const articlesService = new ArticlesService();
