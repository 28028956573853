import React, {useContext, useState, Suspense} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Router} from "react-router-dom";
import {Navigation} from "../navigation/navigation";

import {Layout, Row, Space, Spin, Typography} from "antd";
import {routers} from "../../routers/routers";

import {RootStore, RootStoreContext} from "../../stores/root/root";
import {RootControllers, RootControllersContext} from "../../controllers/root/root.controllers";
import {ExtendedSwitch} from "../core/route/extended.switch";
import ErrorBoundary from "../core/commons/error-boundary/error.boundary";
import history from "../../utils/history";
import "./app.scss";
import "antd/dist/antd.css";
import "../../utils/icons";
import {initInterceptors} from "../../utils/interceptors";
import {ClosedComponent} from "../closed/closed.component";
import {VersionChecker} from "../core/commons/version-checker/version-checker";
import {initializeAnnouncements} from "../../utils/announcements";
import {notifications} from "../../services/notifications/notifications";
import {Confirmation} from "../core/commons/confirmation/confirmation";


export const MainContainer: React.FC = observer(() => {
    const authStore = useContext(RootStoreContext).authStore;
    const {ready} = useTranslation('translation', {useSuspense: false});

    if (!ready || !authStore.inited) {
        return (
            <Row justify="center" align="middle" style={{width: "100vw", height: "100vh"}}>
                <Spin/>
            </Row>
        )
    }

    if (authStore.isLoggedIn && authStore.closed) {
        return <ClosedComponent />;
    }

    return (
        <Router history={history}>
            {authStore.isLoggedIn ? <Navigation/> : null}
            <Layout.Content>
                <div className="page_content_container">
                    <VersionChecker />
                    <Confirmation />
                    <ExtendedSwitch routers={routers}/>
                </div>
            </Layout.Content>
            {authStore.isLoggedIn ? (
                <footer className="page_footer">
                    <Typography.Text>© FEBEST Europe Distribution {new Date().getFullYear()}</Typography.Text>
                </footer>
            ) : null}
        </Router>
    );
})


const App: React.FC = () => {
    const {t, i18n} = useTranslation('translation', {useSuspense: false});
    const [rootStore] = useState(() => new RootStore());
    const [controllers] = useState(() => {
        initializeAnnouncements(t);
        initInterceptors(
            rootStore,
            () => t("FROM.ERROR.SERVER_ERROR"),
            () => t("ERROR"),
            () => t("ACCESS_FORBIDDEN_ERROR")
        );

        return new RootControllers(rootStore, i18n);
    });

    notifications.init(t);

    return (
        <RootStoreContext.Provider value={rootStore}>
            <RootControllersContext.Provider value={controllers}>
                <Suspense fallback={
                    <Space direction={"horizontal"} align={"center"}
                           style={{height: "100vh", width: "100vw", justifyContent: "center"}}>
                        <Spin/>
                    </Space>
                }>
                    <ErrorBoundary>
                        <MainContainer/>
                    </ErrorBoundary>
                </Suspense>
            </RootControllersContext.Provider>
        </RootStoreContext.Provider>
    );
}

export default App;
