import React from "react";
import ReactMarkdown from "react-markdown";
import {RenderHeader} from "./render/render-header";
import {RenderParagraph} from "./render/render-paragraph";
import {RenderCode} from "./render/render-code";
import './markdown-viewer.scss';
import remarkGfm from 'remark-gfm';
import remarkHint from 'remark-hint';
import remarkBreaks from 'remark-breaks';

export const MarkdownViewer: React.FC<any> = ({children}) => {
    return (
        <ReactMarkdown
            className={"markdown-viewer"}
            remarkPlugins={[remarkGfm, remarkHint, remarkBreaks]}
            components={{
                h1: RenderHeader, h2: RenderHeader, h3: RenderHeader, h4: RenderHeader, h5: RenderHeader, h6: RenderHeader,
                p: RenderParagraph,
                code: RenderCode
            }}>
            {children}
        </ReactMarkdown>
    )
}