import React, {Context} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {OrderComment, OrderData} from "../../../models/orders/order-data/order.data";

export class OrderDataStore {
    @observable
    private _loading: boolean = false;

    @observable
    private _orderData: OrderData | null = null;

    @observable
    private _orderComments: OrderComment[] = [];

    @observable
    private _invoiceToExport: number | null = null;

    @observable
    private _invoiceExportModalShown: boolean = false;

    constructor() {
        makeObservable(this);
    }

    private set _exportModalShown(value: boolean) {
        this._invoiceExportModalShown = value;
    }

    private set loading(value: boolean) {
        this._loading = value
    }

    private set _order(data: OrderData | null) {
        this._orderData = data;
    }

    private set _comments(value: OrderComment[]) {
        this._orderComments = value;
    }

    private set _exportInvoice(value: null | number) {
        this._invoiceToExport = value;
    }

    @computed
    public get exportModalShown(): boolean {
        return this._invoiceExportModalShown;
    }

    @computed
    public get exportInvoice(): number | null {
        return this._invoiceToExport;
    }

    @computed
    public get order(): OrderData | null {
        return this._orderData;
    }

    @computed
    public get comments(): OrderComment[] {
        return this._orderComments;
    }

    @computed
    public get isLoading(): boolean {
        return this._loading;
    }

    @action
    public setExportModalShown(value: boolean): void {
        this._exportModalShown = value;
    }

    @action
    public setExportInvoice(value: null | number): void {
        this._exportInvoice = value;
    }

    @action
    public setOrderData(data: OrderData | null): void {
        this._order = data;
    }

    @action
    public setOrderComments(data: OrderComment[]): void {
        this._comments = data;
    }

    @action
    public addComment(item: OrderComment): void {
        this._comments = [...this._orderComments, item];
    }

    @action
    public setLoading(value: boolean): void {
        this.loading = value;
    }
}


export const OrderDataStoreContext = React.createContext<null | OrderDataStore>(null) as Context<OrderDataStore>;
