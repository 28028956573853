import React, { CSSProperties, LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { Button, InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import "./catalog.card.popover.scss";
import {InCart} from "../../../models/articles/article";

interface ArticleCardPopupProps {
    article: InCart;
    style?: CSSProperties;
    addToCart: any;
    deleteFromCart: any;
    close: () => void;
    inCartQty: number;
}

export const ArticleCardPopup: React.FC<ArticleCardPopupProps> = observer(
    ({ article, close, addToCart, deleteFromCart, style, inCartQty }) => {

        const [quantity, setQuantity] = useState<number>(inCartQty ? inCartQty : 0);
        const popupRef: LegacyRef<HTMLDivElement> | undefined = React.createRef();
        const removeTimerRef = useRef<any>(null);
        const addTimerRef = useRef<any>(null);
        const inputValueTimerRef = useRef<any>(null);

        useEffect(() => {
            if (quantity === 0) {
                setQuantity(article.multiple);
                addToCart(article, article.multiple).client();
                addToCart(article, article.multiple).back();
            }
        }, []);

        useEffect(() => {
            const closePopupHandler = (event: MouseEvent) => {
                const target = event.target as HTMLElement;
                if (!popupRef?.current?.contains(target)) {
                    close();
                    event.stopPropagation();
                }
            };

            document.addEventListener("mousedown", closePopupHandler);

            return () => {
                document.removeEventListener("mousedown", closePopupHandler);
            };
        });

        const onAdd = useCallback(async () => {
            if (addTimerRef.current) {
                clearTimeout(addTimerRef.current);
            }
            const newQuantity = quantity + article.multiple;
            setQuantity(newQuantity);
            addToCart(article, newQuantity).client();
            addTimerRef.current = setTimeout(async () => {
                await addToCart(article, newQuantity).back();
            }, 500);
        }, [addToCart, article, quantity]);

        const onRemove = useCallback(async () => {
            if (removeTimerRef.current) {
                clearTimeout(removeTimerRef.current);
            }
            const newQuantity = quantity - article.multiple;
            setQuantity(newQuantity);
            if (newQuantity <= 0) {
                close();
                deleteFromCart(article).client();
                removeTimerRef.current = setTimeout(async () => {
                    await deleteFromCart(article).back();
                }, 500);
            } else {
                addToCart(article, newQuantity).client();
                removeTimerRef.current = setTimeout(() => {
                    addToCart(article, newQuantity).back();
                }, 500);
            }
        }, [addToCart, article, close, deleteFromCart, quantity]);

        const onChange = useCallback(
            (value: any) => {
                if (inputValueTimerRef.current) {
                    clearTimeout(inputValueTimerRef.current);
                }
                setQuantity(value);
                if (value === 0) {
                    close();
                    deleteFromCart(article).client();
                    inputValueTimerRef.current = setTimeout(async () => {
                        await deleteFromCart(article).back();
                    }, 500);
                } else {
                    addToCart(article, value).client();
                    inputValueTimerRef.current = setTimeout(async () => {
                        await addToCart(article, value).back();
                    }, 500);
                }
            },
            [addToCart, article, close, deleteFromCart]
        );

        return (
            <div className={"catalog_popover"} ref={popupRef} style={style}>
                <Button
                    className="popup-button ws_button catalog_button"
                    icon={<FontAwesomeIcon icon={["fas", "minus"]} />}
                    onClick={onRemove}
                />
                <InputNumber
                    value={quantity}
                    onChange={onChange}
                    className="catalog_content_card_quantity"
                    stringMode={false}
                    bordered={false}
                    type={"number"}
                    min={0}
                    style={{ width: "38px" }}
                />
                <Button
                    className="popup-button ws_button catalog_button"
                    icon={<FontAwesomeIcon icon={["fas", "plus"]} />}
                    onClick={onAdd}
                />
            </div>
        );
    }
);
