import React from "react";
import {List} from "antd";
import {CatalogArticle} from "../../../../../models/articles/catalog/search";
import {CatalogProductCart} from "../../../product/cart/catalog.product.cart";
import {Pagination} from "../../../../../models/requests/paginated-result/paginated.result";

interface CatalogCartsViewItemsBlockProps {
    items: CatalogArticle[];
    pagination?: Pagination;
    onPageChange?: (page: number, size: number) => void;
}

export const CatalogCartsViewItemsBlock: React.FC<CatalogCartsViewItemsBlockProps> = ({items, pagination, onPageChange}) => {

    return (
        <>
            {items.length ? (
                <List
                    className="catalog_content_list"
                    itemLayout="horizontal"
                    dataSource={items}
                    renderItem={item => (
                        <CatalogProductCart article={item} />
                    )}
                    pagination={pagination ? {
                        position: "bottom",
                        total: pagination.total,
                        current: pagination.page,
                        pageSize: pagination.limit,
                        onChange: onPageChange,
                    } : undefined}
                />
            ) : null}
        </>
    )
}