import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Modal} from "antd";
import {RootStoreContext} from "../../../../stores/root/root";

export const Confirmation: React.FC = observer(() => {

    const {confirmStore} = useContext(RootStoreContext)

    if (!confirmStore.shown) {
        return null;
    }

    return (
        <Modal
            title={"Warning"}
            visible
            onOk={() => confirmStore.success()}
            onCancel={() => confirmStore.close()}
            okButtonProps={{disabled: confirmStore.disabled}}
            cancelButtonProps={{disabled: confirmStore.disabled}}
        >
            {confirmStore.message}. Continue?
        </Modal>
    )
});