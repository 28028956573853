import React, {useState} from "react";
import {Button, Input, Space} from "antd";

interface SelectQtyProps {
    maxQty: number;
    onSelect: (value: number) => void;
}

export const SelectQty: React.FC<SelectQtyProps> = ({onSelect, maxQty}) => {

    const [value, setValue] = useState<number>(0);

    const updateValue = (value: string) => {
        setValue(Math.min(parseInt(value), maxQty))
    }

    return (
        <Space size={[10, 10]}>
            <Input
                value={value || ""}
                onChange={v => updateValue(v.target.value)}
            />
            <Button
                type={"primary"}
                disabled={!value}
                onClick={() => onSelect(value)}
            >
                Select
            </Button>
        </Space>
    )
}