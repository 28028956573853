import React, { useState } from "react";
import { Button, Dropdown, Menu, Skeleton, Space, Typography } from "antd";
import { useRequiredLayers } from "../../../../utils/context";
import { useTranslation } from "react-i18next";
import { Export } from "../../../core/commons/export/export";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import {CreditInvoiceControllerContext} from "../invoice.controller";
import {CreditInvoiceStoreContext} from "../invoice.store";

interface InvoiceHeaderProps {
    invoiceId: string;
}

export const CreditInvoiceHeader: React.FC<InvoiceHeaderProps> = observer(({ invoiceId }) => {
    const { store } = useRequiredLayers(CreditInvoiceStoreContext, CreditInvoiceControllerContext);
    const { t } = useTranslation("translation", { useSuspense: false });
    const [exportModalShown, setExportModalShown] = useState<boolean>(false);

    return (
        <>
            <Space direction="vertical" size={[50, 30]} style={{ width: "100%" }}>
                {store.loading ? (
                    <Skeleton active paragraph={{ rows: 1 }} title={false} />
                ) : (
                    <Typography.Title level={1} className="main-title">
                        {t("INVOICES.CREDIT.TITLE")} #{invoiceId}
                    </Typography.Title>
                )}
                {store.loading ? (
                    <Space wrap direction="horizontal" size={[10, 5]}>
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                    </Space>
                ) : (
                    <Space wrap direction={"horizontal"} size={[10, 5]}>
                        <Dropdown
                            placement={"bottomRight"}
                            trigger={["click"]}
                            overlay={
                                <Menu style={{ textAlign: "left" }}>
                                    <Menu.Item key={1}>
                                        <a
                                            style={{ textAlign: "left" }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={"ant-btn ant-btn-link"}
                                            href={"/documents/" + store.invoice!.id}
                                        >
                                            {t("DOCUMENTS.FILES.SHOW_PDF")}
                                        </a>
                                    </Menu.Item>
                                    {/*<Menu.Item key={2} onClick={() => setExportModalShown(true)}>*/}
                                    {/*    {t("DOWNLOAD.CSV_EXCEL")}*/}
                                    {/*</Menu.Item>*/}
                                </Menu>
                            }
                        >
                            <Button> {t("ORDERS.ORDER.VIEW.INVOICE")}</Button>
                        </Dropdown>
                    </Space>
                )}
            </Space>
            {store.invoice ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={exportModalShown}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/documents/invoices/" + store.invoice!.id,
                    }}
                    onClose={() => setExportModalShown(false)}
                />
            ) : null}
        </>
    );
});
