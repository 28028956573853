import React, {useEffect, useState} from "react";
import {WidgetProps} from "../../../../../core/ui/widget/widget";
import {useTranslation} from "react-i18next";
import {Select} from "antd";
import {articlesService} from "../../../../../../services/articles/articles.service";
import {Model} from "../../../../../../models/articles/models/model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface UIInputWidgetModelProps extends WidgetProps {
    manufacturerId: number;
    onClear?: () => void;
    onSelect?: () => void;
}

export const UIInputWidgetModel: React.FC<UIInputWidgetModelProps> = ({value, onChange, multiple, manufacturerId, onClear, onSelect}) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const [values, setValues] = useState<Model[]>([]);

    useEffect(() => {
        if (manufacturerId) {
            articlesService.getManufacturerModels(manufacturerId).then(values => setValues(values))
        } else {
            setValues([])
        }
    }, [manufacturerId])

    return (
        <Select
            suffixIcon={<FontAwesomeIcon icon={["fas", "angle-down"]} />}
            bordered={false}
            className="selects_block_select"
            placeholder={t("ARTICLES.MODEL")}
            dropdownMatchSelectWidth={300}
            showSearch
            optionFilterProp={"children"}
            onSelect={onSelect}
            onClear={onClear}
            allowClear
            value={value}
            onChange={onChange}
            disabled={!manufacturerId}
        >
            {values.map(({id, fullName}) => (
                <Select.Option key={id} value={id}>{fullName}</Select.Option>
            ))}
        </Select>
    )
}