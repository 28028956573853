import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Date} from "../../core/commons/date/date";
import {useParams} from "react-router-dom";
import {IdParams} from "../../../models/core/route-params/id-params/id.params";
import {Announcement} from "../../../models/announcements/announcement";
import {announcementsService} from "../../../services/announcements/announcements.service";
import {Space, Typography} from "antd";
import {useTranslation} from "react-i18next";

export const AnnouncementView: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const {id} = useParams<IdParams>();

    const [announcement, setAnnouncement] = useState<Announcement|null>(null);

    useEffect(() => {
        announcementsService.getAnnouncement(parseInt(id)).then(announcement => setAnnouncement(announcement));
    }, [id]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            announcementsService.markViewed(parseInt(id));
        }, 3000);

        return () => {
            clearTimeout(timeout);
        }
    }, [id]);

    if (!announcement) {
        return null;
    }

    return (
        <div className="page_content">
            <Space direction="vertical" size={[10, 10]} className="content">
                <Typography.Title level={1} className="main-title">{t("ANNOUNCEMENTS.TITLE")}</Typography.Title>
                <div className={"announcement"}>
                    <div className={"announcement-title"}>
                        <h2>{announcement.shortMessage}</h2>
                    </div>
                    <div className={"announcement-date"}>
                        <Date dateString={announcement.createdAt} showTime={true} />
                    </div>
                    <div className={"announcement-text"}>
                        {announcement.message.split('\n').map(function(item, key) {
                            return (
                                <span key={key}>
                                    {item}
                                    <br/>
                                </span>
                            )
                        })}
                    </div>
                </div>
            </Space>
        </div>
    );
}