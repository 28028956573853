import React, {useEffect} from "react";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {RouteTabs} from "../../core/commons/route-tabs/route.tabs";
import {Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";

export const AnnouncementsList: React.FC<ComponentPropsFromRoute> = ({name, routeChildren}) => {

    const location = useLocation();
    const history = useHistory();
    const {t} = useTranslation('translation', {useSuspense: false});

    useEffect(() => {
        if (location.pathname === '/announcements') {
            history.push('/announcements/relevant');
        }
    }, [location.pathname])

    return (
        <>
            <div className="page_content">
                <Space direction="vertical" size={[20, 20]} className="content">
                    <Typography.Title level={1} className="main-title">{t("ANNOUNCEMENTS.TITLE")}</Typography.Title>
                    <RouteTabs routes={routeChildren || []} />
                </Space>
            </div>
        </>
    );
}