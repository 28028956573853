import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Descriptions, Row, Space, Typography} from "antd";
import {ApplicationsStatus} from "../common/status/application.status";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {useTranslation} from "react-i18next";
import {Application} from "../../../../models/production/application";
import {productionService} from "../../../../services/production/production.service";
import {Date} from "../../../core/commons/date/date";

export const ProductionRequestView: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const {t} = useTranslation("translation", {useSuspense: false});

    const { id } = useParams<{ id: string }>();

    const [application, setApplication] = useState<Application | null>(null);

    const reload = async () => {
        setApplication(null);
        await productionService.getApplication(parseInt(id!)).then(app => setApplication(app));
    }

    useEffect(() => {
        reload();
    }, [id]);

    if (!application) {
        return <></>
    }

    return <>
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Row justify="space-between" align="middle">
                    <Typography.Title level={1} className="main-title">
                        {t("PRODUCTION_REQUEST.VIEW.TITLE")} #{application.id}
                    </Typography.Title>
                    <ApplicationsStatus status={application.status} large/>
                </Row>
                <Space direction={"vertical"} size={[50, 50]} style={{marginTop: 20}}>
                    <div className="data-descriptions-with-edit-wrapper">
                        <div className="data-content-wrapper">
                            <Descriptions
                                className="data-content-wrapper-descriptions"
                                size={"small"}
                                layout={"vertical"}
                                contentStyle={{fontSize: "17px"}}
                                labelStyle={{color: "#858585"}}
                                column={{
                                    xxl: 4,
                                    xl: 4,
                                    lg: 4,
                                    md: 3,
                                    sm: 2,
                                    xs: 1,
                                }}
                            >
                                <Descriptions.Item label={t("USER.TITLE")}>
                                    {application.user.firstName} {application.user.lastName}
                                </Descriptions.Item>
                                <Descriptions.Item label={t("DATE")}>
                                    <Date dateString={application.createdAt} showTime/>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                    <Descriptions
                        layout={"horizontal"}
                        bordered
                        column={1}
                        size={"middle"}
                        title={"Application Data"}
                        labelStyle={{width: '20%'}}
                    >
                        <Descriptions.Item label={t("TYPE")}>
                            {application.type}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("PRODUCTION_REQUEST.FIELD.OE_NUMBER.TITLE")}>
                            {application.OENumber}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("PRODUCTION_REQUEST.FIELD.DESCRIPTION.TITLE")}>
                            {application.description}
                        </Descriptions.Item>
                        {application.type === 'offer' ? (
                            <Descriptions.Item label={t("PRODUCTION_REQUEST.FIELD.JUSTIFICATION.TITLE")}>
                                {application.justification}
                            </Descriptions.Item>
                        ) : null}
                        <Descriptions.Item label={t("PRODUCTION_REQUEST.FIELD.QTY.TITLE")}>
                            {application.qty}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("PRODUCTION_REQUEST.FIELD.PRICE.TITLE")}>
                            {application.price}
                        </Descriptions.Item>
                    </Descriptions>
                </Space>
            </Space>
        </div>
    </>
}