import React from "react";

import { ReactComponent as IconGER } from "../navigation-icons/flags/Flag_of_Germany.svg";
import { ReactComponent as IconEST } from "../navigation-icons/flags/Flag_of_Estonia.svg";
import { ReactComponent as IconBGR } from "../navigation-icons/flags/Flag_of_Bulgaria.svg";

interface NavigationFlagIconProsp {
    branch: string;
}
const allowedBranches = ["fed", "ged", "fbg"];
export const NavigationFlagIcon: React.FC<NavigationFlagIconProsp> = ({ branch }) => {
    if (!allowedBranches.includes(branch)) {
        return null;
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 10 }}>
            {branch === "fed" ? (
                <IconEST width="25" height="25" />
            ) : branch === "fbg" ? (
                <IconBGR width="25" height="25" />
            ) : branch === "ged" ? (
                <IconGER width="25" height="25" />
            ) : null}
        </div>
    );
};
