import React, {useMemo, useState} from "react";
import {
    Button, Divider, Row,
    Space, Table,
    Typography
} from "antd";
import {useTranslation} from "react-i18next";
import {ClaimType, CreateClaimItem} from "../../../models/claims/claims";
import i18n from "i18next";
import {columnsFactory} from "./claims.create.columns";
import {ClaimsCreateModal} from "./claims.create.modal";
import {claimService} from "../../../services/claims/claims.service";
import {useHistory} from "react-router";
import Dragger from "antd/es/upload/Dragger";
import * as CSV from 'csv-string';
import {useParams} from "react-router-dom";
import moment from "moment";

export const ClaimsCreate: React.FC = () => {

    const { t } = useTranslation("translation", { useSuspense: false });

    const [items, setItems] = useState<CreateClaimItem[]>([]);

    const [modalShown, setModalShown] = useState<boolean>(false);

    const history = useHistory();
    const {type}: {type: ClaimType} = useParams();

    if (-1 === Object.values(ClaimType).indexOf(type)) {
        history.push("/");
    }

    const removeItem = (item: CreateClaimItem) => {
        const newItems = [...items];
        const idx = newItems.indexOf(item);
        if (-1 !== idx) {
            newItems.splice(idx, 1);
        }

        setItems(newItems);
    }

    const insertItem = (item: CreateClaimItem) => {
        setItems([...items, item]);
    }

    const createClaim = async () => {
        const claim = await claimService.createClaim({items, type});
        history.push('/claims/' + claim.id);
    }

    const columns = useMemo(() => columnsFactory(t, removeItem), [i18n.language, items]);

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Row justify="space-between" align="middle">
                    <Typography.Title level={1} className="main-title">
                        {t("CLAIMS.CREATE.TYPE.TITLE")} {t("CLAIMS.CREATE.TYPE." + type.toUpperCase())}
                    </Typography.Title>
                </Row>

                <Button
                    type={"primary"}
                    onClick={() => setModalShown(true)}
                >
                    {t('CLAIMS.CREATE.ADD_ITEM')}
                </Button>

                <Divider plain style={{margin: 0}}>{t('CLAIMS.CREATE.CAN_UPLOAD_HELP_TEXT')}</Divider>

                <Dragger
                    accept={".csv"}
                    showUploadList={false}
                    customRequest={(data) => {
                        const file = data.file as Blob;
                        file.text().then(value => {
                            const newItems: CreateClaimItem[] = [];
                            CSV.parse(value).forEach(item => {
                                newItems.push({type: "unknown", clarification: {}, data: {sku: item[0], price: parseFloat(item[2].replace(",", ".")), qty: parseInt(item[1]), invoiceNumber: item[3], invoiceDate: moment(item[4]), description: item[5]}});
                            })
                            setItems([...items, ...newItems]);
                        })
                    }}
                >
                    <p className="ant-upload-text" style={{textAlign: "left", paddingLeft: 20}}>{t('CLAIMS.CREATE.UPLOAD.P1')}</p>
                    <p className="ant-upload-hint" style={{textAlign: "left", paddingLeft: 20}}>{t('CLAIMS.CREATE.UPLOAD.P2')}</p>
                    <p className="ant-upload-hint" style={{textAlign: "left", paddingLeft: 20}}>{t('CLAIMS.CREATE.UPLOAD.P3')}</p>
                </Dragger>

                {items.length > 0 ? (
                    <>
                        <Table
                            dataSource={items}
                            columns={columns}
                            pagination={false}
                            rowKey={"sku"}
                        />
                        <Button type={"primary"} onClick={() => createClaim()}>{t('CLAIMS.CREATE.TITLE')}</Button>
                    </>
                ) : null}

                {modalShown ? (
                    <ClaimsCreateModal
                        onClose={() => setModalShown(false)}
                        onComplete={(item) => {
                            insertItem(item);
                            setModalShown(false);
                        }}
                    />
                ) : null}
            </Space>
        </div>
    );
}
