import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useParams, useLocation, useRouteMatch} from "react-router-dom";
import {Popover, Row} from "antd";

interface NavigationItemProps {
    path: string,
    name?: string,
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    className: string,
    elementModifier?: string
    minimized?: boolean
}

export const NavigationItem: React.FC<NavigationItemProps> = (
    {
        path,
        icon,
        className = 'blue',
        name = '',
        minimized
    }) => {

    const {pathname} = useLocation();
    const {t} = useTranslation("translation", {useSuspense: false});
    const Icon = icon;
    const active = pathname.includes(path)

    if (minimized) {
        return (
            <Popover
                placement="bottom"
                content={t(name)}
                overlayClassName={"nav-item-popover"}
            >
                <Link to={path} className={active ? className + " page_header_container-link-active" : className} style={{alignItems: "center", justifyContent: "center"}}>
                    <Row justify="center" align="middle" className={'nav-img-container'}>
                        <Icon/>
                    </Row>
                </Link>
            </Popover>
        );
    }

    return (
        <Link to={path} className={active ? className + " page_header_container-link-active" : className} style={{alignItems: "center", justifyContent: "center"}}>
            <Row justify="center" align="middle" className={'nav-img-container'}>
                <Icon/>
                <span>{t(name)}</span>
            </Row>
        </Link>
    );
}
