import React, {Context} from "react";
import {CountriesService} from "../../services/countries/countries.service";
import {LocalesService} from "../../services/locales/locales.service";
import {LangStore} from "../../stores/lang/lang.store";

export class LangController {

    constructor(
        private readonly _langStore: LangStore,
        private readonly _countriesService: CountriesService,
        private readonly _localesService: LocalesService
    ) {
        this.reloadLocales();
    }
    private async reloadLocales(): Promise<void> {
        this._langStore.setLocales((await this._localesService.getLocales()).sort((a, b) => a > b ? 1 : -1));
    }
    public async loadCountries(): Promise<void> {
        if (this._langStore.countriesInitialized) {
            return;
        }
        this._langStore.countriesInitialized = true;
        this._langStore.setCountries(await this._countriesService.getCountries());
    }
}

export const LangControllerContext = React.createContext<null | LangController>(null) as Context<LangController>;
