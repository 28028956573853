import React from "react";
import {Card, Col, Row, Skeleton, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../core/commons/custom-card/custom.card";
import {observer} from "mobx-react";
import {useRequiredLayers} from "../../../../utils/context";
import {CreditInvoiceStoreContext} from "../invoice.store";
import {CreditInvoiceControllerContext} from "../invoice.controller";

export const CreditInvoiceCredentials: React.FC = observer(() => {
    const { store } = useRequiredLayers(CreditInvoiceStoreContext, CreditInvoiceControllerContext);
    const {t} = useTranslation("translation", {useSuspense: false});
    return (
        <div className="invoice_credentials">
            <Typography.Title level={3}>{t("PAYER_SELLER_DETAILS.TITLE")}</Typography.Title>
            <Row gutter={[20, 20]}>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Card
                        className="card"
                        title={
                            <Typography.Text title={t("PAYER_SELLER_DETAILS.PAYER_DETAILS")}>
                                {t("PAYER_SELLER_DETAILS.PAYER_DETAILS")}:
                            </Typography.Text>
                        }
                        bordered={false}
                        size="small"
                    >
                        {store.loading ? (
                            <Skeleton active paragraph={{rows: 5, width: "100%"}} title={false}/>
                        ) : (
                            <CustomCard
                                data={{
                                    name: store.invoice.billingAddress.companyName,
                                    address: [store.invoice.billingAddress.address, store.invoice.billingAddress.index, store.invoice.billingAddress.city, store.invoice.billingAddress.region, store.invoice.billingAddress.country.originalName].filter(item => item.trim() !== "").join(", "),
                                    vat: store.invoice.billingAddress.vat,
                                    phone: store.invoice.billingAddress.phone,
                                    email: store.invoice.billingAddress.email
                                }}
                                rowKeys={[{key: "name"}, {key: "address"}, {
                                    key: "vat",
                                    label: "VAT.TITLE"
                                }, {key: "phone"}, {key: "email"}]}
                            />

                        )}
                    </Card>
                </Col>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Card
                        className="card"
                        title={
                            <Typography.Text title={t("PAYER_SELLER_DETAILS.RECEIVER_DETAILS")}>
                                {t("PAYER_SELLER_DETAILS.RECEIVER_DETAILS")}:
                            </Typography.Text>
                        }
                        size="small"
                    >
                        {store.loading ? (
                            <Skeleton active paragraph={{rows: 5, width: "100%"}} title={false}/>
                        ) : (
                            <CustomCard
                                data={{
                                    name: store.invoice.shippingAddress.companyName,
                                    address: [store.invoice.shippingAddress.address, store.invoice.shippingAddress.index, store.invoice.shippingAddress.city, store.invoice.shippingAddress.region, store.invoice.shippingAddress.country.originalName].filter(item => item.trim() !== "").join(", "),
                                    vat: store.invoice.shippingAddress.vat,
                                    phone: store.invoice.shippingAddress.phone,
                                    email: store.invoice.shippingAddress.email
                                }}
                                rowKeys={[{key: "name"}, {key: "address"}, {
                                    key: "vat",
                                    label: "VAT.TITLE"
                                }, {key: "phone"}, {key: "email"}]}
                            />

                        )}
                    </Card>
                </Col>
                <Col className="card-col" xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Card
                        className="card"
                        title={
                            <Typography.Text title={t("PAYER_SELLER_DETAILS.SELLER_DETAILS")}>
                                {t("PAYER_SELLER_DETAILS.SELLER_DETAILS")}:
                            </Typography.Text>
                        }
                        size="small"
                    >
                        {store.loading ? (
                            <Skeleton active paragraph={{rows: 3, width: "100%"}} title={false}/>
                        ) : (
                            <CustomCard
                                data={{
                                    name: store.invoice.sellerAddress.name,
                                    vat: store.invoice.sellerAddress.vatNumber,
                                    address: [store.invoice.sellerAddress.street, store.invoice.sellerAddress.zip, store.invoice.sellerAddress.city, store.invoice.sellerAddress.country.originalName].filter(item => item.trim() !== "").join(", ")
                                }}
                                rowKeys={[{key: "name"}, {key: "vat", label: "VAT.TITLE"}, {key: "address"}]}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
});
