import {TFunction} from "i18next";
import {notification} from "antd";

export class Notifications
{
    private t?: TFunction;

    constructor() {
    }

    init(t: TFunction) {
        this.t = t;
    }

    public successfully(): void
    {
        this.message("success", "Completed successfully");
    }

    /**
     *
     * @param type
     * @param message
     */
    public message(type: "success" | "error", message: string) {
        this.t && notification[type]({message: <>{this.t(message)}</>})
    }
}

export const notifications = new Notifications();