import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {issuesService} from "../../../../../../services/issues/issues.service";
import {PlannerDepartment} from "../../../../../../models/issues/issues";
import {WidgetProps} from "../../../../../core/ui/widget/widget";

export const UIInputWidgetDepartment: React.FC<WidgetProps> = ({value, onChange, multiple}) => {

    const [departments, setDepartments] = useState<PlannerDepartment[]>([]);

    useEffect(() => {
        issuesService.getDepartments().then(departments => setDepartments(departments));
    }, [])

    return (
        <Select
            mode={multiple ? "multiple" : undefined}
            onChange={onChange as any}
            value={value as any}
            allowClear={true}
            showSearch
            optionFilterProp={"children"}
        >
            {departments.map(department => (
                <Select.Option value={department.id}>{department.name}</Select.Option>
            ))}
        </Select>
    )
}