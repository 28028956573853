import React, {useCallback, useContext, useMemo, useState} from "react";
import {StepperContext, StepperContextValue} from "../../../../core/commons/stepper/stepper";
import {CreateClaimItem} from "../../../../../models/claims/claims";
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Space, Table} from "antd";
import {DebounceSelect} from "../../../../core/commons/debounce-select/debounce.select";
import {useTranslation} from "react-i18next";
import {useForm} from "antd/es/form/Form";
import {articlesService} from "../../../../../services/articles/articles.service";
import {claimService} from "../../../../../services/claims/claims.service";
import {columnsFactory} from "./select-sku.step.columns";
import {dataFactory, TableLineData} from "./select-sku.step.data";
import {ArticleIdentification} from "../../../../../models/articles/article";

export const SelectSkuStep: React.FC = () => {

    const ctx = useContext<StepperContextValue<CreateClaimItem>>(StepperContext);

    const [customForm] = useForm();

    const { t } = useTranslation("translation", { useSuspense: false });

    const [searched, setSearched] = useState<boolean>(false);

    const [mergedInvoices, setMergedInvoices] = useState<TableLineData[]>([]);

    const [customFormShown, setCustomFormShown] = useState<boolean>(false);

    const [articlesMap, setArticlesMap] = useState<Record<number, string>>({});

    const [selectedArticleSku, setSelectedArticleSku] = useState<string>("");

    const articleFetcher = async (value: string) => {
        const articles = await articlesService.searchArticles(value, true);
        const articlesMap: Record<number, string> = {};
        articles.forEach(item => articlesMap[item.id] = item.name);
        setArticlesMap(articlesMap);
        return articles.map((item) => ({
            key: item.id,
            value: item.id,
            label: item.name + " - " + item.description,
        }));
    }

    const onSelectItem = useCallback((item: TableLineData, qty: number) => {
        ctx.data.type = "known";
        ctx.data.data = {
            sku: selectedArticleSku,
            qty: qty,
            invoice: item.invoice,
            item: item.item,
            description: ""
        }
        ctx.goNext();
    }, [ctx, selectedArticleSku]);

    const onManualItem = useCallback((values: any) => {
        ctx.data.type = "unknown";
        ctx.data.data = {
            sku: selectedArticleSku,
            description: "",
            ...values
        }
        ctx.goNext();
    }, [ctx, selectedArticleSku]);

    const onSelect = (articleId: number) => {
        setMergedInvoices([])
        setSearched(false);
        setSelectedArticleSku(articlesMap[articleId]);
        claimService
            .searchMergedInvoices(articleId)
            .then(mergedInvoices => {
                setMergedInvoices(dataFactory(mergedInvoices))
                setSearched(true);
                if (0 === mergedInvoices.length) {
                    setCustomFormShown(true);
                }
            })
    }

    const columns = useMemo(() => columnsFactory(t, onSelectItem), [onSelectItem, t])

    return (
        <>
            <Row gutter={[10, 0]} align={"middle"}>
                <Col span={24}>
                    <h2 style={{marginTop: 10}}>1. Select article</h2>
                    <Form.Item
                        name={"article"}
                        rules={[
                            {
                                required: true,
                                message: t("FROM.ERROR.PLEASE_SELECT_VALUE"),
                            },
                        ]}
                    >
                        <DebounceSelect
                            onSelect={onSelect}
                            autoFocus={true}
                            placeholder={t("ARTICLES.SEARCH")}
                            style={{ width: "100%" }}
                            fetchOptions={articleFetcher}
                            labelInValue={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {searched ? (
                <>
                    <h2 style={{marginTop: 10}}>2. Select invoice and qty</h2>
                    {!customFormShown && mergedInvoices.length ? (
                        <Table
                            columns={columns}
                            dataSource={mergedInvoices}
                            pagination={false}
                        />
                    ) : null}
                    {customFormShown ? (
                        <Space size={[10, 10]}>
                            <Form
                                form={customForm}
                                initialValues={{}}
                                layout="vertical"
                                onFinish={values => {
                                    customForm.validateFields().then(() => {
                                        onManualItem(values);
                                    })
                                }}
                            >
                                <Row gutter={10}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={t('INVOICE.NUMBER_FEBEST')}
                                            name={['invoiceNumber']}
                                            rules={[{ required: true }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={t('INVOICES.INVOICE.DATE')}
                                            name={['invoiceDate']}
                                            rules={[{ required: true }]}
                                        >
                                            <DatePicker style={{width: "100%"}} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={t('PRICE')}
                                            name={['price']}
                                            rules={[{ required: true }]}
                                        >
                                            <InputNumber style={{width: "100%"}} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={t('QUANTITY_LONG')}
                                            name={['qty']}
                                            rules={[{ required: true }]}
                                        >
                                            <InputNumber style={{width: "100%"}} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <Button
                                            type={"primary"}
                                            onClick={() => customForm.submit()}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    ) : (
                        <Space size={[10, 10]} style={{marginTop: 20}}>
                            <Button
                                type={"primary"}
                                onClick={() => setCustomFormShown(true)}>
                                Need another invoice
                            </Button>
                        </Space>
                    )}
                </>
            ) : null}
        </>
    );
}