import React, {useEffect, useState} from "react";
import {Tag} from "antd";
import {FilterItemState, FilterKey, FilterTag} from "../../../../../models/filtered-table/filtered.table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FilterPopup from "./popup/filter.popup";
import {createItemStatesFromTags, createTagsFromItemStates} from "../../../../../models/filtered-table/filter.helper";
import {useTranslation} from "react-i18next";
import "./filter.scss";

interface FilterProps {
    filterKeys: FilterKey[],
    defaultValue: FilterItemState[],
    onUpdated: (filters: FilterItemState[]) => void,
}

const Filter: React.FC<FilterProps> = ({filterKeys, onUpdated, defaultValue}) => {
    const {t} = useTranslation('translation', {useSuspense: false});
    const [isPopupShown, setIsPopupShown] = useState<boolean>(false);
    const [tags, setTags] = useState<FilterTag[]>([]);
    const [editingTag, setEditingTag] = useState<FilterTag | undefined>(undefined);

    useEffect(() => {
        setTags(createTagsFromItemStates(filterKeys, defaultValue));
        return () => {
            localStorage.removeItem("storedKeysWithOptions");
        }
    }, [JSON.stringify(defaultValue)]);

    const openPopup = (): void => {
        setIsPopupShown(true);
    }
    const closePopup = (): void => {
        setIsPopupShown(false);
    }

    const deleteTag = (tag: FilterTag): void => {
        const removeIndex = tags.indexOf(tag);
        if (removeIndex !== -1) {
            const newTags = [...tags];
            newTags.splice(removeIndex, 1);
            setTags(newTags);
            onUpdated(createItemStatesFromTags(newTags));
        }
    }

    const onTagEdit = (tag: FilterTag): void => {
        setEditingTag(tag);
        openPopup();
    }

    const onRemove = (): void => {
        setEditingTag(undefined)
    }

    return (
        <>
            <div className="filter-wrapper" onClick={openPopup}>
                <FontAwesomeIcon icon={['fas', 'filter']} size={'sm'}
                                 color={'rgba(0,0,0, 0.3)'}
                                 style={{marginRight: '10px', marginLeft: '4px'}}
                />
                { tags.length > 0
                    ? tags.map((tag, index) => (
                        <Tag
                            key={tag.label}
                            closable
                            onClick={() => onTagEdit(tag)}
                            onClose={() => deleteTag(tag)}
                            style={{cursor: "pointer"}}
                            color={(tag === editingTag ? '#f93822' : '#108ee9')}
                        >
                            {tag.label}
                        </Tag>
                    ))
                    : <span className={'filterPlaceHolder'}>{t("FILTER.ADD")}</span>
                }
                {
                    isPopupShown ? (
                        <FilterPopup
                            filter={filterKeys.filter(item => !tags.filter(item => editingTag !== item).map(tag => tag.key).includes(item.key))}
                            onAddCallback={(tag) => {
                                const newTags = [...tags];
                                if (editingTag) {
                                    const editingTagIndex = newTags.indexOf(editingTag);
                                    if (editingTagIndex !== -1) {
                                        newTags[editingTagIndex] = tag;
                                    }
                                } else {
                                    newTags.push(tag);
                                }
                                setIsPopupShown(false);
                                setTags(newTags);
                                onUpdated(createItemStatesFromTags(newTags));
                            }}
                            close={closePopup}
                            editingTag={editingTag}
                            removeEditingTag={onRemove}
                        />
                    ) : null
                }
            </div>
        </>
    );
}

export default Filter;
