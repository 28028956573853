import {ColumnsType} from "antd/lib/table";
import {Link} from "react-router-dom";
import {Amount} from "../../../models/amount/amount";
import {displayPrice} from "../../../models/amount/amount.helper";
import {Date} from "../../core/commons/date/date";
import React from "react";
import {TFunction} from "react-i18next";
import {CreditInvoiceTableItem} from "../../../models/invoices/credit-invoice/credit.invoice";

export const columnsFactory = (t: TFunction): ColumnsType<CreditInvoiceTableItem> => [
    {
        title: t("INVOICES.NUMBER"),
        dataIndex: "docNumber",
        key: "merged_invoices_docNumber",
        render: (number: string, row) => (
            <Link className="link no-wrap" to={"/credit-invoices/" + row.id}>
                {number}
            </Link>
        ),
    },
    {
        title: t("TOTAL_AMOUNT"),
        dataIndex: "grandTotal",
        key: "merged_invoices_grandTotal",
        render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
    },
    {
        title: t("TOTAL_PRICE"),
        dataIndex: "subtotal",
        key: "merged_invoices_subtotal",
        render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
    },
    {
        title: "VAT",
        dataIndex: "subtotalVat",
        key: "merged_invoices_subtotalVat",
        render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
    },
    {
        title: t("DATE"),
        dataIndex: "date",
        key: "merged_invoices_date",
        render: (date: string) => <Date dateString={date} showTime />,
    },
    {
        title: t("CREATED_AT"),
        dataIndex: "createdAt",
        key: "merged_invoices_createdAt",
        render: (date: string) => <Date dateString={date} showTime />,
    },
];