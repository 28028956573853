import API from "./api";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ErrorResponseData } from "../models/core/response";
import { notification } from "antd";
import { RootStore } from "../stores/root/root";
import history from "./history";
import {version} from "../version";

export const initInterceptors = (
    rootStore: RootStore,
    errorMessageGetter: () => string,
    errorTitleGetter: () => string,
    accessForbiddenErrorMessageGetter: () => string
): void => {
    initMessagesInterceptor(errorTitleGetter);
    initConfirmMessagesInterceptor(rootStore);
    initServerErrorInterceptor(errorMessageGetter, errorTitleGetter);
    initAccessForbiddenErrorInterceptor(accessForbiddenErrorMessageGetter, errorTitleGetter);
    initLocaleHeaderInterceptor(rootStore);
    initCurrencyCodeHeaderInterceptor(rootStore);
    initNotFoundInterceptor();
    initVersionInterceptor(rootStore);
};

const initMessagesInterceptor = (errorTitleGetter: () => string): void => {
    API.interceptors.response.use(
        (value: AxiosResponse): AxiosResponse => {
            return value;
        },
        (error: AxiosError<ErrorResponseData>) => {
            if (error.response?.status === 400 && error.response.data.messages) {
                error.response.data.messages.forEach((item) => {
                    notification.error({
                        message: errorTitleGetter(),
                        description: item,
                    });
                });
            }
            return Promise.reject(error);
        }
    );
};

const initConfirmMessagesInterceptor = (rootStore: RootStore): void => {
    API.interceptors.response.use((value: AxiosResponse): AxiosResponse => {
        return value;
    }, (error: AxiosError<ErrorResponseData>) => {
        if (error.response?.status === 402 && error.response.data.messages) {
            const message = error.response.data?.messages[0] || "";

            return new Promise((resolve, reject) => {
                rootStore.confirmStore.open(
                    message,
                    () => {
                        const config: AxiosRequestConfig = {
                            ...error.config,
                            params: {
                                force: 1
                            }
                        };

                        return API
                            .request(config)
                            .then(result => resolve(result))
                            .catch(reason => reject(reason))
                    },
                    () => {
                        reject(error);
                    }
                );
            });
        }
        return Promise.reject(error);
    });
};

const initServerErrorInterceptor = (errorMessageGetter: () => string, errorTitleGetter: () => string): void => {
    API.interceptors.response.use(
        (value: AxiosResponse): AxiosResponse => {
            return value;
        },
        (e: AxiosError<ErrorResponseData>) => {
            if (e.response?.status === 500) {
                notification.error({
                    message: errorTitleGetter(),
                    description: errorMessageGetter(),
                });
            }
            return Promise.reject(e);
        }
    );
};

export const initVersionInterceptor = (rootStore: RootStore) => {
    API.interceptors.response.use((response) => {
        if (response.headers.version != version) {
            console.log("Version outdated. New version: " + response.headers.version + ", Current version: " + version);
            rootStore.commonStore.setOutdated();
        }
        return response;
    })
}

const initAccessForbiddenErrorInterceptor = (
    accessForbiddenErrorMessageGetter: () => string,
    errorTitleGetter: () => string
): void => {
    API.interceptors.response.use(
        (value: AxiosResponse): AxiosResponse => {
            return value;
        },
        (e: AxiosError<ErrorResponseData>) => {
            if (e.response?.status === 403) {
                notification.error({
                    message: errorTitleGetter(),
                    description: accessForbiddenErrorMessageGetter(),
                });
            }
            return Promise.reject(e);
        }
    );
};

const initLocaleHeaderInterceptor = (store: RootStore) => {
    initCustomHeaderInterceptor("Locale", () => store.langStore.i18n?.language || null);
};

const initCurrencyCodeHeaderInterceptor = (store: RootStore) => {
    initCustomHeaderInterceptor("CC", () => store.authStore.selectedCurrencyCode || null);
};

const initNotFoundInterceptor = () => {
    API.interceptors.response.use(
        (value: AxiosResponse): AxiosResponse => {
            return value;
        },
        (error: AxiosError<ErrorResponseData>) => {
            if (error.response?.status === 404) {
                history.push("/not-found");
            }
            return Promise.reject(error);
        }
    );
};

export const initCustomHeaderInterceptor = (name: string, valueGetter: () => string | null) => {
    API.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            const value = valueGetter();
            if (value) {
                config.headers[name] = value;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};
