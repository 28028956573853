import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import {useHistory} from "react-router";

export interface URLHookData {
    urlParams: queryString.ParsedQuery<string | number>;
    setURLParams: (values: ((oldValue: queryString.ParsedQuery<string | number>) => queryString.ParsedQuery<string | number>) | queryString.ParsedQuery<string | number>, replace: boolean) => void;
}

export type ArrayFormat = 'bracket' | 'index' | 'comma' | 'separator' | 'bracket-separator' | 'colon-list-separator' | 'none';

export const useURLParams = (format: ArrayFormat = 'bracket') => {

    const history = useHistory();
    const location = useLocation();
    const params = useMemo<queryString.ParsedQuery<string | number>>(() => queryString.parse(location.search, {arrayFormat: format}), [location.search]);

    const historyPush = (
        values: ((oldValue: queryString.ParsedQuery<string | number>) => queryString.ParsedQuery<string | number>) | queryString.ParsedQuery<string | number>,
        replace: boolean = false
    ) => {

        if (typeof values === 'function') {
            values = values(params);
        }

        const qs = '?'+ queryString.stringify(values, {arrayFormat: format});

        if (location.search !== qs) {
            if (replace) {
                history.push(qs, {replace: true});
            } else {
                history.push(qs);
            }
        }
    }

    return {
        urlParams: params,
        setURLParams: historyPush
    }
}