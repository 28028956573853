import {ClaimItemAttachment} from "../../../../../models/claims/claims";

export const createFileList = (attachments: ClaimItemAttachment[]): any[] => {
    return attachments.map(attachment => {
        return {
            uid: attachment.id.toString(),
            name: attachment.fileName,
            url: '/api/v3/claim/attachment/' + attachment.hash + '/' + attachment.fileName,
            thumbUrl: '/api/v3/claim/attachment/' + attachment.hash + '/' + attachment.fileName,
            status: 'done',
            size: 0,
            type: ''
        }
    })
}