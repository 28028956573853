import React, {useState} from "react";
import {FakerController} from "./faker.controller";
import {useRequiredLayers} from "../../../../utils/context";
import {RootStoreContext} from "../../../../stores/root/root";
import {RootControllersContext} from "../../../../controllers/root/root.controllers";
import {Col, Form, Modal, Row} from "antd";
import {DebounceSelect} from "../debounce-select/debounce.select";
import {ErrorResponseData} from "../../../../models/core/response";
import {AxiosError} from "axios";
import {useTranslation} from "react-i18next";
import {userService} from "../../../../services/user/user.service";


interface FakerModalProps {
    visible: boolean;
    onClose: () => void
}
export const FakerModal: React.FC<FakerModalProps> = ({visible, onClose}) => {
    const {store: {authStore}, controller: {loginController}} = useRequiredLayers(RootStoreContext, RootControllersContext)
    const [controller] = useState(() => new FakerController(authStore, loginController, userService));
    const [loading, setLoading] = useState<boolean>(false);
    const {t} = useTranslation("translation", {useSuspense: false});
    const [form] = Form.useForm();

    return (
        <Modal
            destroyOnClose
            visible={visible}
            maskClosable={false}
            confirmLoading={loading}
            title={t("USER.SWITCH")}
            okText={t("SAVE")}
            cancelText={t("CANCEL")}
            onOk={() => form.submit()}
            onCancel={() => {
                onClose();
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name={"faker_form"}
                onFinish={() => form
                    .validateFields()
                    .then(values => {
                        setLoading(true);
                        controller.switchUser(values)
                            .then(
                                () => {
                                    form.resetFields();
                                    return onClose();
                                },
                                (e: AxiosError<ErrorResponseData>) => {
                                    if (e.response?.data.fields) {
                                        form.setFields(e.response?.data?.fields);
                                    }
                                })
                            .then(() => {
                            }, (e) => form.setFields(e.response?.data?.fields))
                            .finally(() => setLoading(false));
                    })
                }
            >
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item
                            name={"user"}
                            label={t("USER.SELECT")}
                        >
                            <DebounceSelect
                                allowClear
                                placeholder={t("USER.SELECT")}
                                style={{width: "100%"}}
                                disabled={loading}
                                fetchOptions={controller.searchUsers}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}