import { ArticleStore } from "./article.store";
import { ArticlesService } from "../../services/articles/articles.service";
import React, { Context } from "react";
import {Article, ArticleOem} from "../../models/articles/article";
import { WarehousesService } from "../../services/warehouses/warehouses.service";
import {CartCreateType} from "../../models/cart/create/cart.create";

export class ArticleController {
    constructor(
        private readonly id: string,
        private readonly _articleStore: ArticleStore,
        private readonly _articlesService: ArticlesService,
        private readonly _warehousesService: WarehousesService
    ) {
        this.addToCart = this.addToCart.bind(this);
        this.deleteFromCart = this.deleteFromCart.bind(this);
        this.addToWishList = this.addToWishList.bind(this);
        this.deleteFromWishList = this.deleteFromWishList.bind(this);
        this.onLngChange = this.onLngChange.bind(this);

        this.loadData();
    }

    public onLngChange(): void {
        this.reloadArticleData();
    }

    private async reloadArticleData(): Promise<void> {
        this._articleStore.setArticle(await this._articlesService.getArticle(this.id));
    }

    public onUmnount(): void {
        this._articleStore.setArticle(null);
    }

    private updateArticle(values: { [k: string]: any }) {
        this._articleStore.setArticle({ ...this._articleStore.article, ...values });
    }

    public async addToWishList(article: Article): Promise<void> {
        await this._articlesService.addToWishList(article.id);
        this.updateArticle({ inWishList: true });
    }

    public async deleteFromWishList(article: Article): Promise<void> {
        await this._articlesService.removeFromWishList(article.id);
        this.updateArticle({ inWishList: false });
    }

    public addToCart = (article: Article, qty: number) => {
        return {
            client: () => this.updateArticle({ inCartQty: qty }),
            back: () => this._articlesService.addToCart({ article: article.id, qty, type: "regular" }),
        };
    };

    public deleteFromCart = (article: Article) => {
        return {
            client: () => this.updateArticle({ inCartQty: 0 }),
            back: () => this._articlesService.removeFromCart(article.id),
        };
    };

    public addToReservation = (article: Article, qty: number) => {
        return {
            client: () => this.updateArticle({ inReservationQty: qty }),
            back: () => this._articlesService.addToCart({ article: article.id, qty, type: "reservation" }),
        };
    };

    public deleteFromReservation = (article: Article) => {
        return {
            client: () => this.updateArticle({ inReservationQty: 0 }),
            back: () => this._articlesService.removeReservationFromCart(article.id),
        };
    };

    private generateArticleOemMap(oem: ArticleOem[]): { brandName: string; names: string[] }[] {
        const oemMap: { [manufacturer: string]: string[] } = {};
        oem.forEach((item) => {
            if (oemMap[item.brand]) {
                oemMap[item.brand].push(item.name);
            } else {
                oemMap[item.brand] = [item.name];
            }
        });
        return Object.keys(oemMap).map((key) => ({ brandName: key, names: oemMap[key] }));
    }

    private async loadData(): Promise<void> {
        this._articleStore.setLoading(true);
        this._articleStore.setWarehouses(await this._warehousesService.getWarehouses());

        const article = await this._articlesService.getArticle(this.id);
        this._articleStore.setArticle(article);
        this._articleStore.setOem(this.generateArticleOemMap(article.analogues.oem || []));
        this._articleStore.setAnalogues(this.generateArticleOemMap(article.analogues.analogue || []));
        this._articleStore.setImagePath("/product_photos/1600/photos/" + this._articleStore.article.name + ".jpg");

        this._articleStore.setLoading(false);

        this._articleStore.setVehicles(await this._articlesService.getArticleVehicles(this.id));
    }
}

export const ArticleControllerContext = React.createContext<null | ArticleController>(
    null
) as Context<ArticleController>;
