import React, {useContext} from "react";
import { Space, Tabs } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {ExtendedSwitch} from "../../route/extended.switch";
import {RouteConfig} from "../../../../routers/routers";
import {useHistory} from "react-router";
import {RootStoreContext} from "../../../../stores/root/root";
import {isAllowed} from "../../../../utils/helpers";

export interface RouteTabsProps {
    hiddenTabs?: string[];
    customSpliceNum?: number;
    withoutRootTabPath?: boolean;
    routes: RouteConfig[];
    tabBarExtraContent?: React.ReactNode;
}

const bindPathParams = (path: string, params: string[]) => {
    for (let key in params) {
        path = path.replace(":" + key, params[key]);
    }

    return path;
};

export const RouteTabs = <T extends object>({
    routes,
    hiddenTabs = [],
    withoutRootTabPath = false,
    customSpliceNum = 4,
    tabBarExtraContent
}: RouteTabsProps) => {
    const { t } = useTranslation("translation", { useSuspense: false });
    const { pathname } = useLocation();
    const history = useHistory();

    const params = useParams<string[]>();
    const rootTabPath = withoutRootTabPath ? pathname : pathname.split("/").slice(0, customSpliceNum).join("/");

    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);

    const tabs = routes.filter(
        route => (!route.name || !hiddenTabs?.includes(route.name)) && isAllowed(attributes, route.access)
    );

    return (
        <Space direction={"vertical"} size={[0, 0]} style={{width: '100%'}}>
            {tabs.length > 1 ? (
                <Tabs
                    activeKey={rootTabPath}
                    onTabClick={(key) => history.push(key)}
                    tabPosition={"top"}
                    tabBarExtraContent={tabBarExtraContent}
                >
                    {tabs.map((route) => {
                        return (
                            <Tabs.TabPane tab={t(route.systemName)} key={bindPathParams(route.path, params)} />
                        )
                    })}
                </Tabs>
            ) : null}
            <ExtendedSwitch routers={routes} />
        </Space>
    );
};
