import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Space} from "antd";
import {InvoiceHeader} from "./invoice-header/invoice.header";
import {InvoiceCredentials} from "./invoice-credentials/invoice.credentials";
import {InvoiceDetails} from "./invoice-details/invoice.details";
import {InvoiceStore, InvoiceStoreContext} from "./invoice.store";
import {InvoiceController, InvoiceControllerContext} from "./invoice.controller";
import {invoicesService} from "../../../services/invoices/invoices.service";

export const Invoice: React.FC = () => {
    const {id} = useParams<{id: string}>();
    const [store] = useState(() => new InvoiceStore());
    const [controller] = useState(() => new InvoiceController(id, store, invoicesService));

    return (
        <InvoiceStoreContext.Provider value={store}>
            <InvoiceControllerContext.Provider value={controller}>
                <div className="page_content invoice">
                    <Space direction="vertical" size={[30, 30]} className="content">
                        <InvoiceHeader invoiceId={id}/>
                        <InvoiceCredentials/>
                        <InvoiceDetails/>
                    </Space>
                </div>
            </InvoiceControllerContext.Provider>
        </InvoiceStoreContext.Provider>
    );
};
