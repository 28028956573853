import React, {useEffect, useState} from "react";
import {WidgetProps} from "../../../../../core/ui/widget/widget";
import {useTranslation} from "react-i18next";
import {Select} from "antd";
import {Group} from "../../../../../../models/articles/groups/group";
import {articlesService} from "../../../../../../services/articles/articles.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface UIInputWidgetSubGroupProps extends WidgetProps {
    groupId: number;
    onClear?: () => void;
    onSelect?: () => void;
}

export const UIInputWidgetSubGroup: React.FC<UIInputWidgetSubGroupProps> = ({value, onChange, multiple, groupId, onClear, onSelect}) => {

    const {t} = useTranslation('translation', {useSuspense: false});

    const [values, setValues] = useState<Group[]>([]);

    useEffect(() => {
        if (groupId) {
            articlesService.getSubgroups(groupId).then(values => setValues(values))
        } else {
            setValues([])
        }
    }, [groupId])

    return (
        <Select
            suffixIcon={<FontAwesomeIcon icon={["fas", "angle-down"]} />}
            bordered={false}
            dropdownMatchSelectWidth={200}
            className="selects_block_select"
            placeholder={t("GROUP.SUBGROUP")}
            showSearch
            optionFilterProp={"children"}
            value={value}
            onChange={onChange}
            allowClear
            disabled={!groupId}
        >
            {values.map(({id, name}) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
        </Select>
    )
}