import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {CreditInvoice, CreditInvoiceTableItem} from "../../models/invoices/credit-invoice/credit.invoice";

export class CreditInvoicesService {
    public async getAll(request: PaginatedRequest): Promise<PaginatedResult<CreditInvoiceTableItem>> {
        return (await apiService.get<PaginatedResult<CreditInvoiceTableItem>>(
            "documents/credit-invoices",
            {
                params: request.toParams()
            }
        )).data;
    }

    public async getDocument(id: string): Promise<CreditInvoice> {
        return (await apiService.get<CreditInvoice>("documents/credit-invoices/" + id)).data;
    }
}

export const creditInvoicesService = new CreditInvoicesService();
