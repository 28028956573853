import React, {useContext, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, FormInstance, Row, Select, Spin, Tooltip} from "antd";
import {AccessesStoreContext} from "../accesses.store";
import {getGroupsBySystemId} from "../../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SystemAccessProps {
    form: FormInstance,
    defaultSelectedSystems: string[],
    loading: boolean,
}
export const SystemAccess: React.FC<SystemAccessProps> = observer(({form, defaultSelectedSystems, loading}) => {
    const {t} = useTranslation("translation", {useSuspense: false});
    const store = useContext(AccessesStoreContext);
    const [selectedSystems, setSelectedSystems] = useState<string[]>(defaultSelectedSystems);

    if (!store.systemWithGroups.length) {
        return <Spin/>;
    }

    const isSelectOptionDisabled = (id: string) => form.getFieldValue("systemAccesses").map((item: {system: string, group: number}) => item ? item.system : undefined).includes(id);

    const isAddButtonDisabled = (): boolean => {
        const systemAccessesFields = form.getFieldValue(["systemAccesses"]);
        if (systemAccessesFields) {
            const systemAccessesFieldsLength = systemAccessesFields.length;
            const lastSelectedSystem: string = form.getFieldValue(["systemAccesses", systemAccessesFieldsLength - 1]);
            return !lastSelectedSystem && systemAccessesFieldsLength !== 0;
        }
        return false;
    }

    return (

        <>
            <Form.List
                name={"systemAccesses"}
                rules={[{
                    validator: async (rule, names) => {
                        try {
                            if (!names || names.length === 0) {
                                return Promise.reject((t("FROM.ERROR.PLEASE_ADD_VALUE")));

                            }
                        } catch (error) {
                            throw new Error(String(error));
                        }
                    }
                }]}
            >
                {(fields, {add, remove}, {errors}) => (
                    <>
                        {fields.map((field, index) => (
                            <Row justify="space-between" gutter={[10, 5]} key={field.key}>
                                <Col span={11}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "system"]}
                                        rules={[{
                                            required: true,
                                            message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                                        }]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp={"children"}
                                            disabled={loading}
                                            onChange={value => {
                                                const currentField = form.getFieldValue(["systemAccesses", index]);

                                                if (currentField) {
                                                    const systemAccessesFields: {system: string, group: number | undefined}[] = form.getFieldsValue().systemAccesses;
                                                    const fieldItemIndex = systemAccessesFields.findIndex(item => item.system === value);
                                                    const fieldItem: {system: string, group: number | undefined} = systemAccessesFields[fieldItemIndex];
                                                    const systemWithGroup = store.systemWithGroups.find(item => item.id === fieldItem.system);
                                                    const group = systemWithGroup ? (systemWithGroup.groups[0] ? systemWithGroup.groups[0].id : undefined) : undefined;

                                                    systemAccessesFields[fieldItemIndex] = {
                                                        ...fieldItem,
                                                        group
                                                    };
                                                    form.setFieldsValue({
                                                        ...form.getFieldsValue(),
                                                        systemAccessesFields
                                                    });
                                                }

                                                setSelectedSystems(form.getFieldValue(["systemAccesses"]).map((item: {system: string, group: number}) => item ? item.system : null));
                                            }}
                                            placeholder={t("SYSTEM")}>
                                            {store.systemWithGroups.map(systemGroup => (
                                                <Select.Option
                                                    disabled={isSelectOptionDisabled(systemGroup.id)}
                                                    key={systemGroup.id}
                                                    value={systemGroup.id}
                                                >
                                                    {systemGroup.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>

                                    <Form.Item
                                        noStyle
                                        shouldUpdate={true}
                                    >
                                        {({getFieldValue}) => (
                                            <Form.Item
                                                {...field}
                                                name={[field.name, "group"]}
                                                rules={[{
                                                    required: true,
                                                    message: t("FROM.ERROR.PLEASE_SELECT_VALUE")
                                                }]}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp={"children"}
                                                    disabled={!getFieldValue(["systemAccesses", index]) || loading}
                                                    placeholder={t("GROUP.TITLE")}>
                                                    {getGroupsBySystemId(form.getFieldValue(["systemAccesses", index]), store.systemWithGroups).map(group => (
                                                        <Select.Option
                                                            key={group.id}
                                                            value={group.id}
                                                        >
                                                            {group.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Tooltip placement="topRight" title={t("DELETE")}>
                                        <Button
                                            type="default"
                                            danger
                                            disabled={loading}
                                            onClick={() => {
                                                remove(field.name);
                                                const selectedSystemsWithoutRemoved = [...selectedSystems].splice(index, 1);
                                                setSelectedSystems(selectedSystemsWithoutRemoved);
                                            }}
                                            icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                            className="form-list-btn"
                                        />
                                    </Tooltip>
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                disabled={isAddButtonDisabled() || loading}
                                onClick={() => add()}
                                block
                                icon={<FontAwesomeIcon icon={["fas", "plus-circle"]}/>}
                            >
                                {t("ADD")}
                            </Button>
                            <Form.ErrorList errors={errors}/>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    );
})