import {TFunction} from "react-i18next";
import {ColumnsType} from "antd/lib/table/interface";
import {Date} from "../../../../core/commons/date/date";
import {TableLineData} from "./select-sku.step.data";
import {displayPrice} from "../../../../../models/amount/amount.helper";
import {SelectQty} from "./select-qty/select-qty";

export const columnsFactory = (
        t: TFunction<"translation">,
        onSelect: (item: TableLineData, value: number) => void
    ): ColumnsType<TableLineData> => {
        return [
            {
                title: "Document",
                width: 150,
                dataIndex: "docNumber",
                key: "docNumber",
                render: (docNumber) => docNumber
            },
            {
                title: "Dispatch date",
                dataIndex: "date",
                key: "date",
                render: (date) => <Date dateString={date} />
            },
            {
                title: "Price",
                dataIndex: "price",
                key: "price",
                render: (price) => displayPrice(price)
            },
            {
                title: "Qty",
                dataIndex: "qty",
                key: "qty",
            },
            {
                title: "Use Qty",
                width: 180,
                key: "useQty",
                render: (_, data) =>
                    <SelectQty
                        onSelect={val => onSelect(data, val)}
                        maxQty={data.qty}
                    />
            }
        ]
}