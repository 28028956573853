import {ExpadedTableStore} from "./expaded.table.store";

export class ExpandedTableController {
    constructor(
        private readonly _orderDetailsStore: ExpadedTableStore
    ) {
        this.openColumnsSettingsModal = this.openColumnsSettingsModal.bind(this);
        this.closeColumnsSettingsModal = this.closeColumnsSettingsModal.bind(this);
        this.selectColumns = this.selectColumns.bind(this);
    }

    public selectColumns({columns}: {columns: string[]}): void {
        this._orderDetailsStore.setSelectedColumns(columns);
        if (this._orderDetailsStore.pageKey) {
            localStorage.setItem(this._orderDetailsStore.pageKey, JSON.stringify(columns))
        }
    }

    public openColumnsSettingsModal(): void {
        this._orderDetailsStore.setColumnsSettingsModalShown(true);
    }

    public closeColumnsSettingsModal(): void {
        this._orderDetailsStore.setColumnsSettingsModalShown(false);
    }
}