import {Access} from "../../models/accesses/access";
import {apiService} from "../api.service";
import {AccessRequest} from "../../models/accesses/access.request";

export class AccessesService {
    public async getAccesses(): Promise<Access[]> {
        return (await apiService.get<Access[]>("users/accesses")).data;
    }

    public async postAccess(values: AccessRequest, id?: number): Promise<Access> {
        const url = id ? "users/accesses/" + id : "users/accesses";
        return (await apiService.post<Access>(url, {...values})).data;
    }

    public async removeAccess(id: number): Promise<void> {
        await apiService.delete("users/accesses/" + id);
    }
}

export const accessesService = new AccessesService();