import React from "react";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

import "./order.status.scss";

interface OrderStatusProps {
    status: OrderStatusType;
    large?: boolean;
}

export type OrderStatusType =
    | "not_completed"
    | "not_confirmed"
    | "confirmed"
    | "cancelled"
    | "taken"
    | "ordered"
    | "merged"
    | "in_progress"
    | "collected"
    | "packed"
    | "shipped"
    | "delivered"
    | "pre_ordered";
export type StatusColor = Record<OrderStatusType, string>;
export const statusColor: StatusColor = {
    not_completed: "gold",
    not_confirmed: "gold",
    confirmed: "gold",
    cancelled: "red",
    taken: "red",
    ordered: "gold",
    merged: "",
    in_progress: "green",
    collected: "green",
    packed: "green",
    shipped: "green",
    delivered: "green",
    pre_ordered: "gold",
};

export const OrderStatus: React.FC<OrderStatusProps> = ({ status, large }) => {
    const { t } = useTranslation("translation", { useSuspense: false });

    return (
        <Tag
            className={large ? "order-status-large order-status" : "order-status"}
            color={statusColor[status] ? statusColor[status] : undefined}
        >
            {t("ORDERS.ORDER.STATUS." + status.toUpperCase())}
        </Tag>
    );
};
