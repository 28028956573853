import React, {useContext, useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Select, Space, Typography} from "antd";
import {useRequiredLayers} from "../../../../utils/context";
import {RootStore, RootStoreContext} from "../../../../stores/root/root";
import {RootControllers, RootControllersContext} from "../../../../controllers/root/root.controllers";
import {AxiosError} from "axios";
import {ErrorResponseData} from "../../../../models/core/response";
import {observer} from "mobx-react";
import {RegistrationController, RegistrationControllerContext} from "../registation.controller";
import {useTranslation} from "react-i18next";


export const RegistrationForm: React.FC = observer(() => {
    const {t} = useTranslation("translation", {useSuspense: false});
    const {
        store: {langStore},
        controller: {langController}
    } = useRequiredLayers<RootStore, RootControllers>(RootStoreContext, RootControllersContext);
    const controller = useContext<RegistrationController>(RegistrationControllerContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(true);

    const [form] = Form.useForm();

    useEffect(() => {
        langController.loadCountries();
    }, []);


    return (
        <Form
            form={form}
            name="registration_form"
            className="registration-form"
            initialValues={{}}
            layout="vertical"
            onFinish={() => form
                .validateFields()
                .then(values => {
                    setLoading(true);
                    controller.register(values, checked)
                        .then(() => {
                            form.resetFields();
                        }, (e: AxiosError<ErrorResponseData>) => {
                            if (e.response?.data.fields) {
                                form.setFields(e.response?.data?.fields);
                            }
                        })
                        .then(() =>
                            // onClose(),
                            (e: any) => form.setFields(e.response?.data?.fields))
                        .finally(() => setLoading(false));
                })
            }
        >
            <Space direction="vertical" size={[20, 20]}>
                <Row gutter={[10, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item name={["agent", "businessName"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("COMPANY_LEGAL_NAME")}>
                            <Input onChange={event => {
                                const value = event.target.value.trim();
                                form.setFieldsValue({account: {billingAddress: {companyName: value}}})
                            }}
                                   disabled={loading} type="text" placeholder={t("LEGAL_NAME")}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[10, 0]}>
                    <Col span={24}>
                        <Typography.Title level={3} className="registration-form_title">
                            {t("BILLING.ADDRESS")}
                        </Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item name={["billingAddress", "companyName"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("LEGAL_NAME")}>
                            <Input disabled={loading} type="text" placeholder={t("LEGAL_NAME")}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item name={["billingAddress", "regCode"]}
                                   label={t("REG_NUMBER")}>
                            <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item name={["billingAddress", "vat"]}
                                   label={"VAT"}>
                            <Input disabled={loading} type="text" placeholder={"VAT"}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item name={["billingAddress", "customsCode"]}
                                   label={"EORI"}>
                            <Input disabled={loading} type="text" placeholder={"EORI"}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "country"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("COUNTRY")}
                        >
                            <Select
                                disabled={loading}
                                showSearch
                                optionFilterProp={"children"}
                                placeholder={t("COUNTRY_SELECT")}
                            >
                                {langStore.countries.map(({id, name}) => (
                                    <Select.Option key={id} value={id}>{name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "region"]}
                                   label={t("REGION")}>
                            <Input disabled={loading} type="text" placeholder={t("REGION")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "city"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("CITY")}>
                            <Input disabled={loading} type="text" placeholder={t("CITY")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "index"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("INDEX")}>
                            <Input disabled={loading} type="text" placeholder={t("INDEX")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "address"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("ADDRESS")}>
                            <Input disabled={loading} type="text" placeholder={t("ADDRESS")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "firstName"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("FIRSTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "lastName"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("LASTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("LASTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "phone"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("PHONE")}>
                            <Input disabled={loading} type="text" placeholder={t("PHONE")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["billingAddress", "email"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={"Email"}>
                            <Input disabled={loading} type="text" placeholder={"Email"}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[10, 0]}>
                    <Col span={24}>
                        <Typography.Title level={3} className="registration-form_title">
                            {t("SHIPPING.ADDRESS")}
                        </Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            checked={checked}
                            disabled={loading}
                            style={{marginBottom: 10}}
                            onChange={e => {
                                if (!e.target.checked) {
                                    form.setFieldsValue({account: {deliveryAddress: undefined}})
                                }
                                setChecked(e.target.checked)
                            }}
                        >
                            {t("SHIPPING.AS_BILLING")}
                        </Checkbox>
                    </Col>
                    {!checked ? (
                        <>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item name={["deliveryAddress", "companyName"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("LEGAL_NAME")}>
                                    <Input disabled={loading} type="text" placeholder={t("LEGAL_NAME")}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item name={["deliveryAddress", "regCode"]}
                                           label={t("REG_NUMBER")}>
                                    <Input disabled={loading} type="text" placeholder={t("REG_NUMBER")}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item name={["deliveryAddress", "vat"]}
                                           label={"VAT"}>
                                    <Input disabled={loading} type="text" placeholder={"VAT"}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item name={["deliveryAddress", "customsCode"]}
                                           label={"EORI"}>
                                    <Input disabled={loading} type="text" placeholder={"EORI"}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "country"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("COUNTRY")}>
                                    <Select
                                        disabled={loading}
                                        showSearch
                                        optionFilterProp={"children"}
                                        placeholder={t("COUNTRY_SELECT")}
                                    >
                                        {langStore.countries.map(({id, name}) => (
                                            <Select.Option key={id} value={id}>{name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "region"]}
                                           label={t("REGION")}>
                                    <Input disabled={loading} type="text" placeholder={t("REGION")}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "city"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("CITY")}>
                                    <Input disabled={loading} type="text" placeholder={t("CITY")}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "index"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("INDEX")}>
                                    <Input disabled={loading} type="text" placeholder={t("INDEX")}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "address"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("ADDRESS")}>
                                    <Input disabled={loading} type="text" placeholder={t("ADDRESS")}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "firstName"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("FIRSTNAME")}>
                                    <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "lastName"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("LASTNAME")}>
                                    <Input disabled={loading} type="text" placeholder={t("LASTNAME")}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "phone"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={t("PHONE")}>
                                    <Input disabled={loading} type="text" placeholder={t("PHONE")}/>
                                </Form.Item>
                            </Col>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                                <Form.Item name={["deliveryAddress", "email"]}
                                           rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                           label={"Email"}>
                                    <Input disabled={loading} type="text" placeholder={"Email"}/>
                                </Form.Item>
                            </Col>
                        </>
                    ) : null}
                </Row>
                <Row gutter={[10, 0]}>
                    <Col span={24}>
                        <Typography.Title level={3} className="registration-form_title">
                            {t("CONTACT_PERSON")}
                        </Typography.Title>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["contact", "firstName"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("FIRSTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("FIRSTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["contact", "lastName"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("LASTNAME")}>
                            <Input disabled={loading} type="text" placeholder={t("LASTNAME")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item name={["contact", "position"]}
                                   label={t("POSITION")}>
                            <Input disabled={loading} type="text" placeholder={t("POSITION")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["contact", "email"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("WORK_EMAIL")}>
                            <Input disabled={loading} type="text" placeholder={t("WORK_EMAIL")}/>
                        </Form.Item>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item name={["contact", "workPhone"]}
                                   rules={[{required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE")}]}
                                   label={t("WORKPHONE")}>
                            <Input disabled={loading} type="text" placeholder={t("WORKPHONE")}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button disabled={loading} htmlType="submit" type="primary">{t("SIGN_UP")}</Button>
                    </Col>
                </Row>
            </Space>
        </Form>
    );
});
