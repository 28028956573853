import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {ExtendedSwitch} from "../core/route/extended.switch";
import {Col, Row, Tabs, Typography} from "antd";
import {ComponentPropsFromRoute} from "../../routers/routers";
import {AddressesController, AddressesControllerContext} from "./addresses/addresses.controller";
import {AddressesStore, AddressesStoreContext} from "./addresses/addresses.store";
import {addressesService} from "../../services/addresses/addresses.service";
import history from "../../utils/history";
import {isAllowed} from "../../utils/helpers";
import {RootStoreContext} from "../../stores/root/root";


interface MyProfileProps extends ComponentPropsFromRoute {
};
export const MyProfile: React.FC<MyProfileProps> = ({routeChildren}) => {
    const {authStore: {attributes}} = useContext(RootStoreContext)
    const [store] = useState(() => new AddressesStore());
    const [controller] = useState(() => new AddressesController(store, addressesService));
    const {t} = useTranslation('translation', {useSuspense: false});
    const location = useLocation();
    const [mode, setMode] = useState<"top" | "left">("top");

    return (
        <div className="page_content">
            <Row className="content" style={{width: "100%", marginRight: 0, marginLeft: 0}} gutter={[20, 20]}
                 align="stretch">
                <Col span={24}>
                    <AddressesStoreContext.Provider value={store}>
                        <AddressesControllerContext.Provider value={controller}>
                            <Typography.Title
                                level={1}
                                className="main-title"
                                style={{marginBottom: 30}}
                            >
                                {t("MY_PROFILE.TITLE")}
                            </Typography.Title>
                            <Tabs
                                tabPosition={mode}
                                activeKey={location.pathname}
                                onTabClick={(key) => history.push(key)}
                            >
                                {routeChildren!.map((item) => isAllowed(attributes, item.access) ? (
                                    <Tabs.TabPane tab={t(item.systemName)} key={item.path}/>
                                ) : null)}
                            </Tabs>
                            <ExtendedSwitch routers={routeChildren!}/>
                        </AddressesControllerContext.Provider>
                    </AddressesStoreContext.Provider>
                </Col>
            </Row>
        </div>
    );
};
