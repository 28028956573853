import {Country} from "../country/country";

export enum AddressType {
    BILLING = 'billing',
    SHIPPING = 'shipping'
}

export interface Address {
    id: number,
    name: string,
    type: AddressType,
    vat: string,
    customsCode: string,
    country: Country,
    region: string,
    city: string,
    address: string,
    index: string,
    phone: string,
    email: string,
    companyName: string,
    firstName: string,
    lastName: string,
    default: boolean
}

export interface EditAddressResult {
    address: Address;
    resultCode: number;
}

export interface AddressesMap {[type: string]: Address[]};
