import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Alert, Button, Col, Form, Input, InputNumber, Radio, Row, Space, Typography} from "antd";
import {useForm} from "antd/es/form/Form";
import {notifications} from "../../../../services/notifications/notifications";
import {productionService} from "../../../../services/production/production.service";
import {useHistory} from "react-router";

export const ProductionRequestCreate: React.FC<ComponentPropsFromRoute> = ({}) => {
    const { t } = useTranslation("translation", { useSuspense: false });

    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Typography.Title level={1} className="main-title">
                    {t("PRODUCTION_REQUEST.CREATE.TITLE")}
                </Typography.Title>
                <Alert
                    showIcon
                    description={t("PRODUCTION_REQUEST.CREATE.HINT")}
                />
                <Form
                    form={form}
                    initialValues={{
                        type: 'order'
                    }}
                    layout="vertical"
                    onFinish={() => form
                        .validateFields()
                        .then(async (values) => {
                            setLoading(true);
                            try {
                                const finalValues = {...values}
                                const type = finalValues.type;
                                delete finalValues.type;
                                if (type === 'order') {
                                    await productionService.sendOrderApplication(finalValues);
                                } else {
                                    await productionService.sendOfferApplication(finalValues);
                                }
                                notifications.successfully();
                                history.push('/production');
                            } catch (e: any) {
                                if (e.response?.data.fields) {
                                    form.setFields(e.response?.data?.fields);
                                }
                            } finally {
                                setLoading(false)
                            }
                        })
                    }
                >
                    <Row gutter={10}>
                        <Col span={14}>
                            <Form.Item
                                label={t("PRODUCTION_REQUEST.FIELD.TYPE.TITLE")}
                                name={['type']}
                                rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                            >
                                <Radio.Group
                                    disabled={loading}
                                    optionType="button"
                                    buttonStyle="solid"
                                    options={[
                                        {label: t("PRODUCTION_REQUEST.FIELD.TYPE.ORDER"), value: 'order'},
                                        {label: t("PRODUCTION_REQUEST.FIELD.TYPE.OFFER"), value: 'offer'}
                                    ]}
                                >
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item
                                label={t("PRODUCTION_REQUEST.FIELD.OE_NUMBER.TITLE")}
                                name={['OENumber']}
                                rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                            >
                                <Input
                                    placeholder={t("PRODUCTION_REQUEST.FIELD.OE_NUMBER.PH")}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item
                                label={t("PRODUCTION_REQUEST.FIELD.DESCRIPTION.TITLE")}
                                name={['description']}
                                rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                            >
                                <Input.TextArea
                                    placeholder={t("PRODUCTION_REQUEST.FIELD.DESCRIPTION.PH")}
                                    disabled={loading}
                                />
                            </Form.Item>
                        </Col>
                        <Form.Item
                            noStyle
                            shouldUpdate={(o, n) => o.type !== n.type}
                        >
                            {(f) => f.getFieldValue('type') === 'offer' ? (
                                <>
                                    <Col span={14}>
                                        <Form.Item
                                            label={t("PRODUCTION_REQUEST.FIELD.JUSTIFICATION.TITLE")}
                                            name={['justification']}
                                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                                        >
                                            <Input.TextArea
                                                placeholder={t("PRODUCTION_REQUEST.FIELD.JUSTIFICATION.PH")}
                                                disabled={loading}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item
                                            label={t("PRODUCTION_REQUEST.FIELD.QTY.TITLE")}
                                            name={['qty']}
                                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                                        >
                                            <InputNumber
                                                placeholder={t("PRODUCTION_REQUEST.FIELD.QTY.PH_OF")}
                                                disabled={loading}
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item
                                            label={t("PRODUCTION_REQUEST.FIELD.PRICE.TITLE")}
                                            name={['price']}
                                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                                        >
                                            <Input
                                                placeholder={t("PRODUCTION_REQUEST.FIELD.PRICE.PH_OF")}
                                                disabled={loading}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={14}>
                                        <Form.Item
                                            label={t("PRODUCTION_REQUEST.FIELD.QTY.TITLE")}
                                            name={['qty']}
                                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                                        >
                                            <InputNumber
                                                placeholder={t("PRODUCTION_REQUEST.FIELD.QTY.PH_OR")}
                                                disabled={loading}
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item
                                            label={t("PRODUCTION_REQUEST.FIELD.PRICE.TITLE")}
                                            name={['price']}
                                            rules={[{ required: true, message: t("FROM.ERROR.PLEASE_ENTER_VALUE") }]}
                                        >
                                            <Input
                                                placeholder={t("PRODUCTION_REQUEST.FIELD.PRICE.PH_OR")}
                                                disabled={loading}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Form.Item>
                        <Col span={14}>
                            <Button
                                type={"primary"}
                                onClick={() => form.submit()}
                                loading={loading}
                            >
                                {t("SUBMIT")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Space>
        </div>
    );
}
