import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {Order} from "../../models/orders/order/order";
import {OrderComment, OrderData} from "../../models/orders/order-data/order.data";
import {ShipmentWithTrackingUrl} from "../../components/orders/order/order-shipments/order.shipments.model";

export class OrdersService {

    public async getOrders(request: PaginatedRequest): Promise<PaginatedResult<Order[]>> {
        return (await apiService.get<PaginatedResult<Order[]>>(
            "orders",
            {
                params: request.toParams()
            }
        )).data;
    }

    public async getOrder(id: string): Promise<OrderData> {
        return (await apiService.get<OrderData>("orders/" + id)).data;
    }

    public async removeComment(id: number): Promise<void> {
        await apiService.delete("orders/comments/" + id);
    }

    public async postComment(id: number, values: {message: string}): Promise<OrderComment> {
        return (await apiService.post<OrderComment>("orders/" + id + "/comments", values)).data;
    }

    getOrderShipments(id: number): Promise<ShipmentWithTrackingUrl[]> {
        return apiService.get<ShipmentWithTrackingUrl[]>("orders/" + id + "/shipments").then((x) => x.data);
    }
}
export const ordersService = new OrdersService();
