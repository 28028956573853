import {apiService} from "../api.service";
import {Integration, IntegrationConnectionTestValues} from "../../models/integrations/integration";

export class IntegrationsService {
    public async getIntegrations(): Promise<Integration[]> {
        return (await apiService.get<Integration[]>("integrations")).data;
    }

    public async postIntegration(values: Integration, id?: number): Promise<Integration> {
        const url = id ? "integrations/" + id : "integrations";
        return (await apiService.post<Integration>(url, {...values})).data;
    }

    public async removeIntegration(id: number): Promise<void> {
        await apiService.delete("integrations/" + id);
    }

    public async uploadIntegrationData(integrationId: number): Promise<void> {
        await apiService.post<Integration>("integrations/" + integrationId + "/upload");
    }

    public async testIntegrationConnection(values: IntegrationConnectionTestValues, id?: number): Promise<any> {
        const url = id ? "integrations/" + id + "/test" : "integrations/test";
        return await apiService.post<any>(url, {...values});
    }

}

export const integrationService = new IntegrationsService();