import {action, computed, makeObservable, observable} from "mobx";
import { PaginatedRequest } from "../../../models/requests/paginated-request/paginated.request";
import {Order} from "../../../models/orders/order/order";
import {FilterDataController} from "../../../controllers/filtered-table/filter.data.controller";
import {ordersService} from "../../../services/orders/orders.service";
import {CartCreateType} from "../../../models/cart/create/cart.create";

export class OrdersListStore {

    @observable
    public request: PaginatedRequest | null = null;

    dataHandler: FilterDataController<Order[]>;



    constructor(type: CartCreateType) {

        makeObservable(this);

        this.dataHandler = new FilterDataController<Order[]>(request => {
            request.addFilter('type', type);
            return ordersService.getOrders(request);
        });
    }


    @action
    public setRequest(value: PaginatedRequest): void {
        this.request = value;
    }
}
