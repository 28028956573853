import React, {useContext} from "react";
import {Redirect} from "react-router-dom";
import {observer} from "mobx-react";
import {RootStoreContext} from "../../stores/root/root";

export const Index: React.FC = observer(() => {
    const {authStore: {isLoggedIn}} = useContext(RootStoreContext);

    if (!isLoggedIn) {
        return <Redirect to={"/login"}/>
    }

    return (
        <Redirect to={"/catalog"}/>
    )
})
