import React from "react";
import {Row} from "antd";
import {useTranslation} from "react-i18next";

interface NavigationBtnItemProps {
    className: string,
    onClick: () => void,
    danger: boolean,
    name?: string,
}
export const NavigationBtnItem: React.FC<NavigationBtnItemProps> = ({className, danger, name, onClick}) => {
    const {t} = useTranslation("translation", {useSuspense: false});
    const onBtnClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();
        onClick();
    }

    return (
        <a
            className={className}
            style={{alignItems: "center", justifyContent: "center", backgroundColor: danger ? "#f93822" : undefined}}
            onClick={onBtnClick}
        >
            <Row justify="center" align="middle" className={'nav-img-container'}>
                {name ? <span>{t(name)}</span> : null}
            </Row>
        </a>
    );
}