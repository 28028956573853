export class PaginatedRequest {
    public page: number = 1;
    public limit: number = 20;
    // public filters: {[K: string]: string | string[] | {[K:string] : string}} = {};
    public filters: {[K: string]: any} = {};

    public addFilter(key: string, value: string | string[]): void {
        this.filters[key] = value;
    }

    public toParams(): {page: number, limit: number, filters: {[K: string]: string | string[]}} {
        let params: any = {
            page: this.page,
            limit: this.limit
        };

        for (let prop in this.filters) {
            params[`filter[${prop}]`] = this.filters[prop];
        }
        return params;
    }

    public toFilters() {
        let params: any = {};

        for (let prop in this.filters) {
            params[`filter[${prop}]`] = this.filters[prop];
        }
        return params;
    }

    public isEqual(req: PaginatedRequest): boolean {
        if (this.page !== req.page || this.limit !== req.limit) {
            return false;
        }

        for (let p in req.filters) {
            if(!this.filters.hasOwnProperty(p) || String(this.filters[p]) !== String(req.filters[p])) {
                return false;
            }
        }

        for (let p in this.filters) {
            if(!req.filters.hasOwnProperty(p) || String(this.filters[p]) !== String(req.filters[p])) {
                return false;
            }
        }

        return true;
    }
}
