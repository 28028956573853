import React, {RefObject, SVGProps, useContext, useRef, useState} from "react";
import {Button, Select} from "antd";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {routers} from "../../../routers/routers";
import {RootControllers, RootControllersContext} from "../../../controllers/root/root.controllers";

import {useTranslation} from "react-i18next";
import {useRequiredLayers} from "../../../utils/context";
import {RootStore, RootStoreContext} from "../../../stores/root/root";

import "./navigation.mobile.menu.scss";

interface NavigationMobileMenuProps {
    opened: boolean,
    onClose: () => void
}

export const NavigationMobileMenu: React.FC<NavigationMobileMenuProps> = ({opened, onClose}) => {
    const {t, i18n} = useTranslation("translation", {useSuspense: false});
    const {store: {langStore}} = useRequiredLayers<RootStore, RootControllers>(RootStoreContext, RootControllersContext);
    const loginController = useContext(RootControllersContext).loginController;
    const [selectOpened, setSelectOpened] = useState<boolean>(false);
    const selectWrapper = useRef<HTMLElement>(null) as RefObject<any>;

    return (
        <div className={opened ? "mobile-navigation-container open" : "mobile-navigation-container close"}
             style={{display: opened ? "block" : "none"}}>
            <Button onClick={onClose} className="mobile-navigation-button ws_button"
                    icon={<FontAwesomeIcon icon={["fas", "times"]} size={"1x"}/>}/>
            <ul className="mobile-navigation-list">
                <li ref={selectWrapper} onClick={() => setSelectOpened(!selectOpened)} className="language-select mobile-navigation-item">
                    <FontAwesomeIcon icon={["fas", "globe-americas"]} size={"lg"}/>
                    <Select
                        open={selectOpened}
                        className="page_header_language-select"
                        value={i18n.language}
                        onChange={(value: string) => i18n.changeLanguage(value)}
                        bordered={false}
                        getPopupContainer={() => selectWrapper.current}
                        dropdownMatchSelectWidth={300}

                    >
                        {langStore.locales.map(locale => (
                            <Select.Option key={locale} value={locale}>{locale.toUpperCase()}</Select.Option>
                        ))}
                    </Select>
                </li>
                {routers.map(route => {
                    const Icon: React.FunctionComponent<SVGProps<SVGSVGElement>> = route.icon!;
                    return route.viewInNavigationPanel ? (
                        <li key={route.systemName} className={route.path === "/claims" ? "claims mobile-navigation-item mobile-navigation-item" : "mobile-navigation-item"}>
                            <Link onClick={onClose} to={route.path}>
                                <Icon/>
                                {t(route.systemName)}
                            </Link>
                        </li>
                    ) : null
                })}
                <li className="mobile-navigation-item">
                    <Button
                        onClick={loginController.logout}
                        icon={<FontAwesomeIcon className="mobile-navigation-icon" icon={["fas", "power-off"]}/>}
                        className={"page_header_container-link ws_button"}
                    >
                        {t("LOGOUT.TITLE")}
                    </Button>
                </li>
            </ul>
        </div>
    );
}
