import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Row, Space, Typography} from "antd";
import {Claim} from "../../../models/claims/claims";
import {claimService} from "../../../services/claims/claims.service";
import {useParams} from "react-router-dom";
import {ClaimsStatus} from "../status/claim.status";
import {ClaimComments} from "./comments/claim.comments";
import {ClaimParts} from "./parts/claim.parts";
import {ClaimDescription} from "./descrption/claim.description";

export const ClaimView: React.FC<ComponentPropsFromRoute> = observer(({name}) => {

    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation("translation", { useSuspense: false });

    const [claim, setClaim] = useState<Claim>();
    const [updateState, setUpdateState] = useState<boolean>(false);

    const update = async () => {
        setUpdateState(true);
        await claimService.getClaim(parseInt(id)).then(claim => setClaim(claim));
        setUpdateState(false);
    }

    useEffect(() => {
        update();
    }, [])

    if (!claim) {
        return <></>
    }

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Row justify="space-between" align="middle">
                    <Typography.Title level={1} className="main-title">
                        {t("CLAIMS.CLAIM.TITLE") + " #" + claim.documentNumber}
                    </Typography.Title>
                    <ClaimsStatus large status={claim.status} />
                </Row>
                <Row>
                    <Typography.Text disabled>
                        {t("CLAIMS.CLAIM.TYPE." + claim.type.toUpperCase())}
                    </Typography.Text>
                </Row>
                <ClaimDescription claim={claim} />
                <ClaimComments claim={claim} />
                {updateState ? null : <ClaimParts claim={claim} onUpdate={update} />}
            </Space>
        </div>
    );
});
