import React from "react";
import {Col, Row, Table, Typography, Skeleton, Card, Space} from "antd";
import {useTranslation} from "react-i18next";
import "./cart.checkout.order.body.loader.scss";

export const CartCheckoutOrderBodyLoader: React.FC = () => {
    const {t} = useTranslation('translation', {useSuspense: false});

    const columns: any[] = [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "articleName",
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "articleDescription",
            key: "articleDescription",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            align: "right",
            width: "150px",

        },
        {
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalAmount",
            key: "totalAmount",
        }
    ];


    return (
        <>
            <Row style={{width: "100%"}} gutter={[0, 30]}>
                <Col xxl={24} xl={24} md={24} sm={24} xs={24}>
                    <Row justify="space-between" align="middle" wrap>
                        <Col xs={24} sm={24} md={20} xl={20} xxl={20}>
                            <Skeleton active paragraph={{rows: 1}} title={false}/>
                        </Col>
                        <Col xs={4} sm={4} md={4} xl={4} xxl={4}>
                            <Skeleton.Button active className="basket-apply-cancel-btn-loader"/>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table
                        rowKey={"key"}
                        columns={columns}
                        dataSource={[]}
                        loading={true}
                        pagination={false}
                        footer={() => <Skeleton paragraph={{rows: 3}}/>}
                    />
                </Col>
                <Col span={24}>
                    <Typography.Title level={3}>{t("CART.ASSOCIATED_NUMBER.TITLE")}</Typography.Title>
                    <Skeleton.Input className={"basket-apply-input-loader"} active/>
                </Col>
                <Col span={24}>
                    <Space direction="vertical" style={{width: "100%"}} size={[0, 0]}>
                        <Row>
                            <Typography.Title style={{marginRight: 5}}  level={3}>{t("CART.BILLING.ADDRESSES")}</Typography.Title>
                                <Skeleton.Button active shape={"circle"}/>
                        </Row>
                        <Row gutter={[30, 30]}>
                            <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Card
                                    className="card"
                                    title={<Skeleton active paragraph={{rows: 1, width: "100%", style: {marginTop: 20}}} title={false}/>}
                                >
                                    <Skeleton active paragraph={{rows: 5, width: "100%"}} title={false}/>
                                </Card>
                            </Col>
                        </Row>
                    </Space>
                </Col>
                <Col span={24}>
                    <Space direction="vertical" style={{width: "100%"}} size={[0, 0]}>
                        <Row>
                            <Typography.Title style={{marginRight: 5}}  level={3}>{t("CART.DELIVERY.ADDRESSES")}</Typography.Title>
                                <Skeleton.Button active shape={"circle"}/>
                        </Row>
                        <Row gutter={[30, 30]}>
                            <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Card
                                    className="card"
                                    title={<Skeleton active paragraph={{rows: 1, width: "100%", style: {marginTop: 20}}} title={false}/>}
                                >
                                    <Skeleton active paragraph={{rows: 5, width: "100%"}} title={false}/>
                                </Card>
                            </Col>
                        </Row>
                    </Space>
                </Col>
                <Col span={24}>
                    <Space direction="vertical" style={{width: "100%"}} size={[0, 0]}>
                        <Row>
                            <Typography.Title level={3}>{t("CART.DELIVERY.OPTIONS.TITLE")}</Typography.Title>
                        </Row>
                        <Row gutter={[30, 30]}>
                            <Col className="card-col" xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Card
                                    className="card"
                                    title={<Skeleton active paragraph={{rows: 1, width: "100%", style: {marginTop: 20}}} title={false}/>}
                                >
                                    <Skeleton active paragraph={{rows: 3, width: "100%"}} title={false}/>
                                </Card>
                            </Col>
                        </Row>
                    </Space>
                </Col>
                <Col span={24}>
                    <Typography.Title level={3}>{t("ORDERS.COMMENT")}</Typography.Title>
                    <Skeleton.Input className="basket-apply-input-loader" active/>
                </Col>
                <Col span={24}>
                    <Row justify={"center"}>
                        <Skeleton.Button active/>
                    </Row>
                </Col>
            </Row>
        </>
    )
}
