import {
    SearchedMergedInvoice,
    SearchedMergedInvoiceItem
} from "../../../../../models/invoices/merged-invoice/merged.invoice";
import {Amount} from "../../../../../models/amount/amount";

export interface TableLineData {
    invoice: SearchedMergedInvoice;
    item: SearchedMergedInvoiceItem;
    docNumber: string;
    date: string;
    price: Amount;
    qty: number;
}

export const dataFactory = (invoices: SearchedMergedInvoice[]): TableLineData[] => {
    const lines: TableLineData[] = [];

    invoices.forEach(invoice => {
        let base: boolean = true;
        invoice.items.forEach(item => {
            lines.push({
                invoice: invoice,
                item: item,
                docNumber: base ? invoice.docNumber : '',
                date: item.date,
                price: item.price,
                qty: item.qty
            });
            base = false;
        })
    })

    return lines;
}