import { AxiosInstance } from "axios";
import API from "../../utils/api";
import {
    Claim,
    ClaimAddComment,
    ClaimComment,
    CreateClaim, CreateClaimItemWithInvoice, EditClaimItem
} from "../../models/claims/claims";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";
import {SearchedMergedInvoice} from "../../models/invoices/merged-invoice/merged.invoice";
import {apiService} from "../api.service";

export class ClaimsService {
    private api: AxiosInstance = API;

    getAll(request: PaginatedRequest): Promise<PaginatedResult<Claim>> {
        return this.api
            .get<PaginatedResult<Claim>>("claims", {params: request.toParams()})
            .then((x) => x.data);
    }

    getClaim(id: number): Promise<Claim> {
        return this.api.get<Claim>("claims/" + id).then((x) => x.data);
    }

    getClaimComments(id: number): Promise<ClaimComment[]> {
        return this.api
            .get<ClaimComment[]>("claims/" + id + "/comments")
            .then((x) => x.data);
    }

    addComment(id: number, comment: ClaimAddComment): Promise<ClaimComment> {
        return this.api
            .post<ClaimComment>("claims/" + id + "/comments", comment)
            .then((x) => x.data);
    }

    createClaim(request: CreateClaim): Promise<Claim> {

        const items = request.items.map(item => { // transform images

            const newItem: any = {
                ...item,
                data: {...item.data}
            };

            if (item.type === "known") {
                const data = item.data as CreateClaimItemWithInvoice;
                newItem.data.invoice = data.invoice?.id;
                newItem.data.item = data.item?.id;
            }

            if (item.data?.images) {
                newItem.data.images = item.data.images.map(i => parseInt(i.uid));
            }

            return newItem;
        })

        return this.api
            .post<Claim>("/claims", {items, type: request.type})
            .then((x) => x.data);
    }

    updateItem(id: number, request: EditClaimItem): Promise<any> {

        return this.api
            .post<void>("claims/items/" + id, request);
    }

    removeFile(id: number): Promise<any> {
        return this.api
            .delete<void>("claim/attachment/" + id);
    }

    public async searchMergedInvoices(articleId: number): Promise<SearchedMergedInvoice[]> {
        return (await apiService.get<SearchedMergedInvoice[]>("claims/merged-invoices?article=" + articleId)).data;
    }
}

export const claimService = new ClaimsService();