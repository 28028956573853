import React, {useEffect, useState} from "react";
import {ComponentPropsFromRoute} from "../../../../routers/routers";
import {Button, Col, Row, Space, Typography, Divider} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useParams, useRouteMatch} from "react-router-dom";
import {WikiArticle} from "../../../../models/wiki/wiki";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import {downloadBlobFile} from "../../../../utils/download.blob";
import {MarkdownViewer} from "../../../core/markdown/markdown-viewer/markdown-viewer";
import {wikiService} from "../../../../services/wiki/wiki.service";
import {MarkdownHeaders} from "../../../core/markdown/markdown-headers/markdown-headers";
import {copyToClipboard} from "../../../../utils/clipboard";

const { Title, Text } = Typography;

interface WikiViewPageProps extends ComponentPropsFromRoute {
}

export const WikiViewPage: React.FC<WikiViewPageProps> = () => {

    const match = useRouteMatch();

    const {t} = useTranslation("translation", {useSuspense: false});

    const params = useParams<{ id: string }>();

    const [article, setArticle] = useState<WikiArticle>();

    const [disabled, setDisabled] = useState<boolean>(false);


    const reload = () => {
        wikiService
            .getArticle(parseInt(params.id))
            .then(article => {
                setArticle(article)
                // pageStore.updateRouteName(match.url, article.title)
            })
    }

    const downloadPDF = async () => {
        setDisabled(true);
        const {blob, filename} = await wikiService.downloadArticleAsPDF(parseInt(params.id))
        downloadBlobFile(blob, filename);
        setDisabled(false);
    }

    const downloadHTML = async () => {
        setDisabled(true);
        const {blob, filename} = await wikiService.downloadArticleAsHTML(parseInt(params.id))
        downloadBlobFile(blob, filename);
        setDisabled(false);
    }

    useEffect(() => {
        reload();
    }, [params.id])

    useEffect(() => {
        i18n.on("languageChanged", reload);
        return () => i18n.off("languageChanged", reload);
    }, []);

    if (!article) {
        return <></>
    }

    return (
        <Row wrap={false} gutter={[40, 0]}>
            <Col flex="auto">
                <Title level={2} style={{marginBottom: 0}}>{article.title} <Button icon={<FontAwesomeIcon icon={["fas", "file-pdf"]} />} onClick={downloadPDF} disabled={disabled} /></Title>
                <div style={{paddingBottom: 2}}>{article.description}</div>
                <div style={{paddingBottom: 10, color: "#969696"}}>{moment(article.lastEditedAt).format("DD/MM/YYYY HH:MM")}</div>
                <MarkdownViewer>{article.content}</MarkdownViewer>
            </Col>
        </Row>
    )
}