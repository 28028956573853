import React, { useContext } from "react";
import { Row, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import { Date } from "../../core/commons/date/date";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import { ColumnsType } from "antd/lib/table";
import { displayPrice } from "../../../models/amount/amount.helper";
import { MergedInvoiceTableItem } from "../../../models/invoices/merged-invoice/merged.invoice";
import { Amount } from "../../../models/amount/amount";
import { useTranslation } from "react-i18next";
import { FilterKey } from "../../../models/filtered-table/filtered.table";
import { mergedInvoicesService } from "../../../services/invoices/merged.invoices.service";
import { RootStoreContext } from "../../../stores/root/root";
import { isAllowed } from "../../../utils/helpers";
import { observer } from "mobx-react";

interface MergedInvoicesListProps extends ComponentPropsFromRoute {}
export const MergedInvoicesList: React.FC<MergedInvoicesListProps> = observer(({ name }) => {
    const { t } = useTranslation("translation", { useSuspense: false });
    const {
        authStore: { attributes },
    } = useContext(RootStoreContext);
    const columns: ColumnsType<MergedInvoiceTableItem> = [
        {
            title: t("INVOICES.NUMBER"),
            dataIndex: "docNumber",
            key: "merged_invoices_docNumber",
            render: (number: string, row) => (
                <Link className="link no-wrap" to={"/final-invoices/" + row.id}>
                    {number}
                </Link>
            ),
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "merged_invoices_qty",
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "grandTotal",
            key: "merged_invoices_grandTotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("TOTAL_PRICE"),
            dataIndex: "subtotal",
            key: "merged_invoices_subtotal",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "merged_invoices_subtotalVat",
            render: (amount: Amount) => <span style={{ fontWeight: "bold" }}>{displayPrice(amount)}</span>,
        },
        {
            title: t("DATE"),
            dataIndex: "date",
            key: "merged_invoices_date",
            render: (date: string) => <Date dateString={date} showTime />,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "merged_invoices_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
    ];
    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "INVOICES.NUMBER",
            key: "merged_invoices_docNumber",
        },
        {
            title: "QUANTITY",
            key: "merged_invoices_qty",
        },
        {
            title: "TOTAL_AMOUNT",
            key: "merged_invoices_grandTotal",
        },
        {
            title: "TOTAL_PRICE",
            key: "merged_invoices_subtotal",
        },
        {
            title: "VAT.TITLE",
            key: "merged_invoices_subtotalVat",
        },
        {
            title: "DATE",
            key: "merged_invoices_date",
        },
        {
            title: "CREATED_AT",
            key: "merged_invoices_createdAt",
        },
    ];
    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "number",
            label: t("INVOICES.NUMBER"),
            type: "string",
        },
        {
            key: "clientName",
            label: t("CLIENT.NAME"),
            type: "string",
        },
        {
            type: "int",
            label: t("QUANTITY"),
            key: "qty",
        },
        {
            key: "grandTotal",
            label: t("TOTAL_AMOUNT"),
            type: "int",
        },
        {
            key: "subtotal",
            label: t("TOTAL_PRICE"),
            type: "int",
        },
        {
            key: "subtotalVat",
            label: "VAT",
            type: "int",
        },
        {
            key: "date",
            label: t("DATE"),
            type: "date",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];

    return (
        <Space direction="vertical" size={[30, 30]} className="content-inner">
            <FilteredTable
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: name! }}
                filterKeys={filters}
                loadDataCallback={(request) => mergedInvoicesService.getMergedInvoices(request)}
            />
        </Space>
    );
});
