import React from "react";
import { Button, Row, Space, Typography, Menu, Dropdown, Skeleton, Col, Alert } from "antd";
import { OrderDataController, OrderDataControllerContext } from "../order.data.controller";
import { useRequiredLayers } from "../../../../utils/context";
import { OrderDataStore, OrderDataStoreContext } from "../order.data.store";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../order-status/order.status";
import { Export } from "../../../core/commons/export/export";
import { OrderHeaderDocumentButtons } from "./order.header.document.buttons";
import { Link } from "react-router-dom";

export const OrderHeader: React.FC = observer(() => {
    const { store, controller } = useRequiredLayers<OrderDataStore, OrderDataController>(
        OrderDataStoreContext,
        OrderDataControllerContext
    );
    const { t } = useTranslation("translation", { useSuspense: false });

    return (
        <>
            <Space direction="vertical" size={[20, 20]} style={{ width: "100%" }}>
                <Row justify="space-between" align="middle">
                    {store.isLoading ? (
                        <>
                            <Col style={{ paddingTop: 10 }} xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                                <Skeleton active paragraph={{ rows: 1 }} title={false} />
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                <Skeleton.Button className="order-status-loader" style={{ width: "100%" }} active />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Space
                                wrap
                                align={"center"}
                                style={{ width: "100%", marginBottom: 10, justifyContent: "space-between" }}
                                size={[8, 8]}
                            >
                                <Typography.Title level={1} className="main-title">
                                    {t("ORDERS.ORDER.TITLE")} #{store.order?.id}
                                </Typography.Title>
                                <OrderStatus large status={store.order!.status} />
                            </Space>
                            {store.order?.status === "not_confirmed" ? (
                                <Space direction={"vertical"} size={[0, 0]}>
                                    <Alert message={t("ORDERS.ORDER.NOT_CONFIRMED")} type={"warning"} />
                                </Space>
                            ) : null}
                        </>
                    )}
                </Row>
                {store.isLoading ? (
                    <Space wrap direction="horizontal" size={[10, 5]}>
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                        <Skeleton.Button active />
                    </Space>
                ) : (
                    <Row justify="space-between">
                        {store.order?.status === "not_completed" ? (
                            <Alert
                                showIcon
                                type="info"
                                description={<Link to="/cart/checkout">{t("CLIENT.CONTINUE_CHECKOUT")}</Link>}
                                style={{ marginBottom: 5 }}
                                message={t("ADVANCED_ORDER.CONTINUE")}
                            />
                        ) : null}
                        <OrderHeaderDocumentButtons />
                        {}
                    </Row>
                )}
            </Space>
            {store.exportInvoice ? (
                <Export
                    title={"ORDERS.ORDER.DOWNLOAD_CSV_INVOICE"}
                    isShown={store.exportModalShown}
                    withBasicExportFields
                    config={{
                        link: "/api/v3/documents/invoices/" + store.exportInvoice,
                    }}
                    onClose={controller.closeExportModal}
                />
            ) : null}
        </>
    );
});
