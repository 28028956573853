import {Announcement} from "../../models/announcements/announcement";
import {apiService} from "../api.service";
import {PaginatedRequest} from "../../models/requests/paginated-request/paginated.request";
import {PaginatedResult} from "../../models/requests/paginated-result/paginated.result";

export class AnnouncementsService {

    public async getAnnouncement(id: number): Promise<Announcement> {
        return (await apiService.get<Announcement>("announcements/" + id)).data;
    }

    public async getAnnouncements(request: PaginatedRequest, type: string): Promise<PaginatedResult<Announcement>> {
        return (await apiService.get<PaginatedResult<Announcement>>("announcements/list/" + type, {params: request.toParams()})).data;
    }

    public async getUnreadAnnouncements(): Promise<Announcement[]> {
        return (await apiService.get<Announcement[]>("announcements/unread")).data;
    }

    public async getInCartAnnouncements(): Promise<Announcement[]> {
        return (await apiService.get<Announcement[]>("announcements/cart")).data;
    }

    public async markNotified(id: number): Promise<unknown> {
        return (await apiService.post("announcements/" + id + '/notified')).data;
    }

    public async markViewed(id: number): Promise<unknown> {
        return (await apiService.post("announcements/" + id + '/viewed')).data;
    }
}

export const announcementsService = new AnnouncementsService();
