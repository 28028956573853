import { NotCompletedOrder } from "../../../models/cart/checkout/not.completed.order";
import { apiService } from "../../api.service";
import { OrdersCheckoutRequest } from "../../../models/cart/checkout/request/apply.request";
import {
    CartCheckoutShipping,
    CartCheckoutShippingQuery,
} from "../../../models/cart/checkout/shipping/cart.checkout.shipping";

export class CartCheckoutService {
    public async getNotCompletedOrders(billingData?: { [key: string]: any }): Promise<NotCompletedOrder[]> {
        return (
            await apiService.get<NotCompletedOrder[]>("/orders/not-completed", {
                params: { ...billingData },
            })
        ).data;
    }

    public async getCalculatedNotCompletedOrder(
        id: number,
        billingData?: { [key: string]: any }
    ): Promise<NotCompletedOrder> {
        return (
            await apiService.get<NotCompletedOrder>("orders/not-completed/" + id, {
                params: { ...billingData },
            })
        ).data;
    }

    public async getShippings(orderId: number, query: CartCheckoutShippingQuery): Promise<CartCheckoutShipping[]> {
        return (
            await apiService.get<CartCheckoutShipping[]>("shipping/prices/order/" + orderId + "/prices", {
                params: query,
            })
        ).data;
    }

    public async completeOrder(orderId: number, request: OrdersCheckoutRequest): Promise<void> {
        return (await apiService.post<void>("orders/not-completed/" + orderId, { ...request })).data;
    }

    public async cancelOrder(orderId: number, params?: { move: "1" | "0" }): Promise<void> {
        return (await apiService.delete<void>("orders/not-completed/" + orderId, { params })).data;
    }
}

export const cartCheckoutService = new CartCheckoutService();
