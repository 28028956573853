import {MergedInvoiceStore} from "./merged.invoice.store";
import {MergedInvoicesService} from "../../../services/invoices/merged.invoices.service";

export class MergedInvoiceController {
    constructor(
        private readonly _id: string,
        private readonly _mergedInvoiceStore: MergedInvoiceStore,
        private readonly _mergedInvoiceService: MergedInvoicesService
    ) {
        this.loadData();
    }

    private async loadData(): Promise<void> {
        this._mergedInvoiceStore.setLoading(true);
        this._mergedInvoiceStore.setInvoice(await this._mergedInvoiceService.getMergedInvoice(this._id));
        this._mergedInvoiceStore.setLoading(false);
    }
}