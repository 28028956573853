import React, { useContext, useEffect, useMemo } from "react";
import { Route, Redirect } from "react-router-dom";
import { RootStoreContext } from "../../../stores/root/root";
import { RouteConfig } from "../../../routers/routers";
import { isAllowed } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

interface ExtendedRouteProps {
    authRequired?: boolean;
    component: any;
    path: string;
    exact?: boolean;
    systemName?: string;
    routeChildren?: RouteConfig[];
    access?: string;
    name?: string;
    disallowAttributes?: string[];
    configData?: { [key: string]: any };
}

export const ExtendedRoute: React.FC<ExtendedRouteProps> = ({
    component,
    exact,
    authRequired,
    path,
    systemName,
    routeChildren,
    configData,
    disallowAttributes = [],
    access,
    name,
    ...rest
}) => {
    const Component = component;
    const { authStore } = useContext(RootStoreContext);
    const { t } = useTranslation("translation", { useSuspense: false });

    useEffect(() => {
        document.body.style.height = path === "/catalog" ? "auto" : "100%";
        document.title = systemName ? t(systemName) + " - FEBEST Wholesale System" : "FEBEST Wholesale System";
    }, [t]);

    // if (authRequired && !authStore.hasToken) {
    //     return <Redirect to={"/login"} />;
    // }
    const redirect = useMemo(
        () => disallowAttributes.some((item) => authStore.attributes.includes(item)),
        [authStore.attributes, disallowAttributes]
    );

    if (redirect) {
        return <Redirect to={"/"} />;
    }

    return (
        <Route {...rest} exact={exact} path={path}>
            {isAllowed(authStore.attributes, access) ? (
                <Component requiredConfigs={configData} name={name} routeChildren={routeChildren} />
            ) : (
                <Redirect to={"/"} />
            )}
        </Route>
    );
};
