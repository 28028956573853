import axios from 'axios';

declare module 'axios' {
    export interface AxiosRequestConfig {
        isAuthRequired?: boolean;
    }
}

const API = axios.create();
API.defaults.baseURL = '/api/v3';
API.defaults.isAuthRequired = true;

export default API;
