import {SystemUser} from "../user/user";

export type ApplicationType = "order" | "offer"

export enum ApplicationStatus
{
    PENDING   = "pending",
    ACCEPTED  = "accepted",
    REJECTED  = "rejected",
    SUBMITTED = "submitted",
    FEEDBACK_RECEIVED   = "feedback_received",
}

export const applicationStatusColor: Record<ApplicationStatus, string> =
    {
        [ApplicationStatus.PENDING]           : "gold",
        [ApplicationStatus.ACCEPTED]          : "green",
        [ApplicationStatus.REJECTED]          : "red",
        [ApplicationStatus.SUBMITTED]         : "gold",
        [ApplicationStatus.FEEDBACK_RECEIVED] : "green",
    }

export interface Application
{
    id: number;
    type: ApplicationType;
    status: ApplicationStatus;
    OENumber: string;
    description: string;
    justification: string;
    qty: number;
    price: string;
    user: SystemUser;
    createdAt: string;
}