import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ComponentPropsFromRoute} from "../../../routers/routers";
import {Row, Space, Typography} from "antd";
import {ColumnsType} from "antd/es/table";
import {Claim} from "../../../models/claims/claims";
import i18n from "i18next";
import {claimService} from "../../../services/claims/claims.service";
import {columnsFactory} from "./claim.list.columns";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import {FilterDataController} from "../../../controllers/filtered-table/filter.data.controller";
import {Link} from "react-router-dom";
import {ColumnsConfig, convertTableColumnsToColumnConfigs} from "../../../utils/table";
import {FilterKey} from "../../../models/filtered-table/filtered.table";
import {filterKeysFactory} from "./claim.list.filter-keys";

export const ClaimList: React.FC<ComponentPropsFromRoute> = ({name}) => {

    const {t} = useTranslation("translation", {useSuspense: false});

    const columns: ColumnsType<Claim> = useMemo(() => columnsFactory(t), [i18n.language]);
    const columnsConfigs: ColumnsConfig = useMemo(() => convertTableColumnsToColumnConfigs<Claim>(columns), [i18n.language])

    const fcgConfigs: FilterKey[] = useMemo(() => filterKeysFactory(t), []);
    const dataHandler: FilterDataController<Claim> = useMemo(() => new FilterDataController(request => claimService.getAll(request)), []);

    return (
        <div className="page_content">
            <Space direction="vertical" size={[20, 20]} className="content">
                <Typography.Title level={1} className="main-title">
                    {t("CLAIMS.TITLE")}
                </Typography.Title>
                <Space direction={"horizontal"} size={[10, 10]}>
                    <>
                        <Link to="/claims/new" className="ant-btn ant-btn-primary">
                            {t("CLAIMS.CREATE.TITLE")}
                        </Link>
                    </>
                </Space>
                <FilteredTable
                    columns={columns}
                    columnsConfigs={{columns: columnsConfigs, pageKey: name!}}
                    dataHandler={dataHandler}
                    filterKeys={fcgConfigs}
                />
            </Space>
        </div>
    );
}
